import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Document } from '@prismicio/client/types/documents';
import { TrackingPayload } from '../types/tracking.type';
import { Slice } from '../types/prismic.type';
import { getExperiments } from '../utils/optimize';
import { getUtm } from '../utils/analytics';
import useGeoCountry from './useGeoCountry';

interface useTrackingPayloadProps {
  slice?: Slice['slice'];
  cluster?: Document['data'];
  vertical?: Document['data'];
  product?: Document['data'];
}

export const useTrackingPayload = ({
  slice, cluster, vertical, product,
}: useTrackingPayloadProps): TrackingPayload => {
  const gclidKey = 'gclid';
  const utm = getUtm();
  const router = useRouter();
  const experiments = getExperiments();
  const geoCountry = useGeoCountry();
  const [params, setParams] = useState<TrackingPayload>({
    context: {
      platform: 'Web',
      slice: slice?.slice_type,
      slice_variant: slice?.variation,
    },
    product: {
      name: cluster?.name ?? vertical?.name,
      business_unit: cluster?.businessUnit ?? vertical?.name,
      vertical: vertical?.name,
      start_date: product?.startDate,
      end_date: product?.endDate,
    },
    geo_country: '',
    experiments,
  });

  useEffect(() => {
    const gclid = (router?.query[gclidKey] as string) || undefined;

    setParams((prevParams) => ({
      ...prevParams,
      geo_country: geoCountry,
      tracking: {
        gclid,
        utm: Object.keys(utm).length ? utm : undefined,
      },
    }));
  }, [router, geoCountry]); // eslint-disable-line react-hooks/exhaustive-deps

  return params;
};
