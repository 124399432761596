import classNames from 'classnames';
import { nanoid } from 'nanoid';
import Image from 'next/image';
import { getColor } from '../../../utils/colors';
import { scrollTo } from '../../../utils/common';

const HeroWithPills = ({ slice }) => {
  const vertical = slice?._vertical?.data;
  const pillColor = getColor(slice.primary.pillColor || vertical?.color);

  return (
    <section className="relative overflow-hidden">
      <div className="w-full top-0 left-0">
        {slice.primary?.backgroundImage?.url && (
        <div className="w-full">
          <Image
            className="object-cover w-full"
            src={slice.primary.backgroundImage.url}
            alt={slice.primary.backgroundImage.alt}
            layout="fill"
            objectFit="cover"
            objectPosition="center center"
            priority
          />
        </div>
        )}
        <div className="w-full h-screen absolute top-0 left-0 z-0 bg-black-1/85" />
      </div>
      <div className="lg:m-auto lg:max-w-7xl 2xl:max-w-8xl 3xl:max-w-8x5l">
        <div className="relative flex flex-col items-center py-17.5 px-4
      lg:p-0 lg:w-8/12 lg:m-auto lg:my-20 lg:items-start
      2xl:my-37 2xl:w-6/12 2xl:ml-77
      3xl:my-47 3xl:w-5/12 3xl:ml-91"
        >
          <div className={classNames(
            pillColor.bg,
            pillColor.textContrast,
            'rounded-4xl flex items-center font-medium text-center',
            'text-sm h-8 justify-center leading-6 px-4 py-1.5 inline-block',
            'lg:text-lg',
            '3xl:px-5 3xl:py-2 3xl:text-2xl 3xl:min-h-[2.938rem]',
          )}
          >
            {slice.primary.pillText}
          </div>
          <div className="text-white-1 text-h4 text-center font-bold pt-4 pb-8
        lg:text-left lg:text-6.7xl lg:py-6"
          >
            {slice.primary.title}
          </div>
          <div className="flex flex-col items-center lg:flex-row">
            {slice.items?.map((item) => (
              <div
                key={nanoid()}
                className="text-white-2 rounded-4xl border-white-1 font-medium
                text-xs border mb-1.5 px-3.5 py-1.25 text-center inline-block
                lg:mb-0 lg:mr-3.7 lg:text-sm lg:px-4 lg:py-1.5"
              >
                {item.repeatablePillText}
              </div>
            ))}
          </div>
          <div className="mt-15.5 w-full lg:w-auto flex flex-col lg:flex-row items-center">
            <button
              type="button"
              onClick={() => scrollTo({ id: slice.primary.buttonScrollTo })}
              className={classNames(
                'font-bold bg-white-1 gap-3 text-black-1 rounded-x inline-block',
                'flex-none leading-5 py-3 px-6 text-base w-full text-center mb-4',
                'lg:mb-0 lg:mr-3 lg:w-auto lg:min-w-20',
                '3xl:text-lg 3xl:min-w-25 3xl:py-4 3xl:mr-4',
              )}
            >
              {slice.primary.buttonLabel}
            </button>
            <button
              type="button"
              onClick={() => scrollTo({ id: slice.primary.button2ScrollTo })}
              className={classNames(
                'font-bold bg-transparent border-white-1 border-2 text-white-1 rounded-x inline-block',
                'flex-none leading-5 py-3 px-6 text-base w-full text-center gap-3',
                'lg:w-auto lg:min-w-20',
                '2xl:py-3',
                '3xl:text-lg 3xl:min-w-25 3xl:py-4',
              )}
            >
              {slice.primary.button2Label}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};
export default HeroWithPills;
