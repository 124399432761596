import { nanoid } from 'nanoid';
import { FC } from 'react';
import chunk from 'lodash.chunk';
import Marquee from 'react-easy-marquee';
import Image from 'next/image';
import { RichText } from 'prismic-reactjs';
import { MarqueeImagesProps } from '../Marquee.types';

const Item = ({ image }) => (
  <div className="lg:w-80 text-center">
    {!!image?.url && (
    <div className="max-w-full lg:w-56 relative">
      {image?.url && (
      <Image
        src={image.url}
        alt={image.alt}
        width={image.dimensions.width || '100%'}
        height={image.dimensions.height || '100%'}
        layout="intrinsic"
        objectFit="contain"
      />
      )}
    </div>
    )}
  </div>
);

const RowsDefault = 1;
const marqueeOptions = {
  duration: 30000,
};

const MarqueeImagesWithGrid: FC<MarqueeImagesProps> = ({ slice }) => {
  if (!slice.items?.length) return null;

  const rows = slice.primary?.rows || RowsDefault;
  const itemsGrouped = chunk(slice.items, Math.ceil(slice.items.length / rows));

  return (
    <section className="dh-content-full py-30 lg:px-0 lg:py-24 2xl:py-40 3xl:py-52">
      {!!slice.primary?.title?.length && (
      <header>
        <h2 className="text-2xl mb-16 text-center lg:text-4.5xl lg:mb-20 3xl:text-5xl 3xl:mb-40">
          <RichText render={slice.primary.title} />
        </h2>
      </header>
      )}

      <div className="grid grid-cols-2 gap-x-6 gap-y-8 lg:hidden">
        {slice.items.map(({ image }) => <Item image={image} key={nanoid()} />)}
      </div>

      <div className="hidden lg:block space-y-14">
        {itemsGrouped.map((items, index) => (
          <Marquee {...marqueeOptions} axis="X" reverse={!(index % 2 === 1)} key={nanoid()}>
            {items.map(({ image }) => <Item image={image} key={nanoid()} />)}
          </Marquee>
        ))}
      </div>
    </section>
  );
};

export default MarqueeImagesWithGrid;
