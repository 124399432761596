import { RichTextBlock } from 'prismic-reactjs';
import { Image, Slice } from 'types/prismic.type';

export enum FeatureVariations {
  leftIcons = 'featureLeftIcons',
  rightIcons = 'featureRightIcons',
  withList = 'featureWithList',
  withListLight = 'featureWithListLight',
  withGridList = 'featureWithGridList',
  simpleList = 'featureSimpleList',
}

export type FeatureWithListProps = Slice & {
  slice: {
    primary?: {
      title?: string;
      description?: RichTextBlock[];
      bottomText?: string;
    };
    items?: {
      label?: string;
      text?: RichTextBlock[];
      logo?: Image;
    }[];
  }
}

export type FeatureSimpleListProps = Slice & {
  slice: {
    primary?: {
      title?: string;
    };
    items?: {
      title?: string;
      description?: RichTextBlock[];
    }[];
  }
}
