import { nanoid } from 'nanoid';
import { FC } from 'react';
import chunk from 'lodash.chunk';
import Marquee from 'react-easy-marquee';
import Image from 'next/image';
import { RichText } from 'prismic-reactjs';
import { MarqueeImagesProps } from '../Marquee.types';

const Item = ({ image }) => (
  <div className="w-64 p-x-4 text-center">
    {!!image?.url && (
    <div className="w-40 relative" key={nanoid()}>
      {image?.url && (
      <Image
        src={image.url}
        alt={image.alt}
        width="150"
        height="120"
        layout="responsive"
        objectFit="contain"
      />
      )}
    </div>
    )}
  </div>
);

const RowsDefault = 3;
const marqueeOptions = {
  duration: 30000,
  height: '128px',
};

const MarqueeImages: FC<MarqueeImagesProps> = ({ slice }) => {
  if (!slice.items?.length) return null;

  const rows = slice.primary?.rows || RowsDefault;
  const itemsGrouped = chunk(slice.items, Math.ceil(slice.items.length / rows));

  return (
    <section className="dh-content">
      {!!slice.primary?.title?.length && (
      <header>
        <h2 className="text-2xl mb-10 text-center md:text-4.5xl md:mb-16"><RichText render={slice.primary.title} /></h2>
      </header>
      )}
      <div className="sm:hidden">
        <Marquee {...marqueeOptions} duration={50000} reverse>
          {slice.items.map(({ image }) => <Item image={image} key={nanoid()} />)}
        </Marquee>
      </div>
      <div className="hidden sm:block space-y-14">
        {itemsGrouped.map((items, index) => (
          <Marquee {...marqueeOptions} axis="X" reverse={!(index % 2 === 1)} key={nanoid()}>
            {items.map(({ image }) => <Item image={image} key={nanoid()} />)}
          </Marquee>
        ))}
      </div>
    </section>
  );
};

export default MarqueeImages;
