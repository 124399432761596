import { RichText } from 'prismic-reactjs';
import { nanoid } from 'nanoid';
import { FC } from 'react';
import Image from 'next/image';
import classNames from 'classnames';
import { GridListsWithImageProps } from '../GridLists.types';

const GridListsWithImage: FC<GridListsWithImageProps> = ({ slice }) => (slice?.items?.length ? (
  <section className={classNames(
    'bg-white text-gray-1',
    { 'hidden lg:block': slice.primary?.hiddenOnMobile },
  )}
  >
    <div className="dh-content">
      {!!slice.primary?.title && (
        <header>
          <h2 className="text-3.5xl text-center mb-12">
            <RichText render={slice.primary.title} />
          </h2>
        </header>
      )}
      <div className="flex flex-wrap justify-center gap-28 lg:gap-10">
        {slice.items.map((item) => (
          <div
            key={nanoid()}
            className="space-y-4 w-80 lg:w-64"
          >
            {item.image?.url && (
            <div className="w-25 h-25 m-auto relative rounded-2xl overflow-hidden">
              <Image
                src={item.image.url}
                alt={item.image.alt}
                layout="fill"
                objectFit="cover"
              />
            </div>
            )}
            {!!item.title?.length && (
              <div className="font-semibold text-h5">
                <RichText render={item.title} />
              </div>
            )}
            <div className="text-base leading-7 font-archivo">
              {!!item.description?.length && RichText.render(item.description)}
            </div>
          </div>
        ))}
      </div>
    </div>
  </section>
) : null);

export default GridListsWithImage;
