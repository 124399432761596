import { useState, useEffect, FunctionComponent } from 'react';
import classnames from 'classnames';
import { Link } from 'prismic-reactjs';
import { useRouter } from 'next/router';
import { nanoid } from 'nanoid';
import dynamic from 'next/dynamic';
import { Color, getColor } from '../../../utils/colors';
import {
  DATE_LOCALES,
  getCurrentDateLocale,
  parseDate,
} from '../../../utils/dates';
import {
  getRealLocale, getVerticalByLink, prismicLinkResolver,
} from '../../../utils/common';

const LinkWithQuery = dynamic(() => import('../../../components/LinkWithQuery'), {
  ssr: false,
});
const ReactCarousel = dynamic(() => import('../../../components/ReactCarousel'), {
  ssr: false,
});

type CarouselItem = {
  program: string;
  title: string;
  dateStart: string;
  dateEnd: string;
  days: string;
  hours: string;
  quota: string;
  price: string;
  link: string;
  linkLabel: string;
};

interface CarouselItemProps {
  item: CarouselItem;
  lang: string;
  className?: string;
  color: Color;
}

const dateFromat = (date: string, language: string): string => {
  const locale = getCurrentDateLocale(DATE_LOCALES, language);

  return parseDate(date, locale, 'd MMM');
};

const Item: FunctionComponent<CarouselItemProps> = ({
  item,
  lang,
  className,
  color,
}) => (
  <div
    className={classnames(
      'grid w-full h-full py-5 px-8 rounded-2xl border bg-gray-4',
      'lg:px-6 xl:px-8',
      color.border,
      className,
    )}
  >
    {item.title && (
    <h3 className="uppercase tracking-widest-xl text-white text-base">
      {item.title}
    </h3>
    )}
    {item.dateStart && (
    <div className={classnames('uppercase text-2xl my-4', `${color.text}`)}>
      <span>{dateFromat(item.dateStart, lang)}</span>
      {item.dateEnd && (
      <>
        <span> - </span>
        <span>{dateFromat(item.dateEnd, lang)}</span>
      </>
      )}
    </div>
    )}
    <div className="text-left text-base text-white">
      {item.days && <p className="font-semibold">{item.days}</p>}
      {item.hours && <p className="my-3">{item.hours}</p>}

      {item.quota && <p className="mt-3 text-xs">{item.quota}</p>}

      {item.price && <p className="text-3xl">{item.price}</p>}
    </div>

    {item.link && item.linkLabel && (
    <LinkWithQuery
      href={Link.url(item.link, prismicLinkResolver)}
      className={classnames('dh-button', color.bg, color.bgHover)}
    >
      {item.linkLabel}
    </LinkWithQuery>
    )}
  </div>
);

const getAvailableItems = (items) => items?.filter(({ dateStart }) => {
  const date = new Date(dateStart).getTime();
  const now = new Date().getTime();

  return now <= date;
});

const getColorByVertical = (link, verticals, clusters) => {
  const { color: verticalColor } = getVerticalByLink(link, verticals, clusters);
  return getColor(verticalColor);
};

const carouselOptions = {
  isRTL: false,
  pagination: true,
};

const CarouselWithPrice = ({ slice }) => {
  const items = getAvailableItems(slice?.items);
  const [lang, setLang] = useState<string>('es');
  const router = useRouter();
  const verticals = slice._verticals ?? [slice._vertical];
  const clusters = slice._clusters ?? [slice._cluster];

  useEffect(() => {
    if (router?.locale) setLang(getRealLocale(router.locale));
  }, [router]);

  return (
    <div
      id={slice.primary?.title}
      className="text-center bg-white dark:bg-blueGray-900"
    >
      <div className="dh-content">
        {slice.primary?.title && (
          <h2 className="dh-title">{slice.primary.title}</h2>
        )}

        <div className="my-8">
          {!!items?.length && (
            <>
              <div className="hidden lg:block">
                <ReactCarousel options={carouselOptions}>
                  {items.map((item) => (
                    <Item
                      key={nanoid()}
                      item={item}
                      lang={lang}
                      color={getColorByVertical(item.link, verticals, clusters)}
                    />
                  ))}
                </ReactCarousel>
              </div>
              <div className="lg:hidden">
                {items.map((item) => (
                  <Item
                    key={nanoid()}
                    item={item}
                    lang={lang}
                    className="my-4"
                    color={getColorByVertical(item.link, verticals, clusters)}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CarouselWithPrice;
