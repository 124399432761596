import {
  FC, useEffect, useState, Fragment,
} from 'react';
import { RichText } from 'prismic-reactjs';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import { VideoYoutubeTwoColsProps } from '../Video.types';
import ImagePreview, { getPreviewProps } from './components/ImagePreview';

const VideoYoutubeRwoCols: FC<VideoYoutubeTwoColsProps> = ({ slice }) => {
  const [videoProps, setVideoProps] = useState<any>();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setVideoProps(getPreviewProps(slice?.primary?.videoLink?.url));
  }, [slice]);

  if (!videoProps?.video) return null;

  return (
    <section className="bg-gray-1">
      <div className="dh-content grid justify-center text-center">
        {slice.primary?.title && (
          <header>
            <h2 className="text-athensGray font-semibold text-3.5xl pb-4 sm:pb-12">
              {slice.primary?.title}
            </h2>
          </header>
        )}
        <div className="grid gap-4 justify-center sm:grid-cols-2">
          <div className="overflow-hidden">
            <ImagePreview
              image={videoProps.preview}
              play={() => { setOpen(true); }}
            />
          </div>
          <aside className="text-white-1">
            <h3 className="pb-4 text-athensGray font-semibold text-3xl sm:pb-12 text-left">
              {slice.primary?.videoTitle}
            </h3>
            <div className="text-left">
              <RichText render={slice.primary?.videoDescription} />
            </div>
          </aside>
        </div>
      </div>

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 flex z-40 justify-center items-center" onClose={() => setOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black/80" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="w-11/12 h-2/5 z-30 sm:w-10/12 sm:h-3/4">
              <button
                type="button"
                onClick={() => setOpen(false)}
                className="px-3 py-3 text-white-1 place-self-end z-50"
              >
                <XIcon className="h-5 w-5 text-white-1" aria-hidden="true" />
              </button>
              <iframe
                className="w-full h-full rounded-2xl overflow-hidden"
                src={videoProps.video.url}
                title={slice.primary?.title || 'YouTube video player'}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>
    </section>
  );
};

export default VideoYoutubeRwoCols;
