import { Document } from '@prismicio/client/types/documents';
import { Link, RichTextBlock } from 'prismic-reactjs';
import { CountryCodes } from '@digitalhouse-tech/ts-lib-checkout-url-mapper/.dist/util/countryMap';
import { ProductDocument } from 'types/product.type';
import { IOffer, IProduct, IProductGroup } from '../../graphql/model/interfaces';
import { Image, Slice } from '../../types/prismic.type';

export enum GridListsVariations {
  twoColumnsWithImage = 'gridListsTwoColumnsWithImage',
  withIcon = 'gridListsWithIcon',
  withIconSimple = 'gridListsWithIconSimple',
  withIconDark = 'gridListsWithIconDark',
  withIconLight = 'gridListsWithIconLight',
  withIconCentered = 'gridListsWithIconCentered',
  fiveColumns = 'gridListsFiveColumns',
  withOnlyText = 'gridListsWithOnlyText',
  withImage = 'gridListsWithImage',
  withFilter = 'gridListsWithFilter',
  cardAnimated = 'gridListsCardAnimated',
  cta = 'gridListsCta',
  productsCheckout = 'gridListsProductsCheckout',
  products = 'gridListsProducts',
  profiles = 'gridListsProfiles',
}

type SliceWithTitle = Slice & {
  slice: {
    primary?: {
      title?: string;
    };
    items?: object;
  }
}

export type GridListsWithIconSimpleProps = SliceWithTitle & {
  slice: {
    items?: {
      icon?: string;
      description?: RichTextBlock[];
    }[];
  }
}

export type GridListsWithIconProps = SliceWithTitle & {
  slice: {
    items?: {
      icon?: string;
      title?: string;
      description?: RichTextBlock[];
    }[];
  }
}

export type GridListsWithOnlyTextProps = SliceWithTitle & {
  slice: {
    items?: {
      title?: string;
      description?: RichTextBlock[];
    }[]
  }
}

export type GridListsTwoColumnsWithImageProps = Slice & {
  slice: {
    primary?: {
      applyButtonLabel?: string;
      applyFragmentIdentifier?: string;
    },
    items?: {
      image?: Image;
      title?: string;
      subtitle?: string;
      description?: RichTextBlock[];
      termsAndConditionsLabel?: string;
      termsAndConditionsText?: RichTextBlock[];
    }[]
  }
}

export type GridListsWithImageProps = Slice & {
  slice: {
    primary?: {
      title?: RichTextBlock[];
      hiddenOnMobile?: boolean;
    },
    items?: {
      image?: Image;
      title?: RichTextBlock[];
      description?: RichTextBlock[];
    }[]
  }
}

export type GridListsWithFilterProps = Slice & {
  slice: {
    primary?: {
      blankStateMessage: RichTextBlock[];
    };
    items?: {
      vertical?: Document;
      logo?: Image;
      title?: string;
      dateTime?: string;
      description?: RichTextBlock[];
      buttonLink?: string;
      buttonLabel?: string;
    }[];
    _verticals: Document[];
  }
}

export type GridListsCardAnimatedProps = Slice & {
  slice: {
    primary?: {
      title: string;
      highlightedText: string;
      video: Link;
    };
    items?: {
      image?: Image;
      title?: string;
      description?: RichTextBlock[];
      color?: string;
    }[];
  }
}

export type GridListsCTAProps = Slice & {
  slice: {
    primary?: {
      title?: RichTextBlock[];
      highlightedText?: string;
    },
    items?: {
      color?: string;
      image?: Image;
      title?: RichTextBlock[];
      buttonLabel?: string;
      buttonLink?: Link;
    }[]
  }
}

export interface ICheckout {
  wareId: string;
  groupId: string;
  defaultOffer: IOffer;
  country: CountryCodes;
  sandbox: boolean;
}

export type GridListsProductsProps = Slice & {
  slice: {
    primary: {
      color?: string;
      title?: string;
      description?: RichTextBlock[];
      dateIcon?: Image,
      dateText?: string,
      scheduleDaysIcon?: Image,
      scheduleDaysText?: string,
      scheduleHoursIcon?: Image,
      scheduleHoursText?: string,
      buttonBookLabel?: string,
      buttonSoldoutLabel?: string,
      buttonScrollTo?: string;
      lastplaceLabel?: string,
    },
    _checkout: ICheckout & IProduct & {
      name: string;
      products?: IProductGroup[];
      defaultOffer?: IOffer & {
        currency: {
          symbol: string;
          code: CountryCodes;
        }
      };
    }
    _products?: ProductDocument[];
  }
}

export type GridListsProductsCheckoutProps = Slice & {
  slice: {
    primary: {
      color?: string;
      title?: string;
      description?: RichTextBlock[];
      modalityIcon?: Image,
      modalityText?: string,
      dateStartIcon?: Image,
      dateStartText?: string,
      shiftIcon?: Image,
      shiftText?: string,
      daysText?: string,
      daysIcon?: Image,
      scheduleText?: string,
      scheduleIcon: Image,
      buttonBookLabel?: string,
      buttonSoldoutLabel?: string,
      lastplaceLabel?: string,
    },
    _checkout: ICheckout & IProduct & {
      name: string;
      products?: IProductGroup[];
      defaultOffer?: IOffer & {
        currency: {
          symbol: string;
          code: CountryCodes;
        }
      };
    }
    _products?: ProductDocument[];
  }
}

export type GridListsProfilesProps = Slice & {
  slice: {
    primary?: {
      title?: RichTextBlock[];
    },
    items?: {
      image?: Image;
      name?: string;
      role?: string;
      company?: Image;
      link?: Link;
      linkLabel?: string;
    }[]
  }
}
