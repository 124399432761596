import { TabsVariations } from './Tabs.types';
import Pricing from '../Pricing';
import TabsProductCardCheckout from './variations/TabsProductCardCheckout';
import TabsWithAccordionOnMobile from './variations/TabsWithAccordionOnMobile';

const Stats = ({ slice }) => {
  switch (slice.variation) {
    case TabsVariations.withAccordionOnMobile:
      return <TabsWithAccordionOnMobile slice={slice} />;
    case TabsVariations.productCardCheckout:
      return <TabsProductCardCheckout slice={slice} />;
    default:
      return null;
  }
};

export default Stats;
