import { useCallback, useState } from 'react';
import classNames from 'classnames';
import { Link, RichText } from 'prismic-reactjs';
import { useIntl } from 'react-intl';
import { nanoid } from 'nanoid';
import { CheckCircleIcon } from '@heroicons/react/outline';
import { SubmitHandler, useForm } from 'react-hook-form';
import set from 'lodash.set';
import cloneDeep from 'lodash.clonedeep';
import dynamic from 'next/dynamic';
import { useTrackingPayload } from '../../../hooks/useTrackPayload';
import { getDefaultText, prismicLinkResolver, slugify } from '../../../utils/common';
import { getColor } from '../../../utils/colors';
import useLocale from '../../../hooks/useLocale';
import { TrackEvents } from '../../../utils/constants';
import { useAnalytics } from '../../../hooks/useAnalytics';

const LinkWithQuery = dynamic(() => import('../../../components/LinkWithQuery'), {
  ssr: false,
});
const FormInput = dynamic(() => import('../../../components/FormInput'), {
  ssr: false,
});
const Toast = dynamic(() => import('../../../components/Toast'), {
  ssr: false,
});

interface ContactForm {
  name: string;
  lastname: string;
  email: string;
  phonecode: string;
  phone: string;
  cluster: string;
  tyc: boolean;
  ryp: boolean;
}

const FormContactClusterSelect = ({ slice }) => {
  const { track, identify } = useAnalytics();
  const intl = useIntl();
  const locale = useLocale();
  const [showToast, setShowToast] = useState<boolean>(false);
  const clusters = slice._clusters;
  const verticals = slice._verticals;
  const vertical = slice._verticals?.data;
  const color = getColor(vertical?.color);
  const { register, handleSubmit, reset } = useForm<ContactForm>();
  const trackPayload = useTrackingPayload({ slice, vertical });
  const showRules = !!(slice.primary.textAgree && slice.primary.label1 && slice.primary.link1?.uid);

  const defaultMessages = {
    nameLabel: intl.formatMessage({ defaultMessage: 'Nombre' }),
    lastnameLabel: intl.formatMessage({ defaultMessage: 'Apellido' }),
    emailLabel: intl.formatMessage({ defaultMessage: 'E-mail' }),
    phoneCodeLabel: intl.formatMessage({ defaultMessage: 'Código de Área' }),
    phoneLabel: intl.formatMessage({ defaultMessage: 'Teléfono' }),
    phonePlaceholder: intl.formatMessage({ defaultMessage: '11 0000 0000' }),
    clusterLabel: intl.formatMessage({ defaultMessage: 'Curso de interés' }),
    clusterPlaceholder: intl.formatMessage({ defaultMessage: 'Elegir curso' }),
    sendButtonLabel: intl.formatMessage({ defaultMessage: 'Aplicar ahora' }),
  };

  const formTrack = useCallback((
    localeCountry: string,
    firstName: string,
    lastName: string,
    email: string,
    phoneCode: string,
    phone: string,
    clusterName: string,
  ) => {
    const fields = {
      locale_country: localeCountry,
      first_name: firstName,
      last_name: lastName,
      email,
      phone: `${phoneCode}${phone}`,
    };

    identify(fields);

    const cluster = clusters?.find((c) => c.data?.name === clusterName);
    const verticalData = verticals?.find((c) => c.uid === cluster.data?.verticalPage?.uid);
    const payload = cloneDeep(trackPayload);
    set(payload, 'product_name', cluster?.data?.name);
    set(payload, 'product.name', cluster?.data?.name);
    set(payload, 'product.business_unit', cluster?.data?.businessUnit);
    set(payload, 'product.vertical', verticalData?.data?.name);
    set(payload, 'fields', fields);

    track(TrackEvents.productApplicationSubmitted, payload, locale, true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackPayload]);

  const onSubmit: SubmitHandler<ContactForm> = ({
    name, lastname, email, phonecode, phone, cluster,
  }) => {
    formTrack(
      locale,
      name,
      lastname,
      email,
      phonecode,
      phone,
      cluster,
    );

    setShowToast(true);
    reset();
  };

  return (
    <>
      <section className="bg-gray-900">
        <div className="dh-content grid lg:grid-cols-2">
          <header className="max-w-3xl mx-auto lg:py-20 lg:pr-40 text-left">
            {slice.primary?.title && (
              <h2 id={slugify(slice.primary.title)} className="dh-title mb-11 text-3xl text-white">
                {slice.primary.title}
              </h2>
            )}
            {slice.primary?.description && (
              <div className="dh-description mb-14 text-white text-sm font-normal leading-6">
                <RichText render={slice.primary.description} />
              </div>
            )}
          </header>
          <div className="bg-codGray-900 rounded px-5 sm:px-10 py-12">
            <form onSubmit={handleSubmit(onSubmit)}>
              {slice.primary?.formTitle && (
                <h3 className="text-2xl text-center text-athensGray-100 leading-9 font-bold pb-4">
                  {slice.primary?.formTitle}
                </h3>
              )}
              <div className="sm:rounded-md text-gray-300">
                <div className="py-5 sm:py-6">
                  <div className="grid grid-cols-1 gap-6">
                    <div>
                      <FormInput
                        darkSupport
                        label={getDefaultText('nameLabel', slice.primary, defaultMessages)}
                        placeholder={slice.primary?.namePlaceholder}
                        id="name"
                        required
                        register={register('name', {
                          required: true,
                        })}
                      />
                    </div>

                    <div>
                      <FormInput
                        darkSupport
                        label={getDefaultText('lastnameLabel', slice.primary, defaultMessages)}
                        placeholder={slice.primary?.lastnamePlaceholder}
                        id="lastname"
                        required
                        register={register('lastname', {
                          required: true,
                        })}
                      />
                    </div>

                    <div>
                      <FormInput
                        darkSupport
                        label={getDefaultText('emailLabel', slice.primary, defaultMessages)}
                        placeholder={slice.primary?.emailPlaceholder}
                        id="email"
                        type="email"
                        required
                        register={register('email', {
                          required: true,
                          pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
                        })}
                      />
                    </div>

                    <div className="flex gap-6">
                      <div className="flex-none">
                        <FormInput
                          label={getDefaultText('phoneCodeLabel', slice.primary, defaultMessages)}
                          placeholder={slice.primary?.phoneCodePlaceholder}
                          id="phonecode"
                          className="w-20 lg:w-24"
                          register={register('phonecode', {
                            required: false,
                          })}
                        />
                      </div>

                      <div className="grow">
                        <FormInput
                          darkSupport
                          label={getDefaultText('phoneLabel', slice.primary, defaultMessages)}
                          placeholder={getDefaultText('phonePlaceholder', slice.primary, defaultMessages)}
                          id="phone"
                          register={register('phone', {
                            required: false,
                          })}
                        />
                      </div>
                    </div>

                    {clusters && (
                      <div>
                        <label
                          htmlFor="cluster"
                          className="text-white-3 block text-xs font-semibold mb-2"
                        >
                          {getDefaultText('clusterLabel', slice.primary, defaultMessages)}
                        </label>
                        <select
                          id="cluster"
                          defaultValue=""
                          className={classNames(
                            'h-10 block w-full pl-3 pr-10 py-2 text-base sm:text-sm rounded-md border',
                            'text-gray-900 bg-white placeholder-gray-800 border-black',
                            'focus:ring-gray-800 focus:border-gray-500',
                          )}
                          required
                          {
                            ...register('cluster', {
                              required: true,
                            })
                          }
                        >
                          <option key="select" value="" disabled>
                            {getDefaultText('clusterPlaceholder', slice.primary, defaultMessages)}
                          </option>
                          {clusters?.map(({ data }) => (
                            <option key={nanoid()} value={data.name}>
                              {data.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  </div>
                </div>
                {!!slice.primary?.termsAndConditionsLink?.link_type && (
                  <div className="py-3 text-right">
                    <div className="relative flex items-start">
                      <div className="flex items-center h-5">
                        <input
                          id="tyc"
                          aria-describedby="agree-terms-and-conditions"
                          type="checkbox"
                          className="focus:ring-grey-500 h-4 w-4 text-gey-600 border-gray-300 rounded"
                          required
                          {
                            ...register('tyc', {
                              required: true,
                            })
                          }
                        />
                      </div>
                      <div className="ml-3 text-sm font-normal">
                        <label
                          htmlFor="tyc"
                          className="mr-2 text-white"
                        >
                          {intl.formatMessage({
                            defaultMessage: 'Aceptar',
                          })}
                        </label>
                        <LinkWithQuery
                          href={Link.url(
                            slice.primary.termsAndConditionsLink,
                            prismicLinkResolver,
                          )}
                          target="_blank"
                          className={classNames('underline', color.text)}
                        >
                          <span className="sr-only">
                            {intl.formatMessage({
                              defaultMessage: 'Aceptar',
                            })}
                            {' '}
                          </span>
                          {intl.formatMessage({
                            defaultMessage: 'Términos y condiciones',
                          })}
                        </LinkWithQuery>
                      </div>
                    </div>
                  </div>
                )}
                {showRules && (
                  <div className="py-3 text-right">
                    <div className="relative flex items-start">
                      <div className="flex items-center h-5">
                        <input
                          id="ryp"
                          aria-describedby="agree-terms-and-conditions"
                          type="checkbox"
                          className="focus:ring-grey-500 h-4 w-4 text-gey-600 border-gray-300 rounded"
                          required
                          {
                            ...register('ryp', {
                              required: true,
                            })
                          }
                        />
                      </div>
                      <div className="ml-3 text-sm font-normal">
                        <label
                          htmlFor="ryp"
                          className="mr-2 text-white"
                        >
                          {slice.primary.textAgree}
                        </label>
                        <LinkWithQuery
                          href={Link.url(
                            slice.primary.link1,
                            prismicLinkResolver,
                          )}
                          target="_blank"
                          className={classNames('underline', color.text)}
                        >
                          <span>
                            {slice.primary.label1}
                            {' '}
                          </span>
                        </LinkWithQuery>
                        <label
                          htmlFor="ryp"
                          className="mx-2 text-white-2"
                        >
                          {slice.primary.textConnector}
                        </label>
                        <LinkWithQuery
                          href={Link.url(
                            slice.primary.link2,
                            prismicLinkResolver,
                          )}
                          target="_blank"
                          className={classNames('underline', color.text)}
                        >
                          <span>
                            {slice.primary.label2}
                            {' '}
                          </span>
                        </LinkWithQuery>
                      </div>
                    </div>
                  </div>
                )}
                <div className="py-3 text-right">
                  <button
                    type="submit"
                    className={classNames(
                      'dh-button py-4 text-sm w-full',
                      color.bg,
                      color.bgHover,
                      color.textContrast,
                    )}
                  >
                    {getDefaultText('sendButtonLabel', slice.primary, defaultMessages)}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      <Toast
        show={showToast}
        setShow={setShowToast}
        icon={CheckCircleIcon}
        title={intl.formatMessage({
          defaultMessage: 'Gracias',
        })}
        message={intl.formatMessage({
          defaultMessage: 'Te contactaremos a la brevedad para asesorarte y acompañarte '
            + 'en la transformación que comenzaste hoy.',
        })}
      />
    </>
  );
};

export default FormContactClusterSelect;
