import classNames from 'classnames';
import { RichText } from 'prismic-reactjs';

const Header = ({ title }) => (
  <div
    className={classNames(
      'lg:dark:bg-gray-2 text-lg mx-auto lg:pb-64 lg:pt-24 dark:bg-transparent lg:rounded-t-xl',
      'dark:bg-gradient-to-t from-black to-transparent',
      {
        'pt-20': !title,
        'lg:hidden': !title,
      },
    )}
  >
    <h1>
      {title && (
        <span className="dh-title pt-24 pb-6 block text-center leading-8">
          {title}
        </span>
      )}
    </h1>
  </div>
);

const ContentWithOneColumn = ({ slice }) => (
  <div
    className={classNames(
      'relative lg:pb-16 lg:dark:bg-white dark:bg-transparent overflow-hidden lg:mt-0',
      { '-mt-12': !!slice?.primary?.title },
    )}
  >
    <div className="lg:dh-content relative">
      <Header title={slice?.primary?.title} />
      <div
        className={classNames(
          'lg:dh-description px-6 pb-10 prose-sm dark:text-primary-50 dark:bg-black lg:rounded-xl lg:p-14',
          { 'lg:-mt-56': !!slice?.primary?.title },
        )}
      >
        {RichText.render(slice?.primary?.description)}
      </div>
    </div>
  </div>
);

export default ContentWithOneColumn;
