import { FC } from 'react';
import classNames from 'classnames';
import { Link, RichText } from 'prismic-reactjs';
import Image from 'next/image';
import { nanoid } from 'nanoid';
import sortBy from 'lodash.sortby';
import dynamic from 'next/dynamic';
import { prismicLinkResolver } from '../../../utils/common';
import { ProductDocument } from '../../../types/product.type';
import useLocale from '../../../hooks/useLocale';
import { hasRichText } from '../../../utils/prismic';
import { getColor } from '../../../utils/colors';
import { CardsProductsProps } from '../Cards.types';

const LinkWithQuery = dynamic(() => import('../../../components/LinkWithQuery'), {
  ssr: false,
});
const Tooltip = dynamic(() => import('../../../components/Tooltip'), {
  ssr: false,
});
const Icon = dynamic(() => import('../../../components/Icon'), {
  ssr: false,
});

enum InstallmentKeyText {
  count = '[installment_count]',
}

const installmentFormat = ({ installmentCount, text = '' }): string => text.replace(
  InstallmentKeyText.count,
  installmentCount,
);

const formatCurrency = (symbol: string, number: number, locale: string): string => {
  const numberFormatted = symbol && locale ? new Intl
    .NumberFormat(locale)
    .format(number) : '';

  return ` ${symbol}${numberFormatted}`;
};

const getAvailableItems = (
  items: ProductDocument[],
  clusterUID: string,
): ProductDocument[] => {
  const itemsPage = items
    ?.filter((product) => product?.data?.clusterPage?.uid === clusterUID)
    ?.filter(
      (product) => !product?.data?.startDate || (new Date(product?.data?.startDate).getTime() >= new Date().getTime()),
    );
  return itemsPage;
};

const CardsProducts: FC<CardsProductsProps> = ({ slice }) => {
  const locale = useLocale();
  const products: ProductDocument[] = sortBy(
    getAvailableItems(slice._products, slice._cluster?.uid as string),
    (item) => item.data.startDate,
  );

  if (!products?.length) return null;

  const vertical = slice._vertical?.data;
  const cluster = slice._cluster?.data;
  const primaryColor = getColor(slice.primary.primaryColor || vertical?.color, 600);
  const secondaryColor = getColor(slice.primary.secondaryColor || vertical?.color, 500);
  const uniqueProductPlan: string[] = [];

  return (
    <section className="dh-content-full px-4 py-15 lg:px-15 lg:pb-13 lg:pt-[8.75rem] 3xl:pt-40 bg-white-1">
      <div className="2xl:w-10/12 lg:m-auto lg:max-w-7xl 2xl:max-w-8xl 3xl:w-full">
        <header className="lg:mb-6 lg:flex lg:flex-row">
          <div className="grow">
            {slice.primary?.title && (
            <h2 className="text-black-1 text-h4 font-bold lg:text-h2 3xl:text-h1">
              {slice.primary.title}
            </h2>
            )}
            {hasRichText(slice.primary.description) && (
            <div className="text-gray-1 text-p2 mt-1.5 font-medium lg:w-9/12 lg:mt-4 2xl:w-7/10">
              <RichText render={slice.primary.description} />
            </div>
            )}
          </div>
        </header>

        {products?.map(({ data: item }) => {
          if (!item) return null;

          const productKey = `${item.installmentAmount}_${item?.remarkedInstallmentAmount}_${item?.bookingPrice}`;

          if (uniqueProductPlan.includes(productKey)) return null;
          uniqueProductPlan.push(productKey);

          const installmentAmount = item?.installmentAmount ?? item?.remarkedInstallmentAmount;
          const hasPaymentTooltip = hasRichText(slice.primary.paymentMethodsTooltip) || !!slice.items?.length;

          return (
            <article
              key={nanoid()}
              className={classNames(
                'bg-white-1 border-[0.031rem] border-white-3 shadow-md rounded flex flex-col my-4',
                'lg:gap-8 lg:flex-row',
              )}
            >
              <div className={classNames(
                'text-white-1 lg:w-9 lg:min-h-[11.25rem] text-center font-bold text-h8 overflow-hidden',
                'flex-none flex justify-center items-center rounded-t lg:rounded-l lg:rounded-t-0 lg:rounded-tr-none',
                secondaryColor.bg,
                secondaryColor.textContrast,
              )}
              >
                <div className="lg:-rotate-90 h-9 flex justify-center items-center flex-none">
                  {slice.primary.installmentTag}
                </div>
              </div>
              <div className="
                flex flex-col items-center justify-center w-full gap-8 py-10 px-6
                lg:py-15 lg:flex-row lg:px-0"
              >
                <header>
                  <h2 className={classNames('text-h4 lg:text-h3 font-bold', primaryColor.text)}>{item.title}</h2>
                  <p className="text-p2 text-gray-5 font-bold mt-0.5">{item.description}</p>
                </header>
                <div className="flex flex-col grow gap-8 lg:grid lg:grid-cols-3 lg:mr-14">
                  {item.bookingPrice && (
                    <div className="flex flex-row gap-1.5">
                      <Icon
                        icon="check-circle-solid"
                        className={classNames('w-6 h-6 flex-none', secondaryColor.text)}
                      />
                      <div className="text-gray-1">
                        <span className="text-h8 font-bold block mr-1.5 pt-0.5 3xl:text-h7 3xl:inline-block">
                          {slice.primary.bookingLabel}
                        </span>
                        <span className="text-h8 font-bold block pt-0.5 3xl:text-h7 3xl:inline-block">
                          {item.bookingPrice}
                        </span>
                        <span className="text-p3 font-medium block mt-0.5 text-gray-4">
                          {slice.primary.bookingDescription}
                        </span>
                        {hasPaymentTooltip && (
                          <span className="text-gray-6 text-p4 font-medium mt-1.5 flex items-center gap-0.5">
                            {slice.primary.paymentMethodsLabel}
                            <Tooltip
                              tip={(
                                <div className="text-left">
                                  {
                                    hasRichText(slice.primary.paymentMethodsTooltip)
                                    && (
                                    <div className="text-p4 text-black">
                                      <RichText render={slice.primary.paymentMethodsTooltip} />
                                    </div>
                                    )
                                  }
                                  <div className="mt-2.5">
                                    {slice.items?.map(({ paymentMethodImage }) => paymentMethodImage?.url && (
                                    <div className="inline-block mr-2.5 my-1" key={nanoid()}>
                                      <Image
                                        src={paymentMethodImage.url}
                                        alt={paymentMethodImage.alt}
                                        width={paymentMethodImage.dimensions.width}
                                        height={paymentMethodImage.dimensions.height}
                                      />
                                    </div>
                                    ))}
                                  </div>
                                </div>
                              )}
                            >
                              <Icon icon="exclamation-circle" className="text-gray-6 w-3 h-3 inline-block" />
                            </Tooltip>
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="flex flex-row gap-1.5">
                    <Icon icon="check-circle-solid" className={classNames('w-6 h-6 flex-none', secondaryColor.text)} />
                    <div className="text-gray-1">
                      <span className="text-h8 font-bold block mr-1.5 pt-0.5 3xl:text-h7 3xl:inline-block">
                        {slice.primary.installmentLabel}
                      </span>
                      {!!slice.primary.installmentCount && !!item.installmentCount && (
                        <span className="text-p3 text-gray-5 block pt-0.5">
                          {installmentFormat({
                            installmentCount: item.installmentCount,
                            text: slice.primary.installmentCount,
                          })}
                        </span>
                      )}
                      {cluster.showBenefitsButton && slice.primary?.benefitsLink?.uid && (
                      <span className="text-p4 font-medium block mt-1.5 text-gray-5">
                        <LinkWithQuery
                          href={Link.url(
                            slice.primary?.benefitsLink,
                            prismicLinkResolver,
                          )}
                          target="_blank"
                          className="underline"
                        >
                          {slice.primary.benefitsLabel}
                        </LinkWithQuery>
                      </span>
                      )}
                      {!!slice.primary.totalPriceLabel && (
                        <span className="text-gray-6 text-p4 font-medium mt-1.5 flex items-center gap-0.5">
                          {slice.primary.totalPriceLabel}
                          <Tooltip
                            tip={item.totalPrice}
                          >
                            <Icon icon="exclamation-circle" className="text-gray-6 w-3 h-3 inline-block" />
                          </Tooltip>
                        </span>
                      )}
                    </div>
                  </div>
                  {installmentAmount && (
                    <div className="text-center lg:text-right relative">
                      {!!item?.discount && (
                      <span className="
                        inline-block bg-green-550 text-p3 font-bold text-white-1 rounded-full py-1.5 px-3.5 mb-1.5
                        lg:absolute lg:-top-9 lg:right-0 lg:mb-0
                      "
                      >
                        {item.discount}
                        % Off
                      </span>
                      )}
                      <span className={classNames('text-h4 font-bold block 3xl:text-h3', secondaryColor.text)}>
                        {formatCurrency(item.currency, installmentAmount, locale)}
                        {slice.primary.installmentPriceSuffix}
                      </span>
                      {
                        !!item?.discount
                        && item?.remarkedInstallmentAmount
                        && (
                        <span className="line-through text-p2 font-medium text-gray-8">
                          {formatCurrency(item.currency, item.remarkedInstallmentAmount, locale)}
                          {slice.primary.installmentPriceSuffix}
                        </span>
                        )
                      }
                    </div>
                  )}
                </div>
              </div>
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default CardsProducts;
