import { RichText } from 'prismic-reactjs';
import { nanoid } from 'nanoid';
import classNames from 'classnames';
import { FunctionComponent } from 'react';
import dynamic from 'next/dynamic';
import { GridListsWithIconSimpleProps } from '../GridLists.types';

const Icon = dynamic(() => import('../../../components/Icon'), {
  ssr: false,
});

const GridListsWithIconSimple: FunctionComponent<GridListsWithIconSimpleProps> = ({ slice }) => (
  slice?.items?.length ? (
    <section className="bg-white">
      <div className="dh-content">
        {slice.primary?.title && (
          <header className="mb-8 lg:mb-20 text-left">
            <h2 className="font-bold text-2xl text-black">{slice.primary?.title}</h2>
          </header>
        )}
        <ul className="grid sm:grid-cols-3 gap-10 sm:gap-24">
          {slice.items.map(({ icon, description }) => (
            <li
              key={nanoid()}
              className="space-y-7"
            >
              <Icon icon={icon} className="w-9 text-primary" />
              <div className={classNames(
                'text-sm text-gray-900 font-medium leading-7',
                'before:h-3 before:w-1 before:bg-primary before:block before:absolute before:mt-2 before:-ml-3',
              )}
              >
                <RichText render={description} />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  ) : null);

export default GridListsWithIconSimple;
