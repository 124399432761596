import { useState } from 'react';
import classNames from 'classnames';
import { Link } from 'prismic-reactjs';
import { ArrowNarrowRightIcon } from '@heroicons/react/outline';
import dynamic from 'next/dynamic';
import { getColor } from '../../../utils/colors';
import { getVerticalByLink, prismicLinkResolver } from '../../../utils/common';
import { BoxesVariations } from '../Boxes.types';

const LinkWithQuery = dynamic(() => import('../../../components/LinkWithQuery'), {
  ssr: false,
});

enum BusinessUnits {
  course = 'course',
  career = 'career',
  other = 'other'
}

const getBusinessUnitLabel = (businessUnit: string, data) => {
  switch (businessUnit) {
    case BusinessUnits.course:
      return data.businessUnitCoursesLabel;
    case BusinessUnits.career:
      return data.businessUnitCareerLabel;
    case BusinessUnits.other:
    default:
      return data.businessUnitOtherLabel;
  }
};

const groupBy = (data, key: string) => data.reduce((value, x) => {
  const rv = value;
  (rv[x[key]] = rv[x[key]] || []).push(x);
  return rv;
}, {});

const sort = (data) => {
  const dataByBU = groupBy(data, 'businessUnit');

  return [
    ...(dataByBU[BusinessUnits.course] || []),
    ...(dataByBU[BusinessUnits.career] || []),
    ...(dataByBU[BusinessUnits.other] || []),
  ];
};

const getColorByVertical = (verticalColor: string, color: string) => getColor(verticalColor || color);

const BoxesRectangle = ({ slice }) => {
  const [otherExpanded, setOtherExpanded] = useState<boolean>();
  const hasOthers = slice.items.some(
    (item) => item.businessUnit === BusinessUnits.other,
  );
  const verticals = slice?._verticals ?? [slice?._vertical];
  const clusters = slice?._clusters ?? [slice?._cluster];

  const showOthers = () => {
    setOtherExpanded(true);
  };

  return (
    <section className="bg-white dark:bg-gray-1">
      <div className="dh-content relative space-y-10">
        <div className="text-center grid grid-cols-2 gap-5 lg:gap-y-4 lg:gap-x-4 lg:grid-cols-2">
          {!!slice?.items?.length && (
            <>
              {sort(slice.items).map((item, i) => {
                const key = `${BoxesVariations.rectangle}-item-${i}`;
                const { color: verticalColor } = getVerticalByLink(item.link, verticals, clusters);
                const itemColor = getColorByVertical(verticalColor, item.color);

                return (
                  <div
                    key={key}
                    className={classNames(
                      'w-full rounded-lg', // mobile
                      'lg:w-auto lg:h-auto lg:m-0 lg:overflow-hidden lg:block', // desktop
                      {
                        'hidden lg:hidden':
                          item.businessUnit === BusinessUnits.other
                          && !otherExpanded,
                      },
                    )}
                  >
                    <LinkWithQuery
                      href={Link.url(item.link, (doc) => prismicLinkResolver(doc, item))}
                      className={classNames(
                        'block h-full w-full rounded-lg overflow-hidden text-center break-all cursor-pointer',
                        'lg:flex lg:w-full lg:justify-center lg:items-center lg:font-light lg:p-7 lg:text-xl',
                        'lg:py-4',
                        itemColor.bg,
                        itemColor.bgHover,
                        itemColor.textContrast,
                      )}
                    >
                      <span
                        className={classNames(
                          'text-base flex items-center justify-center h-32 p-2',
                          'lg:grow lg:block lg:w-auto lg:h-auto lg:text-left',
                        )}
                      >
                        <p className="text-center break-normal lg:text-left font-medium">
                          {item.title}
                        </p>
                        <p className="hidden text-sm font-normal text-left lg:inline">
                          {item.description}
                        </p>
                      </span>
                      <ArrowNarrowRightIcon className="hidden w-6 flex-none lg:inline" />
                    </LinkWithQuery>
                  </div>
                );
              })}
              {hasOthers && !otherExpanded && (
                <span
                  className={classNames(
                    !otherExpanded
                      ? 'block mt-9 col-span-2 lg:text-white'
                      : 'hidden',
                  )}
                >
                  <button
                    type="button"
                    className={classNames(
                      'dh-button-sm inline-flex bg-black border border-white mt-0', // mobile
                    )}
                    onClick={!otherExpanded ? showOthers : undefined}
                  >
                    {getBusinessUnitLabel(BusinessUnits.other, slice.primary)}
                  </button>
                </span>
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default BoxesRectangle;
