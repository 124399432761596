import classNames from 'classnames';
import { Link, RichText } from 'prismic-reactjs';
import { useIntl } from 'react-intl';
import { SubmitHandler, useForm } from 'react-hook-form';
import set from 'lodash.set';
import cloneDeep from 'lodash.clonedeep';
import { nanoid } from 'nanoid';
import {
  FC, useCallback, useContext, useEffect, useState,
} from 'react';
import { CheckCircleIcon } from '@heroicons/react/outline';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import sortBy from 'lodash.sortby';
import dynamic from 'next/dynamic';
import { AppContext } from '../../../contexts/AppContext';
import { useAnalytics } from '../../../hooks/useAnalytics';
import { ProductDocument, Product } from '../../../types/product.type';
import { getColor } from '../../../utils/colors';
import useLocale from '../../../hooks/useLocale';
import { getDefaultText, prismicLinkResolver } from '../../../utils/common';
import {
  DATE_LOCALES, getCurrentDateLocale, parseDate,
} from '../../../utils/dates';
import { useTrackingPayload } from '../../../hooks/useTrackPayload';
import { NextStepFlow, TrackEvents } from '../../../utils/constants';
import { FormContactProductsProps } from '../Form.types';
import { hasRichText } from '../../../utils/prismic';

const LinkWithQuery = dynamic(() => import('../../../components/LinkWithQuery'), {
  ssr: false,
});
const Toast = dynamic(() => import('../../../components/Toast'), {
  ssr: false,
});
const FormInput = dynamic(() => import('../../../components/FormInput'), {
  ssr: false,
});

interface ContactForm {
  name: string;
  lastname: string;
  email: string;
  phonecode: string;
  phone: string;
  schedule: string;
  tyc: boolean;
  ryp: boolean;
}

const dateFormat = (date?: string, language = 'es'): string => (date ? parseDate(
  date,
  getCurrentDateLocale(DATE_LOCALES, language),
  'dd/MM/yyyy',
) : '');

const getAvailableItems = (
  items: ProductDocument[],
  clusterId?: string,
): ProductDocument[] => {
  const itemsPage = items
    ?.filter((product) => product?.data?.clusterPage?.id === clusterId)
    ?.filter(
      (product) => !product?.data?.startDate || (new Date(product?.data?.startDate).getTime() >= new Date().getTime()),
    );
  return itemsPage;
};

const FormContactProducts: FC<FormContactProductsProps> = ({ slice }) => {
  const { track, identify } = useAnalytics();
  const { state } = useContext(AppContext);
  const intl = useIntl();
  const locale = useLocale();
  const [showToast, setShowToast] = useState<boolean>(false);
  const showRules = !!(slice.primary.textAgree && slice.primary.label1 && slice.primary.link1?.uid);

  const vertical = slice._vertical?.data;
  const pillColor = getColor(slice.primary.pillColor || vertical?.color, 500);
  const cluster = slice._cluster?.data;
  const products: ProductDocument[] = sortBy(
    getAvailableItems(slice._products, slice._cluster?.id),
    (item) => item.data.startDate,
  );

  const trackPayload = useTrackingPayload({ slice, cluster, vertical });
  const {
    register, handleSubmit, reset, formState, setValue,
  } = useForm<ContactForm>();

  const primaryMessages = slice.primary as { [key: string]: string };
  const defaultMessages = {
    nameLabel: intl.formatMessage({ defaultMessage: 'Nombre' }),
    lastnameLabel: intl.formatMessage({ defaultMessage: 'Apellido' }),
    emailLabel: intl.formatMessage({ defaultMessage: 'E-mail' }),
    phoneCodeLabel: intl.formatMessage({ defaultMessage: 'Código de Área' }),
    phoneLabel: intl.formatMessage({ defaultMessage: 'Teléfono' }),
    phonePlaceholder: intl.formatMessage({ defaultMessage: '11 0000 0000' }),
    scheduleLabel: intl.formatMessage({ defaultMessage: 'Horarios' }),
    termsAndConditionsAgree: intl.formatMessage({ defaultMessage: 'Aceptar' }),
    termsAndConditionsLabel: intl.formatMessage({ defaultMessage: 'Términos y condiciones' }),
    sendButtonLabel: intl.formatMessage({ defaultMessage: 'Enviar' }),
    morningShift: intl.formatMessage({ defaultMessage: 'Turno Mañana' }),
    afternoonShift: intl.formatMessage({ defaultMessage: 'Turno Tarde' }),
    thankyouTitle: intl.formatMessage({ defaultMessage: 'Gracias' }),
    thankyouMessage: intl.formatMessage({
      defaultMessage: 'Te contactaremos a la brevedad para asesorarte y acompañarte '
        + 'en la transformación que comenzaste hoy.',
    }),
  };

  const formTrack = useCallback((
    localeCountry: string,
    firstName: string,
    lastName: string,
    email: string,
    phoneCode: string,
    phone: string,
    product?: Product,
  ) => {
    const fields = {
      locale_country: localeCountry,
      first_name: firstName,
      last_name: lastName,
      email,
      phone: `${phoneCode}${phone}`,
    };

    identify(fields);

    const schedule = [
      `${dateFormat(product?.startDate as string, locale)}`,
      product?.scheduleDays,
      product?.scheduleHours,
    ].join(' | ');

    const payload = cloneDeep(trackPayload);
    set(payload, 'context.next_step', NextStepFlow.advice);
    set(payload, 'fields', fields);
    set(payload, 'fields.schedule', schedule);
    set(payload, 'product.start_date', product?.startDate);
    set(payload, 'product.end_date', product?.endDate);

    track(TrackEvents.productApplicationSubmitted, payload, locale, true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackPayload]);

  const onSubmit: SubmitHandler<ContactForm> = ({
    name, lastname, email, phonecode, phone, schedule,
  }) => {
    const product = slice._products.find(({ id }) => id === schedule);

    formTrack(
      locale,
      name,
      lastname,
      email,
      phonecode,
      phone,
      product?.data,
    );

    setShowToast(true);

    reset();
  };

  useEffect(() => {
    if (state.products.selected) {
      setValue('schedule', state.products.selected.id);
    }
  }, [state.products.selected, setValue]);

  return (
    <section className="bg-black-2">
      <div className="
        dh-content-full flex flex-col p-0
        lg:flex-row lg:gap-4 lg:px-[3.875rem] lg:py-[8.75rem] lg:m-auto lg:max-w-7xl
        2xl:max-w-8xl 3xl:max-w-8x5l 3xl:py-[12.5rem] 3xl:gap-20"
      >
        <header className="w-full flex justify-center lg:justify-end lg:w-6/12">
          <div className="
            w-full bg-gray-3 items-start max-w-full lg:rounded-l lg:rounded-r-[5.75rem] lg:w-[35.5rem] lg:h-[35.5rem]
            py-[4.375rem] px-10.5 flex flex-col justify-center lg:py-0
            2xl:px-10 2xl:w-[38rem]
            3xl:w-[37.5rem] 3xl:h-[37.5rem] 3xl:px-12"
          >
            {slice.primary.pillText && (
            <div className={classNames(
              pillColor.bg,
              'text-white-1 rounded-4xl flex items-center font-medium text-center w-fit',
              'text-sm justify-center leading-6 px-4 py-1.5 inline-block mb-4',
              'lg:text-lg',
            )}
            >
              {slice.primary.pillText}
            </div>
            )}
            {slice.primary.title && (
            <h2 className="text-white-1 text-h4 lg:text-h2 font-bold mb-4 2xl:text-h1">
              {slice.primary.title}
            </h2>
            )}
            <div className="flex gap-3 lg:gap-2 flex-col lg:flex-row">
              <div className="bg-transparent text-p3 py-1.5 px-4 text-white-1 border border-white-1 rounded-4xl">
                {slice.primary.modalityPillText}
              </div>
              <div className="bg-transparent text-p3 py-1.5 px-4 text-white-1 border border-white-1 rounded-4xl">
                {slice.primary.durationPillText}
              </div>
            </div>
            {hasRichText(slice.primary.description) && (
              <div className="text-white-2 text-p3 lg:text-p2 pt-6 lg:pt-8 font-medium 3xl:pt-9">
                <RichText render={slice.primary.description} />
              </div>
            )}
          </div>

        </header>
        <div className="w-full lg:w-6/12 flex justify-start">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="
              w-full max-w-full py-8 px-4 bg-gray-1 rounded flex flex-col justify-center
              lg:w-[35.5rem] lg:h-[35.5rem] lg:px-[3.2rem] lg:py-[4.2rem]
              2xl:lg:w-[38rem]
              3xl:w-[37.5rem] 3xl:h-[37.5rem]"
            noValidate
          >
            <div className="flex flex-col gap-4 lg:gap-6">
              {
                hasRichText(slice.primary.formTitle)
                && (
                <h3 className="text-h7 lg:text-h5 font-bold text-white-1 pb-2.5 text-center">
                  <RichText render={slice.primary.formTitle} />
                </h3>
                )
              }
              <div className="flex flex-col gap-4 lg:grid lg:grid-cols-2 lg:gap-3">
                <FormInput
                  label={getDefaultText('nameLabel', primaryMessages, defaultMessages)}
                  placeholder={slice.primary?.namePlaceholder}
                  id="name"
                  hasError={!!formState.errors?.name}
                  register={register('name', {
                    required: true,
                  })}
                />

                <FormInput
                  label={getDefaultText('lastnameLabel', primaryMessages, defaultMessages)}
                  placeholder={slice.primary?.lastnamePlaceholder}
                  id="lastname"
                  hasError={!!formState.errors?.lastname}
                  register={register('lastname', {
                    required: true,
                  })}
                />
              </div>

              <div className="flex flex-col gap-4 lg:gap-3 lg:flex-row">
                <FormInput
                  className="w-full lg:w-[12.5rem] 3xl:w-[12.938rem]"
                  label={getDefaultText('emailLabel', primaryMessages, defaultMessages)}
                  placeholder={slice.primary?.emailPlaceholder}
                  id="email"
                  type="email"
                  hasError={!!formState.errors?.email}
                  register={register('email', {
                    required: true,
                    pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
                  })}
                />

                <div className="flex gap-3 grow">
                  <div className="flex-none w-20 lg:w-[5.188rem] 3xl:w-[5.375rem]">
                    <FormInput
                      label={getDefaultText('phoneCodeLabel', primaryMessages, defaultMessages)}
                      placeholder={slice.primary?.phoneCodePlaceholder}
                      id="phonecode"
                      className="w-20 lg:w-[5.188rem] 3xl:w-[5.375rem]"
                      register={register('phonecode', {
                        required: false,
                      })}
                    />
                  </div>

                  <div className="grow">
                    <FormInput
                      label={getDefaultText('phoneLabel', primaryMessages, defaultMessages)}
                      placeholder={getDefaultText('phonePlaceholder', primaryMessages, defaultMessages)}
                      id="phone"
                      register={register('phone', {
                        required: false,
                      })}
                    />
                  </div>
                </div>
              </div>

              {!!slice._products?.length && (
                <div>
                  <label
                    htmlFor="schedule"
                    className="text-white-3 block text-p3 lg:text-p2 font-medium mb-1.5"
                  >
                    {getDefaultText('scheduleLabel', primaryMessages, defaultMessages)}
                    {!!formState.errors?.schedule && (
                    <span className="pl-3 inline-block pointer-events-none align-middle">
                      <ExclamationCircleIcon
                        className="h-5 w-5 text-red-500"
                        data-testid="test-error"
                        aria-hidden="true"
                      />
                    </span>
                    )}
                  </label>
                  <select
                    id="schedule"
                    defaultValue=""
                    className={classNames(
                      'rounded-md h-[2.875rem] block w-full shadow-sm p-3 border',
                      'border-white bg-white-2 text-p3',
                      {
                        'text-gray-1 placeholder-gray-7': !formState.errors?.schedule,
                        'focus:ring-gray-800 focus:border-gray-500': !formState.errors?.schedule,
                        'border-red-300 text-red-500 placeholder-red-500': !!formState.errors?.schedule,
                        'focus:ring-red-500 focus:border-red-500 focus:outline-none': !!formState.errors?.schedule,
                      },
                    )}
                    required
                    {
                      ...register('schedule', {
                        required: true,
                      })
                    }
                  >
                    <option key="select" value="" disabled>
                      {slice.primary.schedulePlaceholder ?? ''}
                    </option>
                    {products.map((product) => {
                      const schedule = [
                        dateFormat(product.data.startDate, locale),
                        product?.data.scheduleDays,
                        product?.data.scheduleHours,
                      ].join(' - ');

                      return schedule && (
                        <option key={nanoid()} value={product.id}>
                          {schedule}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
            </div>
            <div className="pt-6 text-right">
              <div className="relative flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="tyc"
                    aria-describedby="agree-terms-and-conditions"
                    type="checkbox"
                    className="focus:ring-grey-500 h-4 w-4 text-gey-600 border-gray-300 rounded"
                    {
                      ...register('tyc', {
                        required: true,
                      })
                    }
                  />
                </div>
                <div className="ml-3 text-p3 lg:text-p2 font-medium">
                  <label
                    htmlFor="tyc"
                    className="mr-2 text-white-2"
                  >
                    {getDefaultText('termsAndConditionsAgree', primaryMessages, defaultMessages)}
                  </label>
                  <LinkWithQuery
                    href={Link.url(
                      slice.primary.termsAndConditionsLink,
                      prismicLinkResolver,
                    )}
                    target="_blank"
                    className="underline font-bold text-white-2"
                  >
                    <span className="sr-only">
                      {getDefaultText('termsAndConditionsAgree', primaryMessages, defaultMessages)}
                      {' '}
                    </span>
                    {getDefaultText('termsAndConditionsLabel', primaryMessages, defaultMessages)}
                  </LinkWithQuery>
                  {!!formState.errors?.tyc && (
                    <span className="pl-3 inline-block pointer-events-none align-middle">
                      <ExclamationCircleIcon
                        className="h-5 w-5 text-red-500"
                        aria-hidden="true"
                        data-testid="test-error"
                      />
                    </span>
                  )}
                </div>
              </div>
            </div>
            {showRules
            && (
            <div className="pt-6">
              <div className="relative flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="ryp"
                    aria-describedby="agree-terms-and-conditions"
                    type="checkbox"
                    className="focus:ring-grey-500 h-4 w-4 text-gey-600 border-gray-300 rounded"
                    {
                      ...register('ryp', {
                        required: true,
                      })
                    }
                  />
                </div>
                <div className="ml-3 text-p3 lg:text-p2 font-medium">
                  <label
                    htmlFor="ryp"
                    className="mr-2 text-white-2"
                  >
                    {slice.primary.textAgree}
                  </label>
                  <LinkWithQuery
                    href={Link.url(
                      slice.primary.link1,
                      prismicLinkResolver,
                    )}
                    target="_blank"
                    className="underline font-bold text-white-2"
                  >
                    <span className="sr-only">
                      {slice.primary.textAgree}
                      {' '}
                    </span>
                    {slice.primary.label1}
                  </LinkWithQuery>
                  <label
                    htmlFor="ryp"
                    className="mx-2 text-white-2"
                  >
                    {slice.primary.textConnector}
                  </label>
                  <LinkWithQuery
                    href={Link.url(
                      slice.primary.link2,
                      prismicLinkResolver,
                    )}
                    target="_blank"
                    className="underline font-bold text-white-2"
                  >
                    <span className="sr-only">
                      {slice.primary.textAgree}
                      {' '}
                    </span>
                    <span className="whitespace-nowrap">
                      {slice.primary.label2}
                      {!!formState.errors?.ryp && (
                      <span className="pl-3 inline-block pointer-events-none align-middle">
                        <ExclamationCircleIcon
                          className="h-5 w-5 text-red-500"
                          aria-hidden="true"
                          data-testid="test-error"
                        />
                      </span>
                      )}
                    </span>
                  </LinkWithQuery>
                </div>
              </div>
            </div>
            )}
            <button
              type="submit"
              className="
                dh-button mx-auto mt-8 px-16 py-3 text-p3 font-bold text-black-1 bg-white-1 hover:bg-white-3
                lg:mt-10 lg:text-p2 lg:min-w-[19.313rem] 2xl:min-w-[22.375rem] 3xl:text-h7 3xl:min-w-[17.938rem]"
            >
              {getDefaultText('sendButtonLabel', primaryMessages, defaultMessages)}
            </button>
          </form>
        </div>
      </div>
      <Toast
        show={showToast}
        setShow={setShowToast}
        icon={CheckCircleIcon}
        title={defaultMessages.thankyouTitle}
        message={defaultMessages.thankyouMessage}
      />
    </section>
  );
};

export default FormContactProducts;
