import { RichText } from 'prismic-reactjs';
import classNames from 'classnames';
import { hasRichText } from '../../../utils/prismic';

const HeaderCentered = ({ slice }) => (
  <div className="bg-white">
    <div className="dh-content">
      <div className="text-center">
        {slice.primary.subtitle && (
        <h2 className="text-base font-semibold text-primary-600 tracking-wide uppercase">
          {slice.primary.subtitle}
        </h2>
        )}
        {hasRichText(slice.primary.title) && (
        <p
          className={classNames(
            'mt-1 max-w-3xl mx-auto text-4xl font-extrabold text-gray-900',
            'sm:text-5xl sm:tracking-tight lg:text-6xl',
          )}
        >
          {RichText.asText(slice.primary.title)}
        </p>
        )}
        {hasRichText(slice.primary.description) && (
        <p className="max-w-2xl mt-5 mx-auto text-xl text-gray-500">
          {RichText.asText(slice.primary.description)}
        </p>
        )}
      </div>
    </div>
  </div>
);

export default HeaderCentered;
