import classNames from 'classnames';
import Image from 'next/image';
import { nanoid } from 'nanoid';
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import {
  getDefaultText, prismicLinkResolver,
} from '../../../utils/common';
import { getColor } from '../../../utils/colors';

const LinkWithQuery = dynamic(() => import('../../../components/LinkWithQuery'), {
  ssr: false,
});
const Tooltip = dynamic(() => import('../../../components/Tooltip'), {
  ssr: false,
});
const Icon = dynamic(() => import('../../../components/Icon'), {
  ssr: false,
});
const ReactCarousel = dynamic(() => import('../../../components/ReactCarousel'), {
  ssr: false,
});

const itemsToShowSm = 1.1;
const itemsToShowMd = 2.5;
const itemsToShowLg = 3;
const breakPoints = [
  {
    width: 1,
    itemsToShow: itemsToShowSm,
    itemPadding: [0, 0, 0, 16],
    showArrows: false,
  },
  {
    width: 955,
    itemsToShow: itemsToShowMd,
    itemPadding: [0, 16, 0, 16],
    showArrows: true,
  },
  {
    width: 1149,
    itemsToShow: itemsToShowLg,
    itemPadding: [0, 39, 0, 39],
    showArrows: true,
  },
  {
    width: 1484,
    itemsToShow: itemsToShowLg,
    itemPadding: [0, 45, 0, 45],
    showArrows: true,
  },
];
const carouselOptions = {
  isRTL: false,
  pagination: false,
  breakPoints: [{
    width: 0,
    itemsToShow: 0,
    itemPadding: [0, 0, 0, 0],
    showArrows: false,
  }],
  classNames: { arrowRight: 'lg:ml-4 2xl:ml-0' },
};

const CarouselWithClusters = ({ slice }) => {
  const items = slice.items?.filter((item) => (item?.link?.link_type !== 'Any'));
  const itemsLength = items?.length;
  const intl = useIntl();
  const defaultMessages = {
    buttonLabel: intl.formatMessage({ defaultMessage: 'Ver Carrera' }),
  };
  const [hasArrows, setHasArrows] = useState(false);
  const showSlice = Boolean(slice._clusters && slice._verticals && slice.primary.title && itemsLength);
  const [classNames4Container, setClassNames4Container] = useState('max-w-none');

  useEffect(() => {
    switch (itemsLength) {
      case 1:
        carouselOptions.breakPoints = [
          breakPoints[0],
          {
            width: 400,
            itemsToShow: 1,
            itemPadding: [0, 0, 0, 0],
            showArrows: false,
          }];

        setClassNames4Container('justify-center max-w-[439px] m-auto');
        break;
      case 2:
        carouselOptions.breakPoints = [
          breakPoints[0],
          {
            width: 750,
            itemsToShow: 2,
            itemPadding: [0, 45, 0, 45],
            showArrows: false,
          }];

        setClassNames4Container('justify-center max-w-[968px] m-auto');
        break;
      default:
        breakPoints[1].showArrows = Boolean(itemsLength > itemsToShowMd);
        breakPoints[2].showArrows = Boolean(itemsLength > itemsToShowLg);
        breakPoints[3].showArrows = Boolean(itemsLength > itemsToShowLg);
        setHasArrows(breakPoints[1].showArrows);
        carouselOptions.breakPoints = breakPoints;
    }
  }, [itemsLength]);

  if (!Object.keys(carouselOptions.breakPoints[0]).length) { return null; }
  return showSlice ? (
    <section className="bg-black-1 py-20 px-4 lg:py-50 lg:px-15">
      <div className={classNames(
        'm-auto 3xl:max-w-[100rem] justify-center',
        { 'lg:w-11/12': hasArrows },
        { 'lg:w-10/12': !hasArrows },
      )}
      >
        <div className="mx-auto text-center mb-[3.813rem] lg:mb-[7.5rem] 2xl:mb-20">
          <h2 className="text-h5 text-left text-white-1 font-bold mb-4
          lg:text-center lg:text-h3 2xl:text-h1"
          >
            {slice.primary.title}
          </h2>
          <p className="text-left lg:text-p1 lg:text-center font-medium text-white-2 2xl:text-h5">
            {slice.primary.description}
          </p>
        </div>
        <div className={classNames4Container}>
          <ReactCarousel options={carouselOptions} color={slice._vertical?.data?.color}>
            {slice.items?.map((item) => {
              const discountColor = getColor(item.discountColor, 600);
              const showPartners = Boolean(item.partnersText && item.partnersImage.url);
              let url;
              let cluster;
              let vertical;

              switch (item?.link?.type) {
                case 'page':
                  url = prismicLinkResolver(item?.link);
                  break;
                case 'clusterpage':
                  cluster = slice?._clusters?.find((element) => element.id === item?.link?.id);
                  url = prismicLinkResolver(cluster);
                  break;
                case 'verticalpage':
                  vertical = slice?._verticals?.find((element) => element.id === item?.link?.id);
                  url = prismicLinkResolver(vertical);
                  break;
                default:
                  url = item?.link?.url;
              }

              return (item?.link?.id) || (item?.link?.url) ? (
                <div
                  className={classNames(
                    'flex flex-col rounded border border-gray-5 bg-gray-3 shadow-sm h-auto px-[1.625rem]',
                    'pt-20 pb-15.5 relative min-w-[16.25rem] min-h-[38.188rem]',
                    'lg:px-7 lg:min-w-[23.5rem] lg:min-h-[40.563rem] 2xl:min-w-[22.375rem]',
                    '3xl:min-h-[38.938rem] 3xl:min-w-[27.438rem]',
                  )}
                  key={nanoid()}
                >
                  {item.discountHeader && (
                    <h2
                      className={classNames(
                        'text-white-2 rounded-t font-bold absolute left-0 top-0 px-1.5 py-3.5 text-p2',
                        'lg:py-3 lg:text-p1 w-full text-center',
                        discountColor.bg,
                      )}
                    >
                      {item.discountHeader}
                    </h2>
                  )}

                  <p className={classNames(
                    { invisible: !item.pillText },
                    'text-white-1 border-white-2 capitalize font-medium border rounded-4xl visible',
                    'w-fit text-p3 py-1.5 px-4 mb-6',
                  )}
                  >
                    {item.pillText}
                  </p>
                  <h2 className="text-white-1 font-bold text-p1 lg:text-h5 3xl:text-h4">
                    {item.cardTitle}
                  </h2>
                  { showPartners
               && (
               <div className="lg:flex items-center pt-4">
                 <p className="font-medium text-p4 text-left pb-1.5 text-gray-8
                 lg:text-p4 lg:mr-3 lg:pb-0"
                 >
                   {item.partnersText}
                 </p>
                 <div className="flex lg:contents">
                   {item.partnersImage.url
                    && (
                      <div className="max-w-lg mr-5">
                        <Image
                          src={item.partnersImage.url}
                          alt={item.partnersImage.alt}
                          width={item.partnersImage.dimensions.width}
                          height={item.partnersImage.dimensions.height}
                        />
                      </div>
                    )}
                   {item.partnersImage2.url
                    && (
                      <div className="max-w-lg">
                        <Image
                          src={item.partnersImage2.url}
                          alt={item.partnersImage2.alt}
                          width={item.partnersImage2.dimensions.width}
                          height={item.partnersImage2.dimensions.height}
                        />
                      </div>
                    )}
                 </div>
               </div>
               )}
                  <p className="font-medium text-white-4 text-p4 pt-6 pb-8 lg:text-p2">
                    {item.cardDescription}
                  </p>
                  <div className="pb-4 grid grid-flow-col justify-start">
                    <Icon
                      icon={item.iconDuration}
                      className={classNames(
                        'text-white-4 w-4 h-4 lg:w-5 lg:h-5',
                      )}
                    />
                    <p className="pl-2 text-p4 text-white-4 font-medium lg:pl-3 lg:text-p2">{item.durationText}</p>
                  </div>
                  <div className="lg:mb-12 grid grid-flow-col justify-start">
                    <Icon
                      icon={item.iconHours}
                      className={classNames(
                        'text-white-4 w-4 h-4 lg:w-5 lg:h-5',
                      )}
                    />
                    <p className="pl-2 text-p4 text-white-4 font-medium lg:pl-3 lg:text-p2">{item.hoursText}</p>
                    {!!item.tooltipText && (
                      <div className="flex items-center ml-2">
                        <Tooltip
                          tip={item.tooltipText}
                        >
                          <Icon icon="exclamation-circle" className="text-white-4 w-3 h-3 inline-block" />
                        </Tooltip>
                      </div>
                    )}
                  </div>
                  <div className="lg:mt-16">
                    <LinkWithQuery
                      href={url}
                      target={item?.link?.target}
                      className="text-black-1 font-bold lg:text-p1
                        absolute min-w-[13rem] lg:mt-12 bg-white-1 px-4 py-2 lg:py-4 text-center rounded-x
                        bottom-[3.875rem] lg:min-w-[20.313rem] left-[1.625rem] right-[1.625rem]"
                    >
                      {getDefaultText('buttonLabel', item, defaultMessages)}
                    </LinkWithQuery>
                  </div>
                </div>
              ) : null;
            })}
          </ReactCarousel>
        </div>
      </div>
    </section>
  ) : null;
};

export default CarouselWithClusters;
