import { RichText } from 'prismic-reactjs';
import { nanoid } from 'nanoid';
import classNames from 'classnames';

const FeatureWithGridList = ({ slice }) => (
  <section className="bg-white">
    <div className="dh-content">
      {slice.primary?.title && (
        <header>
          <h2 className="font-bold text-2xl text-gray-800 mb-8">{slice.primary.title}</h2>
        </header>
      )}
      <ul className="grid lg:gap-x-48 gap-y-5 lg:grid-cols-2">
        {slice.items?.map(
          ({ description }) => (
            <li
              className={classNames(
                'list-none flex text-gray-800 text-sm font-normal items-start',
                'before:flex-none before:inline-block before:-mt-3 before:mr-4',
                "before:content-['•'] before:text-3xl before:text-primary",
              )}
              key={nanoid()}
            >
              <RichText render={description} />
            </li>
          ),
        )}
      </ul>
    </div>
  </section>
);

export default FeatureWithGridList;
