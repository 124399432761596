import classNames from 'classnames';

const contentClassName = 'flex justify-center w-full p-4 lg:p-6';

const Divider = ({ slice, mobile: DividerMobile, desktop: DividerDesktop }) => (
  <>
    <div className={classNames(
      contentClassName,
      'lg:hidden',
      { hidden: slice.primary.hideOnMobile },
    )}
    >
      <DividerMobile />
    </div>
    <div className={classNames(
      contentClassName,
      'hidden',
      { 'lg:hidden': slice.primary.hideOnDesktop, 'lg:flex': !slice.primary.hideOnDesktop },
    )}
    >
      <DividerDesktop />
    </div>
  </>
);
export default Divider;
