import { RichText } from 'prismic-reactjs';
import classNames from 'classnames';
import { hasRichText } from '../../../utils/prismic';

const HeaderLeft = ({ slice }) => {
  const dark = slice.variation.endsWith('Dark');
  return (
    <div
      className={classNames(dark ? 'bg-gray-2' : 'bg-white', {
        'hidden lg:block': slice.primary?.hideMobile,
      })}
    >
      <div className="dh-content lg:flex lg:justify-between">
        <div className="max-w-xl">
          {hasRichText(slice.primary.title) && (
          <h2
            className={classNames(
              dark ? 'text-white' : 'text-gray-900',
              'text-4xl font-extrabold sm:text-5xl sm:tracking-tight lg:text-6xl',
            )}
          >
            {RichText.asText(slice.primary.title)}
          </h2>
          )}
          {hasRichText(slice.primary.description) && (
          <p
            className={classNames(
              dark ? 'text-white' : 'text-gray-500',
              'mt-5 text-xl lg:text-sm',
            )}
          >
            {RichText.asText(slice.primary.description)}
          </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeaderLeft;
