import {
  ChangeEvent, FormEvent, useCallback, useEffect, useState,
} from 'react';
import classNames from 'classnames';
import { saveAs } from 'file-saver';
import { Link } from 'prismic-reactjs';
import { useIntl } from 'react-intl';
import set from 'lodash.set';
import cloneDeep from 'lodash.clonedeep';
import dynamic from 'next/dynamic';
import { useTrackingPayload } from '../../../hooks/useTrackPayload';
import { getColor } from '../../../utils/colors';
import { prismicLinkResolver } from '../../../utils/common';
import { TrackEvents } from '../../../utils/constants';
import useLocale from '../../../hooks/useLocale';
import { useAnalytics } from '../../../hooks/useAnalytics';
import { ModalSize } from '../../../components/Modal';

const LinkWithQuery = dynamic(() => import('../../../components/LinkWithQuery'), {
  ssr: false,
});
const FormInput = dynamic(() => import('../../../components/FormInput'), {
  ssr: false,
});
const Modal = dynamic(() => import('../../../components/Modal'), {
  ssr: false,
});

interface FormData {
  firstName?: string;
  lastName?: string;
  email?: string;
  ex?: string;
  phone?: string;
}

const ButtonDownload = ({ slice }) => {
  const { track, identify } = useAnalytics();
  const intl = useIntl();
  const locale = useLocale();
  const [formData, setFormData] = useState<FormData>({});
  const [open, setOpen] = useState(false);
  const [isSending, setIsSending] = useState<boolean>();
  const vertical = slice?._vertical?.data;
  const cluster = slice?._cluster?.data;
  const color = getColor(vertical?.color);
  const trackPayload = useTrackingPayload({ slice, cluster, vertical });

  const handleDownload = useCallback((
    event: FormEvent,
    file: string,
  ): void => {
    event.preventDefault();
    setIsSending(true);

    const filename = cluster?.name || file.split('/').slice(-1).pop();
    setOpen(false);

    saveAs(file, filename);

    const fields = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      email: formData.email,
      phone: `${formData.ex}${formData.phone}`,
    };

    identify(fields);

    const payload = cloneDeep(trackPayload);
    set(payload, 'product_name', cluster?.name);
    set(payload, 'fields', fields);

    track(TrackEvents.brochureDownloaded, payload, locale, true);

    setFormData({});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackPayload]);

  const handleClick = useCallback((label): void => {
    const payload = cloneDeep(trackPayload);
    set(payload, 'context.label', label);
    setOpen(true);

    track(
      TrackEvents.brochureDownloadedClicked,
      payload,
      locale,
    );
  }, [trackPayload, locale, track]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    key: string,
  ): void => {
    setFormData((prev) => ({
      ...prev,
      [key]: e.target.value,
    }));
  };

  useEffect(() => {
    if (open) setIsSending(false);
  }, [open]);

  return slice.primary?.file ? (
    <>
      <div className="dark:bg-gray-1" id={slice.variation}>
        <div className="dh-content flex justify-center pt-6">
          <button
            type="button"
            onClick={() => handleClick(slice.primary.label)}
            className={classNames(
              'dh-button-lg m-0',
              color.textContrast,
              color.bg,
              color.bgHover,
            )}
          >
            {slice.primary.label}
          </button>
        </div>
      </div>
      <Modal state={open} setOpen={setOpen} size={ModalSize.sm}>
        <form onSubmit={(e) => handleDownload(e, Link.url(slice.primary?.file))}>
          <div>
            <div className="overflow-hidden sm:rounded-md text-gray-800 dark:text-gray-300">
              <div>
                <div className="grid grid-cols-6 gap-4">
                  <div className="col-span-6 sm:col-span-3">
                    <FormInput
                      label={intl.formatMessage({
                        defaultMessage: 'Nombre',
                      })}
                      name="name"
                      id="name"
                      required
                      onChange={(e) => handleChange(e, 'firstName')}
                      darkSupport={false}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <FormInput
                      label={intl.formatMessage({
                        defaultMessage: 'Apellido',
                      })}
                      name="lastname"
                      id="lastname"
                      required
                      onChange={(e) => handleChange(e, 'lastName')}
                      darkSupport={false}
                    />
                  </div>

                  <div className="col-span-6">
                    <FormInput
                      label={intl.formatMessage({
                        defaultMessage: 'E-mail',
                      })}
                      name="email"
                      id="email"
                      type="email"
                      onChange={(e) => handleChange(e, 'email')}
                      required
                      darkSupport={false}
                    />
                  </div>

                  <div className="col-span-2 self-end">
                    <FormInput
                      label={intl.formatMessage({
                        defaultMessage: 'Código de Área',
                      })}
                      name="ex"
                      id="ex"
                      className="w-24"
                      onChange={(e) => handleChange(e, 'ex')}
                      required
                      darkSupport={false}
                    />
                  </div>

                  <div className="col-span-4 self-end">
                    <FormInput
                      label={intl.formatMessage({
                        defaultMessage: 'Teléfono',
                      })}
                      name="phone"
                      id="phone"
                      placeholder={intl.formatMessage({
                        defaultMessage: '11 0000 0000',
                      })}
                      onChange={(e) => handleChange(e, 'phone')}
                      required
                      darkSupport={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {slice.primary?.termsAndConditionsLink?.type && (
            <div className="relative flex items-center pt-4">
              <div className="flex items-center h-5">
                <input
                  id="tyc"
                  aria-describedby="agree-terms-and-conditions"
                  name="tyc"
                  type="checkbox"
                  className="focus:ring-grey-500 h-4 w-4 text-gey-600 border-gray-300 rounded"
                  required
                />
              </div>
              <div className="ml-3 text-sm font-normal font-archivo text-left">
                <label htmlFor="tyc" className="mr-2 text-black">
                  {intl.formatMessage({
                    defaultMessage: 'Aceptar',
                  })}
                </label>
                <LinkWithQuery
                  href={Link.url(
                    slice.primary.termsAndConditionsLink,
                    prismicLinkResolver,
                  )}
                  target={
                    slice.primary.termsAndConditionsLink?.target ?? '_blank'
                  }
                  className={classNames('underline', color.text)}
                >
                  <span className="sr-only">
                    {intl.formatMessage({
                      defaultMessage: 'Aceptar',
                    })}
                    {' '}
                  </span>
                  {intl.formatMessage({
                    defaultMessage: 'Términos y condiciones',
                  })}
                </LinkWithQuery>
              </div>
            </div>
          )}
          <div className="mt-5 sm:mt-6">
            <button
              disabled={isSending}
              type="submit"
              className={classNames(
                'dh-button-sm w-full', // mobile
                color.textContrast,
                color.bg,
                color.bgHover,
              )}
            >
              {slice.primary.label}
            </button>
          </div>
        </form>
      </Modal>
    </>
  ) : (
    <div className="hidden" />
  );
};

export default ButtonDownload;
