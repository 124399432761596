import {
  FC, useCallback, useEffect, useState,
} from 'react';
import classNames from 'classnames';
import { Link, RichText } from 'prismic-reactjs';
import Image from 'next/image';
import { nanoid } from 'nanoid';
import groupBy from 'lodash.groupby';
import set from 'lodash.set';
import cloneDeep from 'lodash.clonedeep';
import dynamic from 'next/dynamic';
import { useIntl } from 'react-intl';
import { useTrackingPayload } from '../../../hooks/useTrackPayload';
import { useAnalytics } from '../../../hooks/useAnalytics';
import { useCheckoutQueryParams } from '../../../hooks/useCheckoutQueryParams';
import { NextStepFlow, TrackEvents } from '../../../utils/constants';
import { IProductGroup } from '../../../graphql/model/interfaces';
import { getCheckoutUrl, getLocalePlans } from '../../../utils/checkout';
import useGeoCountry from '../../../hooks/useGeoCountry';
import { prismicLinkResolver, defaultLocale, getDefaultText } from '../../../utils/common';
import useLocale from '../../../hooks/useLocale';
import { hasRichText } from '../../../utils/prismic';
import { getColor } from '../../../utils/colors';
import { TabsProductCardCheckoutProps, TabsVariations } from '../Tabs.types';

const LinkWithQuery = dynamic(() => import('../../../components/LinkWithQuery'), {
  ssr: false,
});
const Tooltip = dynamic(() => import('../../../components/Tooltip'), {
  ssr: false,
});
const Icon = dynamic(() => import('../../../components/Icon'), {
  ssr: false,
});

enum InstallmentKeyText {
  count = '[installment_count]',
}

interface IHandleClickData {
  nextStep: NextStepFlow, label?: string, url: string, item: IProductGroup
}

const installmentFormat = ({ installmentCount, text = '' }): string => text.replace(
  InstallmentKeyText.count,
  installmentCount,
);

const formatCurrency = (symbol: string, number: number, locale: string): string => {
  const numberFormatted = new Intl
    .NumberFormat(locale)
    .format(number);

  return ` ${symbol}${numberFormatted}`;
};

const PaymentMethod = ({ slice }) => (
  <span className="text-gray-6 text-p4 font-medium mt-1.5 flex items-center gap-0.5">
    {slice.primary.paymentMethodsLabel}
    <Tooltip
      tip={(
        <div className="text-left">
          {
            hasRichText(slice.primary.paymentMethodsTooltip)
            && (
            <div className="text-p4 text-black">
              <RichText render={slice.primary.paymentMethodsTooltip} />
            </div>
            )
          }
          <div className="mt-2.5">
            {slice.items?.map(({ paymentMethodImage }) => paymentMethodImage?.url && (
            <div className="inline-block mr-2.5 my-1" key={nanoid()}>
              <Image
                src={paymentMethodImage.url}
                alt={paymentMethodImage.alt}
                width={paymentMethodImage.dimensions.width}
                height={paymentMethodImage.dimensions.height}
              />
            </div>
            ))}
          </div>
        </div>
      )}
    >
      <Icon icon="exclamation-circle" className="text-gray-6 w-3 h-3 inline-block" />
    </Tooltip>
  </span>
);

const TabsProductCardCheckout: FC<TabsProductCardCheckoutProps> = ({ slice }) => {
  if (slice.primary.isAsync) {
    const allProducts = slice._checkout?.allProducts?.map((p) => ({
      ...p,
      startsAt: '',
      endsAt: '',
    }));

    // eslint-disable-next-line no-param-reassign
    if (slice._checkout?.products) slice._checkout.products = allProducts;
  }

  const vertical = slice._vertical?.data;
  const cluster = slice._cluster?.data;
  const products = slice._checkout?.products;
  const productsByDuration = groupBy(products, 'duration.name');
  const durationKeys = Object.keys(productsByDuration)?.sort()?.reverse();
  const durations = durationKeys.map((d) => productsByDuration[d] && productsByDuration[d][0].duration);

  const locale = useLocale();
  const [tab, setTab] = useState<string | undefined>(durations[0]?.id ?? undefined);

  const primaryColor = getColor(slice.primary.primaryColor, 600);
  const secondaryColor = getColor(slice.primary.secondaryColor, 600);
  const uniqueProductPlan: string[] = [];
  const isSingleTab = durations.length < 2;

  const { track } = useAnalytics();
  const intl = useIntl();
  const trackPayload = useTrackingPayload({ slice, cluster, vertical });
  const geoCountry = useGeoCountry();
  const [showSlice, setShowSlice] = useState<boolean>(false);
  const [currencyCode, setCurrencyCode] = useState<string>(defaultLocale.currencyCode);
  const [currencySymbol, setCurrencySymbol] = useState<string>(defaultLocale.currencySymbol);
  const [countryCode, setCountryCode] = useState<string>('');
  const queryParams = useCheckoutQueryParams();

  const primaryMessages = slice.primary as { [key: string]: string };
  const defaultMessages = {
    checkoutButtonLabel: intl.formatMessage({ defaultMessage: 'Reservar' }),
    checkoutButtonLastplaceLabel: intl.formatMessage({ defaultMessage: '¡Ultimos cupos!' }),
    checkoutButtonSoldoutLabel: intl.formatMessage({ defaultMessage: 'Sold out' }),
  };

  useEffect(() => {
    if (!geoCountry) return;

    const { currentLocale, hasPlans, isUnavailable } = getLocalePlans({ products, geoCountry, locale });

    setShowSlice(!!(hasPlans && !isUnavailable));
    setCountryCode(currentLocale.countryCode);
    setCurrencyCode(currentLocale.currencyCode);
    setCurrencySymbol(currentLocale.currencySymbol);
  }, [geoCountry, locale, products]);

  const handleClick = useCallback(({
    nextStep, label = '', url, item,
  }: IHandleClickData): void => {
    const payload = cloneDeep(trackPayload);
    set(payload, 'context.next_step', nextStep);
    set(payload, 'context.label', label);
    set(payload, 'context.destination_url', url);
    set(payload, 'product.start_date', item.startsAt);
    set(payload, 'product.id', item.id);
    set(payload, 'product.business_unit', item.defaultOffer.businessUnit);
    set(payload, 'product.business_unit_id', item.defaultOffer.businessUnitId);
    set(payload, 'product.vertical', item.defaultOffer.vertical);
    set(payload, 'product.vertical_id', item.defaultOffer.verticalId);
    set(payload, 'product.code', item.code);
    set(payload, 'product.duration', item.duration?.name);

    track(TrackEvents.productApplicationClicked, payload, locale);
  }, [trackPayload, locale, track]);

  if (!showSlice) return null;

  return (
    <section
      className="dh-content-full px-4 py-15 lg:px-15 lg:pb-13 lg:pt-[8.75rem] 3xl:pt-40 bg-white-1"
      data-testid={TabsVariations.productCardCheckout}
    >
      <div className={classNames(
        'flex flex-col justify-center w-full 2xl:w-10/12 2xl:mx-auto',
        'lg:m-auto lg:max-w-7xl 2xl:max-w-8xl 3xl:w-full',
      )}
      >
        <div className="mx-auto">
          <header className="lg:mb-6 lg:flex lg:flex-row">
            <div className="grow">
              {slice.primary?.title && (
              <h2 className="text-black-1 text-h4 font-bold lg:text-h2 3xl:text-h1">
                {slice.primary.title}
              </h2>
              )}
              {hasRichText(slice.primary.description) && (
              <div className="text-gray-1 text-p2 mt-1.5 font-medium lg:w-9/12 lg:mt-4 2xl:w-7/10">
                <RichText render={slice.primary.description} />
              </div>
              )}
            </div>
            { slice.primary.showTabs
          && (
          <div className="flex-none w-full lg:w-3/12 2xl:w-3/10">
            <div className={classNames(
              'border rounded-[1.875rem] p-1 mt-10 w-full',
              primaryColor.border,
            )}
            >
              <div className="flex flex-row relative">
                <div className={classNames(
                  'absolute top-0 h-full rounded-[1.875rem] transition-all',
                  primaryColor.bg,
                  {
                    'left-0': tab === durations[0]?.id || isSingleTab,
                    'left-1/2': tab !== durations[0]?.id && !isSingleTab,
                    'w-1/2': !isSingleTab,
                    'w-full': isSingleTab,
                  },
                )}
                />
                { durations.map((duration) => duration && (
                <button
                  key={nanoid()}
                  type="button"
                  className={classNames(
                    'p-2 text-center text-h7 font-bold cursor-pointer 3xl:text-h5',
                    {
                      'text-white-1 z-0': tab === duration.id,
                      'w-1/2': !isSingleTab,
                      'w-full': isSingleTab,
                      [primaryColor.text]: tab !== duration.id,
                    },
                  )}
                  onClick={() => setTab(duration.id)}
                >
                  {duration.name}
                </button>
                ))}
              </div>
            </div>
          </div>
          )}
          </header>

          {products?.map((item) => {
            const plan = item.defaultOffer.plans?.find((p) => p.currency === currencyCode);

            if (!plan) return null;

            const productKey = `${item.duration?.name}_${plan?.remarkedInstallmentAmount}`;

            if (uniqueProductPlan.includes(productKey)) return null;
            uniqueProductPlan.push(productKey);

            const installmentAmount = plan?.installmentAmount ?? plan?.remarkedInstallmentAmount;
            const hasPaymentTooltip = hasRichText(slice.primary.paymentMethodsTooltip) || !!slice.items?.length;

            return (
              <article
                key={nanoid()}
                className={classNames(
                  'bg-white-1 border-[0.031rem] border-white-3 shadow-md rounded inline-flex flex-col my-4',
                  'lg:gap-8 lg:flex-row',
                  { hidden: item.duration?.id !== tab },
                )}
                data-testid={`${TabsVariations.productCardCheckout}-item`}
              >
                <div className={classNames(
                  'text-white-1 lg:w-9 lg:min-h-[11.25rem] text-center font-bold text-h8 overflow-hidden',
                  'flex-none flex justify-center items-center rounded-t lg:rounded-l lg:rounded-t-0 lg:rounded-tr-none',
                  primaryColor.bg,
                  primaryColor.textContrast,
                )}
                >
                  <div className="lg:-rotate-90 h-9 flex justify-center items-center flex-none">
                    {slice.primary.installmentTag}
                  </div>
                </div>
                <div className="
                flex flex-col items-center justify-center w-full gap-8 py-10 px-6
                lg:py-15 lg:flex-row lg:px-0"
                >
                  {slice.primary.logo?.url ? (
                    <div className="flex-none flex items-center justify-center lg:w-[17.125rem] 3xl:w-[20.625rem]">
                      <Image
                        src={slice.primary.logo?.url}
                        alt={slice.primary.logo?.alt}
                        width={slice.primary.logo?.dimensions.width}
                        height={slice.primary.logo?.dimensions.height}
                      />
                    </div>
                  )
                    : (
                      <div>
                        <h2
                          className={classNames('text-h4 lg:text-h3 font-bold', primaryColor.text)}
                        >
                          {slice.primary.cardTitle}
                        </h2>
                        <p className="text-p2 text-gray-5 font-bold mt-0.5">{slice.primary.cardDescription}</p>
                      </div>
                    )}
                  <div className="flex flex-col grow gap-8 lg:flex-row lg:mr-14 lg:justify-end">
                    {!slice.primary.isAsync && !!plan?.enrollmentAmount && (
                    <div className="flex flex-row gap-1.5">
                      <Icon
                        icon="check-circle-solid"
                        className={classNames('w-6 h-6 flex-none', secondaryColor.text)}
                      />
                      <div className="text-gray-1">
                        <span className="text-h8 font-bold block mr-1.5 pt-0.5 3xl:text-h7 3xl:inline-block">
                          {slice.primary.bookingLabel}
                        </span>
                        {!!plan.enrollmentAmount && (
                          <span className="text-h8 font-bold block pt-0.5 3xl:text-h7 3xl:inline-block">
                            {formatCurrency(currencySymbol, plan?.enrollmentAmount, locale)}
                          </span>
                        )}
                        <span className="text-p3 font-medium block mt-0.5 text-gray-4">
                          {slice.primary.bookingDescription}
                        </span>
                        {hasPaymentTooltip && !slice.primary.isAsync && <PaymentMethod slice={slice} />}
                      </div>
                    </div>
                    )}
                    <div className="flex flex-row gap-1.5">
                      <Icon
                        icon="check-circle-solid"
                        className={classNames('w-6 h-6 flex-none', secondaryColor.text)}
                      />
                      <div className="text-gray-1">
                        <span className="text-h8 font-bold block mr-1.5 pt-0.5 3xl:text-h7 3xl:inline-block">
                          {slice.primary.installmentLabel}
                        </span>
                        {!!slice.primary.installmentCount && !!plan.installmentCount && (
                          <span className="text-h8 font-bold block pt-0.5 3xl:text-h7 3xl:inline-block">
                            {installmentFormat({
                              installmentCount: plan.installmentCount,
                              text: slice.primary.installmentCount,
                            })}
                          </span>
                        )}
                        <span className="text-p3 font-medium block mt-0.5 text-gray-5">
                          {slice.primary.installmentDescription}
                        </span>
                        {!!slice.primary.totalPriceLabel && (
                          <span className="text-gray-6 text-p4 font-medium mt-1.5 flex items-center gap-0.5">
                            {slice.primary.totalPriceLabel}
                            <Tooltip
                              tip={formatCurrency(currencySymbol, plan.programAmount, locale)}
                            >
                              <Icon icon="exclamation-circle" className="text-gray-6 w-3 h-3 inline-block" />
                            </Tooltip>
                          </span>
                        )}
                        {(slice.primary?.benefitsLabel && slice.primary?.benefitsLink?.uid) && (
                          <span className="text-p4 font-medium block mt-1.5 text-gray-5">
                            <LinkWithQuery
                              href={Link.url(
                                slice.primary?.benefitsLink,
                                prismicLinkResolver,
                              )}
                              target="_blank"
                              className="underline"
                            >
                              {slice.primary.benefitsLabel}
                            </LinkWithQuery>
                          </span>
                        )}
                        {hasPaymentTooltip && slice.primary.isAsync && <PaymentMethod slice={slice} />}
                      </div>
                    </div>
                    {!!installmentAmount && (
                    <div className="text-center lg:text-right relative">
                      {!!plan?.discount && (
                      <span className="
                        inline-block bg-green-550 text-p3 font-bold text-white-1 rounded-full py-1.5 px-3.5 mb-1.5
                        lg:absolute lg:-top-9 lg:right-0 lg:mb-0
                      "
                      >
                        {plan.discount}
                        % Off
                      </span>
                      )}
                      <span className={classNames('text-h4 font-bold block 3xl:text-h3', primaryColor.text)}>
                        {formatCurrency(currencySymbol, plan?.installmentAmount, locale)}
                        {slice.primary.installmentPriceSuffix}
                      </span>
                      {
                        !!plan?.discount
                        && plan?.remarkedInstallmentAmount
                        && (
                        <span className="line-through text-p2 font-medium text-gray-8">
                          {formatCurrency(currencySymbol, plan?.remarkedInstallmentAmount, locale)}
                          {slice.primary.installmentPriceSuffix}
                        </span>
                        )
                      }
                    </div>
                    )}
                    {slice.primary.showCheckoutButton && (
                      <div className="relative col-span-2 lg:col-span-1 whitespace-nowrap lg:pl-4">
                        <LinkWithQuery
                          key={nanoid()}
                          href={getCheckoutUrl(item, item.code, queryParams, slice._checkout?.sandbox, countryCode)}
                          onClick={(e: Event) => (
                            item.soldout
                              ? e.preventDefault()
                              : handleClick({
                                nextStep: NextStepFlow.checkout,
                                label: getDefaultText('checkoutButtonLabel', primaryMessages, defaultMessages),
                                url: getCheckoutUrl(
                                  item,
                                  item.code,
                                  queryParams,
                                  slice._checkout?.sandbox,
                                  countryCode,
                                ),
                                item,
                              })
                          )}
                          target="_self"
                          className={classNames(
                            'w-full dh-btn font-bold self-stretch text-p3 lg:text-p2 p-3 3xl:text-lg lg:px-7',
                            { ' bg-black-1 text-white-1 ': !item.soldout },
                            { 'pointer-events-none bg-transparent text-gray-6 border-2 border-gray-6': item.soldout },
                          )}
                        >
                          {
                        item.soldout
                          ? getDefaultText('checkoutButtonSoldoutLabel', primaryMessages, defaultMessages)
                          : getDefaultText('checkoutButtonLabel', primaryMessages, defaultMessages)
                        }
                        </LinkWithQuery>
                        {(item.lastplaces && !item.soldout) && (
                          <p className="
                            text-red-1 font-medium text-p4 w-full  mt-2.5
                            lg:text-p3 text-center lg:absolute"
                          >
                            {getDefaultText('checkoutButtonLastplaceLabel', primaryMessages, defaultMessages)}
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </article>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default TabsProductCardCheckout;
