import {
  useCallback, useContext, useEffect, useState,
} from 'react';
import classNames from 'classnames';
import { Link } from 'prismic-reactjs';
import { useIntl } from 'react-intl';
import { nanoid } from 'nanoid';
import set from 'lodash.set';
import cloneDeep from 'lodash.clonedeep';
import { CheckCircleIcon } from '@heroicons/react/outline';
import { SubmitHandler, useForm } from 'react-hook-form';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import { useTrackingPayload } from '../../../hooks/useTrackPayload';
import useLocale from '../../../hooks/useLocale';
import { getDefaultText, prismicLinkResolver, slugify } from '../../../utils/common';
import { getColor } from '../../../utils/colors';
import { AppContext } from '../../../contexts/AppContext';
import { TrackEvents } from '../../../utils/constants';
import { useAnalytics } from '../../../hooks/useAnalytics';

const LinkWithQuery = dynamic(() => import('../../../components/LinkWithQuery'), {
  ssr: false,
});
const FormInput = dynamic(() => import('../../../components/FormInput'), {
  ssr: false,
});
const Icon = dynamic(() => import('../../../components/Icon'), {
  ssr: false,
});
const Toast = dynamic(() => import('../../../components/Toast'), {
  ssr: false,
});

interface ContactForm {
  name: string;
  lastname: string;
  email: string;
  phonecode: string;
  phone: string;
  benefit: string;
  cluster: string;
  tyc: boolean;
}

const FormContactBenefitSelect = ({ slice }) => {
  const { track, identify } = useAnalytics();
  const intl = useIntl();
  const locale = useLocale();
  const [showToast, setShowToast] = useState<boolean>(false);
  const clusters = slice._clusters ?? [];
  const verticals = slice._verticals ?? [];
  const clustersWithBenefits = clusters.filter(({ data }) => data.showBenefitsButton);
  const color = getColor(slice._vertical?.data?.color);
  const {
    register, handleSubmit, reset, setValue,
  } = useForm<ContactForm>();
  const { state } = useContext(AppContext);
  const trackPayload = useTrackingPayload({ slice });

  const defaultMessages = {
    nameLabel: intl.formatMessage({ defaultMessage: 'Nombre' }),
    lastnameLabel: intl.formatMessage({ defaultMessage: 'Apellido' }),
    emailLabel: intl.formatMessage({ defaultMessage: 'E-mail' }),
    phoneCodeLabel: intl.formatMessage({ defaultMessage: 'Código de Área' }),
    phoneLabel: intl.formatMessage({ defaultMessage: 'Teléfono' }),
    phonePlaceholder: intl.formatMessage({ defaultMessage: '11 0000 0000' }),
    benefitLabel: intl.formatMessage({ defaultMessage: 'Beneficio' }),
    benefitPlaceholder: intl.formatMessage({ defaultMessage: 'Elegir beneficio' }),
    clusterLabel: intl.formatMessage({ defaultMessage: 'Curso de interés' }),
    clusterPlaceholder: intl.formatMessage({ defaultMessage: 'Elegir curso' }),
    sendButtonLabel: intl.formatMessage({ defaultMessage: 'Enviar' }),
  };

  const onSubmit: SubmitHandler<ContactForm> = useCallback(({
    name, lastname, email, phonecode, phone, cluster, benefit,
  }) => {
    const fields = {
      locale_country: locale,
      first_name: name,
      last_name: lastname,
      email,
      phone: `${phonecode}${phone}`,
    };
    const clusterData = clustersWithBenefits?.find(({ data }) => data.name === cluster)?.data;
    const vertical = verticals.find(({ uid }) => uid === clusterData?.verticalPage?.uid)?.data;
    const benefitData = state.benefits?.items?.find((item) => item.name === benefit);

    const payload = cloneDeep(trackPayload);
    set(payload, 'product_name', cluster);
    set(payload, 'product', {
      name: cluster,
      business_unit: clusterData?.businessUnit,
      vertical: vertical?.name,
    });
    set(payload, 'fields', fields);
    set(payload, 'fields.contact_reason', `Inscripcion ${benefit}`);
    set(payload, 'fields.discount_rate', `${benefitData?.name} - ${benefitData?.title} ${benefitData?.subtitle}`);

    identify(fields);

    track(TrackEvents.productApplicationSubmitted, payload, locale, true);

    setShowToast(true);
    reset();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackPayload]);

  useEffect(() => {
    if (state?.benefits?.selected?.name) {
      setValue(
        'benefit',
        state.benefits.selected.name,
        { shouldValidate: true },
      );
    }
  }, [state.benefits.selected, setValue]);

  return (
    <>
      <section className="bg-gray-900" id={slugify(slice.primary?.title)}>
        <div className="relative w-full overflow-hidden">
          <div className="bg-black-2 w-full h-full z-10 absolute opacity-90" />
          {slice.primary.image?.url && (
            <div className="w-full h-full absolute">
              <Image
                src={slice.primary.image?.url}
                alt={slice.primary.image?.alt}
                layout="fill"
                objectFit="cover"
                quality={70}
                className="object-cover h-full w-full"
              />
            </div>
          )}
          <div className="dh-content z-20 relative">
            <div className="bg-black-2 rounded-2xl px-5 sm:px-10 py-5 max-w-2xl w-full m-auto">
              <header className="mx-auto text-center">
                {slice.primary?.title && (
                <h2
                  id={slugify(slice.primary.title)}
                  className="dh-title mb-11 text-2xl leading-9 font-semibold text-white"
                >
                  <Icon icon="arrow-right-down-solid" className="inline-block w-8 mr-4 text-primary" />
                  {slice.primary.title}
                </h2>
                )}
              </header>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="sm:rounded-md text-gray-300">
                  <div className="py-5 sm:py-6">
                    <div className="grid sm:grid-cols-2 gap-6">
                      <div className="space-y-6">
                        <div>
                          <FormInput
                            darkSupport
                            label={getDefaultText('nameLabel', slice.primary, defaultMessages)}
                            placeholder={slice.primary?.namePlaceholder}
                            id="name"
                            required
                            register={register('name', {
                              required: true,
                            })}
                          />
                        </div>
                        <div>
                          <FormInput
                            darkSupport
                            label={getDefaultText('lastnameLabel', slice.primary, defaultMessages)}
                            placeholder={slice.primary?.lastnamePlaceholder}
                            id="lastname"
                            required
                            register={register('lastname', {
                              required: true,
                            })}
                          />
                        </div>
                        <div>
                          <FormInput
                            darkSupport
                            label={getDefaultText('emailLabel', slice.primary, defaultMessages)}
                            placeholder={slice.primary?.emailPlaceholder}
                            id="email"
                            type="email"
                            required
                            register={register('email', {
                              required: true,
                              pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
                            })}
                          />
                        </div>
                      </div>

                      <div className="space-y-6">
                        <div className="flex gap-6">
                          <div className="flex-none">
                            <FormInput
                              label={getDefaultText('phoneCodeLabel', slice.primary, defaultMessages)}
                              placeholder={slice.primary?.phoneCodePlaceholder}
                              id="phonecode"
                              className="w-20 lg:w-24"
                              register={register('phonecode', {
                                required: false,
                              })}
                            />
                          </div>

                          <div className="grow">
                            <FormInput
                              darkSupport
                              label={getDefaultText('phoneLabel', slice.primary, defaultMessages)}
                              placeholder={getDefaultText('phonePlaceholder', slice.primary, defaultMessages)}
                              id="phone"
                              register={register('phone', {
                                required: false,
                              })}
                            />
                          </div>
                        </div>
                        {!!state.benefits?.items?.length && (
                        <div>
                          <label
                            htmlFor="benefit"
                            className="text-white-3 block text-xs font-semibold mb-2"
                          >
                            {getDefaultText('benefitLabel', slice.primary, defaultMessages)}
                          </label>
                          <select
                            id="benefit"
                            defaultValue=""
                            className={classNames(
                              'h-10 block w-full pl-3 pr-10 py-2 text-base sm:text-sm rounded-md border',
                              'text-gray-900 bg-white placeholder-gray-800 border-black',
                              'focus:ring-gray-800 focus:border-gray-500',
                            )}
                            required
                            {
                            ...register('benefit', {
                              required: true,
                            })
                          }
                          >
                            <option key="select" value="" disabled>
                              {getDefaultText('benefitPlaceholder', slice.primary, defaultMessages)}
                            </option>
                            {state.benefits.items.map(({ name }) => (
                              <option
                                key={nanoid()}
                                value={name}
                              >
                                {name}
                              </option>
                            ))}
                          </select>
                        </div>
                        )}
                        {!!clustersWithBenefits.length && (
                        <div>
                          <label
                            htmlFor="cluster"
                            className="text-white-3 block text-xs font-semibold mb-2"
                          >
                            {getDefaultText('clusterLabel', slice.primary, defaultMessages)}
                          </label>
                          <select
                            id="cluster"
                            defaultValue=""
                            className={classNames(
                              'h-10 block w-full pl-3 pr-10 py-2 text-base sm:text-sm rounded-md border',
                              'text-gray-900 bg-white placeholder-gray-800 border-black',
                              'focus:ring-gray-800 focus:border-gray-500',
                            )}
                            required
                            {
                            ...register('cluster', {
                              required: true,
                            })
                          }
                          >
                            <option key="select" value="" disabled>
                              {getDefaultText('clusterPlaceholder', slice.primary, defaultMessages)}
                            </option>
                            {clustersWithBenefits?.map(({ data }) => (
                              <option key={nanoid()} value={data.name}>
                                {data.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {!!slice.primary?.termsAndConditionsLink?.link_type && (
                  <div className="text-right">
                    <div className="relative flex items-start">
                      <div className="flex items-center h-5">
                        <input
                          id="tyc"
                          aria-describedby="agree-terms-and-conditions"
                          type="checkbox"
                          className="focus:ring-grey-500 h-4 w-4 text-gey-600 border-gray-300 rounded"
                          required
                          {
                            ...register('tyc', {
                              required: true,
                            })
                          }
                        />
                      </div>
                      <div className="ml-3 text-sm font-normal font-archivo">
                        <label
                          htmlFor="tyc"
                          className="mr-2 text-white"
                        >
                          {intl.formatMessage({
                            defaultMessage: 'Aceptar',
                          })}
                        </label>
                        <LinkWithQuery
                          href={Link.url(
                            slice.primary.termsAndConditionsLink,
                            prismicLinkResolver,
                          )}
                          target="_blank"
                          className={classNames('underline', color.text)}
                        >
                          <span className="sr-only">
                            {intl.formatMessage({
                              defaultMessage: 'Aceptar',
                            })}
                            {' '}
                          </span>
                          {intl.formatMessage({
                            defaultMessage: 'Términos y condiciones',
                          })}
                        </LinkWithQuery>
                      </div>
                    </div>
                  </div>
                  )}
                  <div className="py-4 flex justify-center">
                    <button
                      type="submit"
                      className={classNames(
                        'dh-button py-2 text-sm w-48 max-w-full',
                        color.bg,
                        color.bgHover,
                        color.textContrast,
                      )}
                    >
                      {getDefaultText('sendButtonLabel', slice.primary, defaultMessages)}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

        </div>
      </section>
      <Toast
        show={showToast}
        setShow={setShowToast}
        icon={CheckCircleIcon}
        title={intl.formatMessage({
          defaultMessage: 'Gracias',
        })}
        message={intl.formatMessage({
          defaultMessage: 'Te contactaremos a la brevedad para asesorarte y acompañarte '
            + 'en la transformación que comenzaste hoy.',
        })}
      />
    </>
  );
};

export default FormContactBenefitSelect;
