import { nanoid } from 'nanoid';
import { RichText } from 'prismic-reactjs';
import { FunctionComponent, useRef } from 'react';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import useLocale from '../../../hooks/useLocale';
import useOnScreen from '../../../hooks/useOnScreen';
import { StatsAnimatedCounterProps } from '../Stats.types';

const AnimatedCounter = dynamic(() => import('../../../components/AnimatedCounter'), {
  ssr: false,
});

const StatsAnimatedCounter: FunctionComponent<StatsAnimatedCounterProps> = ({ slice }) => {
  const { items } = slice;
  const size = items?.length;
  const ref = useRef<HTMLDivElement>(null);
  const onScreen = useOnScreen(ref, '0px');
  const locale = useLocale();
  const hasThreeCards = size === 3;

  const formatNumber = (number: number): string => new Intl
    .NumberFormat(locale)
    .format(number);

  return size ? (
    <section ref={ref} className="bg-gray-3 text-white">
      <div className="dh-content">
        <div className={classNames(
          'grid md:flex md:flex-wrap md:justify-center font-bold text-center gap-9 md:gap-0',
          { 'lg:flex lg:justify-evenly lg:flex-row': hasThreeCards },
        )}
        >
          {
            items?.map(
              ({
                number, description, prefix, suffix,
              }) => (
                <div
                  className={classNames(
                    'justify-center self-center flex flex-col text-center md:w-1/2 md:p-8',
                    'odd:border-none md:border-l-4 border-white lg:items-center',
                    { 'lg:w-1/3 lg:even:border-x-4': hasThreeCards },
                  )}
                  key={nanoid()}
                >
                  <div className="whitespace-nowrap overflow-hidden text-ellipsis max-w-full
                  text-6xl leading-[5.25rem] font-normal  break-words
                  lg:text-5xl lg:font-bold lg:leading-[4.375rem]
                  3xl:text-[5rem] 3xl:leading-[7.625rem]"
                  >
                    {prefix}
                    <AnimatedCounter
                      number={number || 0}
                      duration={2000}
                      onScreen={onScreen}
                      formatNumber={formatNumber}
                    />
                    {suffix}
                  </div>
                  <span className="text-sm font-bold leading-5 pt-4 text-center text-athensGray inline-block
                  lg:font-normal lg:text-xl lg:leading-8 lg:h-16 lg:w-64"
                  >
                    <RichText render={description} />
                  </span>
                </div>
              ),
            )
          }
        </div>
      </div>
    </section>
  ) : null;
};

export default StatsAnimatedCounter;
