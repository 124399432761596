import { nanoid } from 'nanoid';
import Image from 'next/image';
import { Link } from 'prismic-reactjs';
import dynamic from 'next/dynamic';
import { hasLink } from '../../../utils/prismic';
import { prismicLinkResolver } from '../../../utils/common';

const LinkWithQuery = dynamic(() => import('../../../components/LinkWithQuery'), {
  ssr: false,
});
const ReactCarousel = dynamic(() => import('../../../components/ReactCarousel'), {
  ssr: false,
});

const itemsToShowSm = 1;
const itemsToShowLg = 3;
const breakPoints = [
  {
    width: 1,
    itemsToShow: itemsToShowSm,
    itemPadding: [0, 5, 0, 5],
    showArrows: false,
  },
  {
    width: 800,
    itemsToShow: itemsToShowLg,
    itemPadding: [0, 7.5, 0, 7.5],
    showArrows: false,
  },
];
const carouselOptions = {
  isRTL: false,
  pagination: false,
  showArrows: false,
  breakPoints,
};

const CarouselWithPills = ({ slice }) => {
  const quantity = !!slice.items.length;
  carouselOptions.breakPoints[1].showArrows = slice.items.length > itemsToShowLg;

  return quantity ? (
    <section className="-mb-1 bg-black-2 py-20 px-4 lg:px-0 lg:py-8.5 2xl:py-45 3xl:py-50">
      <div className="lg:w-10/12 xl:w-9/12 lg:max-w-7xl lg:m-auto 2xl:max-w-8xl
      3xl:max-w-8x5l 3xl:w-full 3xl:px-45"
      >
        <div>
          {slice.primary?.title && (
          <h2 className="text-white-1 font-bold text-center text-h4 pb-8
          lg:text-h2 lg:pb-15 3xl:text-h1"
          >
            {slice.primary.title}
          </h2>
          )}
        </div>
        <div className="">
          <ReactCarousel options={carouselOptions}>
            {slice.items?.map((item) => (
              <div
                key={nanoid()}
                className="rounded relative overflow-hidden py-8 px-6 h-full min-h-fit"
              >
                { item.backgroundImage?.url && (
                  <div className="w-full h-full absolute left-0 top-0">
                    <Image
                      className="object-cover w-full h-full absolute"
                      src={item.backgroundImage.url}
                      alt={item.backgroundImage.alt}
                      layout="fill"
                      objectFit="cover"
                      objectPosition="center center"
                    />
                  </div>
                )}
                <div className="w-full h-full absolute top-0 left-0 z-0 bg-black-1/80" />
                <div className="relative flex flex-col items-start justify-between h-full gap-15">
                  <div className="flex flex-col items-start gap-4.5 lg:gap-4">
                    {item.pillText && (
                    <p className="bg-transparent dh-pill py-1.25 px-3.5 text-p4 lg:py-[0.375rem] lg:px-4 lg:text-sm">
                      {item.pillText}
                    </p>
                    )}
                    {item.cardTitle && (
                    <p className="font-bold text-white-2
                    text-h7 lg:text-h4 3xl:text-h3"
                    >
                      {item.cardTitle}
                    </p>
                    )}
                  </div>
                  { hasLink(item.buttonLink) && item.buttonLabel && (
                  <LinkWithQuery
                    href={Link.url(item.buttonLink, prismicLinkResolver)}
                    target={item.buttonLink?.target ?? '_blank'}
                    className="bg-white-1 rounded-x text-sm text-center w-full py-3 px-2
                    lg:text-h8 xl:text-h7"
                  >
                    <p className="text-black-1 font-bold text-p3 lg:text-p2 3xl:text-h7">{item.buttonLabel}</p>
                  </LinkWithQuery>
                  )}
                </div>
              </div>
            ))}
          </ReactCarousel>
        </div>
      </div>
    </section>
  ) : null;
};

export default CarouselWithPills;
