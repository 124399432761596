import { RichText } from 'prismic-reactjs';
import { nanoid } from 'nanoid';
import { FunctionComponent } from 'react';
import dynamic from 'next/dynamic';
import { GridListsWithIconProps } from '../GridLists.types';

const Icon = dynamic(() => import('../../../components/Icon'), {
  ssr: false,
});

const GridListsWithIconLight: FunctionComponent<GridListsWithIconProps> = ({ slice }) => (
  slice?.items?.length ? (
    <section className="bg-white-2">
      <div className="dh-content">
        <ul className="grid sm:grid-cols-1 lg:grid-cols-3 gap-16">
          {slice.items.map(({ icon, description }) => (
            <li
              key={nanoid()}
              className="px-8 pt-16 pb-14 space-y-5 rounded-2xl border bg-white border-athensGray-700"
            >
              <Icon icon={icon} className="text-primary w-9" />
              <div className="text-lg text-gray-900 leading-8 font-bold">
                <RichText render={description} />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  ) : null);

export default GridListsWithIconLight;
