import { Link, RichText } from 'prismic-reactjs';
import classNames from 'classnames';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import { prismicLinkResolver } from '../../../utils/common';

const LinkWithQuery = dynamic(() => import('../../../components/LinkWithQuery'), {
  ssr: false,
});

const ContentWithImage = ({ slice }) => (
  <div
    className="bg-gray-2"
  >
    <div
      className={classNames(
        'dh-content py-20 grid grid-cols-1 text-center',
        'sm:gap-x-10 sm:grid-cols-2 sm:text-left',
      )}
    >
      {slice.primary?.image?.url && (
        <div
          className={classNames(
            'text-center',
          )}
        >
          <Image
            src={slice.primary?.image?.url}
            alt={slice.primary?.image?.alt}
            width={slice.primary?.image?.dimensions?.width}
            height={slice.primary?.image?.dimensions?.height}
            layout="responsive"
            objectFit="contain"
            className="rounded-2xl"
          />
        </div>
      )}
      {slice.primary?.description && (
        <div
          className={classNames(
            'flex items-center',
            'text-lg', // mobile
            'sm:m-0 sm:p-0', // desktop
          )}
        >
          <div
            className={classNames(
              'text-white-2 dark:text-white-2',
              'font-normal font-archivo my-richtext-6 text-base leading-6',
            )}
          >
            <h2 className={classNames(
              'dh-title font-semibold text-athensGray dark:text-athensGray text-xl',
              'leading-8 pt-6 sm:pt-0',
            )}
            >
              {slice.primary.title}
            </h2>
            <div className="text-base leading-7">
              <RichText render={slice.primary.description} />
            </div>
            {slice?.primary?.buttonLink?.url && (
            <div>
              <LinkWithQuery
                href={Link.url(slice.primary.buttonLink, prismicLinkResolver)}
                target={slice.primary.buttonLink.target ?? '_self'}
                className={classNames(
                  'dh-button-sm text-sm font-semibold text-athensGray',
                  'bg-transparent hover:bg-transparent border-blue border-2',
                )}
              >
                {slice.primary.buttonLabel}
              </LinkWithQuery>
            </div>
            )}
          </div>
        </div>
      )}
    </div>
  </div>
);

export default ContentWithImage;
