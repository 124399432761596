import classNames from 'classnames';
import { nanoid } from 'nanoid';
import { Link, RichText } from 'prismic-reactjs';
import {
  RefObject, useCallback, useEffect, useRef, useState,
} from 'react';
import cloneDeep from 'lodash.clonedeep';
import set from 'lodash.set';
import Image from 'next/image';
import groupBy from 'lodash.groupby';
import dynamic from 'next/dynamic';
import LinkWithQuery from '../../../components/LinkWithQuery';
import { useTrackingPayload } from '../../../hooks/useTrackPayload';
import { TrackEvents } from '../../../utils/constants';
import useLocale from '../../../hooks/useLocale';
import { getColor } from '../../../utils/colors';
import { useAnalytics } from '../../../hooks/useAnalytics';
import { getVerticalByLink, prismicLinkResolver, scrollTo } from '../../../utils/common';
import { hasLink, hasRichText } from '../../../utils/prismic';

const AccordionFull = dynamic(() => import('../../../components/AccordionFull'), {
  ssr: false,
});
const Tooltip = dynamic(() => import('../../../components/Tooltip'), {
  ssr: false,
});
const DialogDownload = dynamic(() => import('../../../components/DialogDownload'), {
  ssr: false,
});
const Icon = dynamic(() => import('../../../components/Icon'), {
  ssr: false,
});

const AccordionWithTabs = ({ slice }) => {
  const { track } = useAnalytics();
  const [tab, setTab] = useState<number>(1);
  const [openDialog, setOpenDialog] = useState(false);
  const accordionRef: RefObject<HTMLDivElement> = useRef(null);
  const tabsRef: RefObject<HTMLDivElement> = useRef(null);
  const locale = useLocale();

  const maxTabs = 2;
  const maxTabsItems = 4;
  const tabsColor = getColor(slice.primary.tabsColor, 600);
  const iconsColor = getColor(slice.primary.iconsColor, 800);

  const isSingleTab = !(slice.primary.tab1Label && slice.primary.tab2Label);
  const hasDescription = hasRichText(slice.primary.description);

  const tab2ButtonVertical = slice.primary.tab2ButtonUrl ? getVerticalByLink(
    slice.primary.tab2ButtonUrl,
    slice._verticals,
    slice._clusters,
    slice._products,
  ) : null;

  const accordionData = {
    bgColor: 'bg-gray-2',
    textColor: 'text-white-1',
    bgDescColor: 'bg-gray-4',
    chevronColor: 'text-white-2',
  };

  const vertical = slice?._vertical?.data;
  const cluster = slice?._cluster?.data;
  const clusterCheckout = {
    ...cluster,
    businessUnit: slice._checkout?.allProducts[0]?.defaultOffer?.businessUnit ?? cluster.businessUnit,
  };
  const trackPayload = useTrackingPayload({ slice, cluster: clusterCheckout, vertical });
  const groupItems = groupBy(slice.items, 'group');

  const handleClickBtnDownload = useCallback((label: string): void => {
    const payload = cloneDeep(trackPayload);
    set(payload, 'context.label', label);
    setOpenDialog(true);

    track(TrackEvents.brochureDownloadedClicked, payload, locale);
  }, [trackPayload, locale, track]);

  const setTabsMargin = () => {
    if (!accordionRef.current?.offsetTop || !tabsRef.current?.offsetTop) return;

    const offsetDiff = accordionRef.current.offsetTop - tabsRef.current.offsetTop;

    if (offsetDiff <= 0) return;

    tabsRef.current.style.marginTop = `${offsetDiff}px`;
  };

  useEffect(() => {
    const handleWindowSizeChange = () => {
      setTabsMargin();
    };

    setTabsMargin();

    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return (
    <section className="bg-black-2 text-white-2">
      <div className="dh-content-full py-20 lg:py-[8.75rem] 3xl:py-[12.5rem]">
        <div className="lg:max-w-7xl mx-auto 2xl:max-w-8xl 3xl:max-w-8x5l">
          <header className="text-center mb-20">
            {slice.primary.title && (
            <h1 className="font-bold text-h4 lg:text-h2 3xl:text-h1">
              <RichText render={slice.primary.title} />
            </h1>
            )}
            {hasDescription && (
            <div className="font-semibold text-p3 3xl:text-p2 mt-4">
              <RichText render={slice.primary.description} />
            </div>
            )}
          </header>
          <div className="flex flex-col lg:flex-row lg:gap-28 2xl:gap-36 3xl:gap-40 justify-center">
            <div className="mb-14 lg:mb-0 lg:w-7/12 2xl:w-6/12 flex flex-col gap-20">
              {Object.keys(groupItems).map((key, index) => {
                const groupTitle = slice.primary[`accordionGroupName${key}`];
                const tootlip = slice.primary[`accordionGroupNameTooltip${key}`];
                const hasGroupTitle = hasRichText(groupTitle);
                const accordionItems = groupItems[key];
                const hasAccordion = !!accordionItems?.length;
                const isFirstGroup = index === 0;

                if (!hasAccordion) return null;

                const getTitle = (item) => (
                  <span className="font-medium text-p2 lg:text-h6 3xl:text-h5">
                    <RichText render={item.title} />
                  </span>
                );

                const getDescription = (item) => (
                  <RichText render={item.description} />
                );

                const getFooter = (item) => {
                  if (!item.footerText && !item.footerLogo1.url && !item.footerLogo2.url) return null;

                  return (
                    <div className="flex flex-col items-start gap-2 lg:flex-row lg:gap-5 lg:items-center">
                      <div className="">{item.footerText}</div>
                      <div className="flex gap-4 lg:gap-5">
                        {!!item.footerLogo1?.url
                          && (
                            <Image
                              src={item.footerLogo1.url}
                              alt={item.footerLogo1.alt}
                              width={item.footerLogo1.dimensions.width}
                              height={item.footerLogo1.dimensions.height}
                            />
                          )}
                        {!!item.footerLogo2?.url
                          && (
                            <Image
                              src={item.footerLogo2.url}
                              alt={item.footerLogo2.alt}
                              width={item.footerLogo2.dimensions.width}
                              height={item.footerLogo2.dimensions.height}
                            />
                          )}
                      </div>
                    </div>
                  );
                };

                const items = accordionItems.map((item) => ({
                  title: getTitle(item),
                  description: getDescription(item),
                  footer: getFooter(item),
                }));

                return (
                  <div key={nanoid()}>
                    {
                      hasGroupTitle && (
                      <h2 className="text-h3 font-medium mb-8 flex flex-row items-center">
                        <RichText render={groupTitle} />
                        <Tooltip
                          tip={tootlip}
                          className="align-middle ml-2 hidden lg:inline-block"
                        >
                          <Icon icon="exclamation-circle" className="text-gray-8 w-6 h-6 inline-block" />
                        </Tooltip>
                      </h2>
                      )
                    }
                    <div ref={isFirstGroup ? accordionRef : undefined}>
                      <AccordionFull
                        items={items}
                        bgColor={accordionData.bgColor}
                        textColor={accordionData.textColor}
                        bgDescColor={accordionData.bgDescColor}
                        chevronColor={accordionData.chevronColor}
                      />
                    </div>
                  </div>
                );
              })}

            </div>
            <div
              className="py-8 px-6 lg:w-4/12 2xl:w-3/12 lg:px-8 text-gray-1 bg-white-1 rounded h-fit"
              ref={tabsRef}
            >
              <div className={classNames('border rounded-[1.875rem] p-1', tabsColor.border)}>
                <div className="flex flex-row relative">
                  <div className={classNames(
                    'absolute top-0 h-full rounded-[1.875rem] transition-all',
                    tabsColor.bg,
                    tabsColor.textContrast,
                    {
                      'left-0': tab === 1 || isSingleTab,
                      'left-1/2': tab !== 1 && !isSingleTab,
                      'w-1/2': !isSingleTab,
                      'w-full': isSingleTab,
                    },
                  )}
                  />
                  {slice.primary.tab1Label && (
                  <button
                    type="button"
                    className={classNames(
                      'p-2 text-center text-h6 font-bold cursor-pointer 3xl:text-h5',
                      {
                        'text-white-1 z-0': tab === 1,
                        'w-1/2': !isSingleTab,
                        'w-full': isSingleTab,
                        [tabsColor.text]: tab !== 1,
                      },
                    )}
                    onClick={() => setTab(1)}
                  >
                    {slice.primary.tab1Label}
                  </button>
                  )}
                  {slice.primary.tab2Label && (
                  <button
                    type="button"
                    className={classNames(
                      'p-2 text-center text-h6 font-bold cursor-pointer 3xl:text-h5',
                      {
                        'text-white-1 z-0': tab === 2,
                        'w-1/2': !isSingleTab,
                        'w-full': isSingleTab,
                        [tabsColor.text]: tab !== 2,
                      },
                    )}
                    onClick={() => setTab(2)}
                  >
                    {slice.primary.tab2Label}
                  </button>
                  )}
                </div>
              </div>
              {Array
                .from({ length: maxTabs })
                .map((_, iTab) => {
                  const isActiveTab = tab === iTab + 1;
                  return (
                    <ul
                      className={classNames('mt-8 flex flex-col gap-6', isActiveTab ? 'block' : 'hidden')}
                      key={nanoid()}
                    >
                      {Array
                        .from({ length: maxTabsItems })
                        .map((__, iTabItem) => {
                          const prefix = `tab${iTab + 1}Item${iTabItem + 1}`;
                          const icon = slice.primary[`${prefix}Icon`];
                          const title = slice.primary[`${prefix}Title`];
                          const description = slice.primary[`${prefix}Description`];
                          const hasItemDescription = hasRichText(description);
                          const tooltip = slice.primary[`${prefix}Tooltip`];

                          if (!title || !hasItemDescription) return null;

                          return (
                            <li className="flex flex-row gap-4" key={nanoid()}>
                              <span className="row-span-2 flex-none">
                                {icon && <Icon icon={icon} className={classNames('h-6 w-6', iconsColor.text)} />}
                              </span>
                              <div>
                                {title && (
                                  <div className="font-bold text-p2 3xl:text-h6">
                                    {title}
                                    <Tooltip
                                      tip={tooltip}
                                      className="text-p3 align-middle ml-1 hidden lg:inline-block"
                                    >
                                      <Icon icon="exclamation-circle" className="text-gray-700 w-4 h-4 inline-block" />
                                    </Tooltip>
                                  </div>
                                )}
                                {hasItemDescription && (
                                  <div className="font-medium text-p3 3xl:text-p2">
                                    <RichText render={description} />
                                  </div>
                                )}
                              </div>
                            </li>
                          );
                        })}
                    </ul>
                  );
                })}
              <div className="flex flex-col mt-10">
                {(tab === 1) && (
                  <>
                    {slice.primary.buttonDownloadLabel && (
                      <button
                        type="button"
                        onClick={() => handleClickBtnDownload(slice.primary.buttonDownloadLabel)}
                        className="dh-btn bg-black-1 text-white-1 text-p2 md:text-p2 md:p-3 3xl:text-h6 font-bold"
                      >
                        {slice.primary.buttonDownloadLabel}
                      </button>
                    )}
                    {slice.primary.buttonFaqLabel
                      && (
                      <button
                        type="button"
                        className="dh-btn bg-transparent underline font-medium text-p3 text-gray-700 mt-2 3xl:text-p2"
                        onClick={() => scrollTo({ id: slice.primary.buttonFaqScrollTo })}
                      >
                        {slice.primary.buttonFaqLabel}
                      </button>
                      )}
                  </>
                )}
                {(tab === 2) && (
                  <>
                    {hasLink(slice.primary.tab2ButtonUrl) && (
                      <LinkWithQuery
                        href={Link.url(
                          slice.primary.tab2ButtonUrl,
                          (doc) => prismicLinkResolver(doc, {
                            verticalId: tab2ButtonVertical?.uid, clusterId: tab2ButtonVertical?.clusterId,
                          }),
                        )}
                        className="dh-btn bg-black-1 text-white-1 text-p2 md:text-p2 md:p-3 3xl:text-h6 font-bold"
                      >
                        {slice.primary.tab2ButtonLabel}
                      </LinkWithQuery>
                    )}
                    {hasLink(slice.primary.tab2LinkUrl) && (
                      <LinkWithQuery
                        href={Link.url(
                          slice.primary.tab2LinkUrl,
                          (doc) => prismicLinkResolver(doc, {
                            verticalId: tab2ButtonVertical?.uid, clusterId: tab2ButtonVertical?.clusterId,
                          }),
                        )}
                        className="dh-btn bg-transparent underline font-medium text-p3 text-gray-700 mt-2 3xl:text-p2"
                      >
                        {slice.primary.tab2LinkLabel}
                      </LinkWithQuery>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <DialogDownload
        color={tabsColor}
        close={() => setOpenDialog(false)}
        open={openDialog}
        termsAndConditionsLink={slice.primary?.termsAndConditionsLink}
        buttonLabel={slice.primary?.buttonDownloadLabel}
        downloadFile={slice.primary?.buttonDownloadFile}
        cluster={slice?._cluster}
        trackPayload={trackPayload}
      />
    </section>
  );
};

export default AccordionWithTabs;
