import classNames from 'classnames';
import Image from 'next/image';
import { Link } from 'prismic-reactjs';
import dynamic from 'next/dynamic';
import { prismicLinkResolver, scrollTo, slugify } from '../../../utils/common';

const LinkWithQuery = dynamic(() => import('../../../components/LinkWithQuery'), {
  ssr: false,
});

const HeroImageTitleButton = ({ slice }) => {
  const handleClick = (id: string) => (event: Event): void => {
    event.preventDefault();
    scrollTo({ id: slugify(id) });
  };

  return (
    <section className="relative overflow-hidden h-screen md:h-101">
      <div className="w-full absolute top-0 left-0 max-h-161">
        {slice.primary?.image?.url && (
          <div className="w-full h-screen absolute z-0 md:h-101">
            <Image
              className="object-cover h-screen w-full"
              src={slice.primary.image.url}
              alt={slice.primary.image.alt}
              layout="fill"
              objectFit="cover"
              priority
            />
          </div>
        )}
        <div className="w-full absolute top-0 left-0 z-0 bg-black-2/80 h-screen md:h-101" />
      </div>
      <div className="dh-content relative z-0 flex flex-col justify-center items-center h-full">
        {slice.primary?.title && (
          <h1
            className={classNames(
              'text-athensGray text-center text-4xl font-semibold leading-15',
              'md:text-5xl md:font-bold',
            )}
          >
            {slice.primary.title}
          </h1>
        )}
        {!!(slice.primary.buttonLink || slice.primary.fragmentIdentifier) && slice.primary?.buttonLabel && (
        <LinkWithQuery
          onClick={slice.primary.fragmentIdentifier
            ? handleClick(slice.primary.fragmentIdentifier) : () => null}
          href={slice.primary.buttonLink ? Link.url(slice.primary.buttonLink, prismicLinkResolver) : null}
          className={classNames(
            'max-w-sm px-10 py-2 border border-transparent text-center m-x-auto mt-20 text-base leading-7',
            'font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700',
          )}
        >
          {slice.primary.buttonLabel}
        </LinkWithQuery>
        )}
      </div>
    </section>
  );
};
export default HeroImageTitleButton;
