import { FC } from 'react';
import { nanoid } from 'nanoid';
import Image from 'next/image';
import { Link, RichText } from 'prismic-reactjs';
import LazyLoad from 'react-lazyload';
import dynamic from 'next/dynamic';
import { hasRichText } from '../../../utils/prismic';
import { prismicLinkResolver } from '../../../utils/common';
import { GridListsProfilesProps } from '../GridLists.types';

const LinkWithQuery = dynamic(() => import('../../../components/LinkWithQuery'), {
  ssr: false,
});
const Icon = dynamic(() => import('../../../components/Icon'), {
  ssr: false,
});
const ReactCarousel = dynamic(() => import('../../../components/ReactCarousel'), {
  ssr: false,
});

const carouselOptions = {
  isRTL: false,
};
const Item = ({ item }) => (
  <article className="w-full lg:w-2/10 lg:p-1.5">
    <div className="py-8 px-6 bg-gray-3 rounded flex flex-col items-center h-full">
      <header className="flex flex-col items-center text-center">
        {item.image?.url && (
        <div className="flex-none w-[7.875rem] h-[7.875rem] rounded-full overflow-hidden mb-6">
          <Image
            src={item.image.url}
            alt={item.image.alt}
            width={item.image.dimensions.width}
            height={item.image.dimensions.height}
            layout="intrinsic"
            objectFit="contain"
            objectPosition="center"
            className="object-contain mx-auto"
          />
        </div>
        )}
        <h3 className="text-h8 font-bold text-white-1 2xl:text-h7">
          {item.name}
        </h3>
        <h4 className="text-p3 font-medium mt-0.5 text-white-2 2xl:text-p2">
          {item.role}
        </h4>
      </header>

      <span className="flex flex-col justify-end h-full">
        {item.company?.url && (
        <div className="flex-none bg-black px-5 py-1.5 rounded-4xl mt-6 h-9 w-auto">
          <Image
            src={item.company.url}
            alt={item.company.alt}
            width={item.company.dimensions.width}
            height={item.company.dimensions.height}
            layout="intrinsic"
            className="flex-none"
          />
        </div>
        )}
        {item.link?.url && (
        <LinkWithQuery
          key={nanoid()}
          href={Link.url(item.link, prismicLinkResolver)}
          target={item.link?.target ?? '_self'}
          className="text-p4 text-white-2 text-center mt-4 font-medium 2xl:text-p3"
        >
          <span className="underline">{item.linkLabel}</span>
          <Icon icon="chevron-right" className="w-3.5 inline-block" />
        </LinkWithQuery>
        )}
      </span>
    </div>
  </article>
);

const GridListsProfiles: FC<GridListsProfilesProps> = ({ slice }) => {
  if (!slice.items?.length) return null;

  return (
    <section className="bg-black-2">
      <div className="dh-content-full py-17.5 text-white-1 3xl:py-[5.938rem]
      lg:m-auto lg:max-w-7xl 2xl:max-w-8xl 3xl:max-w-8x5l"
      >
        {hasRichText(slice.primary?.title) && (
        <header className="text-center mb-15.5 text-h4 lg:text-h2 3xl:text-h1">
          <h2>
            <RichText render={slice.primary?.title} />
          </h2>
        </header>
        )}
        <div className="w-10/12 flex-wrap mx-auto justify-center hidden lg:flex">
          {slice.items.map((item) => <Item key={nanoid()} item={item} />)}
        </div>

        <div className="lg:hidden">
          <LazyLoad>
            <ReactCarousel options={carouselOptions}>
              {slice.items.map((item) => <Item key={nanoid()} item={item} />)}
            </ReactCarousel>
          </LazyLoad>
        </div>
      </div>
    </section>
  );
};

export default GridListsProfiles;
