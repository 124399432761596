import classNames from 'classnames';
import Image from 'next/image';

const HeroOnlyImage = ({ slice }) => (
  <section
    className={classNames('relative pt-60 bg-white', slice.primary?.classname, {
      'lg:hidden': slice.primary?.hideDesktop,
    })}
  >
    <div className="h-screen w-full absolute top-0 left-0 z-0">
      {slice.primary?.image?.url && (
        <Image
          className="w-full h-full object-center object-cover relative"
          src={slice.primary.image.url}
          alt={slice.primary.image.alt}
          layout="fill"
          objectFit="cover"
          priority
        />
      )}
      <div
        className={classNames(
          'absolute left-0 bottom-0 w-full h-3/4 z-10 mx-auto max-w-full bg-gradient-to-t dark:from-black from-white',
          slice.primary?.classname,
        )}
      />
    </div>
  </section>
);

export default HeroOnlyImage;
