import { EPricingVariations } from './Pricing.types';
import PricingCards from './variations/PricingCards';

const Marquee = ({ slice }) => {
  switch (slice.variation) {
    case EPricingVariations.pricingCards:
      return <PricingCards slice={slice} />;
    default:
      return null;
  }
};

export default Marquee;
