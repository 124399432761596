import { nanoid } from 'nanoid';
import { Link, RichText } from 'prismic-reactjs';
import dynamic from 'next/dynamic';
import { useCallback, useState } from 'react';
import classNames from 'classnames';
import groupBy from 'lodash.groupby';
import cloneDeep from 'lodash.clonedeep';
import { getColor } from '../../../utils/colors';
import { hasLink, hasRichText } from '../../../utils/prismic';
import { getVerticalByLink, prismicLinkResolver } from '../../../utils/common';

const LinkWithQuery = dynamic(() => import('../../../components/LinkWithQuery'), {
  ssr: false,
});
const ReactCarousel = dynamic(() => import('../../../components/ReactCarousel'), {
  ssr: false,
});

const TABS_BG = {
  cursos: getColor('coral'),
  cursosLive: getColor('green'),
  cursosOnDemand: getColor('orange'),
  carreras: getColor('violet'),
  certificaciones: getColor('pink'),
  especializaciones: getColor('pink'),
  escuelas: getColor('yellow'),
  empresas: getColor('blue'),
  membresia: getColor('violet'),
  default: getColor('blue'),
};

const itemsToShowSm = 1;
const itemsToShowLg = 4;
const breakPoints = [
  {
    width: 1,
    itemsToShow: itemsToShowSm,
    itemPadding: [0, 5, 0, 5],
    showArrows: false,
    pagination: true,
  },
  {
    width: 600,
    itemsToShow: itemsToShowLg,
    itemPadding: [0, 7.5, 0, 7.5],
    showArrows: true,
    pagination: true,
  },
];
const carouselOptions = {
  isRTL: false,
  pagination: false,
  showArrows: true,
  breakPoints,
};

const wrapperSize = {
  2: 'lg:w-[610px]',
  3: 'lg:w-[900px]',
  default: '2xl:w-9/12',
};

const CarouselWithTabs = ({ slice }) => {
  const group = groupBy(slice.items, 'tab');
  const [tabItems] = useState(group);
  const [tab, setTab] = useState<string>(Object.keys(group)[0]);
  const renderTabs = useCallback(() => (
    <div className="flex justify-center mt-8 flex-grow">
      <ul className="grid grid-cols-2 lg:inline-flex flex-row bg-[#41414A] p-0.5 rounded m-auto">
        {Object.keys(tabItems).map((key) => {
          const color = getColor(tabItems[key]?.[0]?.color) ?? TABS_BG.default;
          return (
            <li key={`tab_${key}`}>
              <button
                type="button"
                onClick={() => setTab(key)}
                className={classNames(
                  'rounded border border-[#4B5563] min-w-[144px] px-3 min-h-[40px]',
                  'h-full capitalize w-full text-xs sm:text-base',
                  { [`${color.bg} ${color.textContrast}`]: key === tab },
                  { 'bg-[#2C2C31]': key !== tab },
                )}
              >
                {key}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  ), [tabItems, tab, setTab]);

  if (!slice.items.length) return null;

  return (
    <section className="-mb-1 bg-black-2 py-20 px-4 lg:px-0 text-white-1">
      <div className="lg:w-10/12 lg:m-auto">
        <header>
          {slice.primary?.title && (
            <h2 className="text-white-1 text-3xl font-extrabold text-center lg:text-[48px]">
              {slice.primary.title}
            </h2>
          )}
          {hasRichText(slice.primary?.description) && (
            <div className="text-base lg:text-xl font-normal text-center w-full mt-5">
              <RichText render={slice.primary?.description} />
            </div>
          )}
        </header>

        {renderTabs()}

        <div className="min-h-[400px] transition-all">
          {
            Object.keys(tabItems).map((key) => {
              const items = tabItems[key];
              const tabOptions = cloneDeep(carouselOptions);
              tabOptions.breakPoints[1].showArrows = items.length > itemsToShowLg;
              tabOptions.breakPoints[1].pagination = items.length > itemsToShowLg;

              return (
                <div
                  className={classNames(
                    'mt-16 m-auto',
                    {
                      hidden: tab !== key,
                      [wrapperSize[2]]: items.length === 2,
                      [wrapperSize[3]]: items.length === 3,
                      [wrapperSize.default]: items.length >= 4,
                    },
                  )}
                  key={`items_${key}`}
                >
                  <ReactCarousel options={tabOptions}>
                    {items?.map((item) => {
                      const { uid: verticalId, clusterId } = getVerticalByLink(
                        item.link,
                        slice._verticals ?? [slice._vertical],
                        slice._clusters ?? [slice._cluster],
                        slice._products ?? [slice._product],
                      );
                      return (
                        <article
                          key={nanoid()}
                          className="rounded-lg relative overflow-hidden h-full min-h-fit bg-[#202024] lg:max-w-[288px]"
                        >
                          <header className="flex flex-col items-start border-b border-white-1 p-6">
                            {item.title && (
                              <h3 className="font-medium text-lg mb-4">
                                {item.title}
                              </h3>
                            )}
                            {hasRichText(item.description) && (
                              <div className="text-sm font-normal">
                                <RichText render={item.description} />
                              </div>
                            )}
                            {hasLink(item.link) && item.linkLabel && (
                              <LinkWithQuery
                                href={Link.url(item.link, (doc) => prismicLinkResolver(doc, { verticalId, clusterId }))}
                                target={item.link?.target ?? '_self'}
                                className="
                              bg-[#4B5563] rounded-md text-sm text-center
                              font-medium w-full py-3 px-2 text mt-12
                            "
                              >
                                {item.linkLabel}
                              </LinkWithQuery>
                            )}
                          </header>
                          <div className="p-6">
                            {hasRichText(item.content) && (
                              <div className="text-gray-200 font-normal text-xs my-richtext-list-checklist">
                                <RichText render={item.content} />
                              </div>
                            )}
                          </div>
                        </article>
                      );
                    })}
                  </ReactCarousel>
                </div>
              );
            })
          }
        </div>
      </div>
    </section>
  );
};

export default CarouselWithTabs;
