import global from './global';

/**
 * Get experiments data
 */
export const getExperiments = (): { id: string, variant: string }[] | undefined => {
  try {
    const experiments = global.gaData[`${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_KEY}`]?.experiments;

    return experiments ? Object.keys(experiments).map((item) => ({
      id: item,
      variant: experiments[item],
    })) : undefined;
  } catch (e) {
    return undefined;
  }
};
