import {
  FC, ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import cloneDeep from 'lodash.clonedeep';
import set from 'lodash.set';
import { RichText } from 'prismic-reactjs';
import { CheckIcon, FireIcon } from '@heroicons/react/solid';
import { IProductGroup } from 'graphql/model/interfaces';
import Link from 'next/link';
import { AppContext } from '../../../contexts/AppContext';
import useGeoCountry from '../../../hooks/useGeoCountry';
import { useCheckoutQueryParams } from '../../../hooks/useCheckoutQueryParams';
import useLocale from '../../../hooks/useLocale';
import { useAnalytics } from '../../../hooks/useAnalytics';
import { useTrackingPayload } from '../../../hooks/useTrackPayload';
import { getCheckoutUrl, getLocalePlans } from '../../../utils/checkout';
import { NextStepFlow, TrackEvents } from '../../../utils/constants';
import { hasRichText } from '../../../utils/prismic';
import { Color, getColor } from '../../../utils/colors';
import { getDefaultText, scrollTo } from '../../../utils/common';
import { plansByCurrency, reducePlans, sortPlans } from './helpers';
import { IPricingCards } from '../Pricing.types';
import PricingEmpty from './PricingEmpty';

interface IHandleClickData {
  nextStep: NextStepFlow, label?: string, url: string, item: IProductGroup
}

const GRID_COLS = {
  1: 'lg:!grid-cols-1 lg:max-w-[400px] lg:mx-auto',
  2: 'lg:!grid-cols-2 lg:max-w-[800px] lg:mx-auto',
  3: 'lg:!grid-cols-3',
  4: 'lg:!grid-cols-4',
  5: 'lg:!grid-cols-5',
  6: 'lg:!grid-cols-6',
};

const formatCurrency = (symbol: string, number: number, locale: string): ReactNode => {
  const numberFormatted = new Intl
    .NumberFormat(locale)
    .format(number);

  return (
    <>
      <small>{symbol}</small>
      {' '}
      {numberFormatted}
    </>
  );
};

const PricingCards: FC<IPricingCards> = ({ slice }) => {
  const { state } = useContext(AppContext);

  if (slice.primary.isAsync) {
    const allProducts = slice._checkout?.allProducts?.map((p) => ({
      ...p,
      startsAt: '',
      endsAt: '',
    }));

    // eslint-disable-next-line no-param-reassign
    if (slice._checkout?.products) slice._checkout.products = allProducts;
  }

  const products = useMemo(() => slice._checkout?.products, [slice._checkout?.products]);
  const vertical = slice._vertical?.data;
  const cluster = slice._cluster?.data;

  const locale = useLocale();
  const geoCountry = useGeoCountry();
  const [showSlice, setShowSlice] = useState<boolean>(false);
  const [currencyCode, setCurrencyCode] = useState<string>();
  const [countryCode, setCountryCode] = useState<string>('');
  const queryParams = useCheckoutQueryParams();
  const { track } = useAnalytics();
  const trackPayload = useTrackingPayload({ slice, cluster, vertical });
  const intl = useIntl();
  const primaryMessages = slice.primary as { [key: string]: string };
  const defaultMessages = {
    checkoutButtonLabel: intl.formatMessage({ defaultMessage: 'Reservar' }),
    checkoutButtonLastplaceLabel: intl.formatMessage({ defaultMessage: '¡Ultimos cupos!' }),
    checkoutButtonSoldoutLabel: intl.formatMessage({ defaultMessage: 'Sold out' }),
  };
  const hasMounted = useRef(false);

  useEffect(() => {
    const {
      currentLocale, currentCurrency, hasPlans, isUnavailable,
    } = getLocalePlans({
      products,
      geoCountry,
      locale,
      currency: state.currency.selected,
      allCurrencies: state.currency.all,
    });

    setShowSlice(!!(hasPlans && !isUnavailable));
    setCountryCode(currentLocale.countryCode);
    setCurrencyCode(currentCurrency);
  }, [geoCountry, locale, products, state.currency]);

  useEffect(() => {
    if (hasMounted.current) {
      scrollTo({ id: slice.primary.title as string });
    } else {
      setTimeout(() => { hasMounted.current = true; }, 1000);
    }
  }, [state.currency.selected]); // eslint-disable-line react-hooks/exhaustive-deps

  const plans = useMemo(() => {
    const getPlans = (pSlice, pProducts, pCurrencyCode) => {
      if (!pCurrencyCode) return [];

      const productsReduced = reducePlans(pProducts);
      const productsSorted = sortPlans(productsReduced, pSlice.items?.map((i) => i.installments ?? 0) ?? []);
      const p = plansByCurrency(productsSorted, pCurrencyCode);

      return p;
    };

    return getPlans(slice, products, currencyCode);
  }, [slice, products, currencyCode]);

  const handleClick = useCallback(({
    nextStep, label = '', url, item,
  }: IHandleClickData): void => {
    const payload = cloneDeep(trackPayload);
    set(payload, 'context.next_step', nextStep);
    set(payload, 'context.label', label);
    set(payload, 'context.destination_url', url);
    set(payload, 'product.start_date', item.startsAt);
    set(payload, 'product.id', item.id);
    set(payload, 'product.business_unit', item.defaultOffer.businessUnit);
    set(payload, 'product.business_unit_id', item.defaultOffer.businessUnitId);
    set(payload, 'product.vertical', item.defaultOffer.vertical);
    set(payload, 'product.vertical_id', item.defaultOffer.verticalId);
    set(payload, 'product.code', item.code);
    set(payload, 'product.duration', item.duration?.name);

    track(TrackEvents.productApplicationClicked, payload, locale);
  }, [trackPayload, locale, track]);

  const color: Color = getColor(slice.primary.color);

  if (!plans?.length && slice.primary.btnLabelAlt && slice.primary.urlAlt) return <PricingEmpty slice={slice} />;
  if (!showSlice) return null;
  if (!currencyCode) return null;

  return (
    <section className="isolate overflow-hidden" key={currencyCode}>
      <div className="pricing-cards">
        <div className="dh-content">
          <div className="relative z-10">
            <h2 className="pricing-cards__title">
              {slice.primary.title}
            </h2>
            {hasRichText(slice.primary.description) && (
              <div className="pricing-cards__description">
                <RichText render={slice.primary.description} />
              </div>
            )}
          </div>
          {!!plans?.length && (
            <div className={classNames('pricing-cards__plan', GRID_COLS[plans.length])}>
              {plans.map((item: any) => {
                if (item.currency !== currencyCode) return null;

                const product = products?.find(({ code }) => code === item.code) ?? {};
                const installmentProps = slice.items?.find((i) => `${i.installments}` === item.installmentOfferKey)
                  ?? slice.items?.find((i) => i.installments === +item.installmentCount)
                  ?? {};
                const installmentAmount = item?.installmentAmount ?? item?.remarkedInstallmentAmount;

                const checkoutLink = getCheckoutUrl(
                  item,
                  item.code,
                  queryParams,
                  slice._checkout?.sandbox,
                  countryCode,
                  item.id,
                  installmentProps.checkoutVersion ?? 'legacy',
                );

                return (
                  <div
                    key={item.id}
                    className={classNames(
                      'pricing-plan',
                      {
                        'pricing-plan_featured': installmentProps.featured,
                        'pricing-plan_standar': !installmentProps.featured,
                        'pricing-plan_soldout': product.soldout,
                      },
                    )}
                  >
                    <div className="pricing-plan__wrapper">
                      <h3
                        id={item.id}
                        className={classNames(
                          'pricing-plan__installments',
                          {
                            'pricing-plan__installments_featured': installmentProps.featured,
                          },
                        )}
                      >
                        {installmentProps.title?.replace('_installments_', item.installmentCount)}
                      </h3>
                      <div className="pricing-plan__price">
                        <div className="pricing-plan-price">
                          {!!item.discount && (
                            <div className="pricing-plan-discount">
                              <p className={classNames(
                                'pricing-plan-discount__percentage',
                                {
                                  'pricing-plan-discount__percentage_soldout': product.soldout,
                                },
                              )}
                              >
                                -
                                {item.discount}
                                %
                              </p>
                              <p
                                className={classNames(
                                  'pricing-plan-discount__original-price',
                                  {
                                    'pricing-plan-discount__original-price_featured': installmentProps.featured,
                                  },
                                )}
                              >
                                <span className="line-through text-xs">
                                  {formatCurrency(currencyCode, item.remarkedInstallmentAmount, locale)}
                                </span>
                              </p>
                            </div>
                          )}
                          <p
                            className={classNames(
                              'pricing-plan-price__final-price',
                              {
                                'pricing-plan-price__final-price_featured': installmentProps.featured,
                              },
                            )}
                          >
                            {formatCurrency(currencyCode, installmentAmount, locale)}
                          </p>
                        </div>

                        <Link
                          href={checkoutLink}
                          onClick={(e: Event) => (
                            product.soldout
                              ? e.preventDefault()
                              : handleClick({
                                nextStep: NextStepFlow.checkout,
                                label: getDefaultText('checkoutButtonLabel', primaryMessages, defaultMessages),
                                url: checkoutLink,
                                item: product,
                              })
                          )}
                          target="_self"
                          passHref
                        >
                          <a
                            href={checkoutLink}
                            className={classNames(
                              'pricing-plan-price__button',
                              {
                                [color.bg]: !product.soldout,
                                [color.textContrast]: !product.soldout,
                                'pricing-plan-price__button_soldout': product.soldout,
                              },
                            )}
                          >
                            {
                            product.soldout
                              ? getDefaultText('checkoutButtonSoldoutLabel', primaryMessages, defaultMessages)
                              : getDefaultText('checkoutButtonLabel', primaryMessages, defaultMessages)
                          }
                          </a>
                        </Link>
                      </div>
                      <div className="pricing-plan__benefits">
                        <ul
                          className={classNames(
                            'pricing-plan-bullets',
                            {
                              'pricing-plan-bullets_featured': installmentProps.featured,
                              'pricing-plan-bullets_standar': !installmentProps.featured,
                            },
                          )}
                        >
                          {product.lastplaces && (
                            <li className={classNames(
                              'pricing-plan-bullets__item pricing-plan-bullets__last-places',
                              {
                                'pricing-plan-bullets__last-places_featured': installmentProps.featured,
                              },
                            )}
                            >
                              <FireIcon
                                className={classNames(
                                  'pricing-plan-bullets__icon',
                                  {
                                    [color.text]: !product.soldout,
                                    'pricing-plan-bullets__icon_soldout': product.soldout,
                                  },
                                )}
                                aria-hidden="true"
                              />
                              {getDefaultText('checkoutLastPlacesLabel', primaryMessages, defaultMessages)}
                            </li>
                          )}
                          {installmentProps.description?.map(({ text }) => (
                            <li
                              key={text}
                              className={classNames(
                                'pricing-plan-bullets__item',
                                {
                                  'pricing-plan-bullets__item_featured': installmentProps.featured,
                                },
                              )}
                            >
                              <CheckIcon
                                className={classNames(
                                  'pricing-plan-bullets__icon',
                                  {
                                    [color.text]: !product.soldout,
                                    'pricing-plan-bullets__icon_soldout': product.soldout,
                                  },
                                )}
                                aria-hidden="true"
                              />
                              {text}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default PricingCards;
