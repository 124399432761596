import { FC } from 'react';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import { getColor } from '../../../utils/colors';
import { TabsWithAccordionOnMobileProps } from '../Tabs.types';

const Accordion = dynamic(() => import('../../../components/Accordion'), {
  ssr: false,
});
const Icon = dynamic(() => import('../../../components/Icon'), {
  ssr: false,
});
const Tabs = dynamic(() => import('../../../components/Tabs'), {
  ssr: false,
});

const TabsWithAccordionOnMobile: FC<TabsWithAccordionOnMobileProps> = ({ slice }) => {
  const vertical = slice._vertical;
  const color = getColor(vertical?.data?.color);

  return (slice.items?.length ? (
    <section className="bg-gray-2">
      <div className="dh-content max-w-4xl">
        {slice.primary?.title && (
          <h3 className={classNames(
            'font-semibold text-athensGray text-xl leading-8 mb-6 text-center',
            'sm:text-2xl sm:leading-9 sm:text-left',
          )}
          >
            <Icon icon="arrow-right-down-solid" className="hidden sm:inline-block text-pink w-8 mr-3 -rotate-90" />
            {slice.primary.title}
          </h3>
        )}
        <div className="bg-white-2 rounded-2xl py-9 px-7 sm:px-11">
          {slice.primary?.title && (
            <header className="text-center text-gray-1 text-2xl leading-9 font-semibold mb-8">
              <h2>
                {slice.primary.tabsTitle}
              </h2>
            </header>
          )}
          <div className="sm:hidden">
            <Accordion items={slice.items} activeColor={color.text} />
          </div>
          <div className="hidden sm:block">
            <Tabs items={slice.items} activeColor={color.text} />
          </div>
        </div>
      </div>
    </section>
  ) : null);
};

export default TabsWithAccordionOnMobile;
