import classNames from 'classnames';
import { RichText } from 'prismic-reactjs';
import { FC } from 'react';
import { ContentProps } from '../Content.types';

const ContentDescriptiveBox: FC<ContentProps> = ({ slice }) => (
  slice?.primary?.description?.length ? (
    <section className="dh-content-full">
      <div
        className={classNames(
          'w-full px-6 py-12 mx-auto bg-gray-2 text-white-1 rounded',
          'lg:px-30 lg:py-27 3xl:w-10/12',
        )}
      >
        {slice?.primary?.title && <div className="text-xs mb-2 lg:mb-6 lg:text-2xl">{slice?.primary?.title}</div>}
        <div className="text-base lg:text-3.5xl">
          <RichText render={slice.primary.description} />
        </div>
      </div>
    </section>
  ) : null);

export default ContentDescriptiveBox;
