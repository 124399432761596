import { RichText, RichTextBlock } from 'prismic-reactjs';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

const MAX_SIZE = 3;

interface FaqItem {
  question: string;
  answer: RichTextBlock[];
}

const FaqAccordion = ({ slice }) => {
  const intl = useIntl();
  const [expanded, setExpanded] = useState<boolean>();
  const [items, setItems] = useState<FaqItem[]>([]);
  const size = slice?.items?.length;
  const isExpandable = size > MAX_SIZE;
  const seeMoreLabel = intl.formatMessage({ defaultMessage: 'Ver más' });
  const seeLessLabel = intl.formatMessage({ defaultMessage: 'Ver menos' });

  useEffect(() => {
    setItems(expanded ? slice.items : slice?.items?.slice(0, MAX_SIZE));
  }, [expanded, slice.items, setItems]);

  return size ? (
    <section className="bg-athensGray">
      <div className="dh-content">
        <div className="max-w-3xl mx-auto">
          {slice.primary?.title && (
            <div className="flex flex-col sm:flex-row">
              <h2 className="dh-title text-left flex-none dark:text-gray-900">
                {RichText.asText(slice.primary.title)}
              </h2>
              { isExpandable && (
              <div className="w-full flex sm:justify-end">
                <button
                  type="button"
                  className="text-primary underline cursor-pointer"
                  onClick={() => setExpanded((prev) => !prev)}
                >
                  { expanded ? seeLessLabel : seeMoreLabel }
                </button>
              </div>
              )}
            </div>
          )}
          <dl className="mt-6 pb-5 space-y-6 divide-y divide-gray-200">
            {items?.map((faq) => (
              <Disclosure as="div" key={nanoid()} className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                        <span className="font-medium text-gray-900">
                          {faq.question}
                        </span>
                        <span className="ml-3 lg:ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                            className={classNames(
                              open ? '-rotate-180' : 'rotate-0',
                              'h-6 w-6 transform',
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-5">
                        {RichText.asText(faq.answer)}
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </section>
  ) : null;
};

export default FaqAccordion;
