import { RichText } from 'prismic-reactjs';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import { getColor } from '../../../utils/colors';
import { ContentVariations } from '../Content.types';

const HighlightText = dynamic(() => import('../../../components/HighlightText'), {
  ssr: false,
});

const ContentWithTwoColumns = ({ slice }) => {
  const color = getColor(slice._vertical?.data?.color);
  const isDark = slice.variation !== ContentVariations.withTwoColumnsLight;

  return (
    <div
      className={classNames({
        'bg-gray-2 text-white': isDark,
        'bg-athensGray-400 text-gray-800': !isDark,
      })}
    >
      <div
        className={classNames(
          'dh-content grid grid-cols-1 text-center',
          'lg:gap-x-10 lg:grid-cols-2 lg:text-left',
        )}
      >
        {slice.primary?.title && (
          <div
            className={classNames('lg:pr-28 lg:items-center lg:flex', {
              'lg:pl-24 lg:justify-end': isDark,
              'lg:pr-28': !isDark,
            })}
          >
            <div className="lg:max-w-sm">
              <h2
                className={classNames({
                  'dh-title text-3.5xl text-white': isDark,
                  'text-2xl font-bold leading-8 text-black': !isDark,
                })}
              >
                <HighlightText
                  text={slice.primary.title}
                  highlight={slice.primary?.highlightedText}
                  className={classNames(color.textContrast, color.bg)}
                />
              </h2>
            </div>
          </div>
        )}
        {slice.primary?.description && (
          <div
            className={classNames('text-lg font-normal lg:m-0 lg:p-0', {
              'lg:px-8': isDark,
            })}
          >
            <div
              className={classNames(
                'my-richtext-6 font-archivo text-base leading-7',
                {
                  'dh-description': isDark,
                  'font-medium leading-7': !isDark,
                },
              )}
            >
              <RichText render={slice.primary.description} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContentWithTwoColumns;
