import { nanoid } from 'nanoid';
import { FC } from 'react';
import Marquee from 'react-easy-marquee';
import Image from 'next/image';
import { Link, RichText } from 'prismic-reactjs';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import useLocale from '../../../hooks/useLocale';
import { getLocale, prismicLinkResolver } from '../../../utils/common';
import { MarqueeTestimonialsProps } from '../Marquee.types';
import { getColor } from '../../../utils/colors';
import { ReactCarouselProps } from '../../../components/ReactCarousel';

const LinkWithQuery = dynamic(() => import('../../../components/LinkWithQuery'), {
  ssr: false,
});
const ReactCarousel = dynamic(() => import('../../../components/ReactCarousel'), {
  ssr: false,
});

const flagsBasePath = 'assets/flags';
const flags = {
  argentina: `${flagsBasePath}/ar.svg`,
  brasil: `${flagsBasePath}/br.svg`,
  chile: `${flagsBasePath}/cl.svg`,
  colombia: `${flagsBasePath}/co.svg`,
  mexico: `${flagsBasePath}/mx.svg`,
  peru: `${flagsBasePath}/pe.svg`,
  uruguay: `${flagsBasePath}/uy.svg`,
};

const carouselOptions: ReactCarouselProps['options'] = {
  isRTL: false,
  pagination: true,
  itemPadding: [0, 0, 0, 0],
  itemsToShow: 1,
  showArrows: false,
  breakPoints: [],
};

const Item = ({
  photo, testimonial, name, role, country, badgeColor, badgeLabel,
}) => {
  const color = getColor(badgeColor, 200);
  const countryFlag = flags[country];
  const locale = useLocale();
  return (
    <div className={classNames(
      'w-full flex my-1 lg:my-6 flex-row lg:justify-end lg:items-end lg:pr-[12.5%]',
      'lg:odd:flex-row-reverse lg:odd:justify-start lg:odd:pr-0',
    )}
    >
      <div className={classNames(
        'hidden lg:block flex-none h-[max-content] fit w-48 py-6 px-7 rounded-full shadow-md',
        'text-gray-1 text-base text-center',
        '2xl:text-xl 2xl:w-60',
        '3xl:text-2xl 3xl:w-72',
        color.bg,
        { invisible: !badgeLabel },
      )}
      >
        {badgeLabel}
      </div>
      <div className="w-full lg:w-6/12 bg-white-1 border-white-3 rounded py-8 px-6 drop-shadow-md lg:mx-4">
        <div className="font-archivo text-base leading-7 text-gray-4 mb-6">
          <RichText render={testimonial} />
        </div>
        <div className="flex gap-4">
          {!!photo?.url && (
            <div className="flex-none w-10 h-10 lg:w-16 lg:h-16 relative">
              {photo?.url && (
                <Image
                  src={photo.url}
                  alt={photo.alt}
                  layout="fill"
                  objectFit="cover"
                  className="object-cover w-16 h-16 dh-rounded-tr dh-rounded-br"
                />
              )}
            </div>
          )}
          <div className="grow">
            <p className="font-bold text-sm lg:text-lg">{name}</p>
            <span className="text-primary-400 text-sm lg:text-base left-7">{role}</span>
          </div>
          {!!countryFlag && (
          <div className="flex-none flex text-5xl items-center">
            <Image
              width={48}
              height={48}
              src={`${getLocale(locale).path}${countryFlag}`}
              alt={country}
              layout="intrinsic"
            />
          </div>
          )}
        </div>
      </div>
    </div>
  );
};

const MarqueeTestimonials: FC<MarqueeTestimonialsProps> = ({ slice }) => {
  if (!slice.items?.length) return null;

  return (
    <section className={classNames(
      'dh-content-full bg-white-2 lg:gap-4',
      'flex flex-col lg:bg-white lg:pr-0 lg:pt-0 lg:pb-0 lg:mt-24',
      'lg:grid lg:grid-cols-4/12',
    )}
    >
      {slice.primary?.title?.length && (
      <header className="w-full flex flex-col justify-end mt-12 mb-8 lg:m-0">
        <h2 className="text-2xl lg:text-4.5xl 2xl:text-5xl 3xl:text-6xl"><RichText render={slice.primary.title} /></h2>
      </header>
      )}

      <div className="order-last lg:order-none lg:row-start-2">
        <div className="mt-4 text-lg lg:text-2xl 2xl:text-3.5xl">
          <RichText render={slice.primary.description} />
        </div>
        <LinkWithQuery
          href={Link.url(slice.primary.buttonLink, prismicLinkResolver)}
          className="dh-btn text-base lg:text-lg mt-3 lg:mt-8 lg:w-[max-content]"
          target={slice.primary.buttonLink?.target}
        >
          {slice.primary.buttonLabel}
        </LinkWithQuery>
      </div>

      <div className="lg:hidden">
        <ReactCarousel options={carouselOptions} color={slice._vertical?.data?.color}>
          {slice.items.map((item) => <Item {...item} key={nanoid()} />)}
        </ReactCarousel>
      </div>

      <div
        className={classNames(
          'hidden lg:block space-y-14 grow overflow-hidden rounded-tl-[132px] bg-white-2',
          'h-[1000px] xl:pl-24 xl:pr-15 lg:row-span-2',
        )}
      >
        <Marquee duration={70000} height="100%" axis="Y" reverse>
          <div>
            <div className="w-full pr-2 pl-2 whitespace-normal">
              {slice.items.map((item) => <Item {...item} key={nanoid()} />)}
            </div>
          </div>
        </Marquee>
      </div>
    </section>
  );
};

export default MarqueeTestimonials;
