import { Link, RichTextBlock } from 'prismic-reactjs';
import { Image, Slice } from 'types/prismic.type';

export enum MarqueeVariations {
  images = 'marqueeImages',
  imagesWithGrid = 'marqueeImagesWithGrid',
  testimonials = 'marqueeTestimonials',
}

export type MarqueeImagesProps = Slice & {
  slice: {
    primary: {
      title?: RichTextBlock[];
      rows?: number;
    }
    items?: {
      image: Image;
    }[];
  }
}
export type MarqueeTestimonialsProps = Slice & {
  slice: {
    primary: {
      title?: RichTextBlock[];
      description?: RichTextBlock[];
      buttonLink?: Link;
      buttonLabel?: string;
    }
    items?: {
      photo: Image;
      name: string;
      role: string;
      testimonial: RichTextBlock[];
      country: string;
      badgeColor: string;
      badgeLabel: string;
      badgeIcon: Image;
      badgeToRight: boolean;
    }[];
  }
}
