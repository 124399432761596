import { RichText } from 'prismic-reactjs';
import { nanoid } from 'nanoid';
import { FunctionComponent } from 'react';
import classNames from 'classnames';
import Image from 'next/image';
import { hasRichText } from '../../../utils/prismic';
import { FeatureWithListProps } from '../Feature.types';

const FeatureWithList: FunctionComponent<FeatureWithListProps> = ({ slice }) => (
  <section className="bg-white text-athensGray-100">
    <div className={classNames(
      'bg-black rounded-2xl mt-14 mb-7 py-11 mx-4 sm:mx-6 lg:mx-8',
      {
        'lg:pt-40': slice.primary?.bottomText,
      },
    )}
    >
      <div className="dh-content grid lg:grid-cols-3 lg:gap-x-12 py-0">
        {slice.primary?.title && (
          <header className="pr-16">
            <h2 className="font-bold text-2xl mb-8">{slice.primary.title}</h2>
            {hasRichText(slice.primary?.description) && (
              <div className="text-xs my-richtext-2 text-white">
                <RichText render={slice.primary.description} />
              </div>
            )}
          </header>
        )}
        {!!slice.items?.length && (
        <ul className="lg:col-span-2 grid gap-8 my-6 sm:my-0 sm:gap-12 sm:grid-cols-3 sm:mt-16 lg:mt-0">
          {slice.items?.map(
            ({ label, text, logo }) => (
              <li className="list-none sm:text-center text-sm font-normal dark:text-athensGray-100" key={nanoid()}>
                <span className="rounded-md bg-primary font-bold text-2xl inline-block px-2 py-1 mb-3 sm:mb-6">
                  {label}
                </span>
                {hasRichText(text) && (
                <div className="dark:text-white text-xs font-normal leading-5">
                  <RichText render={text} />
                </div>
                )}
                {!!logo?.url && (
                <div className="text-center mt-3 sm:mt-6">
                  <Image
                    src={logo?.url}
                    alt={logo?.alt}
                    height={logo?.dimensions.height}
                    width={logo?.dimensions.width}
                  />
                </div>
                )}
              </li>
            ),
          )}
        </ul>
        )}
        {slice.primary?.bottomText && (
        <div className={classNames(
          'text-dustyGray font-medium italic text-xs leading-5',
          'text-center lg:col-span-2 lg:col-start-2 sm:pt-16',
        )}
        >
          {slice.primary.bottomText}
        </div>
        )}
      </div>
    </div>
  </section>
);

export default FeatureWithList;
