import FaqOffsetLeft from './variations/FaqOffsetLeft';
import FaqColumns from './variations/FaqColumns';
import FaqAccordion from './variations/FaqAccordion';
import FaqAccordionCentered from './variations/FaqAccordionCentered';

enum FaqVariations {
  offsetLeft = 'faqOffsetLeft',
  columns = 'faqColumns',
  accordionCentered = 'faqAccordionCentered',
  accordion = 'faqAccordion',
  accordionProduct = 'faqAccordionProduct'
}

const Faq = ({ slice }) => {
  const clusterSlice = slice._cluster?.data?.slices?.find(
    ({ variation }) => variation === FaqVariations.accordion,
  );

  switch (slice.variation) {
    case FaqVariations.offsetLeft:
      return <FaqOffsetLeft slice={slice} />;
    case FaqVariations.columns:
      return <FaqColumns slice={slice} />;
    case FaqVariations.accordionCentered:
      return <FaqAccordionCentered slice={slice} />;
    case FaqVariations.accordionProduct:
      return <FaqAccordion slice={clusterSlice || {}} />;
    case FaqVariations.accordion:
    default:
      return <FaqAccordion slice={slice} />;
  }
};

export default Faq;
