import { Link, RichTextBlock } from 'prismic-reactjs';
import { Image, Slice } from 'types/prismic.type';

export enum VideoVariations {
  youtubeTwoCols = 'videoYtTwoCols',
  simpleYoutube = 'videoSimpleYouTube',
  carouselWithModal = 'videoCarouselWithModal',
  mp4 = 'videoMp4',
}

export type VideoYoutubeTwoColsProps = Slice & {
  slice: {
    primary?: {
      title?: string;
      videoTitle?: string;
      videoDescription?: RichTextBlock[];
      videoLink?: Link;
    }
  }
}

export type VideoSimpleYouTubeProps = Slice & {
  slice: {
    primary?: {
      title?: string;
      link?: Link;
    }
  }
}

export type VideoMP4Props = Slice & {
  slice: {
    primary?: {
      imagePreviewDesktop?: Image;
      imagePreviewMobile?: Image;
      videoDesktop?: Link;
      videoMobile?: Link;
      backgroundTop?: string;
      backgroundBottom?: string;
    }
  }
}

export type VideoCarouselWithModalProps = Slice & {
  slice: {
    primary?: {
      title?: string;
      description?: RichTextBlock[];
    },
    items: {
      videoLink: Link;
      videoTitle: string;
      videoDescription: RichTextBlock[];
      videoButtonLabel: string;
      videoButtonLink: Link;
    }[]
  }
}
