import { ContentVariations } from './Content.types';
import ContentWithList from './variations/ContentWithList';
import ContentWithLogoCloud from './variations/ContentWithLogoCloud';
import ContentWithOneColumn from './variations/ContentWithOneColumn';
import ContentWithTwoColumns from './variations/ContentWithTwoColumns';
import ContentWithTwoSections from './variations/ContentWithTwoSections';
import ContentWithTwoAlternative from './variations/ContentWithTwoAlternative';
import ContentWithLogo from './variations/ContentWithLogo';
import ContentWithButton from './variations/ContentWithButton';
import ContentWithImage from './variations/ContentWithImage';
import ContentWithSteps from './variations/ContentWithSteps';
import ContentWithOnlyText from './variations/ContentWithOnlyText';
import ContentDescriptiveBox from './variations/ContentDescriptiveBox';
import ContentCheckList from './variations/ContentCheckList';
import ContentWithButtonCentered from './variations/ContentWithButtonCentered';
import ContentCheckListImage from './variations/ContentCheckListImage';

const Content = ({ slice }) => {
  switch (slice.variation) {
    case ContentVariations.withTwoColumns:
    case ContentVariations.withTwoColumnsLight:
      return <ContentWithTwoColumns slice={slice} />;
    case ContentVariations.withList:
      return <ContentWithList slice={slice} />;
    case ContentVariations.withOneColumn:
      return <ContentWithOneColumn slice={slice} />;
    case ContentVariations.withTwoAlternative:
      return <ContentWithTwoAlternative slice={slice} />;
    case ContentVariations.withLogoCloud:
      return <ContentWithLogoCloud slice={slice} />;
    case ContentVariations.withTwoSections:
      return <ContentWithTwoSections slice={slice} />;
    case ContentVariations.withLogo:
      return <ContentWithLogo slice={slice} />;
    case ContentVariations.withButton:
      return <ContentWithButton slice={slice} />;
    case ContentVariations.withButtonCentered:
      return <ContentWithButtonCentered slice={slice} />;
    case ContentVariations.withImage:
      return <ContentWithImage slice={slice} />;
    case ContentVariations.withSteps:
      return <ContentWithSteps slice={slice} />;
    case ContentVariations.withOnlyTextDark:
      return <ContentWithOnlyText slice={slice} opacity />;
    case ContentVariations.withOnlyTextFull:
      return <ContentWithOnlyText slice={slice} fullWidth centered />;
    case ContentVariations.descriptiveBox:
      return <ContentDescriptiveBox slice={slice} />;
    case ContentVariations.checkList:
      return <ContentCheckList slice={slice} />;
    case ContentVariations.checkListImage:
      return <ContentCheckListImage slice={slice} />;
    default:
      return null;
  }
};

export default Content;
