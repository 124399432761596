import { RichText } from 'prismic-reactjs';
import classNames from 'classnames';
import Image from 'next/image';

const ContentWithLogo = ({ slice }) => (
  <section
    className="bg-athensGray"
  >
    <div
      className={classNames(
        'dh-content py-24 grid grid-cols-1 text-center',
        'lg:gap-x-10 lg:grid-cols-2 lg:text-left',
      )}
    >
      {slice.primary?.title && (
      <div
        className={classNames(
          'lg:pr-28 lg:flex lg:items-center',
        )}
      >
        <div className="lg:max-w-sm">
          <h2 className="dh-title font-semibold dark:text-gray-800 text-3.5xl">
            {slice.primary.title}
          </h2>
        </div>
      </div>
      )}
      {slice.primary?.description && (
      <div
        className={classNames(
          'text-lg font-medium', // mobile
          'lg:m-0 lg:p-0', // desktop
        )}
      >
        <div
          className="dh-description text-gray-800 dark:text-gray-800 my-richtext-6 font-medium text-sm leading-6"
        >
          <RichText render={slice.primary.description} />
          <div className="grid grid-cols-2 mt-6 items-center">
            <div className="content-center font-normal">{slice.primary.logoTitle}</div>
            <div>
              {slice.primary?.logo?.url && (
                <Image
                  src={slice.primary.logo?.url}
                  alt={slice.primary.logo?.alt}
                  width={slice.primary.logo?.dimensions?.width}
                  height={slice.primary.logo?.dimensions?.height}
                  layout="intrinsic"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      )}
    </div>
  </section>
);

export default ContentWithLogo;
