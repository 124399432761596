import { LogoCloudVariations } from './LogoCloud.types';
import LogoCloudWithTitle from './variations/LogoCloudWithTitle';
import LogoCloudMultipleCentered from './variations/LogoCloudMultipleCentered';

const LogoCloud = ({ slice }) => {
  switch (slice.variation) {
    case LogoCloudVariations.WithTitle:
      return <LogoCloudWithTitle slice={slice} />;
    default:
      return <LogoCloudMultipleCentered slice={slice} />;
  }
};

export default LogoCloud;
