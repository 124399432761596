import { AccordionVariations } from './Accordion.types';
import AccordionWithTabs from './variations/AccordionWithTabs';
import AccordionWithWhatsApp from './variations/AccordionWithWhatsApp';

const Accordion = ({ slice }) => {
  switch (slice.variation) {
    case AccordionVariations.withTabs:
      return <AccordionWithTabs slice={slice} />;
    case AccordionVariations.withWhatsApp:
      return <AccordionWithWhatsApp slice={slice} />;
    default:
      return null;
  }
};

export default Accordion;
