import StatsAnimatedCounter from './variations/StatsAnimatedCounter';
import StatsImageLight from './variations/StatsImageLight';
import StatsImageDark from './variations/StatsImageDark';
import StatsBackgroundColor from './variations/StatsBackgroundColor';
import StatsInCard from './variations/StatsInCard';
import StatsCounterWithIcons from './variations/StatsCounterWithIcons';
import { StatsVariations } from './Stats.types';

const Stats = ({ slice }) => {
  switch (slice.variation) {
    case StatsVariations.animatedCounter:
      return <StatsAnimatedCounter slice={slice} />;
    case StatsVariations.imageLight:
      return <StatsImageLight slice={slice} />;
    case StatsVariations.imageDark:
      return <StatsImageDark slice={slice} />;
    case StatsVariations.backgroundColor:
      return <StatsBackgroundColor slice={slice} />;
    case StatsVariations.inCard:
      return <StatsInCard slice={slice} />;
    case StatsVariations.counterWithIcons:
      return <StatsCounterWithIcons slice={slice} />;
    default:
      return null;
  }
};

export default Stats;
