import { RichText } from 'prismic-reactjs';
import classNames from 'classnames';
import { hasRichText } from '../../../utils/prismic';

const HeaderCenteredSmall = ({ slice }) => (
  <div className="bg-white">
    <div className="text-center mb-2 mt-24">
      {hasRichText(slice.primary.title) && (
      <h2
        className={classNames(
          'mt-1 max-w-3xl mx-auto text-2xl font-bold text-gray-900',
          'sm:text-2xl sm:tracking-tight lg:text-2xl',
        )}
      >
        {RichText.asText(slice.primary.title)}
      </h2>
      )}
      {hasRichText(slice.primary.description) && (
      <p className="max-w-2xl mt-5 mx-auto text-base text-gray-900">
        {RichText.asText(slice.primary.description)}
      </p>
      )}
    </div>
  </div>
);

export default HeaderCenteredSmall;
