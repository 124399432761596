import { RichText, RichTextBlock } from 'prismic-reactjs';
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import dynamic from 'next/dynamic';
import { getColor, Color } from '../../../utils/colors';

const Icon = dynamic(() => import('../../../components/Icon'), {
  ssr: false,
});

interface CardProps {
  title: string;
  items: {
    title: string;
    description: RichTextBlock[];
    icon: string;
  }[];
  color: Color;
}

const Card = ({ title, items, color }: CardProps) => (
  <section className="bg-gray-200 p-6 sm:py-11 sm:px-12 rounded-lg w-full max-w-md block dark:bg-gray-4">
    <header>
      <h3 className="dark:text-gray-100 font-heading font-bold mb-6 text-lg sm:text-2xl sm:font-semibold">{title}</h3>
    </header>
    <ul className="grid gap-7">
      {items?.map(({ title: itemTitle, description, icon }) => (
        <li
          className="flex gap-x-3 justify-start items-start dark:text-gray-200"
          key={nanoid()}
        >
          <Icon
            icon={icon}
            className={classNames('w-8 flex-none', color.text)}
          />
          <div className="grow">
            <p className="text-base sm:text-lg font-heading font-bold">{itemTitle}</p>
            <span className="font-description text-sm sm:text-base">
              <RichText render={description} />
            </span>
          </div>
        </li>
      ))}
    </ul>
  </section>
);

enum CardPosition {
  left = 'left',
  right = 'right'
}

const IncentivesTwoCards = ({ slice }) => {
  const color = getColor(slice._vertical?.data?.color);
  const itemsLeft = slice.items?.filter(({ card }) => card === CardPosition.left);
  const itemsRight = slice.items?.filter(({ card }) => card === CardPosition.right);

  return slice.items.length ? (
    <section className="dark:bg-woodsmoke-800">
      <div className="dh-content">
        <header className="text-center">
          {slice.primary?.title && (
            <h2 className="font-heading font-bold text-2xl sm:text-3.5xl sm:font-semibold dark:text-white-2">
              {slice.primary.title}
            </h2>
          )}
          {slice.primary?.description && (
            <div className="font-heading font-semibold text-base dark:text-gray-100">
              <RichText render={slice.primary.description} />
            </div>
          )}
        </header>
        <div className="grid gap-8 mt-8 justify-items-center sm:grid-cols-2 sm:gap-5">
          {!!itemsLeft.length && <Card title={slice.primary?.titleCardLeft} items={itemsLeft} color={color} />}
          {!!itemsRight.length && <Card title={slice.primary?.titleCardRight} items={itemsRight} color={color} />}
        </div>
      </div>
    </section>
  ) : (
    <div className="hidden" />
  );
};

export default IncentivesTwoCards;
