import classNames from 'classnames';
import { RichText } from 'prismic-reactjs';
import cloneDeep from 'lodash.clonedeep';
import set from 'lodash.set';
import { useIntl } from 'react-intl';
import { useCallback, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { getDefaultText, buildWhatsappLink } from '../../../utils/common';
import { useTrackingPayload } from '../../../hooks/useTrackPayload';
import { TrackEvents } from '../../../utils/constants';
import useLocale from '../../../hooks/useLocale';
import { hasRichText } from '../../../utils/prismic';
import { useAnalytics } from '../../../hooks/useAnalytics';

const AccordionFull = dynamic(() => import('../../../components/AccordionFull'), {
  ssr: false,
});

// TODO: replace svg with Icon component
const WhatsappIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
  >
    {/* eslint-disable-next-line max-len */}
    <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
  </svg>
);

const AccordionWithWhatsApp = ({ slice }) => {
  const vertical = slice?._vertical?.data;
  const cluster = slice?._cluster?.data;

  const { track } = useAnalytics();
  const locale = useLocale();
  const intl = useIntl();
  const trackPayload = useTrackingPayload({ slice, cluster, vertical });
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [items, setItems] = useState([]);
  useEffect(() => {
    const limitedItems = isDropdownOpen ? slice.items : slice.items.slice(0, 3);
    const formattedItems = limitedItems.map((item) => ({
      ...item,
      description: <div className="text-p3 lg:text-p2 3xl:text-p1"><RichText render={item.description} /></div>,
      title: <span className="font-medium text-p3 lg:text-p2 3xl:text-p1">{item.title}</span>,
    }));
    setItems(formattedItems);
  }, [isDropdownOpen, slice.items]);

  const handleClick = useCallback((url: string, label: string, fixed = false): void => {
    const payload = cloneDeep(trackPayload);
    set(payload, 'context.label', label);
    set(payload, 'context.destination_url', url);
    set(payload, 'context.fixed', fixed);

    track(TrackEvents.whatsappClicked, payload, locale);
  }, [trackPayload, locale, track]);

  if (!items.length) return null;

  const accordionData = {
    bgColor: slice.primary.darkMode ? 'bg-gray-2' : 'bg-white-1',
    textColor: slice.primary.darkMode ? 'text-white-1' : 'text-gray-3',
    bgDescColor: slice.primary.darkMode ? 'bg-gray-4' : 'bg-white-3',
    chevronColor: slice.primary.darkMode ? 'text-white-2' : 'text-gray-6',
  };
  const hasWhatsApp = !!slice.primary.whatsAppNumber;
  const link = buildWhatsappLink(
    slice.primary?.whatsAppNumber,
    slice.primary?.whatsAppMessage ? RichText.asText(slice.primary?.whatsAppMessage) : '',
  );
  const primaryMessages = slice.primary as { [key: string]: string };
  const defaultMessages = {
    textSeeMore: intl.formatMessage({ defaultMessage: 'Ver más' }),
    textSeeLess: intl.formatMessage({ defaultMessage: 'Ver menos' }),
  };

  const onClick = () => { setDropdownOpen(!isDropdownOpen); };

  return (
    <section
      data-testid={slice.primary.darkMode ? 'black' : 'white'}
      className={classNames(
        ' px-4 py-10 lg:py-[6.25rem] lg:px-15',
        { 'bg-black-1': slice.primary.darkMode },
        { 'bg-white-2': !slice.primary.darkMode },
      )}
    >
      <div className={classNames(
        'flex flex-col gap-8 lg:flex-row lg:justify-between lg:m-auto lg:max-w-7xl',
        '2xl:max-w-8xl 3xl:m-auto',
        { 'justify-center': !hasWhatsApp },
      )}
      >
        <div className={classNames(
          'flex flex-col shadow-lg lg:w-7/12 3xl:w-6/10',
          { 'lg:w-10/12 3xl:w-11/12': !hasWhatsApp },
        )}
        >
          <div className={classNames(
            'flex pb-6  items-end justify-between p-4 lg:p-6 rounded-t-[4px] ',
            'lg:items-baseline lg:gap-28',
            { 'justify-between': !hasWhatsApp },
            { 'bg-gray-2 border-b-2 border-gray-4': slice.primary.darkMode },
            { 'bg-white-1 border-b': !slice.primary.darkMode },
          )}
          >
            <p className={classNames(
              'font-bold text-h4 lg:text-h3 3xl:text-h2',
              { 'text-white-2': slice.primary.darkMode },
              { 'text-black-1 ': !slice.primary.darkMode },
            )}
            >
              {slice.primary.title}
            </p>
            <button
              className={classNames(
                'text-white-1 w-full text-right lg:w-fit font-medium underline',
                ' lg:mr-2.5 lg:text-p2 3xl:text-p1',
                { 'text-white-1': slice.primary.darkMode },
                { 'text-gray-6': !slice.primary.darkMode },
              )}
              onClick={onClick}
              type="button"
            >
              { isDropdownOpen
                ? getDefaultText('textSeeLess', primaryMessages, defaultMessages)
                : getDefaultText('textSeeMore', primaryMessages, defaultMessages)}
            </button>
          </div>
          <div>
            <AccordionFull
              items={items}
              bgColor={accordionData.bgColor}
              textColor={accordionData.textColor}
              bgDescColor={accordionData.bgDescColor}
              chevronColor={accordionData.chevronColor}
            />
          </div>

        </div>

        {hasWhatsApp
          && (
          <div className="bg-white-1 border-1 px-4 py-8 border-white-3 rounded shadow-sl flex flex-col h-fit
          lg:w-4/12 lg:p-8 3xl:w-3/10"
          >
            <p className="text-gray-4 font-bold text-p1 pb-4 lg:text-xl 3xl:text-h6">{slice.primary.cardTitle}</p>
            {hasRichText(slice.primary.cardDescription)
            && (
            <div className="text-gray-4 font-medium text-p3 pb-4 lg:pb-6 3xl:text-p2">
              <RichText render={slice.primary.cardDescription} />
            </div>
            )}
            <div className="w-full">
              <a
                onClick={() => handleClick(link, slice.primary?.label)}
                href={link}
                target="_blank"
                className={classNames(
                  'dh-button-full rounded-x p-2.5 3xl:p-3 3xl:text-p1',
                  'text-white-1 font-bold text-p2 bg-whatsapp-900 hover:bg-whatsapp-800',
                )}
                rel="noreferrer"
              >
                <WhatsappIcon
                  className="mr-1.5 lg:mr-[0.313rem] w-6 h-6 text-white-1 fill-current"
                  aria-hidden="true"
                />

                {slice.primary.whatsAppText}
              </a>
            </div>
          </div>
          )}
      </div>
    </section>
  );
};

export default AccordionWithWhatsApp;
