import classNames from 'classnames';
import { nanoid } from 'nanoid';
import { RichText } from 'prismic-reactjs';
import dynamic from 'next/dynamic';
import { getColor } from '../../../utils/colors';
import {
  PicCircleRectangleOutline, PicDiamondCircle, PicDiamondRectangle, PicDiamondRectangleOutline, PicDiamonds,
} from '../components/BoxesCardsPics';
import { ReactCarouselProps } from '../../../components/ReactCarousel';

const ReactCarousel = dynamic(() => import('../../../components/ReactCarousel'), {
  ssr: false,
});

const colorPic = {
  violet: PicDiamonds,
  orange: PicCircleRectangleOutline,
  green: PicDiamondRectangle,
  yellow: PicDiamondCircle,
  blue: PicDiamondRectangleOutline,
  coral: PicCircleRectangleOutline,
  lime: PicDiamondRectangle,
  emerald: PicDiamondRectangle,
  purple: PicDiamonds,
  pink: PicDiamondCircle,
};

const carouselOptions: ReactCarouselProps['options'] = {
  isRTL: false,
  pagination: true,
  itemPadding: [0, 0, 0, 0],
  itemsToShow: 1,
  showArrows: false,
  breakPoints: [],
};

const boxesWidthCards: {[size: number]: string} = {
  2: 'w-6/12',
  4: 'w-6/12',
};

const BoxesColorCards = ({ slice }) => {
  const size = slice.items?.length;

  if (!size) return null;

  return (
    <section className="dh-content-full">
      <div className="lg:hidden">
        <ReactCarousel options={carouselOptions}>
          {slice.items.map((item) => {
            const itemColor200 = getColor(item.color, 200);
            const itemColor400 = getColor(item.color, 400);
            const Pic = colorPic[item.color] || colorPic[0];

            return (
              <div
                key={nanoid()}
                className={classNames(
                  'flex w-full h-full relative text-2xl leading-10 pt-9 pl-6 pr-11 pb-28',
                  'bg-opacity-40 overflow-hidden rounded',
                  itemColor200.bg,
                )}
              >
                <RichText render={item.text} />
                <Pic className={classNames('absolute', itemColor400.text)} />
              </div>
            );
          })}
        </ReactCarousel>
      </div>
      <div className="hidden lg:flex flex-wrap justify-center">
        {slice.items.map((item) => {
          const itemColor200 = getColor(item.color, 200);
          const itemColor400 = getColor(item.color, 400);
          const Pic = colorPic[item.color] || colorPic[0];

          return (
            <div className={classNames('p-1', boxesWidthCards[size] ?? 'w-4/12')} key={nanoid()}>
              <div
                className={classNames(
                  'flex h-full relative text-3.5xl leading-10 pt-9 pl-7 pr-15 pb-24 ',
                  '2xl:text-4.5xl 2xl:leading-15 2xl:pt-12 2xl:pl-10 2xl:pr-20 2xl:pb-32',
                  'bg-opacity-40 overflow-hidden rounded',
                  itemColor200.bg,
                )}
              >
                <RichText render={item.text} />
                <Pic className={classNames('absolute', itemColor400.text)} />
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default BoxesColorCards;
