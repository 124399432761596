import { RichText } from 'prismic-reactjs';
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import { FC, ReactElement } from 'react';
import Image from 'next/image';
import { Color, getColor } from '../../../utils/colors';
import { hasRichText } from '../../../utils/prismic';
import { IStepsDefault } from '../Steps.types';

interface IStepNumber {
  index: number
  color: Color
}

const StartIcon = ({ icon }) => (
  <div className={classNames(
    'rounded-full w-10 flex justify-center items-center',
    'lg:w-[3.75rem] lg:h-[3.75rem] 2xl:w-[4.375rem] 2xl:h-[4.375rem] 3xl:w-20 3xl:h-20',
  )}
  >
    {icon}
  </div>
);

const FinalIcon = ({ icon }) => (
  <div className={classNames(
    'rounded-full w-10 flex justify-center items-center absolute -right-1',
    'xl:w-[3.75rem] lg:h-[3.75rem] 2xl:w-[4.375rem] 2xl:h-[4.375rem] 3xl:w-20 3xl:h-20',
  )}
  >
    {icon}
  </div>
);

const StepNumber: FC<IStepNumber> = ({ index, color }) => (
  <span
    aria-hidden="true"
    className={classNames(
      'h-8 w-8 mx-auto rounded-full flex items-center justify-center',
      'font-bold text-base leading-6 lg:mx-2',
      'lg:w-10 lg:h-10 2xl:w-11 2xl:h-11 3xl:w-14 3xl:h-14',
      'lg:text-h6 2xl:text-h5',
      color.bg,
      color.textContrast,
    )}
  >
    {index + 1}
  </span>
);

interface IStep {
  icon: ReactElement;
  minHeight?: string;
  color?: string;
}

const Step: FC<IStep> = ({
  icon, children, color, minHeight = '4rem',
}) => (
  <div className="flex flex-row gap-3 lg:flex-col">
    <div className="relative flex">
      <div className="absolute inset-0 flex" aria-hidden="true">
        <div className={classNames(
          'w-0.5 h-full border-r border-dashed mx-auto flex-none',
          'lg:h-0.5 lg:w-full lg:border-t lg:border-r-0 lg:my-auto',
          color,
        )}
        />
      </div>
      <div className="
        relative w-10 h-9 flex-none lg:flex lg:items-center
        lg:w-full lg:h-[3.75rem] 2xl:h-[4.375rem] 3xl:h-20
        "
      >
        {icon}
      </div>
    </div>
    <div className="lg:min-h-[4rem]" style={{ minHeight }}>
      {children}
    </div>
  </div>
);

const StepsDefault: FC<IStepsDefault> = ({ slice }) => {
  const { items } = slice;
  const itemsLength = items.length;
  const vertical = slice?._vertical?.data;
  const color = getColor(slice.primary.color || vertical?.color);
  const backgroundColor = slice.primary?.backgroundColor === 'white' ? 'bg-white-1' : 'bg-black-2';
  const textColor = backgroundColor === 'bg-black-2' ? 'text-white-1' : 'text-black-1 border-black-1';

  if (!itemsLength) return null;

  return (
    <section className={classNames(
      'dh-content-full',
      'py-20 lg:py-[8.75rem] 2xl:py-[11.25rem] 3xl:py-[12.5rem]',
      backgroundColor,
    )}
    >
      <div className="lg:m-auto lg:max-w-7xl 2xl:max-w-8xl 3xl:max-w-8x5l">
        {hasRichText(slice.primary.title) && (
        <header className="text-center mb-8 lg:mb-[3.75rem]">
          <h2 className={classNames('text-h4 lg:text-h2 3xl:text-h1', textColor)}>
            <RichText render={slice.primary.title} />
          </h2>
        </header>
        )}
        <div className="text-left">
          <nav aria-label="Progress">
            <ol className="flex flex-col lg:flex-row lg:w-10/12 lg:justify-center lg:mx-auto">
              { slice.primary?.iconBegin?.url && (
              <li className="relative -mb-2 lg:w-1/10">
                <Step
                  color={textColor}
                  icon={(
                    <StartIcon icon={(
                      <Image
                        width="80px"
                        height="80px"
                        src={slice.primary?.iconBegin?.url}
                        alt={slice.primary?.iconBegin?.alt}
                      />
                  )}
                    />
              )}
                />

              </li>
              )}
              {items.map((item, index) => (
                <li
                  key={nanoid()}
                  className="relative lg:w-2/10"
                >
                  <Step
                    color={textColor}
                    icon={<StepNumber index={index} color={color} />}
                  >
                    <div className="pb-8 lg:px-2">
                      <span className={classNames('text-h7 font-bold 3xl:text-h5', textColor)}>
                        {item.title}
                      </span>
                      {hasRichText(item.description) && (
                      <div className={classNames('text-p3 font-medium mt-1.5 3xl:text-p2', textColor)}>
                        <RichText render={item.description} />
                      </div>
                      )}
                    </div>
                  </Step>
                </li>
              ))}
              { slice.primary?.iconFinal?.url && (
              <li className="relative -mt-2 lg:mt-0 lg:w-1/10">
                <Step
                  color={textColor}
                  minHeight="auto"
                  icon={(
                    <FinalIcon icon={(
                      <Image
                        width="80px"
                        height="80px"
                        src={slice.primary?.iconFinal?.url}
                        alt={slice.primary?.iconFinal?.alt}
                      />
                  )}
                    />
              )}
                />
              </li>
              )}
            </ol>
          </nav>
        </div>
      </div>
    </section>
  );
};

export default StepsDefault;
