import CarouselWithoutControls from './variations/CarouselWithoutControls';
import CarouselWithDate from './variations/CarouselWithDate';
import CarouselWithImage from './variations/CarouselWithImage';
import CarouselWithPrice from './variations/CarouselWithPrice';
import CarouselWithDateLight from './variations/CarouselWithDateLight';
import CarouselWithHeaderImage from './variations/CarouselWithHeaderImage';
import CarouselWithFlags from './variations/CarouselWithFlags';
import CarouselWithPills from './variations/CarouselWithPills';
import CarouselWithClusters from './variations/CarouselWithClusters';
import CarouselWithTabs from './variations/CarouselWithTabs';
import { CarouselVariations } from './Carousel.types';

const Carousel = ({ slice }) => {
  switch (slice.variation) {
    case CarouselVariations.withPrice:
      return <CarouselWithPrice slice={slice} />;
    case CarouselVariations.withDate:
      return <CarouselWithDate slice={slice} />;
    case CarouselVariations.withDateLight:
      return <CarouselWithDateLight slice={slice} />;
    case CarouselVariations.withImage:
      return <CarouselWithImage slice={slice} />;
    case CarouselVariations.withHeaderImage:
      return <CarouselWithHeaderImage slice={slice} />;
    case CarouselVariations.withoutControls:
      return <CarouselWithoutControls slice={slice} />;
    case CarouselVariations.withFlags:
      return <CarouselWithFlags slice={slice} />;
    case CarouselVariations.withPills:
      return <CarouselWithPills slice={slice} />;
    case CarouselVariations.withClusters:
      return <CarouselWithClusters slice={slice} />;
    case CarouselVariations.withTabs:
      return <CarouselWithTabs slice={slice} />;
    default:
      return null;
  }
};

export default Carousel;
