import classNames from 'classnames';
import { nanoid } from 'nanoid';
import Image from 'next/image';

const LogoCloudMultipleCentered = ({ slice }) => (
  <div className="bg-gray-800 dark:bg-gray-800">
    <div className="dh-content">
      {slice.primary.title && (
        <h2
          className={classNames(
            {
              'text-center': slice.primary.titlePosition === 'center',
              'text-right': slice.primary.titlePosition === 'right',
            },
            'dh-title text-white dark:text-white',
          )}
        >
          {slice.primary.title}
        </h2>
      )}
      <div className="flow-root mt-6 lg:mt-8">
        <div className="flex flex-wrap justify-center -mx-4 overflow-hidden">
          {slice?.items?.map((item) => (
            <div
              key={nanoid()}
              className="flex my-6 px-2 w-full max-w-screen-lg overflow-hidden sm:w-1/5 justify-center items-center"
            >
              <div>
                <Image
                  src={item.logo.url}
                  alt={item.logo.alt}
                  width={item.logo.dimensions.width || '100%'}
                  height={item.logo.dimensions.height || '100%'}
                  layout="intrinsic"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);
export default LogoCloudMultipleCentered;
