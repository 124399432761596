import { MarqueeVariations } from './Marquee.types';
import MarqueeImages from './variations/MarqueeImages';
import MarqueeImagesWithGrid from './variations/MarqueeImagesWithGrid';
import MarqueeTestimonials from './variations/MarqueeTestimonials';

const Marquee = ({ slice }) => {
  switch (slice.variation) {
    case MarqueeVariations.images:
      return <MarqueeImages slice={slice} />;
    case MarqueeVariations.imagesWithGrid:
      return <MarqueeImagesWithGrid slice={slice} />;
    case MarqueeVariations.testimonials:
      return <MarqueeTestimonials slice={slice} />;
    default:
      return null;
  }
};

export default Marquee;
