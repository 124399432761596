import { ButtonVariations } from './Button.types';
import ButtonDownload from './variants/ButtonDownload';
import ButtonGeneric from './variants/ButtonGeneric';
import ButtonWhatsapp from './variants/ButtonWhatsapp';

const Button = ({ slice }) => {
  const clusterSlice = slice._cluster?.data?.slices?.find(
    ({ variation }) => variation === ButtonVariations.whatsapp,
  );

  const productSlice = {
    ...slice,
    ...(clusterSlice || {}),
  };

  switch (slice.variation) {
    case ButtonVariations.whatsappProduct:
      return <ButtonWhatsapp slice={productSlice || {}} />;
    case ButtonVariations.whatsapp:
      return <ButtonWhatsapp slice={slice} />;
    case ButtonVariations.download:
      return <ButtonDownload slice={slice} />;
    case ButtonVariations.generic:
      return <ButtonGeneric slice={slice} />;
    default:
      return null;
  }
};

export default Button;
