import { ProductsOverviewsVariations } from './ProductOverviews.types';
import ProductOverviewsWithTwoColumns from './variations/ProductOverviewsWithTwoColumns';

const ProductOverviews = ({ slice }) => {
  switch (slice.variation) {
    case ProductsOverviewsVariations.withTwoColumns:
    default:
      return <ProductOverviewsWithTwoColumns slice={slice} />;
  }
};

export default ProductOverviews;
