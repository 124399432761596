import { RichText } from 'prismic-reactjs';
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import dynamic from 'next/dynamic';
import { getColor } from '../../../utils/colors';

const Icon = dynamic(() => import('../../../components/Icon'), {
  ssr: false,
});

const ContentWithList = ({ slice }) => {
  const color = getColor(slice._vertical?.data?.color);

  return (
    <section className="bg-gray-100">
      <div className="dh-content grid grid-cols-1 lg:gap-x-48 lg:grid-cols-2 lg:text-left">
        <div className="mb-7 lg:mb-0">
          {slice.primary?.title && (
            <header className="lg:max-w-sm">
              <h2 className="text-2xl font-bold mb-7 text-gray-800">
                {slice.primary.title}
              </h2>
            </header>
          )}
          {slice.primary?.description && (
            <div className="text-xs font-semibold text-black">
              <RichText render={slice.primary.description} />
            </div>
          )}
        </div>

        {slice.items?.length && (
          <div className="text-lg font-normal lg:m-0">
            <ul className="text-gray-800 text-xs font-normal lg:max-w-md space-y-5">
              {
                slice.items.map(({ icon, title }) => (
                  <li className="flex items-start" key={nanoid()}>
                    <Icon
                      icon={icon}
                      className={classNames('w-4 mr-4 inline-block flex-none', color.text)}
                    />
                    <span>{title}</span>
                  </li>
                ))
              }
            </ul>
          </div>
        )}
      </div>
    </section>
  );
};

export default ContentWithList;
