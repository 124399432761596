import classnames from 'classnames';
import set from 'lodash.set';
import cloneDeep from 'lodash.clonedeep';
import { useCallback } from 'react';
import { Link } from 'prismic-reactjs';
import { prismicLinkResolver } from '../../../utils/common';
import { hasLink } from '../../../utils/prismic';
import { getColor } from '../../../utils/colors';
import { useTrackingPayload } from '../../../hooks/useTrackPayload';
import { TrackEvents } from '../../../utils/constants';
import useLocale from '../../../hooks/useLocale';
import { useAnalytics } from '../../../hooks/useAnalytics';

const ButtonGeneric = ({ slice }) => {
  const color = getColor(slice.primary.color);
  const { track } = useAnalytics();
  const locale = useLocale();
  const vertical = slice?._vertical?.data;
  const cluster = slice?._cluster?.data;
  const product = slice?._product?.data;
  const trackPayload = useTrackingPayload({
    slice, cluster, vertical, product,
  });

  const handleClick = useCallback((url: string, label: string): void => {
    const payload = cloneDeep(trackPayload);
    set(payload, 'context.label', label);
    set(payload, 'context.destination_url', url);

    track(TrackEvents.buttonClicked, payload, locale);
  }, [trackPayload, locale, track]);

  if (!slice.primary.label) return null;

  if (hasLink(slice.primary.link)) {
    return (
      <div className="flex justify-center w-full lg:py-16">
        <a
          onClick={() => handleClick(slice.primary.link, slice.primary.label)}
          href={Link.url(slice.primary.link, prismicLinkResolver)}
          target={slice.primary.link.target ?? '_blank'}
          className={classnames(
            'dh-button-full',
            color.bg,
            color.bgHover,
            color.textContrast,
          )}
          rel="noreferrer"
        >
          {slice.primary?.label}
        </a>
      </div>
    );
  }

  return (
    <div className="flex justify-center w-full lg:py-16">
      <button
        type="button"
        className={classnames(
          'dh-button-full',
          color.bg,
          color.bgHover,
          color.textContrast,
        )}
      >
        {slice.primary?.label}
      </button>
    </div>
  );
};

export default ButtonGeneric;
