import classNames from 'classnames';
import { nanoid } from 'nanoid';
import useOnScreenByKeys from '../../../hooks/useOnScreenByKeys';
import { scrollTo } from '../../../utils/common';

const HeaderInner = ({ slice }) => {
  const onScreen = useOnScreenByKeys(slice.items.map((item) => item.textScrollTo), '-50%');
  const activeSection = Object.keys(onScreen).filter((key) => onScreen[key])[0];

  return (
    <section className="px-15 py-4 hidden lg:block bg-gray-1 border border-gray-2 sticky top-[60px] z-50">
      <div className="flex justify-between items-center lg:m-auto lg:max-w-7xl 2xl:max-w-8xl 3xl:max-w-8x5l">
        <div className="flex w-full xl:w-[70%] 3xl:justify-start">
          {slice.items.map((item) => (
            <button
              type="button"
              className={classNames(
                'text-base hover:font-bold text-white-1 pr-10 cursor-pointer',
                {
                  'font-bold': item.textScrollTo === activeSection,
                },
              )}
              onClick={() => scrollTo({ id: item.textScrollTo })}
              key={nanoid()}
            >
              {item.text}
            </button>
          ))}
        </div>
        <div>
          <button
            type="button"
            className="
              bg-white-1 font-bold rounded-x text-black-1 px-[2.188rem] py-2.5 text-sm cursor-pointer
                2xl:px-[3.906rem] 3xl:px-[5.5rem]"
            onClick={() => scrollTo({ id: slice.primary.buttonScrollTo })}
          >
            {slice.primary.button}
          </button>
        </div>
      </div>
    </section>
  );
};
export default HeaderInner;
