import { GridListsVariations } from './GridLists.types';
import GridListsTwoColumnsWithImage from './variations/GridListsTwoColumnsWithImage';
import GridListsWithIcon from './variations/GridListsWithIcon';
import GridListsWithIconDark from './variations/GridListsWithIconDark';
import GridListsFiveColumns from './variations/GridListsFiveColumns';
import GridListsWithIconSimple from './variations/GridListsWithIconSimple';
import GridListsWithIconCentered from './variations/GridListsWithIconCentered';
import GridListsWithOnlyText from './variations/GridListsWithOnlyText';
import GridListsWithIconLight from './variations/GridListsWithIconLight';
import GridListsWithImage from './variations/GridListsWithImage';
import GridListsWithFilter from './variations/GridListsWithFilter';
import GridListsCardAnimated from './variations/GridListsCardAnimated';
import GridListsCTA from './variations/GridListsCTA';
import GridListsProducts from './variations/GridListsProducts';
import GridListsProfiles from './variations/GridListsProfiles';
import GridListsProductsCheckout from './variations/GridListsProductsCheckout';

const GridLists = ({ slice }) => {
  switch (slice.variation) {
    case GridListsVariations.withIcon:
      return <GridListsWithIcon slice={slice} />;
    case GridListsVariations.withIconSimple:
      return <GridListsWithIconSimple slice={slice} />;
    case GridListsVariations.withIconDark:
      return <GridListsWithIconDark slice={slice} />;
    case GridListsVariations.withIconLight:
      return <GridListsWithIconLight slice={slice} />;
    case GridListsVariations.withIconCentered:
      return <GridListsWithIconCentered slice={slice} />;
    case GridListsVariations.fiveColumns:
      return <GridListsFiveColumns slice={slice} />;
    case GridListsVariations.withOnlyText:
      return <GridListsWithOnlyText slice={slice} />;
    case GridListsVariations.twoColumnsWithImage:
      return <GridListsTwoColumnsWithImage slice={slice} />;
    case GridListsVariations.withImage:
      return <GridListsWithImage slice={slice} />;
    case GridListsVariations.withFilter:
      return <GridListsWithFilter slice={slice} />;
    case GridListsVariations.cardAnimated:
      return <GridListsCardAnimated slice={slice} />;
    case GridListsVariations.cta:
      return <GridListsCTA slice={slice} />;
    case GridListsVariations.productsCheckout:
      return <GridListsProductsCheckout slice={slice} />;
    case GridListsVariations.products:
      return <GridListsProducts slice={slice} />;
    case GridListsVariations.profiles:
      return <GridListsProfiles slice={slice} />;
    default:
      return null;
  }
};

export default GridLists;
