import Divider from './Divider';

const DividerMobile = () => (
  <svg width="284" height="37" viewBox="0 0 284 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_39_6075)">
      {/* eslint-disable-next-line max-len */}
      <path fillRule="evenodd" clipRule="evenodd" d="M-0.136334 1.48821C-0.125099 0.659854 0.55552 -0.00254913 1.38387 0.00868575C43.7188 0.582876 85.813 7.13437 126.645 19.5032L126.649 19.5045C126.956 19.5985 127.268 19.7036 127.582 19.8192C127.891 19.4924 128.213 19.1859 128.545 18.9025C132.004 15.9525 136.37 14.9018 140.23 14.0717C187.085 3.9671 235.267 6.08605 282.705 8.25692C283.533 8.29479 284.173 8.99636 284.135 9.82393C284.097 10.6515 283.395 11.2917 282.568 11.2538C235.067 9.08002 187.274 6.99511 140.862 17.0045L140.861 17.0046C136.965 17.8424 133.321 18.781 130.509 21.171C132.24 22.1374 133.892 23.3742 135.177 24.7931C137.417 27.2659 138.822 30.6791 136.783 34.1321L136.782 34.1334C135.291 36.6535 132.558 37.3326 130.171 36.8369C127.808 36.3462 125.508 34.6756 124.536 32.1232C123.286 28.8417 124.002 25.2421 125.691 22.3489C85.1522 10.0774 43.3658 3.57836 1.34319 3.00841C0.514835 2.99717 -0.147569 2.31656 -0.136334 1.48821ZM128.498 23.4958C127.028 25.8053 126.433 28.6761 127.339 31.0553C127.903 32.5359 129.293 33.5907 130.781 33.8996C132.243 34.2032 133.518 33.7564 134.2 32.6066C135.279 30.7796 134.77 28.8124 132.954 26.8072C131.777 25.508 130.167 24.3556 128.498 23.4958Z" fill="black" />
    </g>
    <defs>
      <clipPath id="clip0_39_6075">
        <rect width="284" height="37" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const DividerDesktop = () => (
  <svg width="567" height="64" viewBox="0 0 567 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    {/* eslint-disable-next-line max-len */}
    <path fillRule="evenodd" clipRule="evenodd" d="M1.22251 2.59201C1.23211 1.76364 1.91141 1.09988 2.73978 1.10948C86.9812 2.08507 171.095 13.4263 252.357 34.7677L252.357 34.7678C253.281 35.0106 254.224 35.2984 255.175 35.6278C255.967 34.8398 256.811 34.1142 257.691 33.4639C264.267 28.604 272.604 26.8401 280.34 25.4021C373.618 7.91797 469.612 11.6189 564.355 15.3753C565.183 15.4081 565.828 16.1058 565.795 16.9335C565.762 17.7613 565.064 18.4058 564.237 18.3729C469.436 14.6143 373.781 10.9393 280.891 28.3512L280.889 28.3516C273.09 29.8013 265.42 31.4819 259.474 35.8766C259.06 36.182 258.659 36.5032 258.272 36.8386C262.146 38.5276 265.917 40.8414 268.737 43.5444C270.871 45.5899 272.556 47.9503 273.238 50.53C273.938 53.1746 273.548 55.9278 271.744 58.5665C269.137 62.3865 264.437 63.4 260.241 62.6474C256.043 61.8945 251.854 59.3047 250.06 55.2476C247.497 49.453 249.283 43.0124 253.028 38.0802C252.547 37.931 252.068 37.7938 251.595 37.6693C170.571 16.3904 86.7016 5.08204 2.70504 4.10928C1.87667 4.09968 1.21292 3.42038 1.22251 2.59201ZM256.005 39.1408C252.333 43.5202 250.695 49.2663 252.803 54.0342C254.094 56.9529 257.282 59.0688 260.771 59.6945C264.26 60.3204 267.554 59.3859 269.267 56.8747L269.267 56.8735C270.585 54.9458 270.812 53.089 270.338 51.2971C269.847 49.4402 268.568 47.5375 266.661 45.7102C263.882 43.0456 259.972 40.7372 256.005 39.1408Z" fill="black" />
  </svg>
);

const Divider360 = ({ slice }) => (
  <Divider slice={slice} mobile={DividerMobile} desktop={DividerDesktop} />
);

export default Divider360;
