import { RichText, RichTextBlock } from 'prismic-reactjs';
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import { Image as ImageType } from '../../../types/prismic.type';

const HighlightText = dynamic(() => import('../../../components/HighlightText'), {
  ssr: false,
});

interface CardProps {
  tag: string,
  title: string,
  highlight?: string,
  description: RichTextBlock[],
  images?: { image: ImageType }[],
  className?: string
}

const Card = ({
  tag, title, highlight, description, images, className = '',
}: CardProps) => (
  <section
    className={classNames(
      'py-12 lg:py-20 w-full',
      className,
    )}
  >
    <header>
      {tag && (
        <span className={classNames(
          'inline-block font-medium text-xs text-gray-600 bg-gray-400 rounded-sm py-1 px-2 mb-4',
        )}
        >
          {tag}
        </span>
      )}
      {title && (
        <h2 className="text-2xl font-medium leading-9 text-gray-800">
          <HighlightText
            highlight={highlight}
            text={title}
            className="bg-transparent font-bold m-0 px-0"
          />
        </h2>
      )}
    </header>

    {description && (
      <div className={classNames(
        'text-gray-800 text-xs font-normal leading-5 mt-7 my-richtext-6 my-richtext-list-disc',
      )}
      >
        <RichText render={description} />
      </div>
    )}

    {!!images?.length && (
      <div className="grid gap-4 grid-cols-4 mt-7">
        {images?.map(({ image }) => (
          <div key={nanoid()}>
            <Image
              src={image.url}
              alt={image.alt}
              width={image.dimensions?.width}
              height={image.dimensions?.height}
              layout="intrinsic"
            />
          </div>
        )) }
      </div>
    )}

  </section>
);

const ContentWithTwoSections = ({ slice }) => (
  <section className="bg-gray-200 text-black relative overflow-hidden">
    <div className="hidden lg:block absolute top-0 left-1/2 w-full h-full bg-gray-300 z-0" />
    <div className="dh-content grid grid-cols-1 p-0 lg:grid-cols-2 relative z-10 lg:gap-48">
      <Card
        tag={slice.primary?.leftSectionTag}
        title={slice.primary?.leftSectionTitle}
        highlight={slice.primary?.leftSectionHighlightedText}
        description={slice.primary?.leftSectionDescription}
        images={slice.items}
        className="px-4 lg:px-0 lg:pl-8"
      />

      <div className="bg-gray-300">
        <Card
          tag={slice.primary?.rightSectionTag}
          title={slice.primary?.rightSectionTitle}
          highlight={slice.primary?.rightSectionHighlightedText}
          description={slice.primary?.rightSectionDescription}
          className="px-4 lg:px-0 lg:pr-8"
        />
      </div>
    </div>
  </section>
);

export default ContentWithTwoSections;
