import { RichText } from 'prismic-reactjs';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import { getColor } from '../../../utils/colors';

const HighlightText = dynamic(() => import('../../../components/HighlightText'), {
  ssr: false,
});

const ContentWithTwoAlternative = ({ slice }) => {
  const verticals = slice._verticals ?? [slice._vertical];
  const color = getColor(verticals[0]?.data.color);

  return (
    <div
      className={classNames(
        'bg-athensGray-400', // mobile
      )}
    >
      <div
        className={classNames(
          'dh-content grid grid-cols-1 text-center',
          'lg:gap-x-10 lg:grid-cols-2 lg:text-left',
        )}
      >
        {slice.primary?.title && (
          <div
            className={classNames(
              'lg:pr-28 lg:pl-24 lg:flex lg:justify-end lg:items-center',
            )}
          >
            <div className="lg:max-w-sm">
              <h2 className="dh-title dark:text-gray-800">
                <HighlightText
                  text={slice.primary.title}
                  highlight={slice.primary?.highlightedText}
                  className={classNames(color.textContrast, color.bg)}
                />
              </h2>
            </div>
          </div>
        )}
        {slice.primary?.description && (
          <div
            className={classNames(
              'text-lg font-normal', // mobile
              'lg:m-0 lg:p-0 lg:px-8', // desktop
            )}
          >
            <div className="dh-description my-richtext-6 lg:max-w-md dark:text-gray-800">
              <RichText render={slice.primary.description} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContentWithTwoAlternative;
