import { RichText } from 'prismic-reactjs';
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import dynamic from 'next/dynamic';
import { getColor } from '../../../utils/colors';

const Icon = dynamic(() => import('../../../components/Icon'), {
  ssr: false,
});

const IncentivesCards = ({ slice }) => {
  const color = getColor(slice?._vertical?.data?.color);

  return (
    <div className="dark:bg-gray-1">
      <div className="dh-content">
        <div className="max-w-2xl mx-auto px-4 grid grid-cols-1 gap-y-10 gap-x-8 lg:max-w-none lg:grid-cols-3">
          {slice.items?.map(({ title, description, icon }) => (
            <div className="text-center lg:block lg:text-center" key={nanoid()}>
              <div className="lg:shrink-0">
                <div className="flow-root">
                  <Icon
                    icon={icon}
                    className={classNames(
                      'w-12 h-14 mx-auto',
                      color.text,
                    )}
                  />
                </div>
              </div>
              <div className="mt-3 lg:mt-3 lg:ml-0 dark:text-white">
                <h3 className="text-lg font-bold">{title}</h3>
                <p className="mt-2 text-lg font-semibold dark:text-gray-300">
                  {RichText.asText(description)}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default IncentivesCards;
