import { RichText } from 'prismic-reactjs';
import { nanoid } from 'nanoid';
import classNames from 'classnames';
import { FunctionComponent } from 'react';
import { GridListsWithOnlyTextProps } from '../GridLists.types';

const Card = ({
  title, description,
}) => (
  <li
    className="px-6 py-12 space-y-5 rounded-md bg-white"
  >
    <h3 className="text-lg font-bold text-gray-900 leading-6">{title}</h3>
    <div className="text-sm font-normal text-gray-900 leading-5 my-richtext-list-disc">
      <RichText render={description} />
    </div>
  </li>
);

const CardList = ({ items }) => (
  items?.length ? (
    <ul className={classNames(
      'grid sm:grid-cols-2 lg:grid-cols-3 gap-10',
    )}
    >
      {items.slice(0, 3).map(({ title, description }) => (
        <Card key={nanoid()} title={title} description={description} />
      ))}
    </ul>
  ) : null
);

const GridListsWithOnlyText: FunctionComponent<GridListsWithOnlyTextProps> = ({ slice }) => (slice?.items?.length ? (
  <section className="bg-gray-900">
    <div className="dh-content">
      {slice.primary?.title && (
      <header className="mb-8 lg:mb-10">
        <h2 className="font-bold text-2xl text-athensGray-100">{slice.primary?.title}</h2>
      </header>
      )}
      <CardList items={slice?.items} />
    </div>
  </section>
) : (
  <div className="hidden" />
));

export default GridListsWithOnlyText;
