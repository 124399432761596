import classNames from 'classnames';
import { nanoid } from 'nanoid';
import Image from 'next/image';

const LogoCloudWithTitle = ({ slice }) => (
  <div className="dh-content">
    {slice.primary.title && (
      <p
        className={classNames(
          {
            'text-center': slice.primary.titlePosition === 'center',
            'text-right': slice.primary.titlePosition === 'right',
          },
          'dh-title dark:text-gray-900',
        )}
      >
        {slice.primary.title}
      </p>
    )}
    <div className="flow-root mt-6 lg:mt-8">
      <div className="flex flex-wrap justify-between">
        {slice?.items?.map((item) => (
          <div
            key={nanoid()}
            className="my-4 mx-4 flex grow shrink-0 justify-center lg:grow-0 md:mx-8"
          >
            <div>
              <Image
                src={item.logo.url}
                alt={item.logo.alt}
                width={item.logo.dimensions.width || '100%'}
                height={item.logo.dimensions.height || '100%'}
                layout="intrinsic"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default LogoCloudWithTitle;
