import classNames from 'classnames';
import { Link } from 'prismic-reactjs';
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';
import { SubmitHandler, useForm } from 'react-hook-form';
import set from 'lodash.set';
import cloneDeep from 'lodash.clonedeep';
import dynamic from 'next/dynamic';
import { useCallback } from 'react';
import useLocale from '../../../hooks/useLocale';
import { getDefaultText, prismicLinkResolver } from '../../../utils/common';
import { getColor } from '../../../utils/colors';
import { DATE_LOCALES, getCurrentDateLocale, parseDate } from '../../../utils/dates';
import { useTrackingPayload } from '../../../hooks/useTrackPayload';
import { TrackEvents } from '../../../utils/constants';
import { useAnalytics } from '../../../hooks/useAnalytics';

const LinkWithQuery = dynamic(() => import('../../../components/LinkWithQuery'), {
  ssr: false,
});
const FormInput = dynamic(() => import('../../../components/FormInput'), {
  ssr: false,
});

interface ContactForm {
  name: string;
  lastname: string;
  email: string;
  phonecode: string;
  phone: string;
  tyc: boolean;
}

const FormContact = ({ slice }) => {
  const { track, identify } = useAnalytics();
  const intl = useIntl();
  const locale = useLocale();
  const router = useRouter();
  const vertical = slice?._vertical?.data;
  const cluster = slice?._cluster?.data;
  const product = slice?._product?.data;
  const color = getColor(vertical?.color);
  const trackPayload = useTrackingPayload({
    slice, cluster, vertical, product,
  });
  const showBenefitsButton = cluster?.showBenefitsButton;
  const { register, handleSubmit, reset } = useForm<ContactForm>();

  const defaultMessages = {
    nameLabel: intl.formatMessage({ defaultMessage: 'Nombre' }),
    lastnameLabel: intl.formatMessage({ defaultMessage: 'Apellido' }),
    emailLabel: intl.formatMessage({ defaultMessage: 'E-mail' }),
    phoneCodeLabel: intl.formatMessage({ defaultMessage: 'Código de Área' }),
    phoneLabel: intl.formatMessage({ defaultMessage: 'Teléfono' }),
    phonePlaceholder: intl.formatMessage({ defaultMessage: '11 0000 0000' }),
    termsAndConditionsAgree: intl.formatMessage({ defaultMessage: 'Aceptar' }),
    termsAndConditionsLabel: intl.formatMessage({ defaultMessage: 'Términos y condiciones' }),
    sendButtonLabel: intl.formatMessage({ defaultMessage: 'Enviar' }),
  };

  const dateFormat = (date: string, language: string): string => parseDate(
    date,
    getCurrentDateLocale(DATE_LOCALES, language),
    'd MMM',
  );

  const handleClick = useCallback((event): void => {
    const payload = cloneDeep(trackPayload);
    set(payload, 'product_name', cluster?.name);
    set(payload, 'label', 'Benefits');
    set(payload, 'url', event.target.href);
    set(payload, 'context.label', 'Benefits');
    set(payload, 'context.destination_url', event.target.href);

    track(
      TrackEvents.buttonClicked,
      payload,
      locale,
    );
  }, [trackPayload, locale, cluster, track]);

  const formTrack = useCallback((
    localeCountry: string,
    firstName: string,
    lastName: string,
    email: string,
    phoneCode: string,
    phone: string,
  ) => {
    const fields = {
      locale_country: localeCountry,
      first_name: firstName,
      last_name: lastName,
      email,
      phone: `${phoneCode}${phone}`,
    };

    identify(fields);

    const startDate = slice._product?.data?.startDate ? dateFormat(slice._product?.data?.startDate, locale) : '';
    const startEnd = slice._product?.data?.endDate ? dateFormat(slice._product?.data?.endDate, locale) : '';

    const schedule = [
      `${startDate} - ${startEnd}`,
      slice._product?.data?.scheduleDays,
      slice._product?.data?.scheduleHours,
    ].join(' | ');

    const payload = cloneDeep(trackPayload);
    set(payload, 'product_name', cluster?.name || 'Unknown');
    set(payload, 'fields', fields);
    set(payload, 'fields.schedule', schedule);

    track(TrackEvents.productApplicationSubmitted, payload, locale, true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackPayload]);

  const onSubmit: SubmitHandler<ContactForm> = ({
    name, lastname, email, phonecode, phone,
  }) => {
    localStorage?.setItem('formFirstName', name);
    localStorage?.setItem('formVertical', vertical?.color);

    formTrack(
      locale,
      name,
      lastname,
      email,
      phonecode,
      phone,
    );

    reset();

    const redirectLink = `${global.location.pathname}/${slice?._thankyou?.uid}`;

    router?.push({
      pathname: redirectLink,
    });
  };

  return (
    <section className="dark:bg-gray-1">
      <div className="max-w-3xl mx-auto py-24 lg:py-20 lg:px-4">
        {slice.primary?.title && (
          <h2 className="dh-title mb-11 text-3xl text-center">
            {slice.primary?.title}
          </h2>
        )}
        {slice.primary?.description && (
          <p className="dh-description mb-14 text-center">
            {slice.primary.description}
          </p>
        )}
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="overflow-hidden sm:rounded-md text-gray-800 dark:text-gray-300">
              <div className="px-4 py-5 sm:p-6">
                <div className="grid grid-cols-1 gap-6">
                  <div>
                    <FormInput
                      label={getDefaultText('nameLabel', slice.primary, defaultMessages)}
                      placeholder={slice.primary?.namePlaceholder}
                      id="name"
                      required
                      register={register('name', {
                        required: true,
                      })}
                    />
                  </div>

                  <div>
                    <FormInput
                      label={getDefaultText('lastnameLabel', slice.primary, defaultMessages)}
                      placeholder={slice.primary?.lastnamePlaceholder}
                      id="lastname"
                      required
                      register={register('lastname', {
                        required: true,
                      })}
                    />
                  </div>

                  <div>
                    <FormInput
                      label={getDefaultText('emailLabel', slice.primary, defaultMessages)}
                      placeholder={slice.primary?.emailPlaceholder}
                      id="email"
                      type="email"
                      required
                      register={register('email', {
                        required: true,
                        pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
                      })}
                    />
                  </div>

                  <div className="flex gap-6">
                    <div className="flex-none">
                      <FormInput
                        label={getDefaultText('phoneCodeLabel', slice.primary, defaultMessages)}
                        placeholder={slice.primary?.phoneCodePlaceholder}
                        id="phonecode"
                        className="w-20 lg:w-24"
                        register={register('phonecode', {
                          required: false,
                        })}
                      />
                    </div>

                    <div className="grow">
                      <FormInput
                        label={getDefaultText('phoneLabel', slice.primary, defaultMessages)}
                        placeholder={getDefaultText('phonePlaceholder', slice.primary, defaultMessages)}
                        id="phone"
                        register={register('phone', {
                          required: false,
                        })}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-4 py-3 text-right sm:px-6">
                <div className="relative flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="tyc"
                      aria-describedby="agree-terms-and-conditions"
                      type="checkbox"
                      className="focus:ring-grey-500 h-4 w-4 text-gey-600 border-gray-300 rounded"
                      required
                      {
                        ...register('tyc', {
                          required: true,
                        })
                      }
                    />
                  </div>
                  <div className="ml-3 text-sm font-normal">
                    <label
                      htmlFor="tyc"
                      className="mr-2 text-black dark:text-white"
                    >
                      {getDefaultText('termsAndConditionsAgree', slice.primary, defaultMessages)}
                    </label>
                    <LinkWithQuery
                      href={Link.url(
                        slice.primary.termsAndConditionsLink,
                        prismicLinkResolver,
                      )}
                      target="_blank"
                      className={classNames('underline', color.text)}
                    >
                      <span className="sr-only">
                        {getDefaultText('termsAndConditionsAgree', slice.primary, defaultMessages)}
                        {' '}
                      </span>
                      {getDefaultText('termsAndConditionsLabel', slice.primary, defaultMessages)}
                    </LinkWithQuery>
                  </div>
                </div>
              </div>
              <div className="grid lg:grid-cols-2 gap-6 px-4 py-3 text-right sm:px-6">
                <button
                  type="submit"
                  className={classNames(
                    'dh-button',
                    color.bg,
                    color.bgHover,
                    color.textContrast,
                  )}
                >
                  {getDefaultText('sendButtonLabel', slice.primary, defaultMessages)}
                </button>
                {showBenefitsButton
                  && (slice.primary?.benefitsButtonLink?.uid
                    || slice.primary?.benefitsButtonLink?.url
                  ) && (
                  <LinkWithQuery
                    onClick={handleClick}
                    href={Link.url(
                      slice.primary?.benefitsButtonLink,
                      prismicLinkResolver,
                    )}
                    target="_blank"
                    className={classNames(
                      'dh-button border border-black dark:border-white text-black dark:text-white',
                      'bg-transparent hover:bg-transparent',
                    )}
                  >
                    {getDefaultText('benefitsButtonLabel', slice.primary, defaultMessages)}
                  </LinkWithQuery>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default FormContact;
