import classNames from 'classnames';
import { Link, RichText } from 'prismic-reactjs';
import { nanoid } from 'nanoid';
import dynamic from 'next/dynamic';
import { prismicLinkResolver } from '../../../utils/common';

const LinkWithQuery = dynamic(() => import('../../../components/LinkWithQuery'), {
  ssr: false,
});
const Icon = dynamic(() => import('../../../components/Icon'), {
  ssr: false,
});

const Card = ({ children }) => (
  <div className="lg:py-4 w-full">
    {children}
  </div>
);

const Divider = () => (
  <div
    className={classNames(
      'bg-white h-[1px] w-[144px] mx-auto mt-5',
      'lg:absolute lg:h-[144px] lg:w-[1px] lg:left-1/2 lg:top-1/2 lg:mt-[-72px]',
    )}
  />
);

const ProductOverviewsWithTwoColumns = ({ slice }) => (
  <section className="bg-gray-100 text-black relative">
    <article className="dh-content grid lg:grid-cols-2 relative lg:gap-48">
      <Card>
        <header>
          {slice.primary?.title && (
            <h2 className="text-2xl font-bold text-gray-900">
              {slice.primary?.title}
            </h2>
          )}
        </header>

        {!!slice.items.length && (
          <ul className="mt-6 grid grid-cols-2 gap-x-6 gap-y-4">
            {slice.items?.map(({ label, icon }) => (
              <li className="text-gray-800 text-xs font-normal flex items-start" key={nanoid()}>
                <Icon icon={icon} className="w-4 mr-3 flex-none text-primary" />
                {label}
              </li>
            ))}
          </ul>
        )}

        {slice.primary?.description && (
          <div className="text-gray-800 text-xs font-normal mt-6">
            <RichText render={slice.primary?.description} />
          </div>
        )}
      </Card>
      <Divider />
      <Card>
        <div className="font-medium text-xs mt-5">
          <span className="font-bold mr-1">{slice.primary.totalPriceLabel}</span>
          <span className="line-through">{slice.primary.totalPrice}</span>
        </div>
        <div className="flex items-start mt-5">
          <span className="font-bold text-2xl mr-2">{slice.primary.totalPriceDiscount}</span>
          <span className="bg-primary text-white text-xs font-semibold p-1 rounded-sm">
            {slice.primary.totalPriceDiscountPercentage}
          </span>
        </div>
        <div className="text-gray-800 font-medium text-xs mt-3">
          <span className="mr-1">{slice.primary.fixedInstallmentsLabel}</span>
          <span className="font-semibold">{slice.primary.fixedInstallments}</span>
        </div>
        {slice.primary.buttonLink && slice.primary.buttonLabel && (
          <LinkWithQuery
            title={slice.primary.buttonLabel}
            href={Link.url(slice.primary.buttonLink, prismicLinkResolver)}
            className={classNames(
              'dh-button bg-transparent border border-primary inline-block mt-8 text-center',
              'font-semibold text-xs text-gray-800 leading-5 hover:bg-gray-300',
            )}
          >
            {slice.primary.buttonLabel}
          </LinkWithQuery>
        )}
      </Card>
    </article>
  </section>
);

export default ProductOverviewsWithTwoColumns;
