import classNames from 'classnames';
import { Link } from 'prismic-reactjs';
import { nanoid } from 'nanoid';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import {
  getVerticalByLink,
  prismicLinkResolver,
} from '../../../utils/common';
import { getColor } from '../../../utils/colors';

const LinkWithQuery = dynamic(() => import('../../../components/LinkWithQuery'), {
  ssr: false,
});

interface BoxesModel {
  colClass: string,
  rowSpan: number[],
}

const boxesGridTemplateModel: {[size: string]: BoxesModel} = {
  1: {
    colClass: 'lg:grid-cols-1',
    rowSpan: [0],
  },
  2: {
    colClass: 'lg:grid-cols-2',
    rowSpan: [0, 1],
  },
  3: {
    colClass: 'lg:grid-cols-3',
    rowSpan: [0, 1, 2],
  },
  4: {
    colClass: 'lg:grid-cols-3',
    rowSpan: [0, 2],
  },
  5: {
    colClass: 'lg:grid-cols-3',
    rowSpan: [0],
  },
};

const Item = ({ item, color }) => (
  <LinkWithQuery
    href={Link.url(item.link, (doc) => prismicLinkResolver(doc, item))}
    className={classNames(
      'block h-full w-full rounded-lg overflow-hidden text-center break-all cursor-pointer', // mobile
      'bg-gray-200 text-white hover:brightness-75', // mobile
      'lg:relative ', // desktop
      color.bg,
    )}
  >
    <div className="hidden w-full h-full relative lg:block">
      <Image
        src={item.image.url}
        alt={item.image.alt}
        layout="fill"
        objectFit="cover"
      />
    </div>
    <div
      className={classNames(
        'text-base flex items-center justify-center h-32 p-2', // mobile,
        'lg:block lg:h-auto lg:absolute lg:bottom-0 lg:w-full lg:p-5 lg:text-left', // desktop
        color.bg,
      )}
    >
      <p className="font-medium lg:font-medium lg:text-lg dark:text-athensGray break-normal ">
        {item.title}
      </p>
      <span
        className={classNames('hidden', 'lg:block mt-1 text-sm font-normal dark:text-white-2')}
      >
        {item.linkLabel}
      </span>
    </div>
  </LinkWithQuery>
);

const getBoxesModel = (size: number): BoxesModel => {
  const model: { [size: string]: BoxesModel } = {};

  for (let index = 1, i = 1; index <= size; index += 1, i += 1) {
    if (!boxesGridTemplateModel[i]) i = 3;

    model[index] = boxesGridTemplateModel[i];
  }

  return model[size] || {};
};

const mustBeRowSpan = (i: number, boxesModel: BoxesModel): boolean => boxesModel.rowSpan?.includes(i);

const Boxes = ({ slice }) => {
  const boxesSize: number = slice.items?.length ?? 0;
  const boxesModel = getBoxesModel(boxesSize);

  return boxesSize ? (
    <section className="bg-gray-100 dark:bg-gray-1">
      <div className="dh-content space-y-10 text-center">
        {slice.primary?.title && (
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="dh-title">{slice.primary.title}</h2>
            {slice.primary?.description && (
              <p className="dh-description">{slice.primary.description}</p>
            )}
          </div>
        )}
        <div className={classNames(
          'mt-6 max-w-2xl mx-auto lg:max-w-7xl grid grid-cols-2 gap-5',
          boxesModel.colClass,
        )}
        >
          {slice?.items?.map((item, i) => {
            const { color: verticalColor, uid } = getVerticalByLink(
              item?.link,
              slice._verticals,
              slice._clusters,
            );
            const color = getColor(verticalColor);
            const itemWithVerticalId = { ...item, verticalId: uid };

            return (
              <div
                key={nanoid()}
                className={classNames(
                  'w-full rounded-lg', // mobile
                  'lg:w-auto lg:h-auto lg:m-0 lg:aspect-w-3 lg:aspect-h-4 lg:rounded-lg', // desktop
                  'lg:overflow-hidden lg:block', // desktop
                  {
                    'lg:h-56': !mustBeRowSpan(i, boxesModel),
                    'lg:row-span-2 lg:h-117': mustBeRowSpan(i, boxesModel),
                  },
                )}
              >
                <Item item={itemWithVerticalId} color={color} />
              </div>
            );
          })}
        </div>
        {slice.primary?.buttonLink && slice.primary?.buttonLabel && (
          <div className="mt-8 flex justify-center">
            <div className="inline-flex rounded-md shadow">
              <LinkWithQuery
                href={Link.url(slice.primary.buttonLink, prismicLinkResolver)}
                className={classNames(
                  'dh-button-sm bg-black hover:bg-black border border-white',
                )}
              >
                {slice.primary?.buttonLabel}
              </LinkWithQuery>
            </div>
          </div>
        )}
      </div>
    </section>
  ) : null;
};

export default Boxes;
