import { RichText } from 'prismic-reactjs';
import { nanoid } from 'nanoid';
import { FunctionComponent } from 'react';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import { GridListsWithIconProps } from '../GridLists.types';

const Icon = dynamic(() => import('../../../components/Icon'), {
  ssr: false,
});

const gridOptions = {
  fullWidth: [1, 2, 4],
  displayGrid: [2, 4],
};

const GridListsWithIconCentered: FunctionComponent<GridListsWithIconProps> = ({ slice }) => {
  const gridSize = slice.items?.length ?? 0;
  const isFullWidth = gridOptions.fullWidth.includes(gridSize);
  const isGrid = gridOptions.displayGrid.includes(gridSize);

  return (
    gridSize ? (
      <section className="bg-black">
        <div className="dh-content">
          {slice.primary?.title && (
            <header className="mb-8 lg:mb-20 text-center">
              <h2 className="font-semibold text-3.5xl text-white">{slice.primary?.title}</h2>
            </header>
          )}
          <ul
            className={classNames(
              'gap-5',
              { 'flex flex-wrap justify-center': !isGrid },
              { 'grid sm:grid-cols-2': isGrid },
            )}
          >
            {slice.items?.map(({ icon, title, description }) => (
              <li
                key={nanoid()}
                className={classNames(
                  'p-8 space-y-5 rounded-2xl bg-gray-3 text-center',
                  { 'w-full md:max-w-[23.563rem]': !isFullWidth },
                  { 'w-full': isFullWidth },
                )}
              >
                <Icon icon={icon} className="w-10 text-primary inline-block" />
                {title && <h3 className="text-xl leading-8 font-semibold text-athensGray md:min-h-[4rem]">{title}</h3>}
                <div className="text-sm text-white-2 leading-6 text-left">
                  <RichText render={description} />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </section>
    ) : null);
};

export default GridListsWithIconCentered;
