import classnames from 'classnames';
import { RichText } from 'prismic-reactjs';
import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import { getBusinessUnitName, getRealLocale } from '../../../utils/common';
import { getColor } from '../../../utils/colors';

const ReactCarousel = dynamic(() => import('../../../components/ReactCarousel'), {
  ssr: false,
});

const itemsToShowSm = 1;
const itemsToShowLg = 3;
const breakPoints = [
  {
    width: 1,
    itemsToShow: itemsToShowSm,
    itemPadding: [0, 5, 0, 5],
    pagination: true,
  },
  {
    width: 955,
    itemsToShow: itemsToShowLg,
    itemPadding: [0, 16, 0, 16],
    pagination: false,
  },
];
const carouselOptions = {
  isRTL: false,
  pagination: true,
  breakPoints,
};

const CarouselWithImage = ({ slice }) => {
  const [lang, setLang] = useState<string>('es');
  const router = useRouter();
  const itemsLength = slice.items?.length;
  carouselOptions.breakPoints[0].pagination = itemsLength > itemsToShowSm;
  carouselOptions.breakPoints[1].pagination = itemsLength > itemsToShowLg;

  useEffect(() => {
    if (router?.locale) setLang(getRealLocale(router.locale));
  }, [router]);

  return (
    <div className="bg-gray-100 dark:bg-gray-2">
      <div className="dh-content">
        {slice.primary?.title && (
          <div className="max-w-3xl mx-auto text-center mb-16">
            <h2 className="dh-title">{slice.primary.title}</h2>
            {slice.primary?.description && (
              <p className="dh-description font-archivo">
                {slice.primary.description}
              </p>
            )}
          </div>
        )}
        <ReactCarousel options={carouselOptions} color={slice._vertical?.data?.color}>
          {slice.items?.map((item) => {
            const isVerticalPage = item.cluster?.type === 'verticalpage';

            const cluster = isVerticalPage ? slice._verticals?.find(({ uid }) => uid === item.cluster?.uid)
              : slice._clusters?.find(({ uid }) => uid === item.cluster?.uid);
            const vertical = isVerticalPage ? cluster
              : slice._verticals?.find(({ uid }) => uid === cluster?.data?.verticalPage?.uid);

            const itemBadgeLabel = item.badgeLabel || (isVerticalPage ? cluster?.data?.name
              : getBusinessUnitName(cluster?.data?.businessUnit, lang));

            const itemColor = item.color || vertical?.data?.color || slice._vertical?.data?.color;
            const itemSubtitle = item.subtitle || cluster?.data?.name;
            const color = getColor(itemColor);

            return (
              <div
                key={nanoid()}
                className={classnames(
                  'w-full py-10 px-6 relative border bg-gray-4 text-center rounded-lg',
                  color.border,
                )}
              >
                {itemBadgeLabel && (
                  <span
                    className={classnames(
                      'absolute left-0 top-5 inline-flex items-center px-4 py-3 rounded-r-xl',
                      'text-sm font-medium text-black capitalize',
                      color.bg,
                    )}
                  >
                    {itemBadgeLabel}
                  </span>
                )}
                <div className="space-y-6 xl:space-y-10">
                  {item.image?.url && (
                    <div className="mx-auto relative h-28 w-28">
                      <Image
                        className="rounded-full"
                        src={item.image.url}
                        alt={item.image.alt}
                        layout="fill"
                        objectFit="contain"
                      />
                    </div>
                  )}

                  <div className="xl:flex xl:items-center xl:justify-between">
                    <div className="font-medium text-lg leading-6 space-y-1">
                      {item.title && (
                        <h3 className="text-white text-xl pb-2">
                          {item.title}
                        </h3>
                      )}
                      {itemSubtitle && (
                        <h4 className="text-secondary-400 pb-3">
                          {itemSubtitle}
                        </h4>
                      )}
                      {item.description && (
                        <div className="text-sm pt-5 text-white font-archivo">
                          {RichText.render(item.description)}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </ReactCarousel>
      </div>
    </div>
  );
};

export default CarouselWithImage;
