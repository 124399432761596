import { useEffect, useState } from 'react';
import { getElementByKey } from '../utils/common';

const useOnScreenByKeys = (keys: string[], rootMargin = '0px') => {
  const [onScreen, setOnScreen] = useState({});
  const [observers, setObservers] = useState({});

  useEffect(() => {
    keys.forEach((key) => {
      const currentRef = getElementByKey(key);
      const observer = new IntersectionObserver(
        ([entry]) => {
          setOnScreen((prev) => ({
            ...prev,
            [key]: entry.isIntersecting,
          }));
        },
        {
          rootMargin,
        },
      );

      if (currentRef) {
        observer.observe(currentRef);
        setObservers((prev) => ({ ...prev, [key]: observer }));
      }
    });

    return () => {
      if (observers) {
        Object.keys(observers).forEach((key) => {
          const currentRef = getElementByKey(key);
          if (currentRef && observers[key]) observers[key].unobserve(currentRef);
        });
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return onScreen;
};

export default useOnScreenByKeys;
