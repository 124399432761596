import { RichText } from 'prismic-reactjs';
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import { FunctionComponent } from 'react';
import { ContentWithStepsProps } from '../Content.types';

const MAX_ITEMS = 3;

const ContentWithSteps: FunctionComponent<ContentWithStepsProps> = ({ slice }) => {
  const items = slice.items?.slice(0, MAX_ITEMS) || [];
  const itemsLength = items.length;

  return itemsLength ? (
    <div className="bg-white text-gray-800">
      <div className="dh-content grid lg:flex text-center my-7 lg:gap-x-10 lg:text-left">
        <header className="lg:pr-28 lg:flex lg:items-center">
          <div className="lg:max-w-sm">
            {slice.primary?.title && (
              <h2 className="text-black text-lg font-semibold leading-6">
                {slice.primary.title}
              </h2>
            )}
            {slice.primary?.description && (
              <div className="font-normal text-xs leading-5 mt-2">
                <RichText render={slice.primary?.description} />
              </div>
            )}
          </div>
        </header>
        <div className="text-lg font-normal mt-6 lg:mt-0">
          <nav aria-label="Progress">
            <ol className={classNames(
              'grid items-start gap-2 sm:gap-3',
              {
                'grid-cols-2': itemsLength === 2,
                'grid-cols-3': itemsLength === 3,
              },
            )}
            >
              {items.map((item, index) => (
                <li
                  key={nanoid()}
                  className={classNames('relative')}
                >
                  <div className="grid items-start">
                    <div className="relative px-0 sm:px-10 flex items-center justify-center">
                      <div className="absolute inset-0 flex items-center" aria-hidden="true">
                        <div className={classNames(
                          'h-0.5 w-full border-t border-dashed border-gray-700',
                          {
                            'ml-[50%] -mr-2 sm:-mr-3': !index,
                            '-ml-2 sm:-ml-3 w-1/2 ': index === itemsLength - 1,
                            hidden: itemsLength === 1,
                          },
                        )}
                        />
                      </div>
                      <div className={classNames(
                        'group relative w-16 h-16 flex items-center justify-center',
                        'bg-white border-14 border-primary/20 rounded-full',
                      )}
                      >
                        <span
                          aria-hidden="true"
                          className={classNames(
                            'h-9 w-9 rounded-full bg-primary flex items-center justify-center',
                            'text-white font-semibold text-base leading-6',
                          )}
                        >
                          {index + 1}
                        </span>
                      </div>
                    </div>
                    <span className="text-center mt-7 font-bold text-black text-xs sm:text-sm leading-5">
                      {item.stepLabel}
                    </span>
                  </div>
                </li>
              ))}
            </ol>
          </nav>
        </div>
      </div>
    </div>
  ) : <div className="hidden" />;
};

export default ContentWithSteps;
