import { Link, RichText } from 'prismic-reactjs';
import { FC } from 'react';
import dynamic from 'next/dynamic';
import { prismicLinkResolver, scrollTo } from '../../../utils/common';
import { hasRichText } from '../../../utils/prismic';
import { ContentWithButtonCenteredProps } from '../Content.types';

const LinkWithQuery = dynamic(() => import('../../../components/LinkWithQuery'), {
  ssr: false,
});

interface IButton {
  link?: Link;
  scrollToId?: string;
  className?: string;
}

const Button: FC<IButton> = ({
  link, scrollToId, className, children,
}) => (scrollToId
  ? (
    <button
      type="button"
      className={className}
      onClick={() => scrollTo({ id: scrollToId })}
    >
      {children}
    </button>
  ) : (
    <LinkWithQuery
      href={Link.url(link, prismicLinkResolver)}
      target={link?.target ?? '_self'}
      className={className}
    >
      {children}
    </LinkWithQuery>
  ));

const ContentWithButtonCentered: FC<ContentWithButtonCenteredProps> = ({ slice }) => {
  const {
    description, buttonLink, buttonScrollTo, buttonLabel,
  } = slice.primary;
  if (!hasRichText(description)) return null;

  return (
    <section className="dh-content-full bg-white-2 text-black text-h6 font-medium text-center py-10">
      <RichText render={description} />
      <Button
        className="
          dh-btn bg-black-1 font-bold text-white-1 mt-6 lg:text-base 2xl:px-15
          3xl:py-4 3xl:px-[3.125rem] 3xl:text-lg"
        link={buttonLink}
        scrollToId={buttonScrollTo}
      >
        {buttonLabel}
      </Button>
    </section>
  );
};

export default ContentWithButtonCentered;
