import { useContext } from 'react';
import { AppContext } from '../contexts/AppContext';

const useGeoCountry = () => {
  const { state } = useContext(AppContext);

  return state.geoCountry.loc;
};

export default useGeoCountry;
