import { FormVariations } from './Form.types';
import FormContact from './variations/FormContact';
import FormContactBenefitSelect from './variations/FormContactBenefitSelect';
import FormContactClusterSelect from './variations/FormContactClusterSelect';
import FormContactCompany from './variations/FormContactCompany';
import FormContactSchedule from './variations/FormContactSchedule';
import FormContactWithLocaltion from './variations/FormContactWithLocaltion';
import FormContactProducts from './variations/FormContactProducts';

const Form = ({ slice }) => {
  switch (slice.variation) {
    case FormVariations.contactWithLocation:
      return <FormContactWithLocaltion slice={slice} />;
    case FormVariations.contactClusterSelect:
      return <FormContactClusterSelect slice={slice} />;
    case FormVariations.contactBenefitSelect:
      return <FormContactBenefitSelect slice={slice} />;
    case FormVariations.contactCompany:
      return <FormContactCompany slice={slice} />;
    case FormVariations.contact:
      return <FormContact slice={slice} />;
    case FormVariations.contactSchedule:
      return <FormContactSchedule slice={slice} />;
    case FormVariations.contactProducts:
      return <FormContactProducts slice={slice} />;
    default:
      return null;
  }
};

export default Form;
