import { useCallback, useState } from 'react';
import classNames from 'classnames';
import { Link } from 'prismic-reactjs';
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';
import set from 'lodash.set';
import cloneDeep from 'lodash.clonedeep';
import { nanoid } from 'nanoid';
import dynamic from 'next/dynamic';
import useLocale from '../../../hooks/useLocale';
import { getDefaultText, prismicLinkResolver, slugify } from '../../../utils/common';
import { getColor } from '../../../utils/colors';
import { useTrackingPayload } from '../../../hooks/useTrackPayload';
import { TrackEvents } from '../../../utils/constants';
import { useAnalytics } from '../../../hooks/useAnalytics';

const LinkWithQuery = dynamic(() => import('../../../components/LinkWithQuery'), {
  ssr: false,
});
const FormInput = dynamic(() => import('../../../components/FormInput'), {
  ssr: false,
});

const positions = [
  'Dueño',
  'Representante legal',
  'Rector',
  'Directivo (primaria)',
  'Directivo (secundaria)',
  'Coordinador de tecnología',
  'Docente',
  'Padre de alumno escolar',
  'Estudiante',
];

const FormContactWithLocaltion = ({ slice }) => {
  const { track, identify } = useAnalytics();
  const intl = useIntl();
  const locale = useLocale();
  const [isSending, setIsSending] = useState<boolean>();
  const router = useRouter();
  const vertical = slice?._vertical?.data;
  const cluster = slice?._cluster?.data;
  const product = slice?._product?.data;
  const color = getColor(vertical?.color);
  const trackPayload = useTrackingPayload({
    slice, cluster, vertical, product,
  });
  const showRules = !!(slice.primary.textAgree && slice.primary.label1 && slice.primary.link1?.uid);

  const defaultMessages = {
    nameLabel: intl.formatMessage({ defaultMessage: 'Nombre' }),
    lastnameLabel: intl.formatMessage({ defaultMessage: 'Apellido' }),
    emailLabel: intl.formatMessage({ defaultMessage: 'E-mail' }),
    phoneCodeLabel: intl.formatMessage({ defaultMessage: 'Código de Área' }),
    phoneLabel: intl.formatMessage({ defaultMessage: 'Teléfono' }),
    phonePlaceholder: intl.formatMessage({ defaultMessage: '11 0000 0000' }),
    institutionLabel: intl.formatMessage({ defaultMessage: 'Institución' }),
    positionLabel: intl.formatMessage({ defaultMessage: 'Cargo' }),
    stateLabel: intl.formatMessage({ defaultMessage: 'Provincia' }),
    cityLabel: intl.formatMessage({ defaultMessage: 'Ciudad' }),
    termsAndConditionsAgree: intl.formatMessage({ defaultMessage: 'Aceptar' }),
    termsAndConditionsLabel: intl.formatMessage({ defaultMessage: 'Términos y condiciones' }),
    sendButtonLabel: intl.formatMessage({ defaultMessage: 'Enviar' }),
  };

  const formTrack = useCallback((
    localeCountry: string,
    name: string,
    lastname: string,
    email: string,
    phoneCode: string,
    phone: string,
    institution: string,
    state: string,
    city: string,
    position: string,
  ) => {
    const fields = {
      locale_country: localeCountry,
      first_name: name,
      last_name: lastname,
      email,
      phone: `${phoneCode}${phone}`,
      institution,
      company_position: position,
    };

    identify(fields);

    const payload = cloneDeep(trackPayload);
    set(payload, 'product_name', cluster?.name || vertical?.name || 'Unknown');
    set(payload, 'fields', fields);
    set(payload, 'fields.state', state);
    set(payload, 'fields.city', city);

    track(TrackEvents.productApplicationSubmitted, payload, locale, true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackPayload]);

  const onSubmit = (event) => {
    event.preventDefault();
    setIsSending(true);

    const {
      name, lastname, email, phonecode, phone, institution, state, city, position,
    } = event.target;

    localStorage?.setItem('formFirstName', name.value);
    localStorage?.setItem(
      'formVertical',
      slice._vertical?.data?.color,
    );

    try {
      formTrack(
        locale,
        name?.value,
        lastname?.value,
        email?.value,
        phonecode?.value,
        phone?.value,
        institution?.value,
        state?.value,
        city?.value,
        position?.value,
      );
    } catch {
      setIsSending(false);
    }

    const redirectLink = slice._product
      ? `${global.location.pathname}/${slice?._thankyou?.uid}`
      : `${global.location.pathname}/_/_/${slice?._thankyou?.uid}`;

    router?.push({
      pathname: redirectLink,
    });
  };

  return (
    <section className="dark:bg-gray-1">
      <div className="max-w-xl mx-auto py-24 lg:py-20 lg:px-4">
        {slice.primary?.title && (
          <h2 className="dh-title text-white mb-11 text-2xl font-normal text-center" id={slugify(slice.primary.title)}>
            {slice.primary.title}
          </h2>
        )}
        <div>
          <form onSubmit={onSubmit}>
            <div className="overflow-hidden sm:rounded-md text-gray-800 dark:text-gray-300">
              <div className="px-4 py-5 sm:p-6">
                <div className="grid grid-cols-1 gap-6">
                  <div>
                    <FormInput
                      label={getDefaultText('nameLabel', slice.primary, defaultMessages)}
                      placeholder={slice.primary?.namePlaceholder}
                      name="name"
                      id="name"
                      required
                    />
                  </div>
                  <div>
                    <FormInput
                      label={getDefaultText('lastnameLabel', slice.primary, defaultMessages)}
                      placeholder={slice.primary?.lastnamePlaceholder}
                      name="lastname"
                      id="lastname"
                      required
                    />
                  </div>

                  <div>
                    <FormInput
                      label={getDefaultText('emailLabel', slice.primary, defaultMessages)}
                      placeholder={slice.primary?.emailPlaceholder}
                      name="email"
                      id="email"
                      type="email"
                      required
                    />
                  </div>

                  <div className="flex gap-6">
                    <div className="flex-none">
                      <FormInput
                        label={getDefaultText('phoneCodeLabel', slice.primary, defaultMessages)}
                        placeholder={slice.primary?.phoneCodePlaceholder}
                        name="phonecode"
                        id="phonecode"
                        className="w-20 lg:w-24"
                      />
                    </div>

                    <div className="grow">
                      <FormInput
                        label={getDefaultText('phoneLabel', slice.primary, defaultMessages)}
                        placeholder={getDefaultText('phonePlaceholder', slice.primary, defaultMessages)}
                        name="phone"
                        id="phone"
                      />
                    </div>
                  </div>

                  <div>
                    <FormInput
                      label={getDefaultText('institutionLabel', slice.primary, defaultMessages)}
                      placeholder={slice.primary?.institutionPlaceholder}
                      name="institution"
                      id="institution"
                      required
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="position"
                      className="text-white-3 block text-xs font-semibold mb-2"
                    >
                      {getDefaultText('positionLabel', slice.primary, defaultMessages)}
                    </label>
                    <select
                      id="position"
                      name="position"
                      defaultValue=""
                      className={classNames(
                        'h-10 block w-full pl-3 pr-10 py-2 text-base sm:text-sm rounded-md border',
                        'text-gray-900 bg-white placeholder-gray-800 border-black',
                        'focus:ring-gray-800 focus:border-gray-500',
                      )}
                      required
                    >
                      <option key="select" value="" disabled>
                        {slice.primary?.positionPlaceholder}
                      </option>
                      {positions?.map((pos) => (
                        <option key={nanoid()} value={pos}>
                          {pos}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                    <div>
                      <FormInput
                        label={getDefaultText('stateLabel', slice.primary, defaultMessages)}
                        placeholder={slice.primary?.statePlaceholder}
                        name="state"
                        id="state"
                      />
                    </div>

                    <div>
                      <FormInput
                        label={getDefaultText('cityLabel', slice.primary, defaultMessages)}
                        placeholder={slice.primary?.cityPlaceholder}
                        name="city"
                        id="city"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-6 px-4 py-3 text-right sm:px-6">
                {slice.primary?.termsAndConditionsLink?.type && (
                  <div className="relative flex items-center">
                    <div className="flex items-center h-5">
                      <input
                        id="tyc"
                        aria-describedby="agree-terms-and-conditions"
                        name="tyc"
                        type="checkbox"
                        className="focus:ring-grey-500 h-4 w-4 text-gey-600 border-gray-300 rounded"
                        required
                      />
                    </div>
                    <div className="ml-3 text-sm font-normal font-archivo text-left">
                      <label
                        htmlFor="tyc"
                        className="mr-2 text-black dark:text-athensGray"
                      >
                        {getDefaultText('termsAndConditionsAgree', slice.primary, defaultMessages)}
                      </label>
                      <LinkWithQuery
                        href={Link.url(
                          slice.primary.termsAndConditionsLink,
                          prismicLinkResolver,
                        )}
                        target="_blank"
                        className={classNames('underline', color.text)}
                      >
                        <span className="sr-only">
                          {getDefaultText('termsAndConditionsAgree', slice.primary, defaultMessages)}
                          {' '}
                        </span>
                        {getDefaultText('termsAndConditionsLabel', slice.primary, defaultMessages)}
                      </LinkWithQuery>
                    </div>
                  </div>
                )}
                {showRules && (
                  <div className="relative flex items-center">
                    <div className="flex items-center h-5">
                      <input
                        id="ryp"
                        aria-describedby="agree-terms-and-conditions"
                        type="checkbox"
                        className="focus:ring-grey-500 h-4 w-4 text-gey-600 border-gray-300 rounded"
                        required
                      />
                    </div>
                    <div className="ml-3 text-sm font-normal font-archivo text-left">
                      <label
                        htmlFor="ryp"
                        className="mr-2 text-white"
                      >
                        {slice.primary.textAgree}
                      </label>
                      <LinkWithQuery
                        href={Link.url(
                          slice.primary.link1,
                          prismicLinkResolver,
                        )}
                        target="_blank"
                        className={classNames('underline', color.text)}
                      >
                        <span>
                          {slice.primary.label1}
                          {' '}
                        </span>
                      </LinkWithQuery>
                      <label
                        htmlFor="ryp"
                        className="mx-2 text-white-2"
                      >
                        {slice.primary.textConnector}
                      </label>
                      <LinkWithQuery
                        href={Link.url(
                          slice.primary.link2,
                          prismicLinkResolver,
                        )}
                        target="_blank"
                        className={classNames('underline', color.text)}
                      >
                        <span>
                          {slice.primary.label2}
                          {' '}
                        </span>
                      </LinkWithQuery>
                    </div>
                  </div>
                )}
                <button
                  disabled={isSending}
                  type="submit"
                  className={classNames(
                    'dh-button-sm lg:text-sm row-span-2 my-auto mx-auto px-16 py-3',
                    color.bg,
                    color.bgHover,
                    color.textContrast,
                  )}
                >
                  {getDefaultText('sendButtonLabel', slice.primary, defaultMessages)}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default FormContactWithLocaltion;
