import { Link } from 'prismic-reactjs';

const Embed = ({ slice }) => (
  <section>
    {slice.primary.url.url && (
      <iframe
        frameBorder="0"
        src={Link.url(slice.primary.url)}
        title={slice.primary.a11yTitle}
        height={slice.primary.height}
        className="w-full"
      />
    )}
  </section>
);

export default Embed;
