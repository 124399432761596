import { RichTextBlock } from 'prismic-reactjs';
import { Image, Slice } from '../../types/prismic.type';

export enum StatsVariations {
  animatedCounter = 'statsAnimatedCounter',
  imageLight = 'statsImageLight',
  imageDark = 'statsImageDark',
  backgroundColor = 'statsBackgroundColor',
  inCard = 'statsInCard',
  counterWithIcons = 'statsCounterWithIcons'
}

export type StatsAnimatedCounterProps = Slice & {
  slice: {
    items?: {
      number?: number;
      description?: RichTextBlock[];
      prefix?: string;
      suffix?: string;
    }[];
  }
}

export type StatsCounterWithIconsProps = Slice & {
  slice: {
    primary?: {
      backgroundColor?: string;
      textColor?: string;
    },
    items?: {
      icon?: Image;
      number?: number;
      description?: RichTextBlock[];
      prefix?: string;
      suffix?: string;
    }[];
  }
}
