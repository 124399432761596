import { Fragment, FunctionComponent } from 'react';
import classNames from 'classnames';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';

export enum ModalSize {
  sm = 'small',
  md = 'medium',
  lg = 'large'
}

interface ModalProps {
  state: boolean;
  setOpen: (state: boolean) => void;
  size?: ModalSize;
}

const Modal: FunctionComponent<ModalProps> = ({
  state, setOpen, size, children,
}) => (
  <Transition.Root show={state} as={Fragment}>
    <Dialog
      as="div"
      className="fixed z-30 inset-0 overflow-y-auto"
      onClose={() => setOpen(false)}
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black-2/75 transition-opacity" />
        </Transition.Child>

        {/* This element is to trick the browser into centering the modal contents. */}
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            className={classNames(
              'inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl',
              'transform transition-all sm:my-8 sm:align-middle sm:w-full sm:p-6',
              {
                'sm:max-w-sm': size === ModalSize.sm,
                'sm:max-w-sm md:max-w-3xl': size === ModalSize.md,
                'sm:max-w-sm md:max-w-3xl lg:max-w-6xl': !size || (size === ModalSize.lg),
              },
            )}
          >
            <div className="flex justify-end -mt-5 -mr-3">
              <button
                type="button"
                onClick={() => setOpen(false)}
                className={classNames('px-3 py-3 text-black place-self-end')}
              >
                <XIcon className="h-5 w-5 text-black" aria-hidden="true" />
              </button>
            </div>
            {children}
          </div>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition.Root>
);

export default Modal;
