import { RichText } from 'prismic-reactjs';
import { nanoid } from 'nanoid';
import { FunctionComponent } from 'react';
import dynamic from 'next/dynamic';
import { GridListsWithIconProps } from '../GridLists.types';

const Icon = dynamic(() => import('../../../components/Icon'), {
  ssr: false,
});

const GridListsWithIconDark: FunctionComponent<GridListsWithIconProps> = ({ slice }) => (
  slice?.items?.length ? (
    <section className="bg-black">
      <div className="dh-content">
        {slice.primary?.title && (
          <header className="mb-8 lg:mb-20 text-center">
            <h2 className="font-bold text-2xl text-white">{slice.primary?.title}</h2>
          </header>
        )}
        <ul className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
          {slice.items.map(({ icon, title, description }) => (
            <li
              key={nanoid()}
              className="px-6 py-12 space-y-5 rounded-2xl bg-gray-3"
            >
              <Icon icon={icon} className="w-7 text-primary" />
              <h3 className="text-lg font-bold text-athensGray">{title}</h3>
              <div className="text-sm text-white-2 leading-6">
                <RichText render={description} />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  ) : null);

export default GridListsWithIconDark;
