import {
  FC, useCallback, useEffect, useState,
} from 'react';
import { RichText } from 'prismic-reactjs';
import { CheckCircleIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import { useIntl } from 'react-intl';
import sortBy from 'lodash.sortby';
import Image from 'next/image';
import set from 'lodash.set';
import cloneDeep from 'lodash.clonedeep';
import dynamic from 'next/dynamic';
import useGeoCountry from '../../../hooks/useGeoCountry';
import { IProductGroup } from '../../../graphql/model/interfaces';
import { useTrackingPayload } from '../../../hooks/useTrackPayload';
import { getDefaultText } from '../../../utils/common';
import useLocale from '../../../hooks/useLocale';
import { getCheckoutUrl, getLocalePlans } from '../../../utils/checkout';
import { useCheckoutQueryParams } from '../../../hooks/useCheckoutQueryParams';
import { hasRichText } from '../../../utils/prismic';
import { getColor } from '../../../utils/colors';
import { GridListsProductsCheckoutProps } from '../GridLists.types';
import {
  DATE_LOCALES, getCurrentDateLocale, groupBySchedules, isMorning, parseDate,
} from '../../../utils/dates';
import { NextStepFlow, TrackEvents } from '../../../utils/constants';
import { useAnalytics } from '../../../hooks/useAnalytics';

const LinkWithQuery = dynamic(() => import('../../../components/LinkWithQuery'), {
  ssr: false,
});

interface IHandleClickData {
  nextStep: NextStepFlow, label?: string, url: string, item: IProductGroup
}

const GridListsProductsCheckout: FC<GridListsProductsCheckoutProps> = ({ slice }) => {
  const vertical = slice._vertical?.data;
  const cluster = slice._cluster?.data;
  const { track } = useAnalytics();
  const trackPayload = useTrackingPayload({ slice, cluster, vertical });
  const intl = useIntl();
  const queryParams = useCheckoutQueryParams();
  const locale = useLocale();
  const color = getColor(slice.primary?.color, 800);
  const productsCheckout = slice._checkout?.products;
  const products = sortBy(productsCheckout, (item) => item.duration?.name).reverse();
  const geoCountry = useGeoCountry();
  const [showSlice, setShowSlice] = useState<boolean>(false);
  const [countryCode, setCountryCode] = useState<string>('');

  const primaryMessages = slice.primary as { [key: string]: string };
  const defaultMessages = {
    morningShift: intl.formatMessage({ defaultMessage: 'Turno Mañana' }),
    afternoonShift: intl.formatMessage({ defaultMessage: 'Turno Tarde' }),
    dateFormat: intl.formatMessage({ defaultMessage: 'dd/MM/yy' }),
    buttonBookLabel: intl.formatMessage({ defaultMessage: 'Reservar' }),
    lastplaceLabel: intl.formatMessage({ defaultMessage: '¡Ultimos cupos!' }),
    buttonSoldoutLabel: intl.formatMessage({ defaultMessage: 'Sold out' }),
    monday: intl.formatMessage({ defaultMessage: 'Lunes' }),
    tuesday: intl.formatMessage({ defaultMessage: 'Martes' }),
    wednesday: intl.formatMessage({ defaultMessage: 'Miércoles' }),
    thursday: intl.formatMessage({ defaultMessage: 'Jueves' }),
    friday: intl.formatMessage({ defaultMessage: 'Viernes' }),
    saturday: intl.formatMessage({ defaultMessage: 'Sábado' }),
    sunday: intl.formatMessage({ defaultMessage: 'Domingo' }),
  };

  const formatDay = (day) => {
    let formattedDay;
    switch (day) {
      case 0:
        formattedDay = getDefaultText('sunday', primaryMessages, defaultMessages);
        break;
      case 1:
        formattedDay = getDefaultText('monday', primaryMessages, defaultMessages);
        break;
      case 2:
        formattedDay = getDefaultText('tuesday', primaryMessages, defaultMessages);
        break;
      case 3:
        formattedDay = getDefaultText('wednesday', primaryMessages, defaultMessages);
        break;
      case 4:
        formattedDay = getDefaultText('thursday', primaryMessages, defaultMessages);
        break;
      case 5:
        formattedDay = getDefaultText('friday', primaryMessages, defaultMessages);
        break;
      default:
        formattedDay = getDefaultText('saturday', primaryMessages, defaultMessages);
        break;
    }
    return formattedDay;
  };

  useEffect(() => {
    if (!geoCountry) return;

    const { hasPlans, isUnavailable, currentLocale } = getLocalePlans({ products, geoCountry, locale });

    setCountryCode(currentLocale.countryCode);
    setShowSlice(!!(hasPlans && !isUnavailable));
  }, [geoCountry, locale, products]);

  const getColumnClassName = () => {
    let columns = 1;
    if (slice.primary.scheduleText) {
      columns += 1;
    }
    if (slice.primary.dateStartText) {
      columns += 1;
    }
    if (slice.primary.daysText) {
      columns += 1;
    }
    if (slice.primary.modalityText) {
      columns += 1;
    }
    if (slice.primary.shiftText) {
      columns += 1;
    }

    const columnsClass = columns < 4 ? 'lg:grid-cols-3' : 'lg:grid-cols-4';
    return columnsClass;
  };

  const handleClick = useCallback(({
    nextStep, label = '', url, item,
  }: IHandleClickData): void => {
    const payload = cloneDeep(trackPayload);
    set(payload, 'context.next_step', nextStep);
    set(payload, 'context.label', label);
    set(payload, 'context.destination_url', url);
    set(payload, 'product.start_date', item.startsAt);
    set(payload, 'product.id', item.id);
    set(payload, 'product.business_unit', item.defaultOffer.businessUnit);
    set(payload, 'product.business_unit_id', item.defaultOffer.businessUnitId);
    set(payload, 'product.vertical', item.defaultOffer.vertical);
    set(payload, 'product.vertical_id', item.defaultOffer.verticalId);
    set(payload, 'product.code', item.code);
    set(payload, 'product.duration', item.duration?.name);

    track(TrackEvents.productApplicationClicked, payload, locale);
  }, [trackPayload, locale, track]);

  if (!showSlice) return null;

  const dateFormat = (date: string, language: string): string => parseDate(
    date.split('T')[0],
    getCurrentDateLocale(DATE_LOCALES, language),
    defaultMessages.dateFormat,
  );

  return (
    <section className="bg-white-1 py-15 px-0 lg:p-4 lg:py-13 3xl:py-20">
      <div className="lg:w-10/12 m-auto lg:m-auto lg:max-w-7xl 2xl:max-w-8xl 3xl:w-full">
        {slice.primary?.title
        && (
        <p className="text-black-1 font-bold px-4 lg:px-0 pb-1.5 text-h4
        lg:pb-4 lg:text-h2 3xl:text-h1"
        >
          {slice.primary.title}
        </p>
        )}
        {hasRichText(slice.primary?.description)
    && (
    <div className="text-gray-1 font-medium text-p3 px-4 lg:px-0  3xl:text-p2">
      <RichText render={slice.primary?.description} />
    </div>
    )}
        <div>
          <div className="pt-4 lg:pt-10 lg:grid">
            <div
              className={classNames(
                'hidden lg:grid lg:visible gap-4',
                getColumnClassName(),
              )}
            >
              {slice.primary.modalityText
              && (
              <div
                className={classNames(
                  'flex gap-3 pb-3.5 border-b-2 items-center',
                  '3xl:gap-4 3xl:border-b-3',
                  color.border,
                )}
                data-testid="column"
              >
                {slice.primary.modalityIcon?.url
                  && (
                    <div className="h-6 w-6 3xl:w-10 3xl:h-10 relative">
                      <Image
                        className="h-6 w-6 3xl:w-10 3xl:h-10 relative"
                        src={slice.primary.modalityIcon.url}
                        alt={slice.primary.modalityIcon.alt}
                        layout="fill"
                        objectFit="cover"
                      />
                    </div>
                  )}
                <p className="text-gray-1 font-medium text-p1 3xl:text-h5">{slice?.primary?.modalityText}</p>
              </div>
              )}
              {slice.primary.dateStartText
                && (
                <div
                  className={classNames(
                    'flex gap-3 pb-3.5 border-b-2 items-center',
                    '3xl:gap-4 3xl:border-b-3',
                    color.border,
                  )}
                  data-testid="column"
                >
                  {slice.primary.dateStartIcon?.url
                    && (
                      <div className="h-6 w-6 3xl:w-10 3xl:h-10 relative">
                        <Image
                          className="h-6 w-6 3xl:w-10 3xl:h-10 relative"
                          src={slice.primary.dateStartIcon.url}
                          alt={slice.primary.dateStartIcon.alt}
                          layout="fill"
                          objectFit="cover"
                        />
                      </div>
                    )}
                  <p className="text-gray-1 font-medium text-p1 3xl:text-h5">{slice?.primary?.dateStartText}</p>
                </div>
                )}
              {slice.primary.shiftText
                && (
                <div
                  className={classNames(
                    'flex gap-3 pb-3.5 border-b-2 items-center',
                    '3xl:gap-4 3xl:border-b-3',
                    color.border,
                  )}
                  data-testid="column"
                >
                  {slice.primary.shiftIcon?.url
                    && (
                      <div className="h-6 w-6 3xl:w-10 3xl:h-10 relative">
                        <Image
                          className="h-6 w-6 3xl:w-10 3xl:h-10 relative"
                          src={slice.primary.shiftIcon.url}
                          alt={slice.primary.shiftIcon.alt}
                          layout="fill"
                          objectFit="cover"
                        />
                      </div>
                    )}
                  <p className="text-gray-1 font-medium text-p1 3xl:text-h5">{slice?.primary?.shiftText}</p>
                </div>
                )}
              {slice.primary.daysText
                && (
                <div
                  className={classNames(
                    'flex gap-3 pb-3.5 border-b-2 items-center',
                    '3xl:gap-4 3xl:border-b-3',
                    color.border,
                  )}
                  data-testid="column"
                >
                  {slice.primary.daysIcon?.url
                    && (
                      <div className="h-6 w-6 3xl:w-10 3xl:h-10 relative">
                        <Image
                          className="h-6 w-6 3xl:w-10 3xl:h-10 relative"
                          src={slice.primary.daysIcon.url}
                          alt={slice.primary.daysIcon.alt}
                          layout="fill"
                          objectFit="cover"
                        />
                      </div>
                    )}
                  <p className="text-gray-1 font-medium text-p1 3xl:text-h5">{slice?.primary?.daysText}</p>
                </div>
                )}
              {slice.primary.scheduleText
                && (
                <div
                  className={classNames(
                    'flex gap-3 pb-3.5 border-b-2 items-center',
                    '3xl:gap-4 3xl:border-b-3',
                    color.border,
                  )}
                  data-testid="column"
                >
                  {slice.primary.scheduleIcon?.url
                    && (
                      <div className="h-6 w-6 3xl:w-10 3xl:h-10 relative">
                        <Image
                          className="h-6 w-6 3xl:w-10 3xl:h-10 relative"
                          src={slice.primary.scheduleIcon.url}
                          alt={slice.primary.scheduleIcon.alt}
                          layout="fill"
                          objectFit="cover"
                        />
                      </div>
                    )}
                  <p className="text-gray-1 font-medium text-p1 3xl:text-h5">{slice?.primary?.scheduleText}</p>
                </div>
                )}
            </div>
            <div className={classNames('lg:hidden border-t-2 w-4/6 m-auto', color.border)} />
            {products?.map((item) => (
              <div
                key={nanoid()}
                className={classNames(
                  'border-b border-white-4 lg:border-none py-6 lg:p-0',
                  { 'pb-2.5': item.lastplaces },
                )}
              >
                <div className={classNames(
                  'grid grid-cols-2  px-4 lg:px-0 ',
                  'gap-4 lg:py-8 items-center lg:border-b lg:border-white-4',
                  getColumnClassName(),
                )}
                >
                  {slice.primary.modalityText
                  && (
                  <div className="flex items-center">
                    <CheckCircleIcon className={classNames('w-6 lg:w-5 flex-none mr-1.5 3xl:w-6', color.text)} />
                    <div>
                      <p className=" text-gray-6 text-p4 font-medium lg:hidden">{slice.primary?.modalityText}</p>
                      <p className="text-gray-1 text-p3 font-bold 3xl:text-p1">{item.duration?.name}</p>
                    </div>
                  </div>
                  )}
                  {slice.primary.dateStartText
                  && (
                  <div data-testid="column">
                    <p className=" text-gray-6 text-p4 font-medium lg:hidden">{slice.primary?.dateStartText}</p>
                    <p className="text-gray-1 w-full text-p3 font-bold 3xl:text-p1">
                      {dateFormat(item.startsAt.split('T')[0], locale)}
                      {item.endsAt && ` - ${dateFormat(item.endsAt.split('T')[0], locale)}`}
                    </p>
                  </div>
                  )}
                  {slice.primary.shiftText
                  && (
                  <div className="pl-7.5 lg:pl-0">
                    <p className=" text-gray-6 text-p4 font-medium lg:hidden">{slice.primary?.shiftText}</p>
                    <p className="text-gray-1 text-p3 font-bold 3xl:text-p1">
                      {item.slotFrom
                        && (isMorning(item.slotFrom) ? defaultMessages.morningShift : defaultMessages.afternoonShift) }
                    </p>
                  </div>
                  )}
                  {slice.primary.daysText && (
                    <div className="">
                      {groupBySchedules(item?.schedules, formatDay).map((schedule) => (
                        <p className="text-gray-1 text-p3 font-bold 3xl:text-p1" key={nanoid()}>
                          { schedule }
                        </p>
                      ))}
                    </div>
                  )}
                  <div className="relative col-span-2 lg:col-span-1 lg:flex">
                    <LinkWithQuery
                      key={nanoid()}
                      href={getCheckoutUrl(item, item.code, queryParams, slice._checkout?.sandbox, countryCode)}
                      onClick={(e: Event) => (
                        item.soldout
                          ? e.preventDefault()
                          : handleClick({
                            nextStep: NextStepFlow.checkout,
                            label: getDefaultText('buttonBookLabel', primaryMessages, defaultMessages),
                            url: getCheckoutUrl(
                              item,
                              item.code,
                              queryParams,
                              slice._checkout?.sandbox,
                              countryCode,
                            ),
                            item,
                          })
                      )}
                      target="_self"
                      className={classNames(
                        'w-full dh-btn font-bold self-stretch text-p3 lg:text-p2 p-3 3xl:text-lg 3xl:p-4',
                        { ' bg-black-1 text-white-1 ': !item.soldout },
                        { 'pointer-events-none bg-transparent text-gray-6 border-2 border-gray-6': item.soldout },
                      )}
                    >
                      {
                    item.soldout
                      ? getDefaultText('buttonSoldoutLabel', primaryMessages, defaultMessages)
                      : getDefaultText('buttonBookLabel', primaryMessages, defaultMessages)
                    }
                    </LinkWithQuery>
                    {(item.lastplaces && !item.soldout) && (
                      <p className="
                        text-red-1 font-medium text-p4 w-full  mt-2.5
                        lg:text-p3 text-center lg:absolute lg:-bottom-6"
                      >
                        {getDefaultText('lastplaceLabel', primaryMessages, defaultMessages)}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default GridListsProductsCheckout;
