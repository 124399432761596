import HeroLeft from './variants/HeroLeft';
import HeroCardBackgroundImage from './variants/HeroCardBackgroundImage';
import HeroImageSectionText from './variants/HeroImageSectionText';
import HeroOnlyImage from './variants/HeroOnlyImage';
import { HeroVariations } from './Hero.types';
import HeroImageTitleButton from './variants/HeroImageTitleButton';
import HeroCarousel from './variants/HeroCarousel';
import HeroCtd from './variants/HeroCtd';
import HeroWithPills from './variants/HeroWithPills';

const heroTypeId = 'hero';

const Hero = ({ slice }) => {
  const clusterSlice = slice._cluster?.data?.slices?.find(
    ({ slice_type: sliceType }) => sliceType === heroTypeId,
  );

  switch (slice.variation) {
    case HeroVariations.carousel:
      return <HeroCarousel slice={slice} />;
    case HeroVariations.imageSectionText:
      return <HeroImageSectionText slice={slice} />;
    case HeroVariations.imageTitleButton:
      return <HeroImageTitleButton slice={slice} />;
    case HeroVariations.onlyImage:
      return <HeroOnlyImage slice={slice} />;
    case HeroVariations.cardBackgroundImage:
      return <HeroCardBackgroundImage slice={slice} />;
    case HeroVariations.leftProduct:
      if (clusterSlice?.primary) {
        clusterSlice._vertical = slice?._vertical;
        clusterSlice.primary.buttonLabel = null;
        clusterSlice.primary.buttonLink = null;
        clusterSlice.primary.fragmentIdentifier = null;
        clusterSlice.primary.buttonDownloadFile = null;
      }

      return <HeroLeft slice={clusterSlice || {}} />;
    case HeroVariations.left:
    case HeroVariations.leftWithBtnDownload:
    case HeroVariations.leftWithImageTitle:
      return <HeroLeft slice={slice} />;
    case HeroVariations.ctd:
      return <HeroCtd slice={slice} />;
    case HeroVariations.withPills:
      return <HeroWithPills slice={slice} />;
    default:
      return null;
  }
};

export default Hero;
