import { RichText } from 'prismic-reactjs';
import { nanoid } from 'nanoid';
import dynamic from 'next/dynamic';

const Icon = dynamic(() => import('../../../components/Icon'), {
  ssr: false,
});

const IncentivesThreeColumns = ({ slice }) => (
  <div className="bg-gray-1 text-white">
    <div className="dh-content">
      <h2 className="text-[2.125rem] text-center font-semibold leading-[3.375rem] mb-1">{slice.primary.title}</h2>
      <p className="text-base text-center font-semibold leading-7"><RichText render={slice.primary.description} /></p>
      <div className="max-w-2xl mx-auto mt-14 px-4 grid grid-cols-1 gap-y-10 gap-x-8 lg:max-w-none lg:grid-cols-3">
        {slice.items?.map(({ title, description, icon }) => (
          <div className="text-center lg:block lg:text-center" key={nanoid()}>
            <div className="lg:shrink-0">
              <div className="flow-root">
                <Icon
                  icon={icon}
                  className="w-12 h-14 mx-auto text-white"
                />
              </div>
            </div>
            <div className="mt-3 lg:mt-3 lg:ml-0">
              <h3 className="text-base leading-5 font-semibold mb-4">{title}</h3>
              <p className="mt-2 text-base leading-7 font-normal font-archivo">
                {RichText.asText(description)}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default IncentivesThreeColumns;
