import {
  FC, Fragment, useState,
} from 'react';
import dynamic from 'next/dynamic';
import { Link, RichText } from 'prismic-reactjs';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import LinkWithQuery from '../../../components/LinkWithQuery';
import { hasLink, hasRichText } from '../../../utils/prismic';
import { getVerticalByLink, prismicLinkResolver } from '../../../utils/common';
import { VideoCarouselWithModalProps } from '../Video.types';
import ImagePreview, { getPreviewProps } from './components/ImagePreview';

const ReactCarousel = dynamic(() => import('../../../components/ReactCarousel'), {
  ssr: false,
});

const itemsToShowSm = 1;
const itemsToShowLg = 3;
const breakPoints = [
  {
    width: 1,
    itemsToShow: itemsToShowSm,
    itemPadding: [0, 5, 0, 5],
    pagination: true,
  },
  {
    width: 955,
    itemsToShow: itemsToShowLg,
    itemPadding: [0, 16, 0, 16],
    pagination: true,
  },
];
const carouselOptions = {
  isRTL: false,
  pagination: true,
  breakPoints,
};

const VideoCarouselWithModal:FC<VideoCarouselWithModalProps> = ({ slice }) => {
  const [open, setOpen] = useState<any>(null);
  const items = slice.items?.filter((item) => hasLink(item.videoLink));
  const itemsLength = items?.length;
  carouselOptions.breakPoints[0].pagination = itemsLength > itemsToShowSm;
  carouselOptions.breakPoints[1].pagination = itemsLength > itemsToShowLg;

  return (
    <section className="bg-gray-100 dark:bg-gray-2">
      <div className="dh-content">
        {slice.primary?.title && (
          <div className="max-w-3xl mx-auto text-center mb-16">
            <h2 className="dh-title">{slice.primary.title}</h2>
            <div className="text-p2 text-white-1 mt-4">
              {hasRichText(slice.primary?.description) && <RichText render={slice.primary.description} />}
            </div>
          </div>
        )}
        <ReactCarousel options={carouselOptions} color={slice._vertical?.data?.color}>
          {items?.map((item) => {
            const videoProps = getPreviewProps(item.videoLink?.url);
            const { uid: verticalId, clusterId } = getVerticalByLink(
              item.videoButtonLink,
              slice._verticals,
              slice._clusters,
              slice._products,
            );
            return (
              <article key={videoProps.preview?.url}>
                <ImagePreview
                  image={videoProps.preview}
                  play={() => { setOpen({ item, videoProps }); }}
                />
                <h3 className="text-h8 text-white-1 py-2">
                  { item.videoTitle }
                </h3>
                <div className="text-p4 text-white-2">
                  {hasRichText(item.videoDescription) && <RichText render={item.videoDescription} />}
                </div>
                {hasLink(item.videoButtonLink) && (
                  <LinkWithQuery
                    href={Link.url(item.videoButtonLink, (doc) => prismicLinkResolver(doc, { verticalId, clusterId }))}
                    className="dh-button-sm dh-pill mt-4 h-9 self-end text-xs text-athensGray bg-black-2 border-white-1"
                  >
                    {item.videoButtonLabel}
                  </LinkWithQuery>
                )}
              </article>
            );
          })}
        </ReactCarousel>
      </div>
      <Transition.Root show={open !== null} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 flex z-50 justify-center items-center" onClose={() => setOpen(null)}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black/80" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="w-11/12 h-2/5 z-30 sm:w-10/12 sm:h-3/4">
              <button
                type="button"
                onClick={() => setOpen(null)}
                className="px-3 py-3 text-white-1 place-self-end z-50"
              >
                <XIcon className="h-5 w-5 text-white-1" aria-hidden="true" />
              </button>
              {open && (
                <iframe
                  className="w-full h-full rounded-2xl overflow-hidden"
                  src={open.videoProps.video.url}
                  title={open.item.videoTitle || 'YouTube video player'}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              )}
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>
    </section>
  );
};

export default VideoCarouselWithModal;
