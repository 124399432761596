import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { IAllowedCheckoutQueryParams } from 'types/checkout.type';
import { getAllowedQueryString } from '../utils/analytics';

export const useCheckoutQueryParams = (): IAllowedCheckoutQueryParams => {
  const [pathParams, setPathParams] = useState({});
  const [allowedParams, setAllowedParams] = useState({});
  const router = useRouter();

  useEffect(() => {
    setPathParams(router?.query);
  }, [router]);

  useEffect(() => {
    setAllowedParams(getAllowedQueryString(pathParams, true));
  }, [pathParams]);

  return allowedParams;
};
