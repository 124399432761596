import classNames from 'classnames';
import { nanoid } from 'nanoid';
import Image from 'next/image';
import {
  FC, useCallback, useEffect, useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, RichText } from 'prismic-reactjs';
import cloneDeep from 'lodash.clonedeep';
import dynamic from 'next/dynamic';
import { getColor } from '../../../utils/colors';
import { GridListsWithFilterProps } from '../GridLists.types';
import {
  DATE_LOCALES,
  getCurrentDateLocale,
  parseDate,
} from '../../../utils/dates';
import useLocale from '../../../hooks/useLocale';
import { getVerticalByLink, prismicLinkResolver } from '../../../utils/common';

const LinkWithQuery = dynamic(() => import('../../../components/LinkWithQuery'), {
  ssr: false,
});
const Filter = dynamic(() => import('../../../components/Filter'), {
  ssr: false,
});
const BlankState = dynamic(() => import('../../../components/BlankState'), {
  ssr: false,
});
const Icon = dynamic(() => import('../../../components/Icon'), {
  ssr: false,
});

const dateTimeFormat = (date: string, language: string, connector: string): string => {
  const format = `d'.'MM '${connector}' HH'h'`;
  const locale = getCurrentDateLocale(DATE_LOCALES, language);

  return parseDate(date, locale, format);
};

const getAvailableItems = (items) => items?.filter(({ dateTime, buttonLink }) => {
  const itemDate = new Date(dateTime).getTime();
  const now = new Date().getTime();
  return now <= itemDate && buttonLink?.url;
});

const sortBy = (items, key: string) => {
  const compare = (a, b) => {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }

    return 0;
  };

  return items?.sort(compare);
};

const GridListsWithFilter: FC<GridListsWithFilterProps> = ({
  slice,
}) => {
  const availableItems = getAvailableItems(slice?.items);
  const intl = useIntl();
  const dateTimeConnector = intl.formatMessage({ defaultMessage: 'a las' });
  const [open, setOpen] = useState('');
  const [items, setItems] = useState(cloneDeep(availableItems) ?? []);
  const [filter, setFilter] = useState({
    vertical: '',
    sort: 'asc',
  });
  const locale = useLocale();
  const verticals = slice._verticals ?? [];
  const filters = [
    {
      id: 'vertical',
      name: 'Categoría',
      options: [
        { value: '', label: 'Todas las categorías' },
        ...verticals.map(({ data, uid }) => ({ value: uid, label: data.name })).filter(({ label }) => !!label),
      ],
    },
    {
      id: 'sort',
      name: 'Sort',
      options: [
        { label: 'Mas próximo', value: 'asc' },
        { label: 'Mas lejano', value: 'desc' },
      ],
    },
  ];

  const handleOpen = (sectionId) => setOpen(
    (prev) => (prev === sectionId || sectionId === '' ? '' : sectionId),
  );

  const handleFilterChange = useCallback((sectionId, optionValue) => {
    setOpen('');
    setFilter(
      (prev) => ({ ...prev, [sectionId]: optionValue }),
    );
  }, [setFilter, setOpen]);

  useEffect(() => {
    let sortedItems = sortBy(cloneDeep(availableItems), 'dateTime');
    if (filter.vertical) {
      sortedItems = sortedItems.filter(({ verticalPage }) => verticalPage?.uid === filter.vertical);
    }
    if (filter.sort === 'desc') {
      sortedItems = sortedItems.reverse();
    }
    setItems(sortedItems);
  }, [filter, setItems]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <section className="bg-gray-2">
      <div className="dh-content">
        <Filter
          filters={filters}
          handleFilterChange={handleFilterChange}
          handleOpen={handleOpen}
          open={open}
          filter={filter}
          title={(
            <>
              <span className="text-2xl leading-9 font-semibold">
                <FormattedMessage defaultMessage="Lista" />
              </span>
              <span className="font-archivo text-base leading-7 ml-2">
                (
                { items.length }
                {' '}
                <FormattedMessage defaultMessage="eventos" />
                )
              </span>
            </>
          )}
        />
        {!items?.length ? (
          <BlankState className="text-white max-w-3.5xl">
            <RichText render={slice.primary?.blankStateMessage} />
          </BlankState>
        ) : (
          <ul className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
            {items.map(
              ({
                verticalPage,
                title,
                description,
                logo,
                dateTime,
                buttonLink,
                buttonLabel,
              }) => {
                const vertical = getVerticalByLink(verticalPage, slice._verticals);
                const { bg: bgColor, bgHover: bgColorHover } = getColor(vertical?.color);
                const formatedDateTime = dateTime && dateTimeFormat(dateTime, locale, dateTimeConnector);

                return (
                  <li
                    key={nanoid()}
                    className="space-y-5 rounded-2xl bg-white-2 flex flex-col"
                  >
                    <div
                      className={classNames(
                        'flex flex-row justify-between items-cente rounded-t-2xl py-2 px-4',
                        bgColor,
                      )}
                    >
                      <div className="flex flex-row justify-start items-center">
                        <Icon
                          icon="arrow-right-up-circle"
                          className="w-4 flex-none text-athensGray mr-2"
                        />
                        <h3 className="text-sm font-archivo font-normal text-athensGray text-left">
                          {vertical?.name}
                        </h3>
                      </div>
                      {logo?.url && (
                      <div className="h-6 w-24 relative">
                        <Image
                          src={logo?.url}
                          alt={logo?.alt}
                          height={logo?.dimensions?.height}
                          width={logo?.dimensions?.width}
                          layout="responsive"
                          objectFit="contain"
                          className="h-6 w-24"
                        />
                      </div>
                      )}
                    </div>
                    <div className="px-6 pb-6 flex flex-col m-0 justify-between h-full">
                      <h2 className="flex items-center font-bold text-lg leading-6 min-h-[3.125rem]">
                        {title}
                      </h2>
                      <div className="pt-4 pb-7 flex flex-row lg:flex-col font-archivo">
                        <div className="pr-3 mr-3 border-r border-white-4 lg:pr-0 lg:mr-0 lg:border-0">
                          {`${formatedDateTime} `}
                        </div>
                        <div>{description}</div>
                      </div>
                      <div className="flex justify-center">
                        <LinkWithQuery
                          title={buttonLink.title}
                          href={Link.url(buttonLink, prismicLinkResolver)}
                          target={buttonLink?.target}
                          className={classNames(
                            'dh-button',
                            'w-auto inline-block px-5 py-1 text-center rounded-md',
                            'font-semibold text-white text-sm leading-6',
                            bgColor,
                            bgColorHover,
                          )}
                        >
                          {buttonLabel}
                        </LinkWithQuery>
                      </div>
                    </div>
                  </li>
                );
              },
            )}
          </ul>
        )}
      </div>
    </section>
  );
};

export default GridListsWithFilter;
