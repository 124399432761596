import classNames from 'classnames';
import { Link } from 'prismic-reactjs';
import { nanoid } from 'nanoid';
import Image from 'next/image';
import { FunctionComponent } from 'react';
import dynamic from 'next/dynamic';
import { prismicLinkResolver } from '../../../utils/common';
import { TeamWithLargeImagesProps } from '../Team.types';

const LinkWithQuery = dynamic(() => import('../../../components/LinkWithQuery'), {
  ssr: false,
});
const Icon = dynamic(() => import('../../../components/Icon'), {
  ssr: false,
});

const isOdd = (num) => num % 2;

const TeamWithLargeImages: FunctionComponent<TeamWithLargeImagesProps> = ({ slice }) => (
  <div className="bg-white">
    <div className="dh-content">
      <div className="space-y-12">
        <ul
          className="space-y-14 lg:grid lg:gap-y-28 lg:space-y-0"
        >
          {slice.items?.map((person, i) => (
            <li key={nanoid()}>
              <div
                className={classNames('space-y-5 lg:space-y-0 lg:flex', {
                  'lg:flex-row-reverse': isOdd(i),
                })}
              >
                {person.image?.url && (
                  <div className="aspect-w-3 aspect-h-2 lg:w-1/2 rounded-2xl relative overflow-hidden">
                    <Image
                      src={person.image.url}
                      alt={person.image.alt}
                      width={person.image.dimensions.width}
                      height={person.image.dimensions.height}
                      layout="responsive"
                      objectFit="cover"
                    />
                  </div>
                )}
                <div
                  className={classNames(
                    'flex items-center space-y-2 lg:w-1/2',
                    isOdd(i) ? 'lg:pr-28' : 'lg:pl-28',
                  )}
                >
                  <div className="text-lg leading-6 font-medium space-y-5">
                    {person.icon && (
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-primary text-white">
                      <Icon icon={person.icon} className="h-6 w-6" />
                    </div>
                    )}
                    <h2 className="text-2xl font-bold dark:text-black-2">
                      {person.title}
                    </h2>
                    <p className="dh-description dark:text-gray-2">
                      {person.description}
                    </p>
                    {person.link?.url && (
                    <LinkWithQuery
                      href={Link.url(person.link, (doc) => prismicLinkResolver(doc))}
                      className="dh-button-sm"
                    >
                      {person.linkLabel}
                    </LinkWithQuery>
                    )}
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
);

export default TeamWithLargeImages;
