export enum CarouselVariations {
  withoutControls = 'carouselWithoutControls',
  withDate = 'carouselWithDate',
  withDateLight = 'carouselWithDateLight',
  withImage = 'carouselWithImage',
  withHeaderImage = 'carouselWithHeaderImage',
  withPrice = 'carouselWithPrice',
  withFlags = 'carouselWithFlags',
  withPills = 'carouselWithPills',
  withClusters = 'carouselWithClusters',
  withTabs = 'carouselWithTabs',
}
