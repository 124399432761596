import { Link, RichTextBlock } from 'prismic-reactjs';
import { Image, Slice } from 'types/prismic.type';

export enum TeamVariations {
  imageParagraph = 'teamImageParagraph',
  withLargeImages = 'teamWithLargeImages',
  withOneImage = 'teamWithOneImage',
  smallImages = 'teamSmallImages'
}

export type TeamWithLargeImagesProps = Slice & {
  slice: {
    items?: {
      image?: Image;
      icon?: string;
      title?: string;
      description?: RichTextBlock[];
      link?: Link;
      linkLabel?: string;
    }[];
  }
}

export type TeamWithOneImageProps = Slice & {
  slice: {
    primary: {
      title: string;
      image: Image;
    }
  }
}
