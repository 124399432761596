import { DividerVariations } from './Divider.types';
import Divider360 from './variations/Divider360';
import Divider360DownArrow from './variations/Divider360DownArrow';
import Divider360TripleBackflip from './variations/Divider360TripleBackflip';
import DividerTripleArrowDown from './variations/DividerTripleArrowDown';

const Divider = ({ slice }) => {
  switch (slice.variation) {
    case DividerVariations.d360:
      return <Divider360 slice={slice} />;
    case DividerVariations.d360TripleBackflip:
      return <Divider360TripleBackflip slice={slice} />;
    case DividerVariations.d360DownArrow:
      return <Divider360DownArrow slice={slice} />;
    case DividerVariations.tripleArrowDown:
      return <DividerTripleArrowDown slice={slice} />;
    default:
      return null;
  }
};

export default Divider;
