import { RichText } from 'prismic-reactjs';
import classNames from 'classnames';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import { getColor } from '../../../utils/colors';

const HighlightText = dynamic(() => import('../../../components/HighlightText'), {
  ssr: false,
});

const ContentWithLogoCloud = ({ slice }) => {
  const verticals = slice._verticals ?? [slice._vertical];
  const color = getColor(verticals[0]?.data.color);

  return (
    <section
      className={classNames(
        'bg-white text-black dark:bg-gray-1 dark:text-white', // mobile
      )}
    >
      <div
        className={classNames(
          'dh-content grid grid-cols-1',
          'lg:gap-x-10 lg:grid-cols-2 text-left',
        )}
      >
        {slice.primary?.title && (
          <header
            className={classNames(
              'text-center lg:text-left lg:flex lg:items-center',
            )}
          >
            <div className="lg:pl-10 lg:max-w-sm">
              <h2 className="text-2xl lg:text-3.5xl font-semibold not-italic text-white-2">
                <HighlightText
                  text={slice.primary.title}
                  highlight={slice.primary?.highlightedText}
                  className={classNames(color.textContrast, color.bg)}
                />
              </h2>
            </div>
          </header>
        )}

        <div
          className={classNames(
            'text-lg font-normal', // mobile
            'lg:m-0 lg:p-0 lg:px-8', // desktop
          )}
        >
          {slice.primary?.description && (
            <div className="dh-description my-richtext-6 lg:max-w-md dark:text-white-2 text-sm leading-6">
              <RichText render={slice.primary.description} />
            </div>
          )}
          {slice.primary?.logoCloudText && (
            <div className="lg:max-w-md dark:bg-gray-4 rounded-xl p-4 text-sm">
              <div className="dh-description my-richtext-6 dark:text-white-2 -mt-6 -mb-4 leading-6">
                <RichText render={slice.primary.logoCloudText} />
              </div>

              <div className="grid lg:grid-cols-3 gap-4 px-4">
                <div className="grid gap-3 lg:col-span-2">
                  <span className="text-xxs">{slice.primary.logoCloudTitle1}</span>
                  <div className="grid grid-cols-2 gap-4 items-center lg:justify-items-center">
                    {
                      slice.primary.logo1.url
                      && (
                      <div className="w-36 max-w-full">
                        <Image
                          src={slice.primary.logo1.url}
                          alt={slice.primary.logo1.alt}
                          width={slice.primary.logo1.dimensions.width}
                          height={slice.primary.logo1.dimensions.height}
                          layout="responsive"
                          objectFit="cover"
                        />
                      </div>
                      )
                    }
                    {
                      slice.primary.logo2.url
                      && (
                      <div className="w-36 max-w-full">
                        <Image
                          src={slice.primary.logo2.url}
                          alt={slice.primary.logo2.alt}
                          width={slice.primary.logo2.dimensions.width}
                          height={slice.primary.logo2.dimensions.height}
                          layout="responsive"
                          objectFit="cover"
                        />
                      </div>
                      )
                    }
                  </div>
                </div>
                <div className="grid gap-3">
                  <span className="text-xxs">{slice.primary.logoCloudTitle2}</span>
                  <div className="grid items-center lg:justify-items-center">
                    {
                      slice.primary.logo3.url
                      && (
                      <div className="w-36 lg:w-full max-w-full">
                        <Image
                          src={slice.primary.logo3.url}
                          alt={slice.primary.logo3.alt}
                          width={slice.primary.logo3.dimensions.width}
                          height={slice.primary.logo3.dimensions.height}
                          layout="responsive"
                          objectFit="cover"
                        />
                      </div>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          )}
          {slice.primary.bottomText && (
            <div className="dh-description my-richtext-6 dark:text-white-4 text-xs leading-6">
              <RichText render={slice.primary.bottomText} />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default ContentWithLogoCloud;
