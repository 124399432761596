import {
  Locale, parseISO, isAfter,
  format as formatFns,
} from 'date-fns';
import { formatWithOptions } from 'date-fns/fp';
import { es, enUS, ptBR } from 'date-fns/locale';
import { ISchedule } from '../graphql/model/interfaces';

type DateLocales = {
  [key: string]: Locale;
};

export const DATE_LOCALES: DateLocales = {
  es,
  ar: es,
  mx: es,
  co: es,
  cl: es,
  en: enUS,
  pt: ptBR,
  br: ptBR,
};

export const getCurrentDateLocale = (
  dateLocales: DateLocales,
  lang: string,
): Locale => dateLocales[
  Object.keys(dateLocales).find((locale) => new RegExp(`^${locale}`).test(lang)) ?? 'es'
];

export const parseDate = (
  date: string,
  locale: Locale = es,
  format = 'dd/MM/yyyy',
): string => {
  if (!date) return '';

  return formatWithOptions({ locale }, format)(parseISO(date));
};

export const isMorning = (date) => {
  const dateGMT = formatFns(new Date(date), 'yyyy-MM-dd HH:mm:ss').replace(' ', 'T');
  const regex = /([0-2][0-9]:[0-5][0-9]:[0-5][0-9])/gm;
  const noonDate = dateGMT.replace(regex, '12:00:00');
  const noonDummyDate = formatFns(new Date(noonDate), 'yyyy-MM-dd HH:mm:ss');

  return isAfter(new Date(noonDummyDate), new Date(dateGMT));
};

const formatHours = (time: string, endTime: string) => {
  const dummyDate = '3032-12-15';
  const startTimeSlot = new Date(`${dummyDate}T${time}-00:00`);
  const endTimeSlot = new Date(`${dummyDate}T${endTime}-00:00`);

  const formattedHour = time && endTime
    ? `${formatFns(startTimeSlot, 'k:mm')}hs a ${formatFns(endTimeSlot, 'k:mm')}hs`
    : '';

  return formattedHour;
};

export const formatSchedule = (duplicatedSchedules, formatDay) : string[] => {
  const formatted: string[] = [];

  const days = duplicatedSchedules.map((d) => formatDay(d.day)).join(', ');
  const hours = formatHours(duplicatedSchedules[0].startTime, duplicatedSchedules[0].endTime);

  const daysFormatted = days.replace(/,(?=[^,]*$)/, ' y');
  formatted.push(`${daysFormatted} - ${hours}`);

  return formatted;
};

export const groupBySchedules = (schedules, formatDay) => {
  const matrixSchedule: ISchedule[][] = [];

  schedules?.forEach((element) => {
    const index = matrixSchedule.findIndex((item) => item.some(
      (schedule: ISchedule) => schedule.startTime === element.startTime
      && schedule.endTime === element.endTime,
    ));

    if (index >= 0) {
      matrixSchedule[index].push(element);
    } else {
      matrixSchedule.push([element]);
    }
  });

  return matrixSchedule.map((e) => formatSchedule(e, formatDay));
};
