import classNames from 'classnames';
import { nanoid } from 'nanoid';
import Image from 'next/image';
import { getColor } from '../../../utils/colors';
import { scrollTo } from '../../../utils/common';

const HeroCtd = ({ slice }) => {
  const color = getColor(slice.primary.pillColor, 600);
  return (
    <section className="relative overflow-hidden">
      <div className="w-full top-0 left-0 ">
        {slice.primary?.brackgroundImage?.url && (
        <div className="w-full">
          <Image
            className="object-cover w-full"
            src={slice.primary.brackgroundImage.url}
            alt={slice.primary.brackgroundImage.alt}
            layout="fill"
            objectFit="cover"
            objectPosition="center center"
            priority
          />
        </div>
        )}
        <div className="w-full h-screen absolute top-0 left-0 z-0 bg-black-1/85" />
      </div>
      <div className="relative flex flex-col items-center lg:ml-60 lg:my-14 lg:items-start
      2xl:ml-[19.313rem] 2xl:my-[6.563rem]
      3xl:ml-[22.688rem] 3xl:my-28"
      >
        <div className="relative flex flex-col px-4 pt-14 items-center lg:p-0 lg:items-start w-full
        lg:m-auto lg:max-w-7xl 2xl:max-w-8xl 3xl:max-w-8x5l"
        >
          <div className={classNames(
            color.bg,
            color.textContrast,
            'rounded-4xl flex items-center font-medium text-center',
            'text-sm h-8 justify-center leading-6 px-4 py-[0.375rem] inline-block',
            'lg:h-9 lg:text-lg',
            '3xl:px-5 3xl:py-2 3xl:text-2xl 3xl:h-[2.938rem]',
          )}
          >
            {slice.primary.pillText}
          </div>
          <div className="
            mt-4 mb-8 w-72 h-auto
            lg:mb-4 lg:mt-6 lg:w-[31.625rem] lg:ml-[-5.813rem]
            2xl:w-[40.938rem] 2xl:ml-[-7.438rem]
            3xl:w-[51.938rem] 3xl:ml-[-9.313rem]"
          >
            {slice.primary?.title.url && (
              <Image
                width={slice.primary.title.dimensions.width}
                height={slice.primary.title.dimensions.height}
                src={slice.primary.title.url}
                alt={slice.primary.title.alt}
                layout="responsive"
                priority
              />
            )}
          </div>
          <div className="flex flex-col items-center lg:flex-row">
            {slice.items?.map((item) => (
              <div
                key={nanoid()}
                className="text-white-1 rounded-4xl border-white-1
                text-xs border mb-[0.375rem] px-[0.875rem] py-[0.313rem] text-center inline-block
                lg:mb-0 lg:mr-2 lg:text-sm lg:px-4 lg:py-[0.375rem]"
              >
                {item.repeatablePillText}
              </div>
            ))}
          </div>
          <div className="mt-14 w-full lg:w-auto flex flex-col lg:flex-row items-center">
            <button
              type="button"
              onClick={() => scrollTo({ id: slice.primary.button1ScrollTo })}
              className={classNames(
                'font-bold bg-white-1 gap-3 text-black-1 rounded-[0.625rem] inline-block',
                'flex-none leading-5 py-3 text-base w-full text-center mb-4',
                'lg:mb-0 lg:mr-4 lg:w-auto lg:px-8',
                '2xl:px-[3.594rem] 2xl:py-3w',
                '3xl:text-lg 3xl:px-[4.781rem] 3xl:py-4',
              )}
            >
              {slice.primary.buttonLabel}
            </button>
            <button
              type="button"
              onClick={() => scrollTo({ id: slice.primary.button2ScrollTo })}
              className={classNames(
                'font-bold bg-transparent border-white-1 border-2 text-white-1 rounded-[0.625rem] inline-block',
                'flex-none leading-5 py-3 text-base w-full text-center mb-8 gap-3',
                ' lg:m-0 lg:w-auto lg:px-8',
                '2xl:px-[3.594rem] 2xl:py-3w',
                '3xl:text-lg 3xl:px-[4.781rem] 3xl:py-4',
              )}
            >
              {slice.primary.buttonLabel2}
            </button>
          </div>
          <div className="bg-black-1 justify-evenly h-10 w-full items-center hidden lg:flex
        lg:h-14 lg:mt-8 lg:rounded-[2.5rem] lg:py-4 lg:px-6 lg:w-auto lg:gap-4 "
          >
            <p className="text-gray-8 text-xs">{slice.primary.textFrom}</p>
            <div className="w-14 h-[1.125rem] lg:w-20 lg:h-6">
              {slice.primary?.logo1.url && (
              <Image
                width={slice.primary.logo1.dimensions.width}
                height={slice.primary.logo1.dimensions.height}
                src={slice.primary.logo1.url}
                alt={slice.primary.logo1.alt}
                priority
              />
              )}
            </div>
            <div className="w-14 h-[1.125rem] lg:w-20 lg:h-6">
              {slice.primary?.logo2.url && (
              <Image
                width={slice.primary.logo2.dimensions.width}
                height={slice.primary.logo2.dimensions.height}
                src={slice.primary.logo2.url}
                alt={slice.primary.logo2.alt}
                priority
              />
              )}
            </div>
            <p className="text-gray-8 text-xs">{slice.primary.textBy}</p>
            <div className="w-20 h-[1.125rem] lg:w-28 lg:h-6">
              {slice.primary?.logo3.url && (
              <Image
                width={slice.primary.logo3.dimensions.width}
                height={slice.primary.logo3.dimensions.height}
                src={slice.primary.logo3.url}
                alt={slice.primary.logo3.alt}
                priority
              />
              )}
            </div>
          </div>
        </div>
        <div className="flex bg-black-1 justify-evenly h-10 w-full items-center lg:hidden
        lg:h-14 lg:mt-8 lg:rounded-[2.5rem] lg:py-4 lg:px-6 lg:w-auto lg:gap-4 4.5xl:ml-28 5xl:ml-36"
        >
          <p className="text-gray-8 text-xs">{slice.primary.textFrom}</p>
          <div className="w-14 h-[1.125rem] lg:w-20 lg:h-6">
            {slice.primary?.logo1.url && (
              <Image
                width={slice.primary.logo1.dimensions.width}
                height={slice.primary.logo1.dimensions.height}
                src={slice.primary.logo1.url}
                alt={slice.primary.logo1.alt}
                priority
              />
            )}
          </div>
          <div className="w-14 h-[1.125rem] lg:w-20 lg:h-6">
            {slice.primary?.logo2.url && (
              <Image
                width={slice.primary.logo2.dimensions.width}
                height={slice.primary.logo2.dimensions.height}
                src={slice.primary.logo2.url}
                alt={slice.primary.logo2.alt}
                priority
              />
            )}
          </div>
          <p className="text-gray-8 text-xs">{slice.primary.textBy}</p>
          <div className="w-20 h-[1.125rem] lg:w-28 lg:h-6">
            {slice.primary?.logo3.url && (
              <Image
                width={slice.primary.logo3.dimensions.width}
                height={slice.primary.logo3.dimensions.height}
                src={slice.primary.logo3.url}
                alt={slice.primary.logo3.alt}
                priority
              />
            )}
          </div>
        </div>
        <div />
      </div>
    </section>
  );
};
export default HeroCtd;
