import { useRef } from 'react';
import classNames from 'classnames';
import { Link } from 'prismic-reactjs';
import Image from 'next/image';
import { nanoid } from 'nanoid';
import dynamic from 'next/dynamic';
import { prismicLinkResolver, scrollTo } from '../../../utils/common';
import { getColor } from '../../../utils/colors';
import { ReactCarouselProps } from '../../../components/ReactCarousel';

const LinkWithQuery = dynamic(() => import('../../../components/LinkWithQuery'), {
  ssr: false,
});
const Icon = dynamic(() => import('../../../components/Icon'), {
  ssr: false,
});
const HighlightText = dynamic(() => import('../../../components/HighlightText'), {
  ssr: false,
});
const ReactCarousel = dynamic(() => import('../../../components/ReactCarousel'), {
  ssr: false,
});

const carouselOptions: ReactCarouselProps['options'] = {
  isRTL: false,
  pagination: false,
  itemPadding: [0, 0, 0, 0],
  itemsToShow: 1,
  showArrows: false,
  enableAutoPlay: true,
  infiniteLoop: true,
  breakPoints: [],
};

const HeroCarousel = ({ slice }) => {
  const vertical = slice?._vertical?.data;
  const color = getColor(vertical?.color);
  const color200 = getColor(vertical?.color, 200);
  const carouselRef = useRef(null);
  carouselOptions.ref = carouselRef;
  carouselOptions.totalPages = slice.items.length;

  const handleClick = (id: string) => (event: Event): void => {
    event.preventDefault();
    scrollTo({ id });
  };

  return slice.primary ? (
    <section className="relative overflow-hidden text-gray-1 lg:pl-16">
      <div className={classNames('absolute left-0 w-1 lg:w-4 h-full rounded-br', color200.bg)} />
      <div className="flex flex-col-reverse lg:flex-row gap-14 lg:gap-8">
        <div className="px-4 w-full lg:px-0 lg:w-5/12 3xl:w-4/12 lg:flex lg:items-center">
          <div className="flex flex-col gap-8 lg:gap-10">
            {slice.primary?.title && (
              <h1 className="text-2xl lg:text-4.5xl 2xl:text-5xl 3xl:text-6xl">
                <HighlightText
                  richtext={slice.primary.title}
                  highlight={slice.primary.highlightedText}
                  isUnderline
                />
              </h1>
            )}

            {!!(slice.primary.buttonLink || slice.primary.buttonFragmentLink) && (
              <div>
                <LinkWithQuery
                  onClick={slice.primary.buttonFragmentLink
                    ? handleClick(slice.primary.buttonFragmentLink) : () => null}
                  href={slice.primary.buttonLink ? Link.url(slice.primary.buttonLink, prismicLinkResolver) : null}
                  className={classNames(
                    'dh-btn',
                    color.textContrast,
                    color.bg,
                    color.bgHover,
                  )}
                >
                  <span className="flex flex-row items-center justify-center gap-2">
                    {slice.primary.buttonLabel}
                    {
                      slice.primary.buttonIcon
                      && <Icon icon={slice.primary.buttonIcon} className="hidden lg:block w-6 h-6" />
                    }
                  </span>
                </LinkWithQuery>
              </div>
            )}
          </div>
        </div>

        {!!slice.items?.length && (
        <div className="pl-4 w-full lg:w-7/12 3xl:w-8/12 lg:pl-0 overflow-hidden dh-rounded-bl">
          <ReactCarousel options={carouselOptions} color={slice._vertical?.data?.color}>
            {slice.items.map(({ image }) => (
              <div
                className="h-69 lg:h-142 2xl:h-175 3xl:h-200 w-full relative"
                key={nanoid()}
              >
                <Image
                  className="object-cover w-full h-full"
                  src={image.url}
                  alt={image.alt}
                  layout="fill"
                  objectFit="cover"
                  priority
                />
              </div>
            ))}
          </ReactCarousel>
        </div>
        )}
      </div>
    </section>
  ) : null;
};

export default HeroCarousel;
