import { CountryCodes } from '@digitalhouse-tech/ts-lib-checkout-url-mapper/.dist/util/countryMap';
import { Document } from '@prismicio/client/types/documents';
import { Link, RichTextBlock } from 'prismic-reactjs';
import { IOffer, IProduct, IProductGroup } from '../../graphql/model/interfaces';
import { Image, Slice } from '../../types/prismic.type';

export enum TabsVariations {
  withAccordionOnMobile = 'tabsWithAccordionOnMobile',
  productCardCheckout = 'tabsProductCardCheckout',
}

export type TabsWithAccordionOnMobileProps = Slice & {
  slice: {
    primary?: {
      title: string;
      tabsTitle: string;
    },
    items?: {
      title: string;
      description: RichTextBlock[];
    }[];
    _vertical?: Document
  }
}

export interface ICheckout {
  wareId: string;
  groupId: string;
  defaultOffer: IOffer;
  country: CountryCodes;
  sandbox: boolean;
}

export type TabsProductCardCheckoutProps = Slice & {
  slice: {
    primary: {
      isAsync?: boolean;
      showCheckoutButton?: boolean;
      checkoutButtonLabel?: string;
      checkoutButtonLastplaceLabel?: string;
      checkoutButtonSoldoutLabel?: string;
      showTabs?: boolean;
      primaryColor?: string;
      secondaryColor?: string;
      title?: string;
      description?: RichTextBlock[];
      logo?: Image;
      cardTitle?: string;
      cardDescription?: string;
      bookingLabel?: string;
      bookingDescription?: string;
      paymentMethodsLabel?: string;
      paymentMethodsTooltip?: RichTextBlock[];
      installmentTag?: string;
      installmentLabel?: string;
      installmentCount?: string;
      installmentDescription?: string;
      installmentPriceSuffix?: string;
      totalPriceLabel?: string;
      benefitsLabel?: string;
      benefitsLink?: Link;
    };
    items?: {
      paymentMethodImage: Image;
    }[];
    _checkout: ICheckout & IProduct & {
      name: string;
      allProducts?: IProductGroup[];
      products?: IProductGroup[];
      defaultOffer?: IOffer & {
        currency: {
          symbol: string;
          code: CountryCodes;
        }
      };
    };
  }
}
