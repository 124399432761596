import { RichText } from 'prismic-reactjs';
import classnames from 'classnames';
import { nanoid } from 'nanoid';
import Image from 'next/image';

const FeatureRightIcons = ({ slice }) => (
  <div className="overflow-hidden">
    <div className="dh-content relative">
      <svg
        className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
        width={404}
        height={784}
        fill="none"
        viewBox="0 0 404 784"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
            x={0}
            y={0}
            width={20}
            height={20}
            patternUnits="userSpaceOnUse"
          >
            <rect
              x={0}
              y={0}
              width={4}
              height={4}
              className="text-gray-200"
              fill="currentColor"
            />
          </pattern>
        </defs>
        <rect
          width={404}
          height={784}
          fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
        />
      </svg>

      <div className="relative">
        <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div className="lg:col-start-2">
            <h3 className="dh-title dark:text-gray-900">
              {RichText.asText(slice.primary.title)}
            </h3>
            <p className="dh-description dark:text-gray-500">
              {RichText.asText(slice.primary.description)}
            </p>

            <dl className="mt-10 space-y-10">
              {slice.items?.map((item) => (
                <div key={nanoid()} className="relative">
                  <dt>
                    {item.icon.url && (
                    <div className={classnames(
                      'absolute flex items-center justify-center h-12 w-12 rounded-md',
                      'bg-primary-600 text-white',
                    )}
                    >
                      <div className="w-6 h-6">
                        <Image
                          src={item.icon.url}
                          alt={item.icon.alt}
                          width={item.icon.dimensions.width}
                          height={item.icon.dimensions.height}
                          layout="intrinsic"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                    )}
                    <p
                      className={classnames(
                        item.icon.url ? 'ml-16' : '',
                        'text-lg leading-6 font-medium text-gray-900',
                      )}
                    >
                      {item.name}
                    </p>
                  </dt>
                  <dd
                    className={classnames(
                      item.icon.url ? 'ml-16' : '',
                      'mt-2 text-base text-gray-500',
                    )}
                  >
                    {RichText.asText(item.description)}
                  </dd>
                </div>
              ))}
            </dl>
          </div>

          <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
            <svg
              className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
              width={784}
              height={404}
              fill="none"
              viewBox="0 0 784 404"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={784}
                height={404}
                fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)"
              />
            </svg>
            <div className="text-center">
              <Image
                className="rounded-lg md:rounded-2xl"
                src={slice.primary.image.url}
                alt={slice.primary.image.alt}
                width={slice.primary.image.dimensions.width}
                height={slice.primary.image.dimensions.height}
                layout="intrinsic"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default FeatureRightIcons;
