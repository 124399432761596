import classNames from 'classnames';
import Image from 'next/image';
import dynamic from 'next/dynamic';

const Icon = dynamic(() => import('../../../components/Icon'), {
  ssr: false,
});

const HeroImageSectionText = ({ slice }) => (
  <section className="relative overflow-hidden h-screen md:h-101">
    <div className="w-full absolute top-0 left-0 max-h-161">
      {slice.primary?.image?.url && (
        <div className="w-full h-screen absolute md:h-101">
          <Image
            className="object-cover h-screen w-full"
            src={slice.primary.image.url}
            alt={slice.primary.image.alt}
            layout="fill"
            objectFit="cover"
            priority
          />
        </div>
      )}
      <div className="w-full absolute top-0 left-0 z-0 bg-black-2/80 h-screen md:h-101" />
    </div>
    <div className="dh-content pt-0 relative z-0 text-athensGray text-center">
      {slice.primary.sectionName && (
      <div
        className={classNames(
          'text-white-3 font-semibold text-2xl leading-9 pt-40 px-4',
          'md:text-4xl md:pt-20 md:leading-15',
        )}
      >
        <Icon
          icon="arrow-right-down-solid"
          className="inline mr-3 lg:mr-2 w-6 lg:w-8 -rotate-45 opacity-60 text-green"
        />
        {slice.primary.sectionName}
      </div>
      )}
      {slice.primary.title && (
      <h1
        className={classNames(
          'text-4xl font-semibold leading-15 pt-4',
          'md:text-5xl md:font-bold',
        )}
      >
        {slice.primary.title}
      </h1>
      )}
    </div>

    {slice.primary.description && (
      <div className="z-0 absolute w-full bottom-0 left-0 bg-gray-1/60 p-8">
        <p
          className={classNames(
            'dh-content p-0 text-sm text-athensGray text-center leading-6 font-semibold font-archivo relative',
            'md:text-xl md:font-normal md:leading-9',
          )}
        >
          {slice.primary.description}
        </p>
      </div>
    )}
  </section>
);
export default HeroImageSectionText;
