import { RichText } from 'prismic-reactjs';

const StatsBackgroundColor = ({ slice }) => (
  <div className="bg-primary-800">
    <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8 lg:py-20">
      <div className="max-w-4xl mx-auto text-center">
        <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
          {RichText.asText(slice.primary.title)}
        </h2>
        <p className="mt-3 text-xl text-primary-200 sm:mt-4">
          {RichText.asText(slice.primary.description)}
        </p>
      </div>
      <dl className="mt-10 text-center sm:max-w-3xl sm:mx-auto sm:grid sm:grid-cols-3 sm:gap-8">
        <div className="flex flex-col">
          <dt className="order-2 mt-2 text-lg leading-6 font-medium text-primary-200">
            {slice.primary.metric1Label}
          </dt>
          <dd className="order-1 text-5xl font-extrabold text-white">
            {slice.primary.metric1Number}
          </dd>
        </div>
        <div className="flex flex-col mt-10 sm:mt-0">
          <dt className="order-2 mt-2 text-lg leading-6 font-medium text-primary-200">
            {slice.primary.metric2Label}
          </dt>
          <dd className="order-1 text-5xl font-extrabold text-white">
            {slice.primary.metric2Number}
          </dd>
        </div>
        <div className="flex flex-col mt-10 sm:mt-0">
          <dt className="order-2 mt-2 text-lg leading-6 font-medium text-primary-200">
            {slice.primary.metric3Label}
          </dt>
          <dd className="order-1 text-5xl font-extrabold text-white">
            {slice.primary.metric3Number}
          </dd>
        </div>
      </dl>
    </div>
  </div>
);

export default StatsBackgroundColor;
