import BoxesSquare from './variants/boxesSquare';
import BoxesRectangle from './variants/boxesRectangle';
import BoxesRectangleClusters from './variants/boxesRectangleClusters';
import BoxesColorCards from './variants/BoxesColorCards';
import BoxesKPI from './variants/BoxesKPI';
import { BoxesVariations } from './Boxes.types';

const Boxes = ({ slice }) => {
  switch (slice.variation) {
    case BoxesVariations.rectangle:
      return <BoxesRectangle slice={slice} />;
    case BoxesVariations.rectangleClusters:
      return <BoxesRectangleClusters slice={slice} />;
    case BoxesVariations.square:
      return <BoxesSquare slice={slice} />;
    case BoxesVariations.colorCards:
      return <BoxesColorCards slice={slice} />;
    case BoxesVariations.kpi:
      return <BoxesKPI slice={slice} />;
    default:
      return null;
  }
};

export default Boxes;
