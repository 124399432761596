import { FunctionComponent, useEffect, useState } from 'react';
import { VideoSimpleYouTubeProps } from '../Video.types';

const regex = /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*?[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/gi;

const getYouTubeKey = (url = ''): string => {
  const [, key] = regex.exec(url) || [];

  return key;
};

const VideoSimpleYouTube: FunctionComponent<VideoSimpleYouTubeProps> = ({ slice }) => {
  const [videoKey, setVideoKey] = useState<string>();

  useEffect(() => {
    setVideoKey(getYouTubeKey(slice?.primary?.link?.url));
  }, [slice]);

  return videoKey ? (
    <section className="bg-gray-1">
      <div className="dh-content justify-center text-center">
        {slice.primary?.title && (
          <header>
            <h2 className="text-athensGray font-semibold text-3.5xl pb-12">
              {slice.primary?.title}
            </h2>
          </header>
        )}
        <div className="rounded-2xl overflow-hidden aspect-video">
          <iframe
            className="w-full h-full"
            src={`https://www.youtube.com/embed/${videoKey}`}
            title={slice.primary?.title || 'YouTube video player'}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </div>
    </section>
  ) : null;
};

export default VideoSimpleYouTube;
