import classNames from 'classnames';
import { nanoid } from 'nanoid';
import Image from 'next/image';
import { RichText } from 'prismic-reactjs';
import { FC } from 'react';
import chunk from 'lodash.chunk';
import LazyLoad from 'react-lazyload';
import dynamic from 'next/dynamic';
import { getColor } from '../../../utils/colors';
import { GridListsCardAnimatedProps } from '../GridLists.types';

const HighlightText = dynamic(() => import('../../../components/HighlightText'), {
  ssr: false,
});
const FlipCard = dynamic(() => import('../../../components/FlipCard'), {
  ssr: false,
});
const Icon = dynamic(() => import('../../../components/Icon'), {
  ssr: false,
});

const ItemDesktop = ({
  title, description, image, bgColor, className,
}) => (
  <div className={classNames(
    'relative hidden lg:block overflow-hidden rounded-2xl group cursor-default',
    className,
  )}
  >
    {!!image?.url && (
    <div className="w-full h-161 max-h-full absolute z-0">
      <Image
        className="object-cover h-full w-full absolute rounded-2xl"
        src={image.url}
        alt={image.alt}
        layout="fill"
        objectFit="cover"
      />
    </div>
    )}
    <div className={classNames('w-full absolute h-full opacity-90 z-0 rounded-2xl', bgColor)} />
    <div className="h-full relative z-0 text-left px-11 py-9 -top-96 group-hover:top-0 transition-all">
      {title && (
      <div className="text-athensGray text-2xl leading-10 font-semibold mb-2">
        {title}
      </div>
      )}
      {!!description?.length && (
      <div className="font-archivo text-white-2 text-base leading-7">
        <RichText render={description} />
      </div>
      )}
    </div>
    {title && (
    <div className={classNames(
      'text-athensGray text-2xl leading-10 font-semibold p-4 block',
      'absolute top-0 flex w-full left-0 h-full items-center justify-center transition-all',
      'group-hover:h-0 group-hover:w-0 group-hover:-top-28 overflow-hidden',
    )}
    >
      {title}
    </div>
    )}
  </div>
);

const ItemMobile = ({
  image, title, description, bgColor,
}) => (
  <FlipCard
    key={nanoid()}
    className="h-full w-full rounded-2xl lg:hidden"
    front={(
      <div className="h-full">
        {!!image?.url && (
          <Image
            className="object-cover h-full w-full absolute rounded-2xl"
            src={image.url}
            alt={image.alt}
            layout="fill"
            objectFit="cover"
          />
        )}
        <div className={classNames('w-full absolute h-full opacity-90 z-0 rounded-2xl', bgColor)} />
        <div className="relative z-0 space-y-5 h-full w-full p-4 flex items-center justify-center">
          {title && (
            <div className="text-athensGray text-base leading-7 font-bold">
              {title}
            </div>
          )}
        </div>
        <Icon
          icon="arrow-right-down-solid"
          className="absolute w-4 bottom-1 right-1 text-black -rotate-180"
        />
      </div>
    )}
    back={(
      <div>
        {!!image?.url && (
          <div
            className="w-full h-64 max-h-full absolute z-0"
            style={{ transform: 'rotateY(180deg)' }}
          >
            <Image
              className="object-cover h-full w-full absolute rounded-2xl"
              src={image.url}
              alt={image.alt}
              layout="fill"
              objectFit="cover"
            />
          </div>
        )}
        <div className={classNames('w-full absolute h-full opacity-90 z-0 rounded-2xl', bgColor)} />
        <div className="relative z-0 text-left p-4">
          {title && (
            <div className="text-athensGray text-base leading-7 font-bold">
              {title}
            </div>
          )}
          {!!description?.length && (
            <div className="font-archivo text-white-2 text-sm">
              <RichText render={description} />
            </div>
          )}
        </div>
      </div>
    )}
  />
);

const itemsTemplateModel = [
  [
    { height: 'min-h-[17.75rem]' },
    { height: 'min-h-[11rem]' },
    { height: 'min-h-[15rem]' },
  ],
  [
    { height: 'min-h-[12.938rem]' },
    { height: 'min-h-[15.563rem]' },
    { height: 'min-h-[15.3rem]' },
  ],
];

const GridListsCardAnimated: FC<GridListsCardAnimatedProps> = ({
  slice,
}) => {
  if (!slice?.items?.length) return null;

  const itemsCols = chunk(slice.items, Math.ceil(slice.items.length / 2));
  return (
    <div className="relative overflow-hidden bg-gray-1">
      {!!slice.primary?.video?.url && (
        <LazyLoad>
          <video
            className="absolute top-0 left-0 z-0 w-full h-full object-cover"
            preload="none"
            playsInline
            autoPlay
            muted
            loop
          >
            <source src={slice.primary.video.url} type={slice.primary.video.type} />
            Your browser does not support the video tag.
          </video>
        </LazyLoad>
      )}
      <section className="relative z-0 bg-black/50">
        <div className="dh-content">
          {!!slice.primary?.title && (
            <header className="mb-9">
              <h2 className="text-xl lg:text-4.5xl text-white text-center">
                <HighlightText text={slice.primary?.title} highlight={slice.primary?.highlightedText} isUnderline />
              </h2>
            </header>
          )}
          <div className="grid lg:grid-cols-2 gap-5">
            {itemsCols.map((items, col) => (
              <ul className="flex flex-col gap-5" key={nanoid()}>
                {items.map(
                  ({
                    title, description, image, color,
                  }, index) => {
                    const { bg: bgColor } = getColor(color, 800);

                    return (
                      <li key={nanoid()}>
                        <ItemMobile
                          image={image}
                          title={title}
                          description={description}
                          bgColor={bgColor}
                        />

                        <ItemDesktop
                          image={image}
                          title={title}
                          description={description}
                          bgColor={bgColor}
                          className={itemsTemplateModel[col][index]?.height}
                        />
                      </li>
                    );
                  },
                )}
              </ul>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default GridListsCardAnimated;
