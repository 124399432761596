import { Link, RichText } from 'prismic-reactjs';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import { prismicLinkResolver } from '../../../utils/common';

const LinkWithQuery = dynamic(() => import('../../../components/LinkWithQuery'), {
  ssr: false,
});

const ContentWithButton = ({ slice }) => (
  <div
    className={classNames(
      'bg-gray-1',
    )}
  >
    <div
      className={classNames(
        'dh-content py-20 grid grid-cols-1 text-center',
        'sm:gap-x-10 sm:grid-cols-2 sm:text-left',
      )}
    >
      {slice.primary?.title && (
        <div
          className={classNames(
            'sm:pr-28 sm:flex sm:items-center',
          )}
        >
          <div className="sm:max-w-sm">
            <h2 className={classNames(
              'dh-title font-semibold text-athensGray dark:text-athensGray text-3.5xl',
            )}
            >
              {slice.primary.title}
            </h2>
          </div>
        </div>
      )}
      {slice.primary?.description && (
        <div
          className={classNames(
            'text-lg', // mobile
            'sm:flex sm:items-center sm:m-0 sm:p-0', // desktop
          )}
        >
          <div
            className={classNames(
              'text-white-2 dark:text-white-2 font-normal font-archivo my-richtext-6 text-base leading-6',
            )}
          >
            <div className="sm:-mt-6 leading-7">
              <RichText render={slice.primary.description} />
            </div>
            {slice?.primary?.buttonLink?.url && (
            <div>
              <LinkWithQuery
                href={Link.url(slice.primary.buttonLink, prismicLinkResolver)}
                target={slice.primary.buttonLink.target ?? '_self'}
                className={classNames(
                  'dh-button-sm text-sm font-semibold text-athensGray bg-blue leading-6',
                )}
              >
                {slice.primary.buttonLabel}
              </LinkWithQuery>
            </div>
            )}
          </div>
        </div>
      )}
    </div>
  </div>
);

export default ContentWithButton;
