import { FeatureVariations } from './Feature.types';
import FeatureLeftIcons from './variations/FeatureLeftIcons';
import FeatureRightIcons from './variations/FeatureRightIcons';
import FeatureSimpleList from './variations/FeatureSimpleList';
import FeatureWithGridList from './variations/FeatureWithGridList';
import FeatureWithList from './variations/FeatureWithList';
import FeatureWithListLight from './variations/FeatureWithListLight';

const Feature = ({ slice }) => {
  switch (slice.variation) {
    case FeatureVariations.leftIcons:
      return <FeatureLeftIcons slice={slice} />;
    case FeatureVariations.rightIcons:
      return <FeatureRightIcons slice={slice} />;
    case FeatureVariations.withList:
      return <FeatureWithList slice={slice} />;
    case FeatureVariations.withListLight:
      return <FeatureWithListLight slice={slice} />;
    case FeatureVariations.withGridList:
      return <FeatureWithGridList slice={slice} />;
    case FeatureVariations.simpleList:
      return <FeatureSimpleList slice={slice} />;
    default:
      return null;
  }
};

export default Feature;
