import classNames from 'classnames';
import { Link, RichText } from 'prismic-reactjs';
import { FC, useState } from 'react';
import { XIcon } from '@heroicons/react/outline';
import dynamic from 'next/dynamic';
import { getColor } from '../../../utils/colors';
import { prismicLinkResolver } from '../../../utils/common';
import { hasRichText } from '../../../utils/prismic';
import { BannersHeadingProps } from '../Banners.types';

const LinkWithQuery = dynamic(() => import('../../../components/LinkWithQuery'), {
  ssr: false,
});
const Countdown = dynamic(() => import('../../../components/Countdown'), {
  ssr: false,
});

const BannersCountdown: FC<BannersHeadingProps> = ({ slice }) => {
  const hasDescription = hasRichText(slice.primary.description);
  const hasRightContent = !!(slice.primary.countdown && slice.primary.buttonText);
  const hasSession = !!(global.sessionStorage?.getItem('banner'));

  const countDownDate = new Date(slice.primary.countdown || '').getTime();
  const isFutureDate = (countDownDate - new Date().getTime()) > 0;
  const [showBanner, setShowBanner] = useState(hasDescription && !hasSession);

  const onClickX = () => {
    setShowBanner(false);
    global.sessionStorage.setItem('banner', 'show');
  };

  const countdownClasses = {
    numbers: 'text-[26px] lg:text-[32px]',
    titles: 'text-xs',
  };

  const color = getColor(slice.primary.colorBackground);
  const textColor = slice.primary.colorText === 'black' ? 'text-gray-1' : 'text-white-1';
  const buttonColor = slice.primary.colorText === 'black'
    ? 'bg-gray-1 text-white-1'
    : 'bg-white-1 text-gray-1';

  const XColor = slice.primary.colorText === 'black' ? 'gray-1' : 'white-1';

  if (!isFutureDate) {
    return null;
  }

  return showBanner ? (
    <section className={classNames(
      'flex flex-col items-center pt-10 pb-8 px-[54px] w-full relative',
      'lg:flex-row lg:justify-evenly lg:py-3 lg:px-[100px]',
      color.bg,
      textColor,
    )}
    >
      <div className={classNames(
        'text-sm text-center w-[212px] leading-[18px] font-medium lg:w-full lg:text-base',
        { 'lg:w-[60%]': hasRightContent },
      )}
      >
        <RichText render={slice.primary.description} />
      </div>

      {slice.primary.countdown
        && (
        <div className="pt-3 lg:ml-3">
          <Countdown
            finalDate={slice.primary.countdown}
            onFinish={() => setShowBanner(false)}
            classes={countdownClasses}
          />
        </div>
        )}

      { (slice.primary.buttonText) && (
        <LinkWithQuery
          href={Link.url(slice.primary.buttonLink, prismicLinkResolver)}
          target={slice.primary.buttonLink?.target ?? '_blank'}
          className={classNames(
            buttonColor,
            'dh-btn px-6 py-2 rounded-lg mt-3 lg:ml-8 lg:mt-0',
          )}
        >
          <p className="font-bold text-sm leading-5">{slice.primary.buttonText}</p>
        </LinkWithQuery>
      )}
      <div className="absolute right-[22px] top-[30px] lg:h-full lg:flex lg:top-0 lg:right-[34px] lg:align-middle ">
        <XIcon
          className={classNames(
            'w-6 flex-none lg:inline',
            XColor,
          )}
          onClick={onClickX}
        />
      </div>
    </section>
  ) : null;
};

export default BannersCountdown;
