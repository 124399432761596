import { useEffect } from 'react';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';

const PRISMIC_REPO = process.env.NEXT_PUBLIC_PRISMIC_REPO || '';

const useUpdatePreviewRef = (preview, documentId) => {
  const router = useRouter();
  useEffect(() => {
    if (router.isPreview) {
      const rawPreviewCookie = Cookies.get('io.prismic.preview');
      if (rawPreviewCookie) {
        const previewCookie = JSON.parse(rawPreviewCookie);
        const previewCookieObject = previewCookie[`${PRISMIC_REPO}.prismic.io`];
        const previewCookieRef = previewCookieObject && previewCookieObject.preview
          ? previewCookieObject.preview
          : null;
        if (previewCookieRef && preview.activeRef !== previewCookieRef) {
          window.location.href = `/api/preview?token=${previewCookieRef}&documentId=${documentId}`;
        }
      } else {
        window.location.href = '/api/exit-preview';
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useUpdatePreviewRef;
