import { useLinkResolver } from '../hooks/useLinkResolver';

const LinkWithQuery = ({ href, children, ...props }) => {
  const url = useLinkResolver(href);
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <a href={url} {...props}>
      {children}
    </a>
  );
};

export default LinkWithQuery;
