import { RichText } from 'prismic-reactjs';
import { nanoid } from 'nanoid';

const FaqOffsetLeft = ({ slice }) => (
  <section className="bg-white">
    <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
      <div className="lg:grid lg:grid-cols-3 lg:gap-8">
        <div>
          <h2 className="dh-title dark:text-gray-900">
            {RichText.asText(slice.primary.title)}
          </h2>
          <p className="dh-description dark:text-gray-500">
            {RichText.asText(slice.primary.description)}
          </p>
        </div>
        <div className="mt-12 lg:mt-0 lg:col-span-2">
          <dl className="space-y-12">
            {slice.items?.map((faq) => (
              <div key={nanoid()}>
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  {faq.question}
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  {RichText.asText(faq.answer)}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  </section>
);

export default FaqOffsetLeft;
