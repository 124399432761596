import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { getRealLocale } from '../utils/common';

const useLocale = (shortLocale = 'es'): string => {
  const [locale, setLocale] = useState<string>(shortLocale);
  const router = useRouter();

  useEffect(() => {
    if (router?.locale) setLocale(getRealLocale(router.locale));
  }, [router]);

  return locale;
};

export default useLocale;
