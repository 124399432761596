import classNames from 'classnames';
import { RichText } from 'prismic-reactjs';
import { FC } from 'react';
import { nanoid } from 'nanoid';
import dynamic from 'next/dynamic';
import { getColor } from '../../../utils/colors';
import { hasRichText } from '../../../utils/prismic';
import { ContentCheckListProps } from '../Content.types';

const CheckList = dynamic(() => import('../../../components/CheckList'), {
  ssr: false,
});

const ContentCheckList: FC<ContentCheckListProps> = ({ slice }) => {
  const vertical = slice?._vertical?.data;
  const checklistColor = getColor(slice?.primary?.checklistColor || vertical?.color, 600);
  const { items } = slice;
  const htmlSerializer = (type, element) => {
    if (type === 'paragraph') {
      return (
        <p className="mb-6" key={nanoid()}>{element.text}</p>
      );
    }
    return null;
  };
  return (
    <div className="bg-black-2">
      <section className="dh-content-full">
        <div className="flex flex-col px-4 py-3 items-start
        lg:items-center lg:flex-row lg:py-16 lg:justify-evenly lg:max-w-7xl lg:m-auto
        xl:px-27 2xl:max-w-8xl 3xl:max-w-8x5l
        2xl:px-36 2xl:items-end"
        >
          <div
            className={classNames(
              'text-white-1 rounded lg:w-[43%] 2xl:w-[51%]',
            )}
          >
            {slice?.primary?.pill
            && (
            <div className="dh-pill">
              {slice.primary.pill}
            </div>
            )}
            {slice?.primary?.title && (
            <div className="text-3xl leading-9 font-bold pt-5 pb-2 lg:py-4 lg:text-4.5xl lg:leading-15
            3xl:text-[3rem]"
            >
              {slice?.primary?.title}
            </div>
            )}
            { hasRichText(slice?.primary?.description) && (
            <div
              className="text-sm w-72 leading-5 font-medium pb-6 lg:w-full lg:pb-0 lg:text-sm
            2xl:text-base"
            >
              <RichText render={slice.primary?.description} htmlSerializer={htmlSerializer} />
            </div>
            )}
          </div>
          <CheckList items={items} color={checklistColor} />
          <div />
        </div>
      </section>
    </div>
  );
};

export default ContentCheckList;
