import { useCallback, useState } from 'react';
import classNames from 'classnames';
import { Link, RichText } from 'prismic-reactjs';
import set from 'lodash.set';
import cloneDeep from 'lodash.clonedeep';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import useLocale from '../../../hooks/useLocale';
import {
  prismicLinkResolver, scrollTo, slugify,
} from '../../../utils/common';
import { hasRichText } from '../../../utils/prismic';
import { getColor } from '../../../utils/colors';
import { HeroVariations } from '../Hero.types';
import { useTrackingPayload } from '../../../hooks/useTrackPayload';
import { TrackEvents } from '../../../utils/constants';
import { useAnalytics } from '../../../hooks/useAnalytics';

const LinkWithQuery = dynamic(() => import('../../../components/LinkWithQuery'), {
  ssr: false,
});
const HighlightText = dynamic(() => import('../../../components/HighlightText'), {
  ssr: false,
});
const DialogDownload = dynamic(() => import('../../../components/DialogDownload'), {
  ssr: false,
});

const HeroLeft = ({ slice }) => {
  const { track } = useAnalytics();
  const locale = useLocale();
  const [open, setOpen] = useState(false);
  const vertical = slice?._vertical?.data;
  const cluster = slice?._cluster?.data;
  const color = getColor(vertical?.color);
  const trackPayload = useTrackingPayload({ slice, cluster, vertical });

  const handleClick = useCallback((event): void => {
    const payload = cloneDeep(trackPayload);
    set(payload, 'label', 'Hero CTA');
    set(payload, 'url', event.target.href);
    set(payload, 'context.label', 'Hero CTA');
    set(payload, 'context.destination_url', event.target.href);

    track(TrackEvents.buttonClicked, payload, locale);
  }, [trackPayload, locale, track]);

  const handleClickBtnApply = useCallback((id: string, label: string, event: Event): void => {
    event.preventDefault();

    const payload = cloneDeep(trackPayload);
    set(payload, 'context.label', label);
    set(payload, 'context.next_step', 'MoreInfoFlow');

    track(TrackEvents.productApplicationClicked, payload, locale);
    scrollTo({ id: slugify(id) });
  }, [trackPayload, locale, track]);

  const handleClickBtnDownload = useCallback((label: string): void => {
    const payload = cloneDeep(trackPayload);
    set(payload, 'context.label', label);
    setOpen(true);

    track(TrackEvents.brochureDownloadedClicked, payload, locale);
  }, [trackPayload, locale, track]);

  if (!slice.primary) return <div className="hidden" data-testid="empty-slice" />;

  return (
    <section className="lg:overflow-hidden dark:bg-gray-2">
      <div className="dh-content">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8">
          <div
            className={classNames(
              'mx-auto max-w-md sm:max-w-2xl sm:text-center lg:text-left lg:flex lg:items-center pb-10 lg:pb-0',
            )}
          >
            <div className="lg:py-32">
              <div className="mx-auto sm:max-w-xl lg:mx-0">
                {slice.primary?.titleLine1 && (
                  <h1
                    className="font-semibold text-3.5xl leading-15 dark:text-athensGray"
                  >
                    <HighlightText
                      text={RichText.asText(slice.primary.titleLine1)}
                      highlight={slice.primary?.highlightedText}
                      className={classNames(color.textContrast, color.bg)}
                    />
                  </h1>
                )}
                {slice.primary.titleImage?.url && (
                  <div className="w-full">
                    <Image
                      src={slice.primary.titleImage.url}
                      alt={slice.primary.titleImage.alt}
                      width={slice.primary.titleImage.dimensions.width}
                      height={slice.primary.titleImage.dimensions.height}
                      layout="responsive"
                      objectFit="cover"
                      priority
                    />
                  </div>
                )}
                {hasRichText(slice.primary?.description) && (
                  <p
                    className={classNames(
                      'font-normal mt-3 text-base text-gray-50 dark:text-white-2 sm:mt-5',
                      'sm:max-w-xl sm:mx-auto lg:mx-0 font-archivo leading-7',
                    )}
                  >
                    {RichText.asText(slice.primary.description)}
                  </p>
                )}
              </div>

              <div
                className={classNames(
                  'mt-10 sm:flex sm:justify-center lg:justify-start space-y-3 sm:space-y-0 sm:space-x-3',
                )}
              >
                {slice.primary.buttonLabel && (
                  <div>
                    <LinkWithQuery
                      onClick={(e) => (
                        slice.primary?.fragmentIdentifier
                          ? handleClickBtnApply(slice.primary?.fragmentIdentifier, slice.primary.buttonLabel, e)
                          : handleClick(e)
                      )}
                      href={slice.primary?.buttonLink ? Link.url(slice.primary.buttonLink, prismicLinkResolver) : null}
                      className={classNames(
                        'dh-button-lg',
                        color.textContrast,
                        color.bg,
                        color.bgHover,
                      )}
                    >
                      {slice.primary.buttonLabel}
                    </LinkWithQuery>
                  </div>
                )}
                {!!slice.primary.buttonDownloadLabel
                  && !!slice.primary.buttonDownloadFile && (
                    <div>
                      <button
                        type="button"
                        onClick={() => handleClickBtnDownload(slice.primary.buttonDownloadLabel)}
                        className={classNames(
                          'dh-button-lg bg-transparent hover:bg-transparent',
                          color.border,
                        )}
                      >
                        {slice.primary.buttonDownloadLabel}
                      </button>
                    </div>
                )}
              </div>
            </div>
          </div>

          {slice.primary?.image?.url && (
            <div className="lg:ml-8 lg:relative lg:overflow-hidden md:rounded-2xl">
              <div className="mx-auto max-w-md sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
                <div className="w-full rounded-lg md:rounded-2xl overflow-hidden lg:hidden">
                  <Image
                    src={slice.primary.image.url}
                    alt={slice.primary.image.alt}
                    width={slice.primary.image.dimensions.width}
                    height={slice.primary.image.dimensions.height}
                    layout="responsive"
                    objectFit="cover"
                    priority
                  />
                </div>
                <div className="hidden lg:block w-auto h-full max-w-none rounded-2xl inset-y-0 overflow-hidden">
                  <Image
                    src={slice.primary.image.url}
                    alt={slice.primary.image.alt}
                    layout="fill"
                    objectFit="cover"
                    objectPosition="left top"
                    priority
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {[HeroVariations.leftWithBtnDownload, HeroVariations.leftWithImageTitle].includes(slice.variation)
        && (
        <DialogDownload
          color={color}
          close={() => setOpen(false)}
          open={open}
          termsAndConditionsLink={slice.primary?.termsAndConditionsLink}
          buttonLabel={slice.primary?.buttonDownloadLabel}
          downloadFile={slice.primary?.buttonDownloadFile}
          cluster={slice?._cluster}
          trackPayload={trackPayload}
        />
        )}
    </section>
  );
};

export default HeroLeft;
