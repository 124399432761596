import classNames from 'classnames';
import Image from 'next/image';
import { FunctionComponent } from 'react';
import { TeamWithOneImageProps } from '../Team.types';

const TeamWithOneImage: FunctionComponent<TeamWithOneImageProps> = ({ slice }) => (
  <div className="bg-white">
    <div className="dh-content">
      <div
        className={classNames('space-y-5 lg:space-y-0 lg:flex bg-gray-800 rounded p-[3.313rem]')}
      >
        <div
          className={classNames(
            'flex items-center space-y-2 lg:w-1/2',
          )}
        >
          <div className="grid place-content-center text-lg leading-6 font-medium space-y-5 w-full">
            <h2 className="text-2xl leading-[2.188rem] font-bold text-white text-center lg:text-left lg:max-w-xs">
              {slice.primary?.title}
            </h2>
          </div>
        </div>
        {slice.primary.image?.url && (
        <div className={classNames(
          'aspect-w-3 aspect-h-2 lg:w-1/2 relative overflow-hidden lg:max-w-lg',
        )}
        >
          <Image
            className="rounded-[1.25rem] mx-auto"
            src={slice.primary?.image?.url}
            alt={slice.primary?.image?.alt}
            width={slice.primary?.image?.dimensions.width}
            height={slice.primary?.image?.dimensions.height}
            layout="responsive"
            objectFit="cover"
          />
        </div>
        )}
      </div>
    </div>
  </div>
);

export default TeamWithOneImage;
