import { StepsVariations } from './Steps.types';
import StepsDefault from './variations/StepsDefault';

const Steps = ({ slice }) => {
  switch (slice.variation) {
    case StepsVariations.default:
      return <StepsDefault slice={slice} />;
    default:
      return null;
  }
};

export default Steps;
