import { RichTextBlock } from 'prismic-reactjs';
import { Image, Slice } from '../../types/prismic.type';

export enum StepsVariations {
  default = 'steps',
}

export type IStepsDefault = Slice & {
  slice: {
    primary: {
      title?: RichTextBlock[];
      color?: string;
      backgroundColor?: string;
      iconBegin?: Image;
      iconFinal?: Image;
    },
    items: {
      title?: string;
      description?: RichTextBlock[];
    }[],
  }
}
