import { nanoid } from 'nanoid';
import Image from 'next/image';
import { RichText } from 'prismic-reactjs';
import dynamic from 'next/dynamic';
import { getLocale } from '../../../utils/common';
import { hasRichText } from '../../../utils/prismic';
import useLocale from '../../../hooks/useLocale';

const ReactCarousel = dynamic(() => import('../../../components/ReactCarousel'), {
  ssr: false,
});

const itemsToShowSm = 1;
const itemsToShowLg = 4;
const breakPoints = [
  {
    width: 1,
    itemsToShow: itemsToShowSm,
    itemPadding: [0, 7, 0, 7],
  },
  {
    width: 955,
    itemsToShow: itemsToShowLg,
    itemPadding: [0, 7.5, 0, 7.5],
  },
];
const carouselOptions = {
  isRTL: false,
  pagination: false,
  breakPoints,
};

const flagsBasePath = 'assets/flags';
const flags = {
  argentina: `${flagsBasePath}/ar.svg`,
  brasil: `${flagsBasePath}/br.svg`,
  chile: `${flagsBasePath}/cl.svg`,
  colombia: `${flagsBasePath}/co.svg`,
  mexico: `${flagsBasePath}/mx.svg`,
  peru: `${flagsBasePath}/pe.svg`,
  uruguay: `${flagsBasePath}/uy.svg`,
};

const CarouselWithFlags = ({ slice }) => {
  const locale = useLocale();

  return (
    <section className="dh-content-full pb-20 bg-white-1 pt-[3.75rem] lg:pb-[8.75rem]">
      <div className="lg:m-auto lg:max-w-7xl 2xl:max-w-8xl">
        {slice.primary?.title && (
        <div className="mb-3 text-left lg:mb-12 lg:text-center 3xl:mb-14">
          <h2 className="text-black-1 font-bold text-h4 lg:text-h2 3xl:text-h1">{slice.primary.title}</h2>
        </div>
        )}
        <div className="w-full">
          <ReactCarousel options={carouselOptions}>
            {slice.items?.map((item) => {
              const countryFlag = flags[item.flag];
              return (
                <div
                  key={nanoid()}
                  className="py-3"
                >
                  <div className="filter block h-full shadow-md bg-white-1 rounded-[0.25rem] border-white-3
                  border-[0.031rem] w-full p-6 2xl:px-8 3xl:px-6"
                  >
                    <div className="flex pb-4 items-center">
                      <div className=" relative">
                        {item.image?.url && (
                        <div className="mx-auto relative h-[4.5rem] w-[4.5rem] 3xl:w-[7.313rem] 3xl:h-[7.375rem]">
                          <Image
                            className="rounded-full"
                            src={item.image.url}
                            alt={item.image.alt}
                            layout="fill"
                            objectFit="contain"
                          />
                        </div>
                        )}
                        {(!!countryFlag && !!locale) && (
                        <div className="flex-none flex items-center absolute right-0 bottom-0 w-6 h-6
                      3xl:w-[2.188rem] 3xl:h-[2.188rem]"
                        >
                          <Image
                            width="100%"
                            height="100%"
                            src={`${getLocale(locale).path}${countryFlag}`}
                            alt={item.flag}
                            layout="intrinsic"
                          />
                        </div>
                        )}
                      </div>
                      <div className="pl-4">
                        {item.name && (
                        <p className="text-p2 font-bold text-gray-1 pb-0.5
                      3xl:text-p1 3xl:pb-1.5"
                        >
                          {item.name}
                        </p>
                        )}
                        {item.position && (
                        <p className="text-p3 font-medium text-violet-800 3xl:text-p2">{item.position}</p>
                        )}
                      </div>
                    </div>

                    <div className="text-p3 font-medium text-gray-4 3xl:text-base 3xl:font-normal">
                      {hasRichText(item.testimony) && (
                      <RichText render={item.testimony} />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </ReactCarousel>
        </div>
      </div>
    </section>
  );
};

export default CarouselWithFlags;
