export const reducePlans = (products: any[]) => products.reduce((acumulate, product) => {
  const plansMap = product?.defaultOffer?.plans.map(
    (p) => ({
      ...p,
      installmentOfferKey: `${product?.wareId}${p.installmentCount}`,
      wareId: product?.wareId,
      code: product?.code,
      defaultOffer: {
        token: product?.defaultOffer?.token,
      },
    }),
  );

  const newPlans = [
    ...acumulate,
    ...(plansMap ?? []),
  ];
  return newPlans;
}, []);

export const sortPlans = (plans: any[], order: number[]) => {
  let sortedPlans: any[] = [];

  order.forEach((number) => {
    const orderPlans = plans.filter(
      (p) => p.installmentCount === number || `${p.wareId}${p.installmentCount}` === `${number}`,
    ) ?? [];

    sortedPlans = [
      ...sortedPlans,
      orderPlans,
    ];
  });

  return sortedPlans.reduce((acumulate, plan) => {
    const newPlans = [
      ...acumulate,
      ...plan,
    ];
    return newPlans;
  }, []);
};

export const plansByCurrency = (plans, currency) => plans.filter((plan) => plan.currency === currency);
