import { IncentivesVariations } from './Incentives.types';
import IncentivesCards from './variations/IncentivesCards';
import IncentivesThreeColumns from './variations/IncentivesThreeColumns';
import IncentivesTwoCards from './variations/IncentivesTwoCards';

const Incentives = ({ slice }) => {
  switch (slice.variation) {
    case IncentivesVariations.twoCards:
      return <IncentivesTwoCards slice={slice} />;
    case IncentivesVariations.threeColumns:
      return <IncentivesThreeColumns slice={slice} />;
    case IncentivesVariations.cards:
    default:
      return <IncentivesCards slice={slice} />;
  }
};

export default Incentives;
