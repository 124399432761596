import { Link, RichTextBlock } from 'prismic-reactjs';
import { ProductDocument } from '../../types/product.type';
import { Image, Slice } from '../../types/prismic.type';

export enum CardsVariations {
  products = 'cardsProducts',
}

export type CardsProductsProps = Slice & {
  slice: {
    primary: {
      primaryColor?: string;
      secondaryColor?: string;
      title?: string;
      description?: RichTextBlock[];
      logo?: Image;
      bookingLabel?: string;
      bookingDescription?: string;
      paymentMethodsLabel?: string;
      paymentMethodsTooltip?: RichTextBlock[];
      installmentTag?: string;
      installmentLabel?: string;
      installmentCount?: string;
      installmentDescription?: string;
      installmentPriceSuffix?: string;
      totalPriceLabel?: string;
      benefitsLabel?: string;
      benefitsLink?: Link;
    };
    items?: {
      paymentMethodImage: Image;
    }[];
    _products: ProductDocument[]
  }
}
