import { RichText } from 'prismic-reactjs';
import { nanoid } from 'nanoid';

const FaqColumns = ({ slice }) => (
  <section className="bg-white">
    <div className="dh-content">
      <h2 className="text-3xl font-extrabold text-gray-900 text-center">
        {RichText.asText(slice.primary.title)}
      </h2>
      <div className="mt-12">
        <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-12 lg:grid-cols-3">
          {slice.items?.map((faq) => (
            <div key={nanoid()}>
              <dt className="text-lg leading-6 font-medium text-gray-900">
                {faq.question}
              </dt>
              <dd className="mt-2 text-base text-gray-500">
                {RichText.asText(faq.answer)}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  </section>
);

export default FaqColumns;
