import classNames from 'classnames';
import { RichText } from 'prismic-reactjs';
import { FunctionComponent } from 'react';
import { ContentVariations, ContentWithOnlyTextProps } from '../Content.types';

const ContentWithOnlyText: FunctionComponent<ContentWithOnlyTextProps> = ({
  slice, fullWidth, centered, opacity,
}) => (
  slice?.primary?.description?.length ? (
    <div
      className={classNames(
        'relative text-white font-medium',
        { 'bg-gray-800': fullWidth },
        { 'bg-gray-900': !fullWidth },
      )}
    >
      <div className={classNames(
        'dh-content',
        { 'pt-2': !fullWidth, 'p-0': fullWidth },
      )}
      >
        <div
          className={classNames(
            'p-9',
            {
              'text-center': centered,
              rounded: !centered,
              'bg-gray-800': !opacity,
              'bg-gray-800/50': opacity,
              'text-lg leading-[2.875rem]': slice.variation === ContentVariations.withOnlyTextFull,
              'text-sm leading-7': slice.variation === ContentVariations.withOnlyTextDark,
            },
          )}
        >
          {RichText.render(slice?.primary?.description)}
        </div>
      </div>
    </div>
  ) : <div className="hidden" />);

export default ContentWithOnlyText;
