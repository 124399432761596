import { nanoid } from 'nanoid';
import classNames from 'classnames';
import Image from 'next/image';

const MAX_HEIGHT = 128;

const GridListsFiveColumns = ({ slice }) => (slice?.items?.length ? (
  <div className="dh-content bg-white pt-16 pb-32">
    <h2
      className={
        classNames(
          'dh-title text-gray-800 dark:text-gray-800 text-center text-4xl font-bold',
          'max-w-xl mx-auto',
          'mb-10 lg:mb-20',
        )
      }
    >
      {slice.primary.title}
    </h2>
    <div className={classNames(
      'flex flex-wrap gap-x-5 sm:gap-y-11',
      'justify-center',
    )}
    >
      {slice.items.map((item) => (
        <div
          key={nanoid()}
          className="self-center text-center w-[14rem] p-5"
        >
          {item.image?.url && (
            <Image
              src={item.image.url}
              alt={item.image.alt}
              width={item.image.dimensions.width}
              height={MAX_HEIGHT}
              layout="responsive"
              objectFit="contain"
            />
          )}
        </div>
      ))}
    </div>
  </div>
) : (
  <div className="hidden" />
));

export default GridListsFiveColumns;
