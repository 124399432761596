import { CountryCodes } from '@digitalhouse-tech/ts-lib-checkout-url-mapper/.dist/util/countryMap';
import { Link, RichTextBlock } from 'prismic-reactjs';
import { ProductDocument } from '../../types/product.type';
import { IOffer, IProduct, IProductGroup } from '../../graphql/model/interfaces';
import { Image, Slice } from '../../types/prismic.type';
import { ICheckout } from '../Tabs/Tabs.types';

export enum FormVariations {
  contact = 'formContact',
  contactBenefitSelect = 'formContactBenefitSelect',
  contactClusterSelect = 'formContactClusterSelect',
  contactWithLocation = 'formContactWithLocation',
  contactCompany = 'formContactCompany',
  contactSchedule = 'formContactSchedule',
  contactProducts = 'formContactProducts',
}

export type FormContactScheduleProps = Slice & {
  slice: {
    primary: {
      title: string;
      titleImage?: Image;
      fromLabel?: string;
      fromLogo1?: Image;
      fromLogo2?: Image;
      byLabel?: string;
      byLogo?: Image;
      description?: RichTextBlock[];
      formTitle?: RichTextBlock[];
      nameLabel?: string;
      namePlaceholder?: string;
      lastnameLabel?: string;
      lastnamePlaceholder?: string;
      emailLabel?: string;
      emailPlaceholder?: string;
      phoneCodeLabel?: string;
      phoneCodePlaceholder?: string;
      phoneLabel?: string;
      phonePlaceholder?: string;
      scheduleLabel?: string;
      schedulePlaceholder?: string;
      sendButtonLabel?: string;
      termsAndConditionsAgree?: string;
      termsAndConditionsLabel?: string;
      termsAndConditionsLink?: Link;
      textAgree?: string;
      label1?: string;
      link1?: Link;
      textConnector?: string;
      label2?: string;
      link2?: Link;
    }
    _checkout: ICheckout & IProduct & {
      name: string;
      products?: IProductGroup[];
      defaultOffer?: IOffer & {
        currency: {
          symbol: string;
          code: CountryCodes;
        }
      };
    }
  }
}

export type FormContactProductsProps = Slice & {
  slice: {
    primary: {
      pillText?: string;
      pillColor?: string;
      title?: string;
      modalityPillText?: string;
      durationPillText?: string;
      description?: RichTextBlock[];
      formTitle?: RichTextBlock[];
      nameLabel?: string;
      namePlaceholder?: string;
      lastnameLabel?: string;
      lastnamePlaceholder?: string;
      emailLabel?: string;
      emailPlaceholder?: string;
      phoneCodeLabel?: string;
      phoneCodePlaceholder?: string;
      phoneLabel?: string;
      phonePlaceholder?: string;
      scheduleLabel?: string;
      schedulePlaceholder?: string;
      sendButtonLabel?: string;
      termsAndConditionsAgree?: string;
      termsAndConditionsLabel?: string;
      termsAndConditionsLink?: Link;
      textAgree?: string;
      label1?: string;
      link1?: Link;
      textConnector?: string;
      label2?: string;
      link2?: Link;
    }
    _products: ProductDocument[]
  }
}
