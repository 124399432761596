import { RichText } from 'prismic-reactjs';
import { nanoid } from 'nanoid';

const FeatureWithListLight = ({ slice }) => (
  <section className="bg-athensGray-400 text-mineSharft">
    <div className="dh-content grid lg:grid-cols-3 lg:gap-x-12">
      {slice.primary?.title && (
        <header>
          <h2 className="font-bold text-4xl leading-15 mb-8 lg:mb-0">{slice.primary.title}</h2>
        </header>
      )}
      {!!slice.items?.length && (
        <ul className="lg:col-span-2 grid gap-8 my-6 sm:my-0 sm:gap-12 sm:grid-cols-3 lg:mt-0">
          {slice.items?.map(
            ({ label, text }) => (
              <li className="list-none sm:text-center text-sm font-normal" key={nanoid()}>
                <span className="font-semibold text-2xl inline-block px-2 py-1 mb-3">
                  {label}
                </span>
                <div className="text-sm font-medium leading-6">
                  <RichText render={text} />
                </div>
              </li>
            ),
          )}
        </ul>
      )}
    </div>
  </section>
);

export default FeatureWithListLight;
