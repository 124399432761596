import { RichText } from 'prismic-reactjs';
import { FC } from 'react';
import { nanoid } from 'nanoid';
import Image from 'next/image';
import { CheckCircleIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { getColor } from '../../../utils/colors';
import { hasRichText } from '../../../utils/prismic';
import { ContentCheckListImageProps } from '../Content.types';

const ContentCheckListImage: FC<ContentCheckListImageProps> = ({ slice }) => {
  const { items } = slice;
  const vertical = slice?._vertical?.data;
  const bulletsColor = getColor(slice.primary?.checkListColor || vertical?.color, 600);
  const backgroundColor = slice.primary?.backgroundColor === 'black' ? 'bg-black-1' : 'bg-white-1';
  const textColor = backgroundColor === 'bg-black-1' ? 'text-white-1' : 'text-gray-1';

  const htmlSerializer = (type, element) => {
    if (type === 'paragraph') {
      return (
        <p className="mb-3" key={nanoid()}>{element.text}</p>
      );
    }
    return null;
  };

  return (
    <section className={classNames('pb-10 pt-15 px-4 lg:dh-content-full lg:py-20', backgroundColor)}>
      <div className="flex flex-col lg:flex-row lg:items-center w-full
      lg:max-w-7xl lg:m-auto 2xl:max-w-8xl 3xl:max-w-8x5l"
      >
        <div className="flex flex-col lg:w-6/12 lg:items-start 2xl:items-center">
          <div className="lg:w-5/6 2xl:w-4/6">
            <div>
              {slice?.primary?.title && (
              <div className={classNames('text-h4 leading-9 font-bold lg:text-h2 3xl:text-5xl', textColor)}>
                {slice?.primary?.title}
              </div>
              )}
              { hasRichText(slice?.primary?.description) && (
              <div
                className={classNames('text-sm font-medium pt-1.5 pb-4 lg:pt-4 3xl:text-base', textColor)}
              >
                <RichText render={slice.primary?.description} htmlSerializer={htmlSerializer} />
              </div>
              )}
            </div>
            <div className="flex flex-col w-full">
              {items?.map((item) => (
                <div key={nanoid()} className="flex items-center pb-4 lg:pb-1.5">
                  <CheckCircleIcon className={classNames(
                    'flex-none w-6 lg:w-6 lg:h-6 3xl:w-8 3xl:h-8',
                    bulletsColor.text,
                  )}
                  />
                  <p className={classNames(
                    'font-medium leading-5 text-sm',
                    'ml-[0.375rem] lg:ml-3 3xl:text-base 3xl:ml-2',
                    textColor,
                  )}
                  >
                    {item.checkListText}
                  </p>
                </div>
              ))}
            </div>
          </div>

          <div />
        </div>
        <div className="w-full lg:w-6/12">
          { slice.primary?.image?.url && (
          <div className="mt-4 filter drop-shadow-md w-full h-auto self-center 2xl:w-5/6">
            <Image
              width={slice.primary?.image?.dimensions.width}
              height={slice.primary?.image?.dimensions.height}
              src={slice.primary?.image?.url}
              alt={slice.primary?.image?.alt}
            />
          </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default ContentCheckListImage;
