import { Link, RichText } from 'prismic-reactjs';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import { prismicLinkResolver } from '../../utils/common';

const LinkWithQuery = dynamic(() => import('../../components/LinkWithQuery'), {
  ssr: false,
});

const CallToAction = ({ slice }) => {
  const colored = slice.variation.endsWith('Color');

  switch (slice.variation) {
    case 'ctaJustified':
    case 'ctaJustifiedColor':
      return (
        <div className={classNames(colored ? 'bg-primary-700' : 'bg-gray-50')}>
          <div className="dh-content lg:flex lg:items-center lg:justify-between">
            <h2
              className={classNames(
                colored ? 'text-white' : 'text-gray-900',
                'text-3xl font-extrabold tracking-tight  sm:text-4xl',
              )}
            >
              <span className="block">
                {RichText.asText(slice.primary.titleLine1)}
              </span>
              {slice.primary.titleLine2 && (
                <span
                  className={classNames(
                    colored ? '' : 'text-primary-600',
                    'block',
                  )}
                >
                  {RichText.asText(slice.primary.titleLine2)}
                </span>
              )}
            </h2>
            <div className="mt-8 flex lg:mt-0 lg:shrink-0">
              {slice.primary.primaryButtonLabel && (
                <div className="inline-flex rounded-md shadow">
                  <LinkWithQuery
                    href={Link.url(
                      slice.primary.primaryButtonLink,
                      prismicLinkResolver,
                    )}
                    className={classNames(
                      colored
                        ? 'bg-white text-primary-600 hover:bg-primary-50'
                        : 'text-white bg-primary-600 hover:bg-primary-700',
                      'inline-flex items-center justify-center px-5 py-3 border border-transparent',
                      'text-base font-medium rounded-md',
                    )}
                  >
                    {slice.primary.primaryButtonLabel}
                  </LinkWithQuery>
                </div>
              )}
              {slice.primary.secondaryButtonLabel && (
                <div className="ml-3 inline-flex rounded-md shadow">
                  <LinkWithQuery
                    href={Link.url(
                      slice.primary.secondaryButtonLink,
                      prismicLinkResolver,
                    )}
                    className={classNames(
                      colored
                        ? 'text-white bg-primary-800 hover:bg-primary-900'
                        : 'text-primary-600 bg-white hover:bg-primary-50',
                      'inline-flex items-center justify-center px-5 py-3 border border-transparent',
                      'text-base font-medium rounded-md',
                    )}
                  >
                    {slice.primary.secondaryButtonLabel}
                  </LinkWithQuery>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    case 'ctaCentered':
    case 'ctaCenteredColor':
    default:
      return (
        <div className={classNames(colored ? 'bg-primary-700' : 'bg-white')}>
          <div className="dh-content text-center">
            <h2
              className={classNames(
                colored ? 'text-white' : 'text-gray-900',
                'text-3xl font-extrabold tracking-tight  sm:text-4xl',
              )}
            >
              <span className="block">
                {RichText.asText(slice.primary.titleLine1)}
              </span>
              {slice.primary.titleLine2 && (
                <span className="block">
                  {RichText.asText(slice.primary.titleLine2)}
                </span>
              )}
            </h2>
            {slice.primary.description && (
              <p
                className={classNames(
                  colored ? 'text-primary-200' : '',
                  'mt-4 text-lg leading-6',
                )}
              >
                {RichText.asText(slice.primary.description)}
              </p>
            )}
            <div className="mt-8 flex justify-center">
              {slice.primary.primaryButtonLabel && (
                <div className="inline-flex rounded-md shadow">
                  <LinkWithQuery
                    href={Link.url(
                      slice.primary.primaryButtonLink,
                      prismicLinkResolver,
                    )}
                    className={classNames(
                      colored
                        ? 'bg-white text-primary-600 hover:bg-primary-50'
                        : 'text-white bg-primary-600 hover:bg-primary-700',
                      'inline-flex items-center justify-center px-5 py-3 border',
                      'border-transparent text-base font-medium rounded-md',
                    )}
                  >
                    {slice.primary.primaryButtonLabel}
                  </LinkWithQuery>
                </div>
              )}
              {slice.primary.secondaryButtonLabel && (
                <div className="ml-3 inline-flex">
                  <LinkWithQuery
                    href={Link.url(
                      slice.primary.secondaryButtonLink,
                      prismicLinkResolver,
                    )}
                    className={classNames(
                      colored
                        ? 'text-white bg-primary-800 hover:bg-primary-900'
                        : 'text-primary-600 bg-white hover:bg-primary-50',
                      'inline-flex items-center justify-center px-5 py-3 border',
                      'border-transparent text-base font-medium rounded-md',
                    )}
                  >
                    {slice.primary.secondaryButtonLabel}
                  </LinkWithQuery>
                </div>
              )}
            </div>
          </div>
        </div>
      );
  }
};

export default CallToAction;
