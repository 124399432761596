import { RichText } from 'prismic-reactjs';
import { Disclosure } from '@headlessui/react';
import { SelectorIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import { addExtraZeros } from '../../../utils/common';
import { getColor } from '../../../utils/colors';

const FaqAccordionCentered = ({ slice }) => {
  const color = getColor(slice?._vertical?.data?.color);

  return (
    <section className="dark:bg-gray-1">
      <div className="dh-content lg:pb-10">
        {slice.primary?.title && (
          <div className="max-w-3xl mx-auto text-center py-8">
            <h2 className="dh-title">{slice.primary.title}</h2>
          </div>
        )}
        <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
          <dl className="divide-y divide-gray-200">
            {slice.items?.map((item, i) => (
              <Disclosure as="div" key={nanoid()}>
                {() => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className={classNames(
                        'text-left w-full flex items-start space-x-4 space-y-0 text-white bg-gray-900',
                        'py-4 px-4 sm:px-6 lg:rounded-lg lg:px-16',
                      )}
                      >
                        <span
                          className={classNames(
                            'text-2xl font-bold lg:text-4xl lg:font-semibold lg:text-center lg:w-24 lg:mr-8',
                            color.text,
                          )}
                        >
                          {addExtraZeros(i + 1)}
                        </span>
                        <span className="grow self-center text-xs lg:text-lg font-semibold text-white lg:max-w-md">
                          {item.title}
                        </span>
                        <span className="h-7 flex items-center self-center">
                          <SelectorIcon
                            className="h-6 w-6"
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel
                      as="dd"
                      className="p-7 bg-gray-700 lg:rounded-b-lg lg:px-16 lg:py-6"
                    >
                      <p className="text-sm lg:text-lg font-normal text-white">
                        {RichText.asText(item.description)}
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </section>
  );
};

export default FaqAccordionCentered;
