import { RichText } from 'prismic-reactjs';
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import dynamic from 'next/dynamic';
import { getColor } from '../../../utils/colors';

const HighlightText = dynamic(() => import('../../../components/HighlightText'), {
  ssr: false,
});
const ReactCarousel = dynamic(() => import('../../../components/ReactCarousel'), {
  ssr: false,
});

const carouselOptions = {
  isRTL: false,
  breakPoints: [
    {
      width: 1, itemsToShow: 1, itemPadding: [0, 19, 0, 0], outerSpacing: 19,
    },
    {
      width: 880,
      itemsToShow: 3,
      itemPadding: [0, 16, 0, 16],
      pagination: true,
    },
  ],
};

const CarouselWithoutControls = ({ slice }) => {
  const color = getColor(slice?._vertical?.data?.color);
  return (
    <div className="text-center dark:bg-gray-2">
      <div className="dh-content">
        {slice.primary?.title && (
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="dh-title">
              <HighlightText
                text={slice.primary.title}
                highlight={slice.primary?.highlightedText}
                className={classNames(color.textContrast, color.bg)}
              />
            </h2>
          </div>
        )}

        {slice.primary?.description && (
          <p className="dh-description">{slice.primary.description}</p>
        )}

        <div className="my-8">
          <ReactCarousel options={carouselOptions} color={slice?._vertical?.data?.color}>
            {slice.items?.map((item, i) => (
              <div
                key={nanoid()}
                className={classNames(
                  'h-full w-full py-6 px-4 text-center rounded-2xl bg-gray-100 dark:bg-gray-4 xl:py-10 xl:px-6',
                )}
              >
                <div className="space-y-4">
                  {slice.primary?.showNumbers && (
                    <div
                      className={classNames('text-3.5xl font-bold', color.text)}
                    >
                      {i + 1}
                    </div>
                  )}
                  {item.title && (
                    <h4 className="text-xl font-bold uppercase dark:text-athensGray">
                      {item.title}
                    </h4>
                  )}
                  {item.description && (
                    <div className="text-sm font-normal dark:text-white-2 font-archivo">
                      {RichText.render(item.description)}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </ReactCarousel>
        </div>
      </div>
    </div>
  );
};

export default CarouselWithoutControls;
