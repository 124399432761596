import { useState, useEffect } from 'react';
import { Link } from 'prismic-reactjs';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import dynamic from 'next/dynamic';
import {
  DATE_LOCALES,
  getCurrentDateLocale,
  parseDate,
} from '../../../utils/dates';
import {
  getRealLocale, getVerticalByLink, prismicLinkResolver,
} from '../../../utils/common';
import { getColor } from '../../../utils/colors';

const LinkWithQuery = dynamic(() => import('../../../components/LinkWithQuery'), {
  ssr: false,
});
const ReactCarousel = dynamic(() => import('../../../components/ReactCarousel'), {
  ssr: false,
});

const dateFromat = (date: string, language: string): string => {
  const locale = getCurrentDateLocale(DATE_LOCALES, language);

  return parseDate(date, locale, 'd MMM');
};

const getAvailableItems = (items) => items?.filter(({ dateStart }) => {
  const date = new Date(dateStart).getTime();
  const now = new Date().getTime();

  return now <= date;
});

const breakPoints = [
  { width: 1, itemsToShow: 1, itemPadding: [0, 5, 0, 5] },
  { width: 955, itemsToShow: 4, itemPadding: [0, 16, 0, 16] },
];

const CarouselWithDateLight = ({ slice }) => {
  const carouselOptions = { isRTL: false, pagination: true, breakPoints };
  const items = getAvailableItems(slice?.items);
  const [lang, setLang] = useState<string>('es');
  const router = useRouter();

  useEffect(() => {
    if (router?.locale) setLang(getRealLocale(router.locale));
  }, [router]);

  return (
    <div className="bg-white">
      <div className="dh-content pt-5">
        {slice.primary?.title && (
          <h2 className="dh-title">{slice.primary.title}</h2>
        )}

        <div className={classNames({ 'my-8': slice.primary?.title })}>
          {!!items?.length && (
            <ReactCarousel options={carouselOptions}>
              {items.map((item) => {
                const { color: verticalColor, uid: verticalId, clusterId } = getVerticalByLink(
                  item.link,
                  slice._verticals ?? [slice._vertical],
                  slice._clusters ?? [slice._cluster],
                  slice._products ?? [slice._product],
                );
                const color = getColor(verticalColor);

                return (
                  <div
                    className={classNames(
                      'grid w-full h-full py-5 px-8 rounded-2xl border bg-gray-4 dark:bg-white-2',
                      'lg:px-6 xl:px-8 dark:border-none',
                      color.border,
                    )}
                    key={nanoid()}
                  >
                    {item.title && (
                      <h3
                        className={classNames(
                          'font-bold text-white text-lg dark:text-black-2',
                          'lg:min-h-[3.563rem] flex lg:items-center',
                        )}
                      >
                        {item.title}
                      </h3>
                    )}
                    {item.dateStart && (
                      <div className="text-gray-3 text-lg my-2 font-semibold">
                        <span>{dateFromat(item.dateStart, lang)}</span>
                        {item.dateEnd && (
                          <>
                            <span> - </span>
                            <span>{dateFromat(item.dateEnd, lang)}</span>
                          </>
                        )}
                      </div>
                    )}
                    <div className="text-gray-3 text-sm font-normal">
                      {item.days && (
                        <p>{item.days}</p>
                      )}
                      {item.hours && <p className="mt-1">{item.hours}</p>}
                    </div>

                    {item.link && item.linkLabel && (
                      <LinkWithQuery
                        href={Link.url(item.link, (doc) => prismicLinkResolver(doc, { verticalId, clusterId }))}
                        className={classNames(
                          'dh-button-sm mt-4 h-9 self-end text-xs text-athensGray',
                          color.bg,
                          color.bgHover,
                        )}
                      >
                        {item.linkLabel}
                      </LinkWithQuery>
                    )}
                  </div>
                );
              })}
            </ReactCarousel>
          )}
        </div>
      </div>
    </div>
  );
};

export default CarouselWithDateLight;
