import classNames from 'classnames';
import { Link } from 'prismic-reactjs';
import { ArrowNarrowRightIcon } from '@heroicons/react/outline';
import sortBy from 'lodash.sortby';
import dynamic from 'next/dynamic';
import { prismicLinkResolver } from '../../../utils/common';
import { BoxesVariations } from '../Boxes.types';
import { getColor } from '../../../utils/colors';

const LinkWithQuery = dynamic(() => import('../../../components/LinkWithQuery'), {
  ssr: false,
});

const sort = (data) => sortBy(data, (item) => item.data?.priority);

const getClusterLink = (cluster) => ({
  uid: cluster.uid,
  link_type: 'Document',
  type: cluster.type,
  lang: cluster.lang,
  verticalId: cluster?.data?.verticalPage?.uid,
  data: { verticalId: cluster.data.verticalPage.uid },
});

const findVertical = (verticalId: string, verticals) => verticals?.find((vertical) => vertical.uid === verticalId);

const BoxesRectangleClusters = ({ slice }) => {
  const { _clusters: clusters, _verticals: verticals, _vertical: vertical } = slice;

  const filteredClusters = vertical
    ? clusters.filter((item) => item.data?.verticalPage?.uid === vertical.uid)
    : clusters;

  return (
    <section className="bg-white dark:bg-gray-1">
      <div className="dh-content relative space-y-10">
        <div
          className="text-center grid grid-cols-2 gap-5 lg:gap-y-4 lg:gap-x-4 lg:grid-cols-2"
          data-testid="cluster-box"
        >
          {!!filteredClusters?.length && (
            <>
              {sort(filteredClusters).map((cluster, i) => {
                const key = `${BoxesVariations.rectangleClusters}-item-${i}`;
                const itemVerical = vertical || findVertical(cluster?.data?.verticalPage?.uid, verticals);
                const itemColor = getColor(itemVerical?.data?.color);

                return (
                  <div
                    key={key}
                    className={classNames(
                      'w-full rounded-lg', // mobile
                      'lg:w-auto lg:h-auto lg:m-0 lg:overflow-hidden lg:block', // desktop
                    )}
                  >
                    <LinkWithQuery
                      href={Link.url(getClusterLink(cluster), prismicLinkResolver)}
                      className={classNames(
                        'block h-full w-full rounded-lg overflow-hidden text-center break-all cursor-pointer',
                        'lg:flex lg:w-full lg:justify-center lg:items-center lg:font-light lg:p-7 lg:text-xl',
                        'lg:py-4',
                        itemColor.bg,
                        itemColor.bgHover,
                        itemColor.textContrast,
                      )}
                    >
                      <span
                        className={classNames(
                          'text-base flex items-center justify-center h-32 p-2',
                          'lg:grow lg:block lg:w-auto lg:h-auto lg:text-left',
                        )}
                      >
                        <p className="text-center break-normal lg:text-left font-medium">
                          {cluster?.data?.name}
                        </p>
                        <p className="hidden text-sm font-normal text-left lg:inline">
                          {cluster?.data?.description}
                        </p>
                      </span>
                      <ArrowNarrowRightIcon className="hidden w-6 flex-none lg:inline" />
                    </LinkWithQuery>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default BoxesRectangleClusters;
