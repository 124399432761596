import { useCallback, useState } from 'react';
import classNames from 'classnames';
import { Link, RichText } from 'prismic-reactjs';
import { useIntl } from 'react-intl';
import set from 'lodash.set';
import cloneDeep from 'lodash.clonedeep';
import { CheckCircleIcon } from '@heroicons/react/outline';
import { SubmitHandler, useForm } from 'react-hook-form';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { useTrackingPayload } from '../../../hooks/useTrackPayload';
import useLocale from '../../../hooks/useLocale';
import {
  getDefaultText, prismicLinkResolver, slugify,
} from '../../../utils/common';
import { getColor } from '../../../utils/colors';
import { TrackEvents } from '../../../utils/constants';
import { useAnalytics } from '../../../hooks/useAnalytics';

const LinkWithQuery = dynamic(() => import('../../../components/LinkWithQuery'), {
  ssr: false,
});
const Toast = dynamic(() => import('../../../components/Toast'), {
  ssr: false,
});
const FormInput = dynamic(() => import('../../../components/FormInput'), {
  ssr: false,
});
const Icon = dynamic(() => import('../../../components/Icon'), {
  ssr: false,
});

interface ContactForm {
  name: string;
  lastname: string;
  email: string;
  position: string;
  phonecode: string;
  phone: string;
  company: string;
  interestTraining: string;
  date: string;
  tyc: boolean;
  ryp: boolean;
}

const FormContactCompany = ({ slice }) => {
  const { track, identify } = useAnalytics();
  const intl = useIntl();
  const locale = useLocale();
  const [showToast, setShowToast] = useState<boolean>(false);
  const cluster = slice._cluster?.data;
  const vertical = slice._vertical?.data;
  const color = getColor(vertical?.color);
  const {
    register, handleSubmit, reset, formState,
  } = useForm<ContactForm>();
  const trackPayload = useTrackingPayload({ slice, cluster, vertical });
  const showRules = !!(slice.primary.textAgree && slice.primary.label1 && slice.primary.link1?.uid);
  const interestTrainingOptions = slice.primary?.interestTrainingOptions?.map((item) => item?.text);
  const defaultMessages = {
    nameLabel: intl.formatMessage({ defaultMessage: 'Nombre' }),
    lastnameLabel: intl.formatMessage({ defaultMessage: 'Apellido' }),
    companyLabel: intl.formatMessage({ defaultMessage: 'Nombre de empresa' }),
    positionLabel: intl.formatMessage({ defaultMessage: 'Cargo' }),
    emailLabel: intl.formatMessage({ defaultMessage: 'E-mail' }),
    phoneCodeLabel: intl.formatMessage({ defaultMessage: 'Código de Área' }),
    phoneLabel: intl.formatMessage({ defaultMessage: 'Teléfono' }),
    phonePlaceholder: intl.formatMessage({ defaultMessage: '11 0000 0000' }),
    interestTrainingLabel: intl.formatMessage({ defaultMessage: 'Capacitación de interés' }),
    interestTrainingPlaceholder: intl.formatMessage({ defaultMessage: 'Seleccione una opción' }),
    sendButtonLabel: intl.formatMessage({ defaultMessage: 'Enviar' }),
  };

  const onSubmit: SubmitHandler<ContactForm> = useCallback(({
    name, lastname, company, position, email, phonecode, phone, interestTraining,
  }) => {
    const fields = {
      locale_country: locale,
      first_name: name,
      last_name: lastname,
      company,
      company_position: position,
      email,
      phone: `${phonecode}${phone}`,
      interest_training: interestTraining,
    };

    const payload = cloneDeep(trackPayload);
    set(payload, 'fields', fields);

    identify(fields);

    track(TrackEvents.productApplicationSubmitted, payload, locale, true);

    setShowToast(true);
    reset();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackPayload]);

  return (
    <>
      <section className="bg-gray-2 relative" id={slugify(slice.primary?.title ?? '')}>
        {slice.primary.image?.url && (
          <div className="hidden lg:block w-1/2 h-full absolute z-0">
            <div className="bg-black-2 w-full h-full z-10 absolute opacity-90" />
            <Image
              src={slice.primary.image?.url}
              alt={slice.primary.image?.alt}
              layout="fill"
              objectFit="cover"
              quality={70}
              className="object-cover h-full w-full"
            />
          </div>
        )}
        <div className="grid lg:grid-cols-2 relative lg:dh-content">
          <header className="lg:max-w-3xl mx-auto lg:py-20 lg:pr-40 text-left relative">
            {slice.primary.image?.url && (
            <div className="lg:hidden w-full h-full absolute z-0">
              <div className="bg-black-2 w-full h-full z-10 absolute opacity-90" />
              <Image
                src={slice.primary.image?.url}
                alt={slice.primary.image?.alt}
                layout="fill"
                objectFit="cover"
                quality={70}
                className="object-cover h-full w-full"
              />
            </div>
            )}
            <div className="dh-content px-11 lg:p-0 lg:m-0 relative">
              {slice.primary?.title && (
              <>
                <Icon icon="arrow-right-down" className={classNames('w-8 mb-2 block', color.text)} />
                <h2
                  id={slugify(slice.primary.title)}
                  className={classNames('text-2xl pb-2 md:text-5xl text-white border-b inline-block', color.border)}
                >
                  {slice.primary.title}
                </h2>
              </>
              )}
              {slice.primary?.description && (
                <div className={classNames(
                  'dh-description mb-14 text-white text-lg text-center font-normal leading-8',
                  'md:text-left md:text-xl md:leading-9',
                )}
                >
                  <RichText render={slice.primary.description} />
                </div>
              )}
            </div>
          </header>
          <div className="relative w-full overflow-hidden">
            <div className="lg:dh-content relative">
              <div className="bg-gray-900 lg:rounded-2xl px-5 sm:px-10 py-5 lg:max-w-2xl w-full m-auto">
                {slice.primary?.formTitle && (
                <h3
                  id={slugify(slice.primary.formTitle)}
                  className="dh-title mb-4 text-2xl leading-9 font-semibold text-white mx-auto text-center"
                >
                  <Icon icon="arrow-right-down-solid" className={classNames('inline-block w-8 mr-4', color.text)} />
                  {slice.primary.formTitle}
                </h3>
                )}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="sm:rounded-md text-gray-300">
                    <div className="py-5 sm:py-6">
                      <div className="grid sm:grid-cols-2 gap-6">
                        <div>
                          <FormInput
                            darkSupport
                            label={getDefaultText('nameLabel', slice.primary, defaultMessages)}
                            placeholder={slice.primary?.namePlaceholder}
                            id="name"
                            required
                            register={register('name', {
                              required: true,
                            })}
                          />
                        </div>
                        <div>
                          <FormInput
                            darkSupport
                            label={getDefaultText('lastnameLabel', slice.primary, defaultMessages)}
                            placeholder={slice.primary?.lastnamePlaceholder}
                            id="lastname"
                            required
                            register={register('lastname', {
                              required: true,
                            })}
                          />
                        </div>
                        <div>
                          <FormInput
                            darkSupport
                            label={getDefaultText('companyLabel', slice.primary, defaultMessages)}
                            placeholder={slice.primary?.companyPlaceholder}
                            id="company"
                            required
                            register={register('company', {
                              required: true,
                            })}
                          />
                        </div>
                        <div>
                          <FormInput
                            darkSupport
                            label={getDefaultText('positionLabel', slice.primary, defaultMessages)}
                            placeholder={slice.primary?.positionPlaceholder}
                            id="position"
                            required
                            register={register('position', {
                              required: true,
                            })}
                          />
                        </div>
                        <div>
                          <FormInput
                            darkSupport
                            label={getDefaultText('emailLabel', slice.primary, defaultMessages)}
                            placeholder={slice.primary?.emailPlaceholder}
                            id="email"
                            type="email"
                            required
                            register={register('email', {
                              required: true,
                              pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
                            })}
                          />
                        </div>
                        <div className="flex gap-6">
                          <div className="flex-none">
                            <FormInput
                              label={getDefaultText('phoneCodeLabel', slice.primary, defaultMessages)}
                              placeholder={slice.primary?.phoneCodePlaceholder}
                              id="phonecode"
                              className="w-20 lg:w-24"
                              register={register('phonecode', {
                                required: false,
                              })}
                            />
                          </div>
                          <div className="grow">
                            <FormInput
                              darkSupport
                              label={getDefaultText('phoneLabel', slice.primary, defaultMessages)}
                              placeholder={getDefaultText('phonePlaceholder', slice.primary, defaultMessages)}
                              id="phone"
                              register={register('phone', {
                                required: false,
                              })}
                            />
                          </div>
                        </div>
                        <div className="col-span-2">
                          {!!interestTrainingOptions?.length && (
                          <div>
                            <label
                              htmlFor="interestTraining"
                              className="text-white-3 block text-p3 lg:text-p2 font-medium mb-1.5"
                            >
                              {getDefaultText('interestTrainingLabel', slice.primary, defaultMessages)}
                              {!!formState.errors?.interestTraining && (
                              <span className="pl-3 inline-block pointer-events-none align-middle">
                                <ExclamationCircleIcon
                                  className="h-5 w-5 text-red-500"
                                  data-testid="test-error"
                                  aria-hidden="true"
                                />
                              </span>
                              )}
                            </label>
                            <select
                              id="interestTraining"
                              defaultValue=""
                              className={classNames(
                                'rounded-md h-[2.875rem] block w-full shadow-sm p-3 border',
                                'border-white bg-white-2 text-p3',
                                {
                                  'text-gray-1 placeholder-gray-7': !formState.errors?.interestTraining,
                                  'focus:ring-gray-800 focus:border-gray-500': !formState.errors?.interestTraining,
                                  'border-red-300 text-red-500 placeholder-red-500':
                                    !!formState.errors?.interestTraining,
                                  'focus:ring-red-500 focus:border-red-500 focus:outline-none':
                                    !!formState.errors?.interestTraining,
                                },
                              )}
                              required
                              {
                                ...register('interestTraining', {
                                  required: true,
                                })
                              }
                            >
                              <option key="select" value="" disabled>
                                { getDefaultText('interestTrainingPlaceholder', slice.primary, defaultMessages)}
                              </option>
                              {interestTrainingOptions.map((option) => (
                                <option key={`interestTraining_${option}`} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {!!slice.primary?.termsAndConditionsLink?.link_type && (
                    <div className="text-right">
                      <div className="relative flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            id="tyc"
                            aria-describedby="agree-terms-and-conditions"
                            type="checkbox"
                            className="focus:ring-grey-500 h-4 w-4 text-gey-600 border-gray-300 rounded"
                            required
                            {
                            ...register('tyc', {
                              required: true,
                            })
                          }
                          />
                        </div>
                        <div className="ml-3 text-sm font-normal font-archivo">
                          <label
                            htmlFor="tyc"
                            className="mr-2 text-white"
                          >
                            {intl.formatMessage({
                              defaultMessage: 'Aceptar',
                            })}
                          </label>
                          <LinkWithQuery
                            href={Link.url(
                              slice.primary.termsAndConditionsLink,
                              prismicLinkResolver,
                            )}
                            target="_blank"
                            className={classNames('underline', color.text)}
                          >
                            <span className="sr-only">
                              {intl.formatMessage({
                                defaultMessage: 'Aceptar',
                              })}
                              {' '}
                            </span>
                            {intl.formatMessage({
                              defaultMessage: 'Términos y condiciones',
                            })}
                          </LinkWithQuery>
                        </div>
                      </div>
                    </div>
                    )}
                    {showRules && (
                      <div className="py-3 text-right">
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input
                              id="ryp"
                              aria-describedby="agree-terms-and-conditions"
                              type="checkbox"
                              className="focus:ring-grey-500 h-4 w-4 text-gey-600 border-gray-300 rounded"
                              required
                              {
                                ...register('ryp', {
                                  required: true,
                                })
                              }
                            />
                          </div>
                          <div className="ml-3 text-sm font-normal">
                            <label
                              htmlFor="ryp"
                              className="mr-2 text-white"
                            >
                              {slice.primary.textAgree}
                            </label>
                            <LinkWithQuery
                              href={Link.url(
                                slice.primary.link1,
                                prismicLinkResolver,
                              )}
                              target="_blank"
                              className={classNames('underline', color.text)}
                            >
                              <span>
                                {slice.primary.label1}
                                {' '}
                              </span>
                            </LinkWithQuery>
                            <label
                              htmlFor="ryp"
                              className="mx-2 text-white-2"
                            >
                              {slice.primary.textConnector}
                            </label>
                            <LinkWithQuery
                              href={Link.url(
                                slice.primary.link2,
                                prismicLinkResolver,
                              )}
                              target="_blank"
                              className={classNames('underline', color.text)}
                            >
                              <span>
                                {slice.primary.label2}
                                {' '}
                              </span>
                            </LinkWithQuery>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="py-4 flex justify-center">
                      <button
                        type="submit"
                        className={classNames(
                          'dh-button py-2 text-sm w-48 max-w-full',
                          color.bg,
                          color.bgHover,
                          color.textContrast,
                        )}
                      >
                        {getDefaultText('sendButtonLabel', slice.primary, defaultMessages)}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>

          </div>
        </div>
      </section>
      <Toast
        show={showToast}
        setShow={setShowToast}
        icon={CheckCircleIcon}
        title={intl.formatMessage({
          defaultMessage: 'Gracias',
        })}
        message={intl.formatMessage({
          defaultMessage: 'Te contactaremos a la brevedad para asesorarte y acompañarte '
            + 'en la transformación que comenzaste hoy.',
        })}
      />
    </>
  );
};

export default FormContactCompany;
