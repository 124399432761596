import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { getAllowedQueryString } from '../utils/analytics';

export const useLinkResolver = (link = ''): string => {
  const [url, setUrl] = useState(link);
  const [pathParams, setPathParams] = useState({});
  const router = useRouter();
  const href = global?.location?.href;
  const host = global?.location?.host;

  useEffect(() => {
    setPathParams(router?.query);
  }, [router]);

  useEffect(() => {
    const allowedQueryString = getAllowedQueryString(pathParams);
    const linkWithQuery = new URLSearchParams(allowedQueryString).toString();

    // relative path
    if (link && link[0] === '/') {
      setUrl(`${link}${linkWithQuery ? `?${linkWithQuery}` : ''}`);
    } else if (href && link) {
      const siteUrl = new URL(href);
      const linkUrl = new URL(link);

      if (
        siteUrl.hostname === linkUrl.hostname
        || linkUrl.hostname.indexOf(host) >= 0
      ) {
        linkUrl.search = linkWithQuery;
        setUrl(linkUrl.toString());
      }
    }
  }, [pathParams]); // eslint-disable-line react-hooks/exhaustive-deps

  return url;
};
