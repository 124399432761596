import { CardsVariations } from './Cards.types';
import CardsProducts from './variations/CardsProducts';

const GridLists = ({ slice }) => {
  switch (slice.variation) {
    case CardsVariations.products:
      return <CardsProducts slice={slice} />;
    default:
      return null;
  }
};

export default GridLists;
