import Image from 'next/image';

const videoPreview = {
  url: '',
  dimensions: {
    width: 490,
    height: 300,
  },
};

const getYouTubeKey = (url = ''): string => {
  const regex = /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*?[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/gi;
  const [, key] = regex.exec(url) || [];

  return key;
};

export const getPreviewProps = (url?: string) => {
  if (!url) return {};
  const videoKey = getYouTubeKey(url);

  const preview = { ...videoPreview, url: `https://i.ytimg.com/vi/${videoKey}/maxresdefault.jpg` };
  const video = { url: `https://www.youtube.com/embed/${videoKey}?autoplay=1&mute=0&enablejsapi=1` };

  return {
    preview,
    video,
  };
};

const PlayIcon = ({ play }) => (
  <button
    type="button"
    className="absolute w-full h-full flex justify-center items-center cursor-pointer z-10"
    onClick={play}
  >
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer"
    >
      <circle cx="40" cy="40" r="40" fill="#FCFCFE" />
      {/* eslint-disable-next-line max-len */}
      <path d="M53.4922 36.0158C56.1589 37.5554 56.1589 41.4044 53.4922 42.944L37.4922 52.1816C34.8255 53.7212 31.4922 51.7967 31.4922 48.7175L31.4922 30.2423C31.4922 27.1631 34.8255 25.2386 37.4922 26.7782L53.4922 36.0158Z" fill="#0F0F11" />
    </svg>
  </button>
);

const ImagePreview = ({ image, play }) => (image?.url ? (
  <div className="aspect-video relative">
    <PlayIcon play={play} />
    <Image
      src={image?.url}
      alt={image?.alt}
      width={image?.dimensions?.width || '490'}
      height={image?.dimensions?.height || '310'}
      layout="intrinsic"
      className="rounded-2xl aspect-video"
    />
  </div>
) : null);

export default ImagePreview;
