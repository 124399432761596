import { VideoVariations } from './Video.types';
import VideoSimpleYouTube from './variations/VideoSimpleYouTube';
import VideoYTTwoCols from './variations/VideoYTTwoCols';
import VideoMP4 from './variations/VideoMP4';
import VideoCarouselWithModal from './variations/VideoCarouselWithModal';

const Form = ({ slice }) => {
  switch (slice.variation) {
    case VideoVariations.youtubeTwoCols:
      return <VideoYTTwoCols slice={slice} />;
    case VideoVariations.simpleYoutube:
      return <VideoSimpleYouTube slice={slice} />;
    case VideoVariations.mp4:
      return <VideoMP4 slice={slice} />;
    case VideoVariations.carouselWithModal:
      return <VideoCarouselWithModal slice={slice} />;
    default:
      return <div />;
  }
};

export default Form;
