import { Link, RichTextBlock } from 'prismic-reactjs';
import { SliceCheckout, Image } from 'types/prismic.type';

export enum EPricingVariations {
  pricingCards = 'pricingCards',
}

export type IPricingCards = SliceCheckout & {
  slice: {
    primary: {
      isAsync?: boolean;
      title?: string;
      description?: RichTextBlock[];
      checkoutButtonLabel?: string;
      checkoutLastPlacesLabel?: string;
      checkoutSoldoutLabel?: string;
      color?: string;
      titleAlt?:string;
      urlAlt?:Link;
      btnLabelAlt?:string;
      image?:Image
    }
    items?: {
      featured?: boolean;
      checkoutVersion?: 'new' | 'legacy';
      installments?: number;
      title?: string;
      description?: RichTextBlock[];
    }[];
  }
}
