import { Link, RichTextBlock } from 'prismic-reactjs';
import { Image, Slice } from '../../types/prismic.type';

export enum ContentVariations {
  withOneColumn = 'contentWithOneColumn',
  withTwoColumns = 'contentWithTwoColumns',
  withTwoColumnsLight = 'contentWithTwoColumnsLight',
  withTwoAlternative = 'contentWithTwoAlternative',
  withLogoCloud = 'contentWithLogoCloud',
  withTwoSections = 'contentWithTwoSections',
  withList = 'contentWithList',
  withLogo = 'contentWithLogo',
  withButton = 'contentWithButton',
  withButtonCentered = 'contentWithButtonCentered',
  withImage = 'contentWithImage',
  withSteps = 'contentWithSteps',
  withOnlyTextDark = 'contentWithOnlyTextDark',
  withOnlyTextFull = 'contentWithOnlyTextFull',
  descriptiveBox = 'contentDescriptiveBox',
  checkList = 'contentCheckList',
  checkListImage = 'contentCheckListImage',
}

export type ContentProps = Slice & {
  slice: {
    primary?: {
      title?: string;
      description?: RichTextBlock[];
    }
  }
}

export type ContentWithStepsProps = ContentProps & {
  slice: {
    items?: {
      stepLabel?: string
    }[]
  }
}

export type ContentWithOnlyTextProps = Slice & {
  slice: {
    primary?: {
      description?: RichTextBlock[];
    }
  },
  fullWidth?: boolean;
  centered?:boolean;
  opacity?: boolean;
}

export type ContentCheckListProps = Slice & {
  slice:{
    primary?: {
      title?: string;
      description?: RichTextBlock[];
      pill?: string;
      checklistColor?: string;
    },
    items?: {
      checkListText?: string;
    }[]
  }
}

export type ContentCheckListImageProps = Slice & {
  slice:{
    primary?: {
      title?: string;
      description?: RichTextBlock[];
      image?: Image;
      backgroundColor?: string;
      checkListColor?: string;
    },
    items?: {
      checkListText?: string;
    }[]
  }
}

export type ContentWithButtonCenteredProps = Slice & {
  slice: {
    primary: {
      description?: RichTextBlock[];
      buttonLabel?: string;
      buttonScrollTo?: string;
      buttonLink?: Link;
    }
  }
}
