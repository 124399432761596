export enum HeroVariations {
  cardBackgroundImage = 'heroCardBackgroundImage',
  left = 'heroLeft',
  leftWithBtnDownload = 'heroLeftWithBtnDownload',
  leftWithImageTitle = 'heroLeftWithImageTitle',
  leftProduct = 'heroLeftProduct',
  onlyImage = 'heroOnlyImage',
  imageSectionText = 'heroImageSectionText',
  imageTitleButton = 'heroImageTitleButton',
  carousel = 'heroCarousel',
  ctd = 'heroCtd',
  withPills = 'heroWithPills',
}
