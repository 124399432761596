import classNames from 'classnames';

export const PicDiamonds = ({ className }) => (
  <svg
    width="58"
    height="162"
    viewBox="0 0 58 162"
    className={classNames('right-11 bottom-6 lg:right-16 w-11 h-32 2xl:w-14 2xl:h-40', className)}
  >
    <g opacity="0.2" clipPath="url(#clip0_39_3784)">
      {/* eslint-disable-next-line max-len */}
      <path d="M46.112 0C46.112 0 45.8078 19.04 34.3214 19.04C45.8078 19.04 46.112 38.08 46.112 38.08C46.112 38.08 46.4162 19.04 57.9027 19.04C46.4162 19.04 46.112 0 46.112 0Z" fill="currentColor" />
      {/* eslint-disable-next-line max-len */}
      <path d="M28.3685 72.99C28.3685 72.99 27.6613 117.25 0.955566 117.25C27.6613 117.25 28.3685 161.51 28.3685 161.51C28.3685 161.51 29.0756 117.25 55.7813 117.25C29.0756 117.25 28.3685 72.99 28.3685 72.99Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_39_3784">
        <rect width="56.9553" height="161.51" fill="white" transform="translate(0.955566)" />
      </clipPath>
    </defs>
  </svg>
);

export const PicCircleRectangleOutline = ({ className }) => (
  <svg
    width="274"
    height="182"
    viewBox="0 0 274 182"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames('right-0 bottom-0 h-36 w-52 2xl:w-64 2xl:h-44', className)}
  >
    {/* eslint-disable-next-line max-len */}
    <path opacity="0.2" d="M229 66C247.225 66 262 51.2254 262 33C262 14.7746 247.225 0 229 0C210.775 0 196 14.7746 196 33C196 51.2254 210.775 66 229 66Z" fill="currentColor" />
    <g opacity="0.2" clipPath="url(#clip0_1_2)">
      {/* eslint-disable-next-line max-len */}
      <path d="M332.79 119.162V207.838H21.7464C11.8033 207.838 3.7214 199.212 3.7214 188.618V138.382C3.7214 127.78 11.8108 119.162 21.7464 119.162H332.79ZM335.756 116H21.7464C10.1499 116 0.755554 126.025 0.755554 138.382V188.618C0.755554 200.983 10.1573 211 21.7464 211H335.756V116Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_1_2">
        <rect width="272.978" height="66" fill="white" transform="translate(0.755554 116)" />
      </clipPath>
    </defs>
  </svg>
);

export const PicDiamondRectangle = ({ className }) => (
  <svg
    width="259"
    height="180"
    viewBox="0 0 259 180"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames('right-8 bottom-0 lg:right-12 w-52 h-36 2xl:w-64 2xl:h-44', className)}
  >
    <path opacity="0.2" d="M206.4 147H0.844482V180H206.4V147Z" fill="currentColor" />
    {/* eslint-disable-next-line max-len */}
    <path opacity="0.2" d="M239.462 0C239.462 0 238.968 30.82 220.378 30.82C238.968 30.82 239.462 61.64 239.462 61.64C239.462 61.64 239.955 30.82 258.545 30.82C239.955 30.82 239.462 0 239.462 0Z" fill="currentColor" />
  </svg>
);

export const PicDiamondCircle = ({ className }) => (
  <svg
    width="120"
    height="137"
    viewBox="0 0 120 137"
    className={classNames('right-8 bottom-8 w-24 h-24 2xl:w-28 2xl:h-32', className)}
  >
    {/* eslint-disable-next-line max-len */}
    <path opacity="0.2" d="M33 137C51.2254 137 66 122.225 66 104C66 85.7746 51.2254 71 33 71C14.7746 71 0 85.7746 0 104C0 122.225 14.7746 137 33 137Z" fill="currentColor" />
    {/* eslint-disable-next-line max-len */}
    <path opacity="0.2" d="M107.302 0C107.302 0 106.998 19.04 95.5111 19.04C106.998 19.04 107.302 38.08 107.302 38.08C107.302 38.08 107.606 19.04 119.092 19.04C107.606 19.04 107.302 0 107.302 0Z" fill="currentColor" />
  </svg>
);

export const PicDiamondRectangleOutline = ({ className }) => (
  <svg
    width="274"
    height="182"
    viewBox="0 0 274 182"
    className={classNames('right-0 bottom-0 w-52 h-36 2xl:w-72 2xl:h-44', className)}
  >
    <g opacity="0.2" clipPath="url(#clip0_1_2)">
      {/* eslint-disable-next-line max-len */}
      <path d="M332.745 119.162V207.838H21.7019C11.7589 207.838 3.67697 199.212 3.67697 188.618V138.382C3.67697 127.78 11.7663 119.162 21.7019 119.162H332.745ZM335.711 116H21.7019C10.1054 116 0.711121 126.025 0.711121 138.382V188.618C0.711121 200.983 10.1129 211 21.7019 211H335.711V116Z" fill="currentColor" />
    </g>
    {/* eslint-disable-next-line max-len */}
    <path opacity="0.2" d="M209.728 0C209.728 0 209.235 30.82 190.644 30.82C209.235 30.82 209.728 61.64 209.728 61.64C209.728 61.64 210.222 30.82 228.812 30.82C210.222 30.82 209.728 0 209.728 0Z" fill="currentColor" />
    <defs>
      <clipPath id="clip0_1_2">
        <rect width="272.978" height="66" fill="white" transform="translate(0.711121 116)" />
      </clipPath>
    </defs>
  </svg>
);
