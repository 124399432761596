import { nanoid } from 'nanoid';
import { RichText } from 'prismic-reactjs';
import { FunctionComponent, useRef } from 'react';
import classNames from 'classnames';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import { getColor } from '../../../utils/colors';
import useLocale from '../../../hooks/useLocale';
import useOnScreen from '../../../hooks/useOnScreen';
import { StatsCounterWithIconsProps } from '../Stats.types';

const AnimatedCounter = dynamic(() => import('../../../components/AnimatedCounter'), {
  ssr: false,
});

const StatsCounterWithIcons: FunctionComponent<StatsCounterWithIconsProps> = ({ slice }) => {
  const { items } = slice;
  const size = items?.length;
  const ref = useRef<HTMLDivElement>(null);
  const onScreen = useOnScreen(ref, '0px');
  const locale = useLocale();
  const verticalColor = getColor(slice.primary?.backgroundColor, 600);
  const color = (slice.primary?.backgroundColor === 'grey') ? 'bg-gray-3' : verticalColor.bg;
  const hasOneCard = size === 1;
  const hasTwoCards = size === 2;
  const hasFourCards = size === 4;
  const hasFiveCards = size === 5;

  const formatNumber = (number: number): string => new Intl
    .NumberFormat(locale)
    .format(number);

  return size ? (
    <section ref={ref} className={classNames(color, 'px-4 py-12 lg:dh-content-full text-white-2')}>
      <div className={classNames(
        'flex flex-col font-bold text-center gap-8',
        'lg:flex lg:flex-row lg:w-10/12 lg:mx-auto lg:flex-wrap lg:p-0 md:justify-between lg:max-w-7xl lg:m-auto',
        'lg:gap-x-16 lg:gap-y-15 2xl:gap-y-[5rem] 2xl:gap-x-[4.875rem]',
        '2xl:w-full 2xl:justify-center 2xl:max-w-8xl 3xl:w-full',
        {
          'lg:justify-center': hasOneCard,
          'lg:w-7/12 lg:gap-x-16 lg:justify-evenly 2xl:w-7/12 2xl:justify-evenly': hasFourCards,
          'lg:justify-center lg:gap-x-16 2xl:gap-x-[4.875rem] 3xl:gap-x-[5.688rem] 2xl:w-10/12':
          hasFiveCards || hasTwoCards,
        },
      )}
      >
        {
            items?.map(
              ({
                number, description, prefix, suffix, icon,
              }) => (
                <div
                  className={classNames(
                    'justify-center self-baseline flex flex-col text-center items-center w-full md:w-3/12',
                    {
                      'lg:w-3/7 2xl:w-3/7': hasFourCards,
                      '3xl:w-3/10': hasTwoCards,
                    },
                  )}
                  key={nanoid()}
                >
                  { icon?.url && (
                  <div className=" block text-center mb-4 lg:mb-6
                    w-[4.75rem] 2xl:w-[5.25rem] 3xl:w-[6.25rem]"
                  >
                    <Image
                      width="100%"
                      height="100%"
                      src={icon?.url}
                      alt={icon?.alt}
                    />
                  </div>
                  )}
                  <div className="whitespace-nowrap overflow-hidden text-ellipsis max-w-full
                  text-h4 leading-9 font-bold break-words
                  lg:text-h2 lg:leading-[3.25rem]
                  3xl:text-5xl 3xl:leading-[3.875rem]"
                  >
                    {prefix}
                    <AnimatedCounter
                      number={number || 0}
                      duration={2000}
                      onScreen={onScreen}
                      formatNumber={formatNumber}
                    />
                    {suffix}
                  </div>
                  <span className="text-sm w-auto leading-5 pt-0.5 font-normal text-center inline-block
                   lg:font-medium lg:text-lg lg:leading-6
                   3xl:text-2xl 3xl:leading-8 "
                  >
                    <RichText render={description} />
                  </span>
                </div>
              ),
            )
          }
      </div>
    </section>
  ) : null;
};

export default StatsCounterWithIcons;
