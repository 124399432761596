import classnames from 'classnames';
import { Link, RichText } from 'prismic-reactjs';
import { nanoid } from 'nanoid';
import Image from 'next/image';
import dynamic from 'next/dynamic';

const ReactCarousel = dynamic(() => import('../../../components/ReactCarousel'), {
  ssr: false,
});

const breakPoints = [
  {
    width: 1, itemsToShow: 1, itemPadding: [0, 5, 0, 5], showArrows: false,
  },
  {
    width: 955, itemsToShow: 3, itemPadding: [0, 32, 0, 32], showArrows: true,
  },
];

const carouselOptions = {
  isRTL: false,
  pagination: true,
  breakPoints,
};

const CarouselWithHeaderImage = ({ slice }) => {
  const itemsLength = slice.items?.length;

  return itemsLength ? (
    <section className="bg-gray-1">
      <div className="dh-content">
        {slice.primary?.title && (
          <header className="max-w-3xl mx-auto text-center mb-16">
            <h2 className="dh-title text-3.5xl text-athensGray">{slice.primary.title}</h2>
          </header>
        )}
        <ReactCarousel options={carouselOptions}>
          {slice.items.map((item) => (
            <a
              key={nanoid()}
              href={Link.url(item.link)}
              target={item.link?.target}
              className={classnames(
                'w-full h-full relative bg-athensGray rounded-lg overflow-hidden',
                {
                  'cursor-pointer': item.link?.url,
                  'pointer-events-none': !item.link?.url,
                },
              )}
            >

              <div>
                {item.headerImage?.url && (
                  <div className="relative w-full h-32">
                    <Image
                      src={item.headerImage.url}
                      alt={item.headerImage.alt}
                      layout="fill"
                      objectFit="cover"
                    />
                  </div>
                )}

                <div>
                  <div className="font-medium text-lg leading-6 space-y-5 pt-5 pb-7 px-7">

                    {item.titleImage?.url && (
                      <div className="relative w-full h-10">
                        <Image
                          src={item.titleImage.url}
                          alt={item.titleImage.alt}
                          layout="fill"
                          objectFit="contain"
                          objectPosition="left"
                        />
                      </div>
                    )}

                    {item.description && (
                      <div className="text-sm leading-6 text-black-2 font-archivo">
                        {RichText.render(item.description)}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </a>
          ))}
        </ReactCarousel>
      </div>
    </section>
  ) : null;
};

export default CarouselWithHeaderImage;
