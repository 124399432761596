import { HeaderVariations } from './Header.types';
import HeaderCentered from './variations/HeaderCentered';
import HeaderCenteredSmall from './variations/HeaderCenteredSmall';
import HeaderLeft from './variations/HeaderLeft';
import HeaderInner from './variations/HeaderInner';

const Header = ({ slice }) => {
  switch (slice.variation) {
    case HeaderVariations.centered:
      return <HeaderCentered slice={slice} />;
    case HeaderVariations.centeredSmall:
      return <HeaderCenteredSmall slice={slice} />;
    case HeaderVariations.left:
      return <HeaderLeft slice={slice} />;
    case HeaderVariations.leftDark:
      return <HeaderLeft slice={slice} />;
    case HeaderVariations.inner:
      return <HeaderInner slice={slice} />;
    default:
      return null;
  }
};

export default Header;
