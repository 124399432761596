import classNames from 'classnames';
import Image from 'next/image';
import { FunctionComponent, useState } from 'react';
import LazyLoad from 'react-lazyload';
import { getColor } from '../../../utils/colors';
import { VideoMP4Props } from '../Video.types';

const PlayIcon = ({ play }) => (
  <button type="button" className="absolute w-full h-full flex justify-center items-center z-10" onClick={() => play()}>
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="40" cy="40" r="40" fill="#FCFCFE" />
      {/* eslint-disable-next-line max-len */}
      <path d="M53.4922 36.0158C56.1589 37.5554 56.1589 41.4044 53.4922 42.944L37.4922 52.1816C34.8255 53.7212 31.4922 51.7967 31.4922 48.7175L31.4922 30.2423C31.4922 27.1631 34.8255 25.2386 37.4922 26.7782L53.4922 36.0158Z" fill="#0F0F11" />
    </svg>
  </button>
);

const ImagePreview = ({ image, playing, play }) => (image?.url && !playing ? (
  <div className="w-full relative">
    <PlayIcon play={play} />
    <Image
      src={image?.url}
      alt={image?.alt}
      width={image?.dimensions?.width || '100%'}
      height={image?.dimensions?.height || '100%'}
      layout="intrinsic"
      className="rounded"
    />
  </div>
) : null);

const Video = ({ video, playing }) => (video.url && playing ? (
  <video
    className="w-full h-full overflow-hidden rounded"
    preload="none"
    playsInline
    autoPlay
    controls
  >
    <source src={video.url} type={video.type} />
    Your browser does not support the video tag.
  </video>
) : null);

enum VideoDevice {
  mobile, desktop
}

const getBgColor = (color) => {
  if (!color) return 'bg-white-1';

  switch (color) {
    case 'black':
      return 'bg-black-2';
    case 'white':
      return 'bg-white-1';
    default:
      return getColor(color, 600).bg;
  }
};

const VideoMP4: FunctionComponent<VideoMP4Props> = ({ slice }) => {
  const [playVideo, setPlayVideo] = useState<number | null>(null);
  const hasVideoMobile = !!(slice.primary?.imagePreviewMobile?.url && slice.primary?.videoMobile?.url);
  const hasVideoDesktop = !!(slice.primary?.imagePreviewDesktop?.url && slice.primary?.videoDesktop?.url);
  const bgTop = getBgColor(slice.primary?.backgroundTop);
  const bgBottom = getBgColor(slice.primary?.backgroundBottom);

  return (
    <section className={classNames('relative', bgTop)}>
      <div className={classNames('absolute bottom-0 w-full h-1/6 -mb-[1px]', bgBottom)} />
      <div className="dh-content-full flex justify-center items-center 3xl:w-8/12 lg:w-10/12 m-auto relative z-0
      lg:m-auto lg:max-w-7xl 2xl:max-w-8xl 3xl:max-w-[74rem]"
      >
        <LazyLoad>
          {hasVideoMobile && (
          <div className="lg:hidden">
            <ImagePreview
              image={slice.primary?.imagePreviewMobile}
              playing={playVideo === VideoDevice.mobile}
              play={() => setPlayVideo(VideoDevice.mobile)}
            />
            <Video
              video={slice.primary?.videoMobile}
              playing={playVideo === VideoDevice.mobile}
            />
          </div>
          )}
          {hasVideoDesktop && (
          <div className="hidden lg:block">
            <ImagePreview
              image={slice.primary?.imagePreviewDesktop}
              playing={playVideo === VideoDevice.desktop}
              play={() => setPlayVideo(VideoDevice.desktop)}
            />
            <Video
              video={slice.primary?.videoDesktop}
              playing={playVideo === VideoDevice.desktop}
            />
          </div>
          )}
        </LazyLoad>
      </div>
    </section>
  );
};

export default VideoMP4;
