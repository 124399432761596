import classNames from 'classnames';
import { nanoid } from 'nanoid';
import Image from 'next/image';
import { RichText } from 'prismic-reactjs';
import { getColor } from '../../../utils/colors';

const BoxesKPI = ({ slice }) => {
  if (!slice.items?.length) return null;

  return (
    <section className="dh-content-full bg-gray-2 py-20 lg:py-24 2xl:py-40 3xl:py-52">
      <div className="lg:w-10/12 flex flex-col mx-auto 2xl:w-full 2xl:flex-row 2xl:gap-[8.33%]">
        <header className="2xl:w-3/12 text-white-1 flex flex-col justify-center mb-6 lg:mb-14">
          <h2 className="text-2xl leading-7 lg:text-4.5xl lg:leading-10 lg:mb-2 2xl:mb-4">
            <RichText render={slice.primary.title} />
          </h2>
          <div className="text-sm lg:text-xl lg:leading-8">
            <RichText render={slice.primary.description} />
          </div>
        </header>
        <div className="grid gap-3 lg:grid-cols-2 lg:gap-4 2xl:w-8/12 3xl:grid-cols-4">
          {slice.items.map((item) => {
            const color = getColor(item.color, 400);

            return (
              <div
                key={nanoid()}
                className={classNames(
                  'border flex flex-row overflow-hidden p-8 3xl:flex-col gap-4 rounded',
                  color.border,
                )}
              >
                <div className="w-6/12 lg:w-4/12 flex items-center justify-center 3xl:w-full">
                  <div className="w-32 lg:w-36 opacity-80">
                    <Image
                      src={item.image.url}
                      alt={item.image.alt}
                      width={item.image.dimensions.width}
                      height={item.image.dimensions.height}
                      layout="responsive"
                      objectFit="cover"
                    />
                  </div>
                </div>
                <div className="grow items-center justify-center flex flex-col">
                  <div className="font-bold text-3.5xl text-center text-white-1 lg:mb-2 lg:text-7xl">
                    {item.value}
                  </div>
                  <div className="text-sm lg:text-lg text-center text-white-4">
                    {item.label}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default BoxesKPI;
