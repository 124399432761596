import { FC, useCallback, useContext } from 'react';
import { RichText } from 'prismic-reactjs';
import { CheckCircleIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import { useIntl } from 'react-intl';
import sortBy from 'lodash.sortby';
import set from 'lodash.set';
import cloneDeep from 'lodash.clonedeep';
import Image from 'next/image';
import { ProductActionTypes } from '../../../contexts/reducers/product.reducer';
import { ProductDocument } from '../../../types/product.type';
import { AppContext } from '../../../contexts/AppContext';
import { useTrackingPayload } from '../../../hooks/useTrackPayload';
import { getDefaultText, prismicLinkResolver, scrollTo } from '../../../utils/common';
import useLocale from '../../../hooks/useLocale';
import { hasRichText } from '../../../utils/prismic';
import { getColor } from '../../../utils/colors';
import { GridListsProductsProps } from '../GridLists.types';
import {
  DATE_LOCALES, getCurrentDateLocale, parseDate,
} from '../../../utils/dates';
import { NextStepFlow, TrackEvents } from '../../../utils/constants';
import { useAnalytics } from '../../../hooks/useAnalytics';

const getAvailableItems = (items?: ProductDocument[], clusterId?: string) => {
  const itemsPage = items
    ?.filter(({ data }) => data?.clusterPage?.id === clusterId)
    ?.filter(({ data }) => data.startDate && (new Date(data.startDate).getTime() >= new Date().getTime()));
  return itemsPage;
};

const dateFormat = (date: string, language: string, format: string): string => parseDate(
  date,
  getCurrentDateLocale(DATE_LOCALES, language),
  format,
);

const GridListsProducts: FC<GridListsProductsProps> = ({ slice }) => {
  const vertical = slice._vertical?.data;
  const cluster = slice._cluster?.data;
  const availableProducts = getAvailableItems(slice._products, slice._cluster?.id);
  const products = sortBy(availableProducts, (item) => item.data.startDate);
  const { dispatch } = useContext(AppContext);
  const { track } = useAnalytics();
  const intl = useIntl();
  const locale = useLocale();
  const trackPayload = useTrackingPayload({ slice, cluster, vertical });

  const handleClick = useCallback((item: ProductDocument): void => {
    const payload = cloneDeep(trackPayload);
    set(payload, 'context.label', item.data.linkDetailLabel);
    set(payload, 'context.next_step', NextStepFlow.advice);
    set(payload, 'context.destination_url', prismicLinkResolver(item));
    set(payload, 'product.start_date', item.data.startDate);
    set(payload, 'product.end_date', item.data.endDate);

    track(TrackEvents.productApplicationClicked, payload, locale);

    dispatch({ type: ProductActionTypes.select, item });

    if (slice.primary.buttonScrollTo) scrollTo({ id: slice.primary.buttonScrollTo });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackPayload]);

  if (!products.length) return null;

  const color = getColor(slice.primary.color, 500);
  const primaryMessages = slice.primary as { [key: string]: string };
  const defaultMessages = {
    dateFormat: intl.formatMessage({ defaultMessage: 'dd/MM/yy' }),
    buttonBookLabel: intl.formatMessage({ defaultMessage: 'Reservar' }),
    lastplaceLabel: intl.formatMessage({ defaultMessage: '¡Ultimos cupos!' }),
    buttonSoldoutLabel: intl.formatMessage({ defaultMessage: 'Sold out' }),
  };

  return (
    <section className="bg-white-1 py-15 px-0 lg:p-4 lg:py-20 3xl:py-20">
      <div className="lg:w-10/12 m-auto lg:max-w-7xl 2xl:max-w-8xl">
        {slice.primary.title
        && (
        <p className="text-black-1 font-bold px-4 lg:px-0 pb-1.5 text-h4
        lg:pb-4 lg:text-h2 3xl:text-h1 3xl:max-w-8xl"
        >
          {slice.primary.title}
        </p>
        )}
        {
          hasRichText(slice.primary.description)
          && (
            <div className="text-gray-1 font-medium text-p3 px-4 lg:px-0  3xl:text-p2 3xl:max-w-8xl">
              <RichText render={slice.primary.description} />
            </div>
          )
        }
        <div>
          <div className="pt-4 lg:pt-10 lg:grid 3xl:max-w-8xl">
            <div className="hidden gap-4 lg:flex lg:justify-between">
              <div className={classNames(
                'flex gap-3 pb-3.5 border-b-2 items-center',
                '3xl:border-b-3 lg:w-2/10',
                color.border,
              )}
              >
                {slice.primary.dateIcon?.url
                  && (
                    <div className="h-6 w-6 3xl:w-10 3xl:h-10 relative">
                      <Image
                        className="h-6 w-6 3xl:w-10 3xl:h-10 relative"
                        src={slice.primary.dateIcon.url}
                        alt={slice.primary.dateIcon.alt}
                        layout="fill"
                        objectFit="cover"
                      />
                    </div>
                  )}
                <p className="text-gray-1 font-medium text-p1 3xl:text-h5">{slice?.primary.dateText}</p>
              </div>
              <div className={classNames(
                'flex gap-3 pb-3.5 border-b-2 items-center',
                '3xl:gap-4 3xl:border-b-3 lg:w-2/10',
                color.border,
              )}
              >
                {slice.primary.scheduleDaysIcon?.url
                  && (
                    <div className="h-6 w-6 3xl:w-10 3xl:h-10 relative">
                      <Image
                        className="h-6 w-6 3xl:w-10 3xl:h-10 relative"
                        src={slice.primary.scheduleDaysIcon.url}
                        alt={slice.primary.scheduleDaysIcon.alt}
                        layout="fill"
                        objectFit="cover"
                      />
                    </div>
                  )}
                <p className="text-gray-1 font-medium text-p1 3xl:text-h5">{slice?.primary.scheduleDaysText}</p>
              </div>
              <div className={classNames(
                'flex gap-3 pb-3.5 border-b-2 items-center',
                '3xl:gap-4 3xl:border-b-3 lg:w-2/10',
                color.border,
              )}
              >
                {slice.primary.scheduleHoursIcon?.url
                  && (
                    <div className="h-6 w-6 3xl:w-10 3xl:h-10 relative">
                      <Image
                        className="h-6 w-6 3xl:w-10 3xl:h-10 relative"
                        src={slice.primary.scheduleHoursIcon.url}
                        alt={slice.primary.scheduleHoursIcon.alt}
                        layout="fill"
                        objectFit="cover"
                      />
                    </div>
                  )}
                <p className="text-gray-1 font-medium text-p1 3xl:text-h5">{slice?.primary.scheduleHoursText}</p>
              </div>
              <div className="w-2/10" />
            </div>
            <div className={classNames('lg:hidden border-t-2 w-4/6 m-auto', color.border)} />
            {products?.map((item) => (
              <div
                key={nanoid()}
                className={classNames(
                  'border-b border-white-4 lg:border-none py-6 lg:p-0',
                  { 'pb-2.5': item.data.lastplaces },
                )}
              >
                <div className={classNames(
                  'grid grid-cols-2 px-4 items-start gap-4 lg:px-0',
                  'lg:flex lg:flex-row lg:py-8 lg:items-center lg:border-b lg:border-white-4 lg:justify-between',
                )}
                >
                  <div className="flex items-center lg:w-2/10">
                    <CheckCircleIcon className={classNames(
                      'w-6 lg:w-5 flex-none mr-1.5 lg:hidden 3xl:w-6',
                      color.text,
                    )}
                    />
                    <div>
                      <p className="text-gray-6 text-p4 font-medium lg:hidden">{slice.primary.dateText}</p>
                      <p className="text-gray-1 w-full text-p3 font-bold 3xl:text-p1">
                        {dateFormat(item.data.startDate as string, locale, defaultMessages.dateFormat)}
                        {' - '}
                        {dateFormat(item.data.endDate as string, locale, defaultMessages.dateFormat)}
                      </p>
                    </div>
                  </div>
                  <div className="lg:w-2/10">
                    <p className=" text-gray-6 text-p4 font-medium lg:hidden">{slice.primary.scheduleDaysText}</p>
                    <p className="text-gray-1 text-p3 font-bold 3xl:text-p1">
                      {item.data.scheduleDays}
                    </p>
                  </div>
                  <div className="col-span-2  pl-7.5 lg:pl-0 lg:col-span-1 lg:w-2/10">
                    <p className=" text-gray-6 text-p4 font-medium lg:hidden">{slice.primary.scheduleHoursText}</p>
                    <p className="text-gray-1 text-p3 font-bold 3xl:text-p1">
                      {item.data.scheduleHours}
                    </p>
                  </div>
                  <div className="relative col-span-2 lg:col-span-1 lg:flex lg:w-2/10">
                    <button
                      type="button"
                      onClick={() => handleClick(item)}
                      className={classNames(
                        'w-full dh-btn font-bold self-stretch text-p3 lg:text-p2 p-3 3xl:text-lg 3xl:p-4',
                        { 'bg-black-1 text-white-1 ': !item.data.soldout },
                        { 'pointer-events-none bg-transparent text-gray-6 border-2 border-gray-6': item.data.soldout },
                      )}
                    >
                      {
                      item.data.soldout
                        ? getDefaultText('buttonSoldoutLabel', primaryMessages, defaultMessages)
                        : getDefaultText('buttonBookLabel', primaryMessages, defaultMessages)
                      }
                    </button>

                    {
                      (item.data.lastplaces && !item.data.soldout) && (
                        <p className="
                          text-red-1 font-medium text-p4 w-full mt-2.5
                          lg:text-p3 text-center lg:absolute lg:-bottom-6 lg:m-0"
                        >
                          {getDefaultText('lastplaceLabel', primaryMessages, defaultMessages)}
                        </p>
                      )
                    }
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default GridListsProducts;
