import { FC } from 'react';
import { nanoid } from 'nanoid';
import Image from 'next/image';
import { Link, RichText } from 'prismic-reactjs';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import { getColor } from '../../../utils/colors';
import { prismicLinkResolver } from '../../../utils/common';
import { GridListsCTAProps } from '../GridLists.types';

const LinkWithQuery = dynamic(() => import('../../../components/LinkWithQuery'), {
  ssr: false,
});
const HighlightText = dynamic(() => import('../../../components/HighlightText'), {
  ssr: false,
});

const GridListsCTA: FC<GridListsCTAProps> = ({ slice }) => {
  if (!slice.items?.length) return null;

  const hasButton = (item): boolean => !!(item.buttonLabel && item.buttonLink);

  return (
    <section className="bg-gray-1">
      <div className="dh-content-full text-white-1">
        <header className="text-center text-2xl pt-16 mb-20 lg:pt-0 lg:text-4.5xl lg:mb-24 2xl:text-5xl 2xl:mb-28">
          <h2>
            <HighlightText richtext={slice.primary?.title} highlight={slice.primary?.highlightedText} isUnderline />
          </h2>
        </header>
        <div className="grid gap-x-4 gap-y-3 lg:gap-y-10 lg:grid-cols-3 lg:w-full 2xl:w-9/12 2xl:mx-auto">
          {slice.items.map((item) => {
            const color = getColor(item.color);
            return (
              <div
                key={nanoid()}
                className={classNames(
                  'flex flex-col w-full rounded bg-gray-4 pl-2 pr-4 py-8 gap-x-2 gap-y-6',
                  'lg:p-6 lg:pt-0 lg:gap-8 xl:p-12 xl:pt-0 3xl:gap-12 3xl:pt-0',
                )}
              >
                <div className="flex flex-row gap-2 lg:flex-col">
                  {item.image?.url && (
                  <div className="flex-none w-28 h-24 lg:w-60 lg:h-52 lg:-mt-5 lg:mb-3 lg:mx-auto lg:text-center">
                    <Image
                      src={item.image.url}
                      alt={item.image.alt}
                      width={item.image.dimensions.width || 240}
                      height={item.image.dimensions.height || 208}
                      layout="intrinsic"
                      objectFit="contain"
                      objectPosition="center"
                      className="object-contain mx-auto"
                    />
                  </div>
                  )}
                  <div
                    className={classNames(
                      'flex grow text-left items-center text-sm',
                      'lg:block lg:flex-none lg:pt-4 lg:items-start lg:text-center lg:text-xl lg:leading-8',
                      '3xl:text-2xl 3xl:leading-9',
                    )}
                  >
                    <RichText render={item.title} />
                  </div>
                </div>
                <div className="flex grow items-end justify-center">
                  {hasButton(item) && (
                    <LinkWithQuery
                      key={nanoid()}
                      href={Link.url(item.buttonLink, prismicLinkResolver)}
                      target={item.buttonLink?.target ?? '_self'}
                      className={classNames(
                        'dh-btn font-semibold text-sm mx-6 lg:text-lg',
                        color.bg,
                        color.textContrast,
                      )}
                    >
                      {item.buttonLabel}
                    </LinkWithQuery>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default GridListsCTA;
