import { Link, RichText } from 'prismic-reactjs';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import { prismicLinkResolver, scrollTo } from '../../../utils/common';
import { hasLink } from '../../../utils/prismic';

const LinkWithQuery = dynamic(() => import('../../../components/LinkWithQuery'), {
  ssr: false,
});

const HeroCardBackgroundImage = ({ slice }) => {
  const renderButton = (label, link, scrollToText, className) => (hasLink(link)
    ? (
      <LinkWithQuery
        href={Link.url(link, prismicLinkResolver)}
        className={className}
      >
        {label}
      </LinkWithQuery>
    ) : (
      <button
        type="button"
        onClick={() => scrollTo({ id: scrollToText })}
        className={className}
      >
        {label}
      </button>
    )
  );

  return (
    <div className="relative bg-black-2">
      <div className="relative sm:overflow-hidden">
        <div className="absolute inset-0">
          <Image
            className="h-full w-full object-cover"
            src={slice.primary.image.url}
            alt={slice.primary.image.alt}
            layout="fill"
            objectFit="cover"
            priority
          />

          <div className="absolute inset-0 bg-black/40 mix-blend-multiply" />
        </div>
        <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
          <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
            <span className="block text-white">
              {RichText.asText(slice.primary.titleLine1)}
            </span>
            {slice.primary.titleLine2 && (
            <span className="block text-white">
              {RichText.asText(slice.primary.titleLine2)}
            </span>
            )}
          </h1>
          <p className="mt-6 mx-auto text-center text-xl text-white sm:max-w-3xl font-archivo">
            {RichText.asText(slice.primary.description)}
          </p>
          {(slice.primary.buttonLabel
            || slice.primary.secondaryButtonLabel) && (
              <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
                {
                  slice.primary.secondaryButtonLabel
                  && renderButton(
                    slice.primary.buttonLabel,
                    slice.primary.buttonLink,
                    slice.primary.buttonScrollTo,
                    'dh-button-lg',
                  )
                }
                {
                  slice.primary.secondaryButtonLabel
                  && renderButton(
                    slice.primary.secondaryButtonLabel,
                    slice.primary.secondaryButtonLink,
                    slice.primary.secondaryButtonScrollTo,
                    'dh-button-lg text-primary-700 bg-white hover:bg-primary-50 sm:px-8 sm:mt-0 sm:ml-3 mt-3 sm:mt-0',
                  )
                }
              </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeroCardBackgroundImage;
