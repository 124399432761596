import classNames from 'classnames';
import { Link, RichText } from 'prismic-reactjs';
import { FC, useState } from 'react';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import { getColor } from '../../../utils/colors';
import { prismicLinkResolver } from '../../../utils/common';
import { hasRichText } from '../../../utils/prismic';
import { BannersCountdownProps } from '../Banners.types';

const LinkWithQuery = dynamic(() => import('../../../components/LinkWithQuery'), {
  ssr: false,
});
const Countdown = dynamic(() => import('../../../components/Countdown'), {
  ssr: false,
});
const BannersCountdown: FC<BannersCountdownProps> = ({ slice }) => {
  const hasTitle = hasRichText(slice.primary.title);
  const hasRightContent = !!(slice.primary.countdown || slice.primary.buttonText);
  const countDownDate = new Date(slice.primary.countdown || '').getTime();
  const isFutureDate = (countDownDate - new Date().getTime()) > 0;
  const [showBanner, setShowBanner] = useState(hasTitle);
  const hasImage = !!slice.primary.countdownBackgroundImage?.url;

  const countdownClasses = {
    numbers: 'text-h3',
    titles: 'text-p3',
  };

  const color = getColor(slice.primary.countdownBackgroundColor);
  const backgroundColor = slice.primary.backgroundColor === 'black' ? 'bg-black-2' : 'bg-white-2';
  const textColor = slice.primary.colorText === 'black' ? 'text-gray-1' : 'text-white-1';
  const buttonColor = slice.primary.colorText === 'black'
    ? 'bg-gray-1 text-white-1'
    : 'bg-white-1 text-gray-1';
  const backgroundCountdown = hasImage ? 'bg-black' : color.bg;

  if (!isFutureDate) {
    return null;
  }

  return showBanner ? (
    <section className={classNames('px-4 py-16 lg:px-15 3xl:py-20 3xl:px-20', backgroundColor)}>
      <div
        className={classNames(
          'py-8 px-4 m-auto flex flex-col rounded items-center justify-evenly w-full',
          'lg:justify-between lg:py-[42px] lg:px-10 relative overflow-hidden',
          { 'border border-gray-3': hasImage },
          backgroundCountdown,
          textColor,
          { 'lg:flex-row': hasRightContent },
        )}
      >
        <div className={classNames(
          'lg:pt-2.5 lg:pr-8',
          { 'lg:text-center': !hasRightContent },
        )}
        >
          {slice.primary?.countdownBackgroundImage?.url && (
          <div className="w-full top-0 left-0 border">
            <div className="w-full">
              <Image
                className="object-cover w-full"
                src={slice.primary.countdownBackgroundImage.url}
                alt={slice.primary.countdownBackgroundImage.alt}
                layout="fill"
                objectFit="cover"
                objectPosition="center center"
                priority
              />
            </div>
            <div className="w-full h-screen absolute top-0 left-0 z-0 bg-black-1/80" />
          </div>
          )}
          <div className="relative font-normal leading-[3rem] text-4xl lg:text-h2">
            <RichText render={slice.primary.title} />
          </div>
          <div className="relative text-h7 pt-3 leading-6 pr-1 ">
            <RichText render={slice.primary.description} />
          </div>
        </div>
        <div className="relative flex flex-col w-full pt-6 items-center justify-evenly gap-2.5 lg:pt-0  lg:w-4/12 ">
          <div className="hidden lg:flex text-h8">
            {slice.primary.countdownText}
          </div>
          {slice.primary.countdown
          && (
          // REVISAR
          <div className="pb-8 px-5 lg:pb-4 lg:pt-0">
            <Countdown
              finalDate={slice.primary.countdown}
              onFinish={() => setShowBanner(false)}
              classes={countdownClasses}
            />
          </div>
          )}
          { (slice.primary.buttonText && slice.primary.buttonLink) && (
          <LinkWithQuery
            href={Link.url(slice.primary.buttonLink, prismicLinkResolver)}
            target={slice.primary.buttonLink?.target ?? '_blank'}
            className={classNames(
              buttonColor,
              'dh-btn text-sm font-bold w-full rounded-lg py-3 px-6 lg:w-80',
            )}
          >
            {slice.primary.buttonText}
          </LinkWithQuery>
          )}
        </div>
      </div>
    </section>
  ) : null;
};

export default BannersCountdown;
