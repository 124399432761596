import { Link, RichTextBlock } from 'prismic-reactjs';
import { Image } from '../../types/prismic.type';

export enum BannersVariations {
  countdown = 'bannersCountdown',
  heading = 'bannersHeading'
}

export type BannersCountdownProps = {
  slice: {
    primary: {
      buttonLink?: Link;
      buttonText?: string;
      countdown?: string;
      countdownText?: string;
      colorText?: string;
      countdownBackgroundColor?: string;
      backgroundColor?: string;
      description?: RichTextBlock[];
      title?: RichTextBlock[];
      countdownBackgroundImage?: Image;
    };
  }
}

export type BannersHeadingProps = {
  slice: {
    primary: {
      buttonLink?: Link;
      buttonText?: string;
      countdown?: string;
      colorText?: string;
      colorBackground?: string;
      description?: RichTextBlock[];
    };
  }
}
