import { RichText } from 'prismic-reactjs';
import { nanoid } from 'nanoid';
import {
  FC, useContext, useEffect, useState,
} from 'react';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import { BenefitActionTypes } from '../../../contexts/reducers/benefit.reducer';
import { scrollTo, slugify } from '../../../utils/common';
import { AppContext } from '../../../contexts/AppContext';
import { GridListsTwoColumnsWithImageProps } from '../GridLists.types';
import { ModalSize } from '../../../components/Modal';

const Modal = dynamic(() => import('../../../components/Modal'), {
  ssr: false,
});

const GridListsTwoColumnsWithImage: FC<GridListsTwoColumnsWithImageProps> = ({ slice }) => {
  const [open, setOpen] = useState<number | boolean>(false);
  const { dispatch } = useContext(AppContext);
  const hasApplyButton = !!(slice.primary?.applyButtonLabel && slice.primary?.applyFragmentIdentifier);
  const hasTyCButton = (item): boolean => !!(item.termsAndConditionsLabel && item.termsAndConditionsText);

  const selectBenefit = (benefit: object, id = ''): void => {
    dispatch({ type: BenefitActionTypes.select, item: benefit });
    scrollTo({ id: slugify(id) });
  };

  useEffect(() => () => dispatch({
    type: BenefitActionTypes.select,
    item: null,
  }), [dispatch]);

  useEffect(() => {
    if (hasApplyButton && slice.items) dispatch({ type: BenefitActionTypes.add, items: slice.items });
  }, [hasApplyButton, slice.items, dispatch]);

  return slice?.items?.length ? (
    <div className="bg-gray-1">
      <div className="dh-content grid lg:grid-cols-3 gap-5">
        {slice.items.map((item, key) => (
          <div
            key={nanoid()}
            className="flex flex-row p-5 gap-5 rounded-lg bg-white"
          >
            <div className="flex flex-col flex-initial w-1/2 xl:w-2/5">
              {item.image?.url && (
                <div className="mb-3 max-w-[6.25rem]">
                  <Image
                    src={item.image.url}
                    alt={item.image.alt}
                    width={item.image.dimensions.width}
                    height={item.image.dimensions.height}
                    layout="responsive"
                    objectFit="cover"
                  />
                </div>
              )}
              <div className="font-light text-5xl break-words">
                {item.title}
              </div>
              <div className="text-sm break-words">{item.subtitle}</div>
            </div>
            <div className="flex flex-col grow text-xs w-1/2 xl:w-3/5">
              {!!item.description?.length && RichText.render(item.description)}

              {hasTyCButton(item) && (
                <>
                  <button
                    type="button"
                    className="pt-3 underline text-left"
                    onClick={() => setOpen(key)}
                  >
                    {item.termsAndConditionsLabel}
                  </button>
                  <Modal state={open === key} setOpen={setOpen} size={ModalSize.md}>
                    <h3 className="text-2xl font-bold text-black mb-5">
                      {item.termsAndConditionsLabel}
                    </h3>
                    <div className="text-sm my-richtext-6">
                      {!!item.termsAndConditionsText?.length && RichText.render(item.termsAndConditionsText)}
                    </div>
                  </Modal>
                </>
              )}

              {hasApplyButton && (
                <button
                  type="button"
                  className="dh-button-sm h-8 w-36 mt-8 self-end max-w-full"
                  onClick={() => selectBenefit(item, slice.primary?.applyFragmentIdentifier)}
                >
                    {slice.primary?.applyButtonLabel}
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : null;
};

export default GridListsTwoColumnsWithImage;
