import { RichText } from 'prismic-reactjs';
import { nanoid } from 'nanoid';
import classNames from 'classnames';
import { FunctionComponent } from 'react';
import dynamic from 'next/dynamic';
import { FeatureSimpleListProps } from '../Feature.types';

const Icon = dynamic(() => import('../../../components/Icon'), {
  ssr: false,
});

const FeatureSimpleList: FunctionComponent<FeatureSimpleListProps> = ({ slice }) => (
  slice.items?.length ? (
    <section className="bg-gray-2">
      <div className="dh-content">
        {slice.primary?.title && (
        <header className="text-center">
          <h2 className="font-semibold text-3.5xl text-white mb-8">{slice.primary.title}</h2>
        </header>
        )}
        <ul className="bg-gray-3 rounded-2xl p-8 max-w-[48rem] m-auto space-y-6">
          {slice.items?.map(
            ({ title, description }) => (
              <li key={nanoid()} className="flex gap-6 items-start">
                <div className={classNames(
                  'relative before:w-6 before:h-6 before:top-1 before:left-1',
                  'before:rounded-full before:bg-white before:block before:absolute',
                )}
                >
                  <Icon icon="check-circle-solid" className="w-8 flex-none text-primary row-span-2 relative z-10" />
                </div>
                <div>
                  <h3 className="font-semibold text-xl leading-8 text-athensGray mb-1">{title}</h3>
                  <div className="font-archivo text-sm leading-6 text-white-2">
                    <RichText render={description} />
                  </div>
                </div>
              </li>
            ),
          )}
        </ul>
      </div>
    </section>
  ) : null);

export default FeatureSimpleList;
