import { BannersVariations } from './Banners.types';
import BannersCountdown from './variations/BannersCountdown';
import BannersHeading from './variations/BannersHeading';

const Banners = ({ slice }) => {
  switch (slice.variation) {
    case BannersVariations.countdown:
      return <BannersCountdown slice={slice} />;
    case BannersVariations.heading:
      return <BannersHeading slice={slice} />;
    default:
      return null;
  }
};

export default Banners;
